import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Text as PaperText } from 'react-native-paper';
import SelectDepartment from './components/SelectDepartment';
import useTranslation from '../../hooks/useTranslation';

const DepartmentsScreen = () => {
  const { screen } = useTranslation();
  return (
    <View style={styles.container}>
      <PaperText style={styles.title}>
        {screen.departments.title}
      </PaperText>
      <SelectDepartment />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 20,
    paddingBottom: 20,
    paddingTop: 50,
  },
  title: {
    fontSize: 26,
    textAlign: 'center',
    marginBottom: 30,
  },
});

export default DepartmentsScreen;
