import React from 'react';
import {
  View, StyleSheet, ViewStyle, useWindowDimensions,
} from 'react-native';

type SlideProps = {
  children: React.ReactNode;
  style?: ViewStyle | ViewStyle[];
};

const Slide: React.FC<SlideProps> = ({ children, style }) => {
  const { width } = useWindowDimensions();
  return (
    <View style={[styles.slide, { width }, style]}>
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  slide: {
    flex: 1,
    paddingTop: 70,
    alignItems: 'center',
    borderColor: 'lightgrey',
    backgroundColor: 'white',
    gap: 20,
    borderRadius: 25,
    maxWidth: 1100,
    marginHorizontal: 'auto',
  },
});

export default Slide;
