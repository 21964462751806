import type { Department, DepartmentQuery, EstimationsByDepartmentIdQuery } from '@types';
import React, { useContext, useEffect, useState } from 'react';
import {
  View, Text, StyleSheet, ScrollView, Platform,
} from 'react-native';
import { useQuery } from '@apollo/client';
import { departmentQuery, estimationsByDepartmentIdQuery } from '../../../queries';
import UserSettingsContext from '../../../contexts/UserSettingsContext';
import SubDepartment from './SubDepartment';
import Box from '../../../components/Box';
import CostDisplay from '../../../components/CostDisplay';
import DepartmentManagers from './DepartmentManagers';
import SaveFileAsXLSX from '../../DepartmentScreen/components/SaveFileAsXLSX';

const SelectDepartment = () => {
  const { currentDepartment } = useContext(UserSettingsContext);

  const isApp = Platform.OS !== 'web';

  const { data: departmentData } = useQuery<DepartmentQuery>(departmentQuery, {
    variables: {
      departmentId: currentDepartment,
    },
  });

  const { data: evaluationsData } = useQuery<EstimationsByDepartmentIdQuery>(estimationsByDepartmentIdQuery, {
    variables: {
      departmentId: currentDepartment,
    },
  });

  const [department, setDepartment] = useState<Department>();

  useEffect(() => {
    if (departmentData?.department?.departments) {
      setDepartment(departmentData?.department as Department);
    }
  }, [departmentData]);

  return (
    <View style={[styles.container]}>
      <ScrollView>
        <Box style={styles.box}>
          <View style={styles.departmentInfo}>
            <View style={styles.borderBottom}>
              <Text style={styles.departmentText}>{`${department?.name} (${evaluationsData?.estimationsByDepartmentId.amountOfTotalCoworkers})`}</Text>
              <DepartmentManagers managers={evaluationsData?.estimationsByDepartmentId.departmentManagers} />
            </View>
            <View style={styles.statsContainer}>
              <View style={styles.statsCenterContainer}>
                <View style={[styles.statusContainer, { backgroundColor: '#6CC24A' }]}>
                  <Text style={styles.statusText}>{evaluationsData?.estimationsByDepartmentId?.amountOfGreen ?? 0}</Text>
                </View>
                <View style={[styles.statusContainer, { backgroundColor: '#FAC003' }]}>
                  <Text style={styles.statusText}>{evaluationsData?.estimationsByDepartmentId?.amountOfYellow ?? 0}</Text>
                </View>
                <View style={[styles.statusContainer, { backgroundColor: '#E87722' }]}>
                  <Text style={styles.statusText}>{evaluationsData?.estimationsByDepartmentId?.amountOfOrange ?? 0}</Text>
                </View>
              </View>
              <CostDisplay isPerMonth cost={Math.round(evaluationsData?.estimationsByDepartmentId?.calculatedCost ?? 0)} />
            </View>
          </View>
          {department?.departments?.map((subDepartment) => (
            <SubDepartment
              key={subDepartment._id}
              index={1}
              subDepartment={subDepartment}
            />
          ))}
        </Box>
      </ScrollView>
      {isApp && (
        <View style={styles.xlsxMobileExport}>
          <SaveFileAsXLSX departmentName={department?.name} xlsxType='Departments' />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  box: {
    padding: 20,
    gap: 10,
  },
  departmentInfo: {
    flex: 1,
    justifyContent: 'center',
    gap: 3,
  },
  departmentText: {
    fontSize: 16,
    fontWeight: '500',
  },
  borderBottom: {
    borderBottomWidth: 1,
    borderColor: '#EDEAE6',
    paddingBottom: 10,
  },
  statsContainer: {
    flexDirection: 'row',
    gap: 6,
    paddingTop: 3,
  },
  statsCenterContainer: {
    flexDirection: 'row',
    paddingRight: 6,
    gap: 6,
  },
  statusContainer: {
    height: 20,
    minWidth: 20,
    paddingHorizontal: 3,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 6,
    backgroundColor: '#6CC24A',
  },
  statusText: {
    color: '#fff',
    fontWeight: 'bold',
  },
  xlsxMobileExport: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingVertical: 20,
    marginLeft: 'auto',
  },
});

export default SelectDepartment;
