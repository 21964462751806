import React, { useContext } from 'react';
import type { CalculatedUserEstimation, EstimationsByUserIdQuery } from '@types';
import { Text, StyleSheet } from 'react-native';
import { useQuery } from '@apollo/client';
import { estimationsByUserIdQuery } from '../../queries';
import UserSettingsContext from '../../contexts/UserSettingsContext';
import StatusIcon from '../../components/StatusIcon';
import ListItem from '../../components/ListItem';

type StatusGreyCoworkerProps = {
  coworker: CalculatedUserEstimation;
};

const StatusGreyCoworker: React.FC<StatusGreyCoworkerProps> = ({ coworker }) => {
  const { currentDepartment } = useContext(UserSettingsContext);
  const { data: evaluationsData } = useQuery<EstimationsByUserIdQuery>(estimationsByUserIdQuery, {
    variables: {
      userId: coworker.userId,
      departmentId: currentDepartment,
    },
  });

  const skippedEvaluations = evaluationsData?.estimationsByUserId?.estimations?.filter((estimation) => estimation.status === 0) ?? [];
  const latestSkippedEvaluation = skippedEvaluations[skippedEvaluations.length - 1];
  return (
    <ListItem key={coworker.userId} style={styles.listItem}>
      <Text>
        {coworker.userFirstName}
        {' '}
        {coworker.userLastName}
      </Text>
      <StatusIcon
        isWithText
        status={0}
        skippedStatus={latestSkippedEvaluation?.activelySkippedStatusBasis ? latestSkippedEvaluation.activelySkippedStatusBasis[0] : undefined}
        height={40}
        width={40}
      />
    </ListItem>
  );
};

const styles = StyleSheet.create({
  listItem: {
    borderRadius: 10,
    paddingVertical: 10,
  },
});

export default StatusGreyCoworker;
