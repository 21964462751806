import { NavigationProp, useNavigation } from '@react-navigation/native';
import { NativeStackHeaderProps } from '@react-navigation/native-stack';
import type { DepartmentQuery, RootStackParamList } from '@types';
import React, { useContext, useState } from 'react';
import {
  View, Text, StyleSheet, Platform, Pressable, useWindowDimensions,
} from 'react-native';
import { TouchableRipple, useTheme } from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Ionicons from '@expo/vector-icons/Ionicons';
import Entypo from '@expo/vector-icons/Entypo';
import { ScrollView, TouchableOpacity } from 'react-native-gesture-handler';
import { useQuery } from '@apollo/client';
import { departmentQuery } from '../queries';
import UserSettingsContext from '../contexts/UserSettingsContext';
import useTranslation from '../hooks/useTranslation';
import SlideOver from '../components/SlideOver';
import ActInfo from '../screens/ActScreen/components/ActInfo';
import ActContact from '../screens/ActScreen/components/ActContact';
import useMediaQueries from '../hooks/useMediaQueries';
import SlideOverContext from '../contexts/SlideOverContext';
import AddCoworkerScreen from '../screens/AddCoworkerScreen/AddCoworkerScreen';
import CoworkerWeb from '../screens/CoworkerScreen/components/CoworkerWeb';
import Modal from '../components/Modal';
import SelectDepartment from '../screens/DepartmentsScreen/components/SelectDepartment';
import SelectRole from '../screens/SuperAdmin/components/SelectRole';
import SaveFileAsXLSX from '../screens/DepartmentScreen/components/SaveFileAsXLSX.web';

const CustomHeader: React.FC<NativeStackHeaderProps> = ({ navigation, options }) => {
  const theme = useTheme();
  const { height } = useWindowDimensions();
  const { isDesktop, isDesktopWide } = useMediaQueries();
  const { screen } = useTranslation();
  const { slideOver, setSlideOver } = useContext(SlideOverContext);
  const { currentDepartment, isWebRoleSelectionOpen, setIsWebRoleSelectionOpen } = useContext(UserSettingsContext);
  const { navigate } = useNavigation<NavigationProp<RootStackParamList>>();

  const [isDepartmentSelectionOpen, setIsDepartmentSelectionOpen] = useState(false);

  const { data: departmentData } = useQuery<DepartmentQuery>(departmentQuery, {
    variables: {
      departmentId: currentDepartment,
    },
  });

  const insets = useSafeAreaInsets();
  const isWeb = Platform.OS === 'web';

  const handleTimeToAct = () => {
    if (isWeb) {
      setSlideOver('act');
    } else {
      navigate('act');
    }
  };

  const navigateToDepartmentSelection = () => {
    if (departmentData?.department.departments?.length === 0) return;

    if (isWeb) {
      setIsDepartmentSelectionOpen(true);
    } else {
      navigate('departments');
    }
  };

  const handleOpenDrawer = () => {
    navigation.openDrawer();
  };

  const closeSlideOver = () => {
    setSlideOver(undefined);
  };

  return (
    <>
      <View style={[styles.header, !isWeb && { height: insets.top + 70 }]}>
        <View style={[styles.headerFlex, isDesktopWide && styles.headerFlexDesktop]}>
          <View style={[styles.headerItem, { backgroundColor: theme.colors.background }]}>
            <Pressable onPress={handleOpenDrawer}>
              <TouchableOpacity activeOpacity={0.7}>
                <Ionicons
                  backgroundColor='transparent'
                  underlayColor='transparent'
                  activeOpacity={0.7}
                  name='menu-outline'
                  size={34}
                  color='#000'
                  style={{
                    marginRight: -2,
                  }}
                />
              </TouchableOpacity>
            </Pressable>
          </View>
          <TouchableRipple
            onPress={navigateToDepartmentSelection}
            style={{ borderRadius: 6, marginRight: isWeb ? 'auto' : undefined }}
            disabled={departmentData?.department.departments?.length === 0}
          >
            <View style={[styles.headerCenter, { alignItems: isWeb ? 'flex-start' : 'center' }]}>
              <View style={styles.headerCenterRow}>
                <Text style={styles.title}>{screen.departments.title}</Text>
                {departmentData?.department.departments && departmentData?.department.departments?.length > 0 && <Entypo name='chevron-thin-down' size={14} />}
              </View>
              <Text style={styles.sub}>{departmentData?.department.name}</Text>
            </View>
          </TouchableRipple>
          <Pressable onPress={handleTimeToAct}>
            <TouchableOpacity style={styles.headerItem} activeOpacity={0.7}>
              <Ionicons
                backgroundColor='transparent'
                underlayColor='transparent'
                activeOpacity={0.7}
                name='notifications-sharp'
                size={28}
                color={theme.colors.primary}
              />
              <Text style={{ fontWeight: '500', fontSize: 12 }}>{screen.act.title}</Text>
            </TouchableOpacity>
          </Pressable>
        </View>
      </View>

      {/* The slideover components for web is placed here because it need to cover the whole screen and position:fixed is not available in react-native. */}
      {isWeb && (
        <>
          <SlideOver isFromRight isOpen={slideOver === 'act'} close={closeSlideOver} style={{ height }} closeButtonStyle={{ backgroundColor: '#fff' }}>
            <ScrollView>
              <View style={{ flexDirection: isDesktop ? 'row' : 'column' }}>
                <ActInfo />
                <ActContact />
              </View>
            </ScrollView>
          </SlideOver>

          <SlideOver
            isOpen={slideOver === 'addCoworker'}
            close={closeSlideOver}
            maxWidth={600}
            isFromRight
            style={{ height }}
          >
            <AddCoworkerScreen />
          </SlideOver>

          <SlideOver
            isOpen={slideOver === 'editCoworker'}
            close={closeSlideOver}
            maxWidth={600}
            isFromRight
            style={{ height }}
          >
            <CoworkerWeb />
          </SlideOver>
          <Modal
            isVisible={isWebRoleSelectionOpen}
            setIsVisible={setIsWebRoleSelectionOpen}
            title={screen.roleSelection.title}
            text={screen.roleSelection.youAreLoggedInAs}
            isClickOutsideAllowed
            content={<SelectRole />}
          />
          <Modal
            title={screen.departments.title}
            isVisible={isDepartmentSelectionOpen}
            setIsVisible={setIsDepartmentSelectionOpen}
            isClickOutsideAllowed
            content={(<SelectDepartment />)}
            bottomContent={(
              <View style={styles.xlsxWebExport}>
                <SaveFileAsXLSX xlsxType='Departments' departmentName={departmentData?.department?.name} />
              </View>
            )}
          />
        </>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  header: {
    backgroundColor: '#fff',
    paddingTop: 10,
    paddingHorizontal: 20,
    paddingBottom: 10,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  headerFlex: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    maxWidth: 1280,
    gap: 20,
  },
  headerFlexDesktop: {
    paddingHorizontal: 16,
  },
  headerCenter: {
    justifyContent: 'center',
    paddingVertical: 7,
    paddingHorizontal: 6,
    gap: 2,
  },
  headerCenterRow: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
  },
  headerItem: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 50,
    width: 50,
    borderRadius: 25,
  },
  title: {
    fontWeight: '500',
    fontSize: 16,
  },
  sub: {
    color: '#707070',
    fontSize: 12,
    textTransform: 'uppercase',
  },
  xlsxWebExport: {
    paddingTop: 10,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
});

export default CustomHeader;
