import React, { useContext, useEffect } from 'react';
import { Platform, useWindowDimensions } from 'react-native';
import { createStackNavigator, TransitionPresets } from '@react-navigation/stack';
import { DrawerContentComponentProps, createDrawerNavigator } from '@react-navigation/drawer';
import type {
  MyRolesQuery, RootStackParamList, UserRoles,
} from '@types';
import { useQuery } from '@apollo/client';
import UserSettingsContext, { UserSettingsProvider } from '../contexts/UserSettingsContext';
import { CoworkerProvider } from '../contexts/CoworkerContext';
import CompaniesScreen from '../screens/SuperAdmin/CompaniesScreen';
import CompanyScreen from '../screens/SuperAdmin/CompanyScreen';
import { EvaluationProvider } from '../contexts/EvaluationContext';
import LoadingScreen from '../screens/LoadingScreen/LoadingScreen';
import LogoutScreen from '../screens/LogoutScreen/LogoutScreen';
import AuthContext from '../contexts/AuthContext';
import DepartmentScreen from '../screens/DepartmentScreen/DepartmentScreen';
import LoginScreen from '../screens/LoginScreen/LoginScreen';
import RoleSelectionScreen from '../screens/RoleSelectionScreen/RoleSelectionScreen';
import AddCoworkerScreen from '../screens/AddCoworkerScreen/AddCoworkerScreen';
import ContactScreen from '../screens/ContactScreen/ContactScreen';
import EvaluationScreen from '../screens/EvaluationScreen/EvaluationScreen';
import ActScreen from '../screens/ActScreen/ActScreen';
import DepartmentsScreen from '../screens/DepartmentsScreen/DepartmentsScreen';
import DrawerMenu from './DrawerMenu/DrawerMenu';
import AboutScreen from '../screens/AboutScreen/AboutScreen';
import useTranslation from '../hooks/useTranslation';
import CoworkerScreen from '../screens/CoworkerScreen/CoworkerScreen';
import CustomHeader from './CustomHeader';
import CheatSheetScreen from '../screens/CheatSheetScreen/CheatSheetScreen';
import CustomModalHeader from './CustomModalHeader';
import EvaluationCoworkersScreen from '../screens/EvaluationCoworkersScreen/EvaluationCoworkersScreen';
import BankIdPollingScreen from '../screens/BankIdPollingScreen/BankIdPollingScreen';
import TermsAndConditionsScreen from '../screens/TermsAndConditions/TermsAndConditionsScreen';
import OnboardingScreen from '../screens/OnboardingScreen/OnboardingScreen';
import AdminCustomHeader from './AdminCustomHeader';
import { myRolesQuery } from '../queries';
import StatusYellowScreen from '../screens/Status/StatusYellowScreen';
import StatusGreenScreen from '../screens/Status/StatusGreenScreen';
import StatusOrangeScreen from '../screens/Status/StatusOrangeScreen';
import StatusGreyScreen from '../screens/Status/StatusGreyScreen';
import PickRoute from './PickRoute';
import CostInfoScreen from '../screens/CostInfoScreen/CostInfoScreen';
import AboutArendraModelScreen from '../screens/AboutArendraModelScreen/AboutArendraModelScreen';
import SettingsScreen from '../screens/SettingsScreen/SettingsScreen';
import useNotifications from '../hooks/useNotifications';
import UserTermsScreen from '../screens/UserTermsScreen/UserTermsScreen';
import IntegrityPolicyScreen from '../screens/IntegritypolicyScreen/IntegrityPolicyScreen';

const Stack = createStackNavigator<RootStackParamList>();

export const LoggedOutNavigation = () => {
  const initialRouteName = 'login';
  const isWeb = Platform.OS === 'web';
  const transitions = Platform.OS === 'web' ? undefined : TransitionPresets.ModalPresentationIOS;

  return (
    <Stack.Navigator
      screenOptions={{ headerShown: false }}
      initialRouteName={initialRouteName}
    >
      <Stack.Screen name='login' component={LoginScreen} />
      <Stack.Screen name='polling' component={BankIdPollingScreen} />
      <Stack.Screen name='terms' component={TermsAndConditionsScreen} />
      <Stack.Screen
        name='userTerms'
        component={UserTermsScreen}
        options={{
          presentation: 'modal',
          gestureEnabled: true,
          ...transitions,
          headerShown: true,
          header: !isWeb ? CustomModalHeader : undefined,
        }}
      />
    </Stack.Navigator>
  );
};

const Drawer = createDrawerNavigator();

const CustomDrawerContent = (props: DrawerContentComponentProps) => (
  <DrawerMenu {...props} />
);

export const DrawerNavigation: React.FC = () => {
  const { width } = useWindowDimensions();

  /** THIS HAS TO BE CALLED HERE! When we call */
  useNotifications();

  return (
    <Drawer.Navigator
      initialRouteName='department'
      screenOptions={{
        headerShown: false,
        drawerStyle: {
          width: width * 0.85,
          maxWidth: 375,
          padding: 10,
        },
      }}
      drawerContent={CustomDrawerContent}
    >
      <Drawer.Screen name='user' component={LoggedInNavigation} />

    </Drawer.Navigator>
  );
};

export const LoggedInNavigation = ({ navigation }) => {
  const { screen } = useTranslation();
  const { data: myRolesData, loading: myRolesLoading } = useQuery<MyRolesQuery>(myRolesQuery);
  const { setUserRoles, setCurrentDepartment } = useContext(UserSettingsContext);

  /** If we are logged in but we have not yet seen the tutorial it will be displayed for us. */

  const isWeb = Platform.OS === 'web';

  useEffect(() => {
    if (myRolesData?.myRoles) {
      setUserRoles(myRolesData.myRoles as UserRoles);
    }
  }, [myRolesData, setUserRoles]);

  if (myRolesLoading) {
    return <LoadingScreen />;
  }

  const customHeader = (props) => <CustomHeader {...props} />;
  const adminCustomHeader = (props) => <AdminCustomHeader {...props} />;

  const isSuperAdmin = myRolesData?.myRoles?.isSuperAdmin;
  const initialRouteName = PickRoute(myRolesData?.myRoles);

  const departmentIdObject: { departmentId: string } | undefined = myRolesData
    && myRolesData.myRoles
    && myRolesData?.myRoles?.departmentsIManage
    && myRolesData?.myRoles?.departmentsIManage[0]
    ? { departmentId: myRolesData?.myRoles?.departmentsIManage[0]._id } : undefined;

  if (initialRouteName === 'department' && departmentIdObject) {
    setCurrentDepartment(departmentIdObject.departmentId);
  }

  const transitions = Platform.OS === 'web' ? undefined : TransitionPresets.ModalPresentationIOS;

  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: true,
        headerBackVisible: false,
        header: customHeader,
        headerShadowVisible: false,
      }}
      initialRouteName={initialRouteName}
    >

      {isSuperAdmin ? <Stack.Screen name='companies' options={{ title: 'Companies', header: adminCustomHeader }} component={CompaniesScreen} /> : null}
      {isSuperAdmin ? <Stack.Screen name='company' options={{ title: 'Company', header: adminCustomHeader }} component={CompanyScreen} /> : null}

      <Stack.Screen name='department' options={{ title: screen.department.title }} component={DepartmentScreen} initialParams={departmentIdObject} />
      <Stack.Screen name='loading' options={{ title: screen.editCoworker.title }} component={LoadingScreen} />
      <Stack.Screen name='evaluation' options={{ title: screen.evaluation.title, headerShown: false, animation: 'slide_from_bottom' }} component={EvaluationScreen} />
      <Stack.Screen name='onBoarding' options={{ title: screen.onBoarding.title, headerShown: false }} component={OnboardingScreen} />
      <Stack.Group screenOptions={{
        presentation: 'modal',
        gestureEnabled: true,
        ...transitions,
        headerShown: true,
        header: !isWeb ? CustomModalHeader : undefined,
      }}
      >
        <Stack.Screen name='act' options={{ title: screen.act.title }} component={ActScreen} />
        <Stack.Screen name='about' options={{ title: screen.about.title }} component={AboutScreen} />
        <Stack.Screen name='contact' options={{ title: screen.contact.title }} component={ContactScreen} />
        <Stack.Screen name='userTerms' options={{ title: screen.userTerms.title }} component={UserTermsScreen} />
        <Stack.Screen name='integrityPolicy' options={{ title: screen.integrityPolicy.title }} component={IntegrityPolicyScreen} />
        {/* <Stack.Screen name='roleSelection' options={{ title: screen.roleSelection.title, headerShown: false }} component={RoleSelectionScreen} /> */}
        <Stack.Screen name='roleSelection' options={{ title: screen.roleSelection.title }} component={RoleSelectionScreen} />
        <Stack.Screen name='addCoworker' options={{ title: screen.addCoworker.title }} component={AddCoworkerScreen} />
        <Stack.Screen name='coworker' options={{ title: screen.coworker.title }} component={CoworkerScreen} />
        <Stack.Screen name='cheatSheet' options={{ title: screen.cheatSheet.title }} component={CheatSheetScreen} />
        <Stack.Screen name='evaluationCoworkers' options={{ title: screen.evaluationCoworkers.title }} component={EvaluationCoworkersScreen} />
        <Stack.Screen name='departments' options={{ title: screen.evaluation.title }} component={DepartmentsScreen} />
        <Stack.Screen name='statusGrey' options={{ title: screen.evaluation.title }} component={StatusGreyScreen} />
        <Stack.Screen name='statusGreen' options={{ title: screen.evaluation.title }} component={StatusGreenScreen} />
        <Stack.Screen name='statusYellow' options={{ title: screen.evaluation.title }} component={StatusYellowScreen} />
        <Stack.Screen name='statusOrange' options={{ title: screen.evaluation.title }} component={StatusOrangeScreen} />
        <Stack.Screen name='costInfo' options={{ title: screen.costInfo.title }} component={CostInfoScreen} />
        <Stack.Screen name='aboutArendraModel' options={{ title: screen.aboutArendraModel.title }} component={AboutArendraModelScreen} />
        <Stack.Screen name='settings' options={{ title: screen.settings.title }} component={SettingsScreen} />
      </Stack.Group>

      <Stack.Screen name='logout' component={LogoutScreen} />
      {/* <Stack.Screen name='NotFound' component={NotFoundScreen} /> */}
    </Stack.Navigator>
  );
};

const StackNavigation = () => {
  const {
    isContextLoaded, isTokenValid,
  } = useContext(AuthContext);

  const _isTokenValid = isTokenValid();
  if (!isContextLoaded) return <LoadingScreen />;

  return (isContextLoaded && _isTokenValid)
    ? (
      <UserSettingsProvider>
        <CoworkerProvider>
          <EvaluationProvider>
            <DrawerNavigation />
          </EvaluationProvider>
        </CoworkerProvider>
      </UserSettingsProvider>
    )
    : <LoggedOutNavigation />;
};

const AppNavigator = () => <StackNavigation />;

export default AppNavigator;
