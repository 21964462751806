import React from 'react';
import {
  View, StyleSheet, Text, Platform,
} from 'react-native';
import { useTheme } from 'react-native-paper';
import type { EstimationsByUserIdQuery } from '@types';
import Box from '../../../components/Box';
import useTranslation from '../../../hooks/useTranslation';
import ArendraIcon from '../../../components/ArendraIcon';
import ListItem from '../../../components/ListItem';
import StatusIcon from '../../../components/StatusIcon';
import { getColorFromPercent } from '../../../utils';

type PercentageWithIconProps = {
  percentage: number;
};

const PercentageWithIcon: React.FC<PercentageWithIconProps> = ({ percentage }) => {
  const { colors } = useTheme();

  const defaultValue = 0; // was 100

  const correctColor = getColorFromPercent(percentage || defaultValue);
  const iconColor = correctColor === 'green' ? '#6CC24A'
    : correctColor === 'yellow' ? '#FAC003'
      : correctColor === 'orange' ? '#E87722'
        : 'gray';

  const iconName = correctColor === 'green' ? 'icon-fungerar-val'
    : correctColor === 'yellow' ? 'icon-brister-delvis'
      : correctColor === 'orange' ? 'icon-brister-patagligt'
        : 'icon-kan-ej-skatta';
  const progressBarWidth = `${percentage ?? defaultValue}%`;

  return (
    <>
      <View style={[styles.bgContainer, { backgroundColor: colors.background }]}>

        {percentage ? (
          <View style={[styles.progress, { width: progressBarWidth, backgroundColor: iconColor }]}>
            <Text>
              {Math.round(percentage || defaultValue)}
              {' '}
              %
            </Text>
          </View>
        ) : null}
      </View>
      <View style={[styles.iconContainer, { borderColor: colors.background }]}>
        <ArendraIcon name={iconName} size={60} />
      </View>

    </>
  );
};

type CoworkerSummaryProps = {
  evaluationsData: EstimationsByUserIdQuery | undefined;
};

const CoworkerSummary: React.FC<CoworkerSummaryProps> = ({ evaluationsData }) => {
  const { screen } = useTranslation();
  const { colors } = useTheme();
  const isWeb = Platform.OS === 'web';
  return (
    <View style={styles.paddingTop}>
      <View style={styles.summary}>
        <View style={styles.row}>
          <View style={{ flex: 1, alignSelf: 'flex-start' }}>
            <Text style={styles.smallTitle}>{screen.coworker.averageTitle}</Text>
            <View style={styles.progressRow}>
              <PercentageWithIcon percentage={evaluationsData?.estimationsByUserId?.calculatedPercentage} />
            </View>
          </View>
        </View>
        <View style={{ flex: 1 }}>
          <Text style={styles.smallTitle}>{screen.coworker.estimatedCost}</Text>
          <View style={[styles.bgContainer, { backgroundColor: colors.background, justifyContent: 'center', paddingHorizontal: 12 }]}>
            <Text style={{ fontSize: 20 }}>
              -
              {Math.round((evaluationsData?.estimationsByUserId?.calculatedCost ?? 0) * 100) / 100}
              {' '}
              {screen.coworker.SEK}
            </Text>
          </View>
        </View>

        <View style={{ flex: 1 }}>
          <Text style={styles.smallTitle}>{screen.coworker.evaluations}</Text>
          <View style={styles.row}>
            <View style={[styles.statusItem, { backgroundColor: colors.background }]}>
              <ArendraIcon name='icon-fungerar-val' />
              <Text style={{ fontSize: 16 }}>
                {evaluationsData?.estimationsByUserId?.estimations?.filter((e) => e.status === 1).length}
              </Text>
            </View>
            <View style={[styles.statusItem, { backgroundColor: colors.background }]}>
              <ArendraIcon name='icon-brister-delvis' />
              <Text style={{ fontSize: 16 }}>
                {evaluationsData?.estimationsByUserId?.estimations?.filter((e) => e.status === 2).length}
              </Text>
            </View>
            <View style={[styles.statusItem, { backgroundColor: colors.background }]}>
              <ArendraIcon name='icon-brister-patagligt' />
              <Text style={{ fontSize: 16 }}>
                {evaluationsData?.estimationsByUserId?.estimations?.filter((e) => e.status === 3).length}
              </Text>
            </View>
          </View>
        </View>
        <Box>
          {evaluationsData?.estimationsByUserId?.estimations?.map((evaluation, index) => evaluation.status !== null && (
            <ListItem
              key={evaluation._id}
              isBorderBottom={index !== 0}
              style={[{
                gap: 16,
                paddingVertical: 16,
                backgroundColor: colors.background,
              }, !isWeb ? { flex: 1 } : {}]}
            >
              <View style={{ width: 90 }}>
                <Text>{new Date(evaluation.date as string).toLocaleDateString('sv-SE')}</Text>
              </View>

              <View style={{ width: isWeb ? 200 : 175 }}>
                <StatusIcon
                  isWithText
                  status={evaluation.status}
                  skippedStatus={evaluation.activelySkippedStatusBasis ? evaluation.activelySkippedStatusBasis[0] : undefined}
                  statusBasis={evaluation.statusBasis ? evaluation.statusBasis[0] : undefined}
                  height={36}
                  width={36}
                  iconWidth={55}
                />
              </View>
            </ListItem>
          )).reverse()}
        </Box>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  paddingTop: {
    paddingTop: 20,
  },
  summary: {
    paddingVertical: 10,
    justifyContent: 'flex-start',
    gap: 30,
  },
  statusItem: {
    flex: 1,
    height: 50,
    minHeight: 50,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 10,
  },
  smallTitle: {
    fontSize: 12,
    textTransform: 'uppercase',
    marginBottom: 8,
    color: '#666',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
  },
  progressRow: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 16,
  },
  bgContainer: {
    flex: 1,
    minHeight: 50,
    borderRadius: 10,
  },
  progress: {
    minHeight: 50,
    alignItems: 'flex-end',
    justifyContent: 'center',
    paddingHorizontal: 16,
    borderRadius: 10,
  },
  iconContainer: {
    height: 50,
    aspectRatio: 1,
    borderRadius: 25,
    borderWidth: 2,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default CoworkerSummary;
