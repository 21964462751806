import React, { useContext } from 'react';
import {
  View, StyleSheet, Platform,
  ScrollView,
} from 'react-native';
import { Text as PaperText, useTheme } from 'react-native-paper';
import { useQuery } from '@apollo/client';
import type { CalculatedUserEstimation, EstimationsByDepartmentIdQuery } from '@types';
import UserSettingsContext from '../../contexts/UserSettingsContext';
import { estimationsByDepartmentIdQuery } from '../../queries';
import useTranslation from '../../hooks/useTranslation';
import StatusGreyCoworker from './StatusGreyCoworker';

type StatusGreyScreenProps = unknown;

const StatusGreyScreen: React.FC<StatusGreyScreenProps> = () => {
  const { colors } = useTheme();
  const { screen } = useTranslation();
  const isWeb = Platform.OS === 'web';

  const { currentDepartment } = useContext(UserSettingsContext);

  const { data: evaluationsData } = useQuery<EstimationsByDepartmentIdQuery>(estimationsByDepartmentIdQuery, {
    variables: {
      departmentId: currentDepartment,
    },
  });

  return (
    <View style={{ backgroundColor: colors.background, paddingVertical: isWeb ? 20 : 70 }}>
      <ScrollView style={{ paddingHorizontal: isWeb ? 0 : 20 }}>
        <View style={styles.centered}>
          <PaperText style={styles.title}>
            {evaluationsData?.estimationsByDepartmentId.coworkersWithoutStatus.length ?? 0}
            {' '}
            {screen.act.statusGrayTitle}
          </PaperText>
          <View style={{ gap: 10 }}>
            {evaluationsData?.estimationsByDepartmentId.coworkersWithoutStatus?.map((coworker) => (
              <StatusGreyCoworker key={coworker.userId} coworker={coworker as CalculatedUserEstimation} />
            ))}
          </View>
        </View>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  centered: {
    justifyContent: 'center',
    gap: 30,
    marginBottom: 30,
  },
  title: {
    fontSize: 26,
    textAlign: 'center',
  },
});

export default StatusGreyScreen;
