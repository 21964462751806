import type { DepartmentCoworker, EvaluateArgs } from '@types';
import React, { useEffect, useState } from 'react';
import {
  View, StyleSheet, Pressable, Text,
} from 'react-native';
import { useTheme, RadioButton, Button } from 'react-native-paper';
import Box from '../../../components/Box';
import ListItem from '../../../components/ListItem';
import useTranslation from '../../../hooks/useTranslation';

type ActivelySkippedProps = {
  localStatus?: number;
  evaluate: (args: EvaluateArgs) => void;
  coworker: DepartmentCoworker;
};

const ActivelySkipped: React.FC<ActivelySkippedProps> = ({ localStatus, evaluate, coworker }) => {
  const { colors } = useTheme();
  const { screen } = useTranslation();

  const [activelySkippedValue, setActivelySkippedValue] = useState<string>('');

  useEffect(() => {
    if (coworker.todaysEstimation?.activelySkippedStatusBasis?.length) {
      const basis = coworker.todaysEstimation.activelySkippedStatusBasis[0];
      if (basis.offDuty) setActivelySkippedValue('1');
      if (basis.haveNotMet) setActivelySkippedValue('2');
      if (basis.absence) setActivelySkippedValue('3');
      if (basis.other) setActivelySkippedValue('4');
    }
  }, [coworker.todaysEstimation]);

  const handleActivelySkipped = () => {
    if (!activelySkippedValue) return;
    switch (activelySkippedValue) {
      case '1':
        evaluate({
          status: localStatus,
          activelySkippedStatusBasis: {
            offDuty: true,
            haveNotMet: false,
            absence: false,
            other: false,
          },
        });
        break;
      case '2':
        evaluate({
          status: localStatus,
          activelySkippedStatusBasis: {
            haveNotMet: true,
            offDuty: false,
            absence: false,
            other: false,
          },
        });
        break;
      case '3':
        evaluate({
          status: localStatus,
          activelySkippedStatusBasis: {
            absence: true,
            haveNotMet: false,
            offDuty: false,
            other: false,
          },
        });
        break;
      default:
        evaluate({
          status: localStatus,
          activelySkippedStatusBasis: {
            other: true,
            haveNotMet: false,
            offDuty: false,
            absence: false,
          },
        });
        break;
    }
  };

  const handleSkip = () => {
    evaluate({ status: localStatus });
  };

  return (
    <View style={[styles.activelySkippedContainer]}>
      <View style={{ flex: 1 }}>
        <RadioButton.Group
          onValueChange={(value) => setActivelySkippedValue(value)}
          value={activelySkippedValue}
        >
          <Box>
            <Pressable onPress={() => setActivelySkippedValue('1')}>
              <ListItem isBorderBottom style={{ padding: 14 }}>
                <RadioButton.Android value='1' color={colors.primary} />
                <Text style={styles.label}>{screen.evaluation.offDuty}</Text>
              </ListItem>
            </Pressable>
            <Pressable onPress={() => setActivelySkippedValue('2')}>
              <ListItem isBorderBottom style={{ padding: 14 }}>
                <RadioButton.Android value='2' color={colors.primary} />
                <Text style={styles.label}>{screen.evaluation.haveNotMet}</Text>
              </ListItem>
            </Pressable>
            <Pressable onPress={() => setActivelySkippedValue('3')}>
              <ListItem isBorderBottom style={{ padding: 14 }}>
                <RadioButton.Android value='3' color={colors.primary} />
                <Text style={styles.label}>{screen.evaluation.absence}</Text>
              </ListItem>
            </Pressable>
            <Pressable onPress={() => setActivelySkippedValue('4')}>
              <ListItem style={{ padding: 14 }}>
                <RadioButton.Android value='4' color={colors.primary} />
                <Text style={styles.label}>{screen.evaluation.other}</Text>
              </ListItem>
            </Pressable>
          </Box>

        </RadioButton.Group>
      </View>
      <View style={styles.buttonRow}>
        <Button
          mode='outlined'
          onPress={handleSkip}
          textColor='#000'
          uppercase={false}
          contentStyle={styles.button}
        >
          {screen.evaluation.skip}
        </Button>
        <Button
          mode='contained'
          onPress={handleActivelySkipped}
          buttonColor={colors.secondary}
          textColor={colors.onSecondary}
          uppercase={false}
          contentStyle={styles.button}
        >
          {screen.evaluation.save}
        </Button>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  activelySkippedContainer: {
    flex: 1,
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    paddingBottom: 16,
    paddingTop: 10,
  },
  label: {
    fontSize: 16,
    lineHeight: 26,
    marginRight: 'auto',
  },
  buttonRow: {
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 16,
    alignItems: 'center',
  },
  button: {
    minWidth: 120,
    height: 42,
  },
});

export default ActivelySkipped;
