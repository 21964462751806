import type { Department, DepartmentManager, EstimationsByDepartmentIdQuery } from '@types';
import React, { useState } from 'react';
import {
  View, Text, StyleSheet, TouchableHighlight,
} from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { useTheme } from 'react-native-paper';
import { useQuery } from '@apollo/client';
import ListItem from '../../../../components/ListItem';
import useMediaQueries from '../../../../hooks/useMediaQueries';
import EditDepartment from './EditDepartment';
import AddDepartment from './AddDepartment';
import useTranslation from '../../../../hooks/useTranslation';
import { estimationsByDepartmentIdQuery } from '../../../../queries';

type DepartmentTableItemProps = {
  index: number;
  department: Department;
  parentId: string;
  companyId: string;
  iconHeaderSize: number;
};

const DepartmentTableItem: React.FC<DepartmentTableItemProps> = ({
  index, parentId, companyId, department, iconHeaderSize,
}) => {
  const { colors } = useTheme();
  const { screen } = useTranslation();
  const { isTablet } = useMediaQueries();

  const { data: evaluationsData } = useQuery<EstimationsByDepartmentIdQuery>(estimationsByDepartmentIdQuery, {
    variables: {
      departmentId: department._id,
    },
  });

  const nestedMargin = index * 6;
  const isCompany = parentId === companyId;

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isAddDepartmentOpen, setIsAddDepartmentOpen] = useState(false);
  const [isEditingOpen, setIsEditingOpen] = useState(false);

  const itemSm = isTablet ? 150 : 75;
  const itemMd = isTablet ? 300 : 150;

  const handleEditDepartment = () => {
    setIsEditingOpen(!isEditingOpen);
    setIsAddDepartmentOpen(false);
  };

  const handleAddDepartment = () => {
    setIsAddDepartmentOpen(!isAddDepartmentOpen);
    setIsEditingOpen(false);
  };

  const handleSubDepartments = () => {
    if (!department.departments?.length) return;

    if (isCollapsed) {
      setIsCollapsed(false);
      setIsAddDepartmentOpen(false);
      setIsEditingOpen(false);
    } else {
      setIsCollapsed(true);
    }
  };

  return (
    <>
      <TouchableHighlight onPress={handleSubDepartments} style={styles.pressable} underlayColor='#E5E5E5'>
        <View style={{ flexDirection: 'row' }}>
          <View style={{
            width: isCompany ? 0 : nestedMargin,
            backgroundColor: '#fff',
          }}
          />
          <ListItem style={[styles.department, {
            borderTopWidth: index === 1 ? 0 : 1,
            borderColor: '#E5E5E5',
          }]}
          >
            <Text style={[styles.flex, { maxWidth: itemMd }]} numberOfLines={1}>
              <Ionicons
                name={isCollapsed ? 'caret-down-sharp' : 'caret-forward-sharp'}
                size={16}
                color={department.departments?.length ? colors.primary : colors.secondary}
                style={{ opacity: department.departments?.length ? 1 : 0.3 }}
              />
              {' '}
              {department.name}
            </Text>
            <View style={[styles.flex, {
              flexDirection: 'row', maxWidth: itemSm, marginLeft: isCompany ? 0 : -nestedMargin,
            }]}
            >
              <Text style={{
                width: iconHeaderSize, textAlign: 'center', marginLeft: 2,
              }}
              >
                {evaluationsData?.estimationsByDepartmentId.amountOfGreen ?? 0}
              </Text>
              <Text style={{
                width: iconHeaderSize, textAlign: 'center', marginLeft: 2,
              }}
              >
                {evaluationsData?.estimationsByDepartmentId.amountOfYellow ?? 0}
              </Text>
              <Text style={{
                width: iconHeaderSize, textAlign: 'center', marginLeft: 2,
              }}
              >
                {evaluationsData?.estimationsByDepartmentId.amountOfOrange ?? 0}
              </Text>
            </View>
            <Text style={[styles.flex, { maxWidth: itemSm }]}>
              {evaluationsData?.estimationsByDepartmentId.calculatedPercentage ? Math.round(evaluationsData?.estimationsByDepartmentId.calculatedPercentage) : 100}
              {' % / '}
              {evaluationsData?.estimationsByDepartmentId.calculatedCost ? Math.round(evaluationsData?.estimationsByDepartmentId.calculatedCost) : 0}
              {' '}
              {screen.department.SEK}
            </Text>
            <Text style={[styles.flex, { maxWidth: itemSm }]}>
              {department?.managers && department.managers.map((manager: DepartmentManager) => (
                <View key={manager.userId}>
                  <Text>
                    {manager.firstName}
                    {' '}
                    {manager.lastName}
                  </Text>
                </View>
              ))}
            </Text>
            <View style={[styles.edit, { maxWidth: itemSm, zIndex: 30 }]}>
              <TouchableHighlight onPress={handleEditDepartment} style={[styles.iconContainer, { backgroundColor: colors.background }]} underlayColor='#E5E5E5'>
                <Ionicons name='create-outline' size={18} color='#000' />
              </TouchableHighlight>
              <TouchableHighlight onPress={handleAddDepartment} style={[styles.iconContainer, { backgroundColor: colors.background }]} underlayColor='#E5E5E5'>
                <Ionicons name='add-outline' size={20} color='#000' />
              </TouchableHighlight>
            </View>
          </ListItem>
        </View>
      </TouchableHighlight>
      {isCollapsed && department.departments && department.departments.map((subDepartment, i) => !isEditingOpen && !isAddDepartmentOpen && (
        <DepartmentTableItem
          key={subDepartment._id}
          department={subDepartment}
          index={index + 1}
          parentId={department._id}
          companyId={companyId}
          iconHeaderSize={iconHeaderSize}
        />
      ))}
      {isAddDepartmentOpen && (
        <AddDepartment
          title={screen.superAdmin.company.addSubDepartment}
          nestedMargin={nestedMargin + 30}
          departmentParentId={department._id}
          companyId={companyId}
          setIsAddDepartmentOpen={setIsAddDepartmentOpen}
          setIsCollapsed={setIsCollapsed}
        />
      )}
      {isEditingOpen && (
        <EditDepartment
          department={department}
          setIsEditing={setIsEditingOpen}
          nestedMargin={nestedMargin + 20}
          companyId={companyId}
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  pressable: {
    borderBottomColor: '#E5E5E5',
    cursor: 'pointer',
  },
  department: {
    flex: 1,
    gap: 8,
  },
  flex: {
    flex: 1,
  },
  edit: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
    justifyContent: 'flex-end',
    maxWidth: 150,
  },
  iconContainer: {
    height: 30,
    width: 30,
    borderRadius: 15,
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
});

export default DepartmentTableItem;
