import React, { useEffect, useState } from 'react';
import { StyleSheet, Image, View } from 'react-native';
import {
  Text, Button, useTheme,
} from 'react-native-paper';
import * as Linking from 'expo-linking';

import { useMutation } from '@apollo/client';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import useTranslation from '../../../hooks/useTranslation';
import { LoginWithBankIdMutation } from '../../../types/generated.types';
import { loginWithBankIdMutation } from '../../../queries';
import { RootStackParamList } from '../../../types/types';
import BankIdWhite from '../../../assets/images/BankID_logo_white.png';
// import { LoginResponse, SessionInfo } from '../types';

type BankIdLoginProps = {
  route: any;
  showAsButton?: boolean;
}
const BankIdLogin: React.FC<BankIdLoginProps> = ({ route, showAsButton = true }) => {
  const [isLoggingInWithBankId, setIsLoggingInWithBankId] = useState<boolean>(false);

  const [orderRef, setOrderRef] = useState<string | null>(null);

  let canOpenBankId = false;

  const { screen } = useTranslation();
  const theme = useTheme();
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();

  const [getBankIdSession,
    {
      data: bankIdSessionData,
      loading: bankIdSessionLoading,
      error: bankIdSessionError,
    }] = useMutation<LoginWithBankIdMutation>(loginWithBankIdMutation);

  const changeCanOpen = (newValue: boolean) => {
    canOpenBankId = newValue;
  };

  const beginLogin = () => {
    void getBankIdSession();
  };

  const openBankId = async (autostartToken: string) => {
    if (canOpenBankId === true) {
      changeCanOpen(false);

      const redirectURL = Linking.createURL('polling');

      const bankIdURL = `bankid:///?autostarttoken=${autostartToken}&redirect=null`;
      console.log('bankIdURL: ', bankIdURL);

      await Linking.openURL(bankIdURL)
        .then(() => {
          changeCanOpen(false);
          setIsLoggingInWithBankId(true);
        })
        .catch((err) => {
          console.log('BankId failed to open: ', err);
          setIsLoggingInWithBankId(false);
          navigation.goBack();
        });

      setIsLoggingInWithBankId(false);
    } else {
      console.log('trying to open BankId while not allowed.');
    }
  };

  useEffect(() => {
    if (bankIdSessionData) {
      canOpenBankId = true;

      setOrderRef(bankIdSessionData?.loginWithBankId.orderRef);

      void openBankId(bankIdSessionData?.loginWithBankId.autoStartToken);
      navigation.navigate('polling', { orderRef: bankIdSessionData?.loginWithBankId.orderRef });
    }
  }, [bankIdSessionData, bankIdSessionError, isLoggingInWithBankId]);

  const bankIDImage = () => (
    <Image style={styles.bankIdImage} source={BankIdWhite} />
  );

  const style = showAsButton ? { marginBottom: 20, borderRadius: theme.roundness, backgroundColor: theme.colors.secondary } : {};
  const contentStyle = showAsButton ? { margin: 10, paddingHorizontal: 5, flexDirection: 'row-reverse' } : {};
  const labelStyle = showAsButton ? { color: 'white', fontSize: 16 }
    : { textDecorationLine: 'underline', color: theme.colors.secondary };
  const buttonMode = showAsButton ? 'contained' : 'text';

  return (
    <View style={styles.container}>

      <Button
        uppercase={false}
        mode={buttonMode}
        style={style}
        contentStyle={contentStyle}
        labelStyle={labelStyle}
        onPress={beginLogin}
      >
        {screen.login.logInWithBankID}
      </Button>

      {bankIdSessionError ? <Text style={styles.error}>{`SessionError: ${bankIdSessionError}`}</Text> : null}
    </View>
  );
};

export default BankIdLogin;
const styles = StyleSheet.create({
  container: {
    // backgroundColor: 'red',
  },
  error: {
    color: 'red',
    fontSize: 12,
  },
  bankIdImage: {
    resizeMode: 'contain',
    height: 60,
    width: 60,
  },
});
