import React from 'react';
import { StyleSheet } from 'react-native';
import { Button, Text as PaperText, useTheme } from 'react-native-paper';
import useMediaQueries from '../../../hooks/useMediaQueries';
import Box from '../../../components/Box';
import useTranslation from '../../../hooks/useTranslation';
import Icon from '../../../components/Icon';
import ArendraWhite from '../../../assets/icons/arendra-white.svg';

type TimeToEvaluateWebProps = {
  navigateToEvaluation: () => void;
  isCoworkersEmpty: boolean;
  isCoworkersLoading: boolean;
};

const TimeToEvaluateWeb: React.FC<TimeToEvaluateWebProps> = ({ navigateToEvaluation, isCoworkersEmpty, isCoworkersLoading }) => {
  const { screen } = useTranslation();
  const { isTablet } = useMediaQueries();
  const { colors } = useTheme();
  return (
    <Box style={[styles.evaluateBoxWeb, {
      backgroundColor: colors.background,
      minHeight: !isTablet ? 350 : 'unset',
    }]}
    >
      <Icon source={ArendraWhite} height='85%' style={styles.arendraWhiteBgWeb} />
      {!isCoworkersLoading && (
        <>
          <PaperText style={{ fontSize: 28, zIndex: 10 }}>
            {isCoworkersEmpty ? screen.department.welcome : screen.department.timeToEvaluate}
          </PaperText>
          {isCoworkersEmpty && (
            <PaperText style={styles.stepOneText}>
              {screen.department.stepOneText}
            </PaperText>
          )}
          <Button
            mode='contained'
            contentStyle={{ height: 50 }}
            style={styles.evaluateButtonWeb}
            buttonColor={colors.primary}
            textColor={colors.onPrimary}
            onPress={navigateToEvaluation}
            uppercase={false}
            disabled={isCoworkersEmpty}
          >
            {screen.evaluation.evaluate}
          </Button>
        </>
      )}
    </Box>
  );
};

const styles = StyleSheet.create({
  evaluateBoxWeb: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  evaluateButtonWeb: {
    borderRadius: 25,
    minWidth: 200,
    marginTop: 50,
    marginHorizontal: 20,
    justifyContent: 'center',
  },
  arendraWhiteBgWeb: {
    position: 'absolute',
    bottom: -50,
    right: -60,
    opacity: 0.5,
    aspectRatio: 1,
  },
  stepOneText: {
    fontSize: 28,
    zIndex: 10,
    textAlign: 'center',
    maxWidth: 500,
  },
});

export default TimeToEvaluateWeb;
