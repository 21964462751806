import React from 'react';
import {
  Text, StyleSheet, Platform, View,
} from 'react-native';
import { Title, Text as PaperText } from 'react-native-paper';
import useTranslation from '../../../hooks/useTranslation';
import DesktopWrapper from '../../../components/DesktopWrapper';

const IntegrityPolicy = () => {
  const { screen } = useTranslation();

  const isWeb = Platform.OS === 'web';
  return (
    <View style={{ flexDirection: 'row' }}>
      <DesktopWrapper style={{ justifyContent: 'center', padding: 20, marginBottom: isWeb ? 0 : 100 }}>
        <PaperText style={styles.title}>{screen.integrityPolicy.title}</PaperText>
        <Title style={styles.subTitle}>{screen.integrityPolicy.p1.title}</Title>
        <Text ellipsizeMode='tail' style={styles.text}>{screen.integrityPolicy.p1.text}</Text>

        <Title style={styles.subTitle}>{screen.integrityPolicy.p2.title}</Title>
        <Text style={styles.text}>{screen.integrityPolicy.p2.text}</Text>

        <Title style={styles.subTitle}>{screen.integrityPolicy.p3.title}</Title>
        <Text style={styles.text}>{screen.integrityPolicy.p3.text}</Text>

        <Title style={styles.subTitle}>{screen.integrityPolicy.p4.title}</Title>
        <Text style={styles.text}>{screen.integrityPolicy.p4.text}</Text>

        <Title style={styles.subTitle}>{screen.integrityPolicy.p5.title}</Title>
        <Text style={styles.text}>{screen.integrityPolicy.p5.text}</Text>

        <Title style={styles.subTitle}>{screen.integrityPolicy.p6.title}</Title>
        <Text style={styles.text}>{screen.integrityPolicy.p6.text}</Text>
      </DesktopWrapper>
    </View>

  );
};

const styles = StyleSheet.create({
  title: {
    fontSize: 26,
    textAlign: 'center',
    marginBottom: 16,
  },
  subTitle: {
    marginTop: 26,
    fontSize: 18,
    marginBottom: 6,
    lineHeight: 24,
  },
  text: {
    fontSize: 16,
    flex: 1,
    flexWrap: 'wrap',
  },
});

export default IntegrityPolicy;
