import React from 'react';
import {
  View, Text, StyleSheet, TextInput, TextStyle, ViewStyle, KeyboardType,
} from 'react-native';
import { useTheme } from 'react-native-paper';

type StyledTextInputProps = {
  value?: string;
  onChangeText?: (text: string) => void;
  placeholder?: string;
  placeholderRight?: string;
  keyboardType?: KeyboardType;
  containerStyle?: ViewStyle | ViewStyle[];
  inputStyle?: TextStyle | TextStyle[];
  // eslint-disable-next-line react/boolean-prop-naming
  autoCorrect?: boolean;
  // eslint-disable-next-line react/boolean-prop-naming
  autoFocus?: boolean;
  autoComplete?:
  | 'birthdate-day'
  | 'birthdate-full'
  | 'birthdate-month'
  | 'birthdate-year'
  | 'cc-csc'
  | 'cc-exp'
  | 'cc-exp-day'
  | 'cc-exp-month'
  | 'cc-exp-year'
  | 'cc-number'
  | 'email'
  | 'gender'
  | 'name'
  | 'name-family'
  | 'name-given'
  | 'name-middle'
  | 'name-middle-initial'
  | 'name-prefix'
  | 'name-suffix'
  | 'password'
  | 'password-new'
  | 'postal-address'
  | 'postal-address-country'
  | 'postal-address-extended'
  | 'postal-address-extended-postal-code'
  | 'postal-address-locality'
  | 'postal-address-region'
  | 'postal-code'
  | 'street-address'
  | 'sms-otp'
  | 'tel'
  | 'tel-country-code'
  | 'tel-national'
  | 'tel-device'
  | 'username'
  | 'username-new'
  | 'off'
  | undefined;
};

const StyledTextInput: React.FC<StyledTextInputProps> = ({
  value,
  onChangeText,
  placeholder,
  placeholderRight,
  containerStyle,
  inputStyle,
  keyboardType,
  autoCorrect,
  autoComplete,
  autoFocus,
}) => {
  const { colors } = useTheme();
  return (
    <View style={[styles.inputContainer, containerStyle]}>
      <TextInput
        style={[styles.input, inputStyle]}
        value={value}
        onChangeText={onChangeText}
        placeholder={placeholder}
        placeholderTextColor={colors.placeholder}
        keyboardType={keyboardType}
        autoCorrect={autoCorrect}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
      />
      <Text style={[styles.inputPlaceholderRight, { color: colors.placeholder }]}>
        {placeholderRight}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  inputContainer: {
    justifyContent: 'center',
  },
  input: {
    backgroundColor: '#fff',
    borderWidth: 1,
    borderRadius: 6,
    borderColor: '#ccc',
    padding: 16,
  },
  inputPlaceholderRight: {
    position: 'absolute',
    right: 16,
  },
});

export default StyledTextInput;
