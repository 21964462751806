import React from 'react';
import { View, StyleSheet } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { useTheme } from 'react-native-paper';
import CoworkersListHeader from './components/CoworkersListHeader';
import CoworkersList from './components/CoworkersList';

const EvaluationCoworkersScreen = () => {
  const { colors } = useTheme();
  return (
    <View style={styles.container}>
      <View style={styles.listHeaderBg}>
        <CoworkersListHeader />
      </View>
      <ScrollView style={{ backgroundColor: colors.background }}>
        <CoworkersList />
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  listHeaderBg: {
    backgroundColor: '#fff',
    paddingTop: 30,
  },
});

export default EvaluationCoworkersScreen;
