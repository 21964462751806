import React from 'react';
import { useWindowDimensions } from 'react-native';

/**
 * @description Hook to get the current window width. Use this to determine if the user is on larger screens since the app is designed as mobile first.
 * @returns isTablet: boolean, isDesktop: boolean
 */
export default function useMediaQueries() {
  const { width } = useWindowDimensions();
  return {
    isTablet: width > 768,
    isDesktop: width > 1024,
    isDesktopWide: width > 1280,
    isDesktopWider: width > 1400,
  };
}
