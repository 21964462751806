import React from 'react';
import {
  View, Text, StyleSheet,
} from 'react-native';
import type { Department, EstimationsByDepartmentIdQuery } from '@types';
import { useQuery } from '@apollo/client';
import { estimationsByDepartmentIdQuery } from '../../../queries';
import CostDisplay from '../../../components/CostDisplay';
import DepartmentManagers from './DepartmentManagers';

type SubDepartmentProps = {
  subDepartment: Department;
  index: number;
};

const SubDepartment: React.FC<SubDepartmentProps> = ({ subDepartment, index }) => {
  const { data: evaluationsData } = useQuery<EstimationsByDepartmentIdQuery>(estimationsByDepartmentIdQuery, {
    variables: {
      departmentId: subDepartment._id,
    },
  });
  const nestedMargin = index * 10;
  console.log(evaluationsData);

  return (
    <>
      <View style={styles.department}>
        <View style={{ flexDirection: 'row' }}>
          <View style={{ width: nestedMargin }} />
        </View>
        <View style={styles.departmentInfo}>
          <View style={[styles.borderBottom]}>
            <Text style={styles.departmentText}>{`${subDepartment?.name} (${evaluationsData?.estimationsByDepartmentId.amountOfTotalCoworkers ?? 0})`}</Text>
            <DepartmentManagers managers={evaluationsData?.estimationsByDepartmentId.departmentManagers} />
          </View>
          <View style={styles.statsContainer}>
            <View style={styles.statsCenterContainer}>
              <View style={[styles.statusContainer, { backgroundColor: '#6CC24A' }]}>
                <Text style={styles.statusText}>{evaluationsData?.estimationsByDepartmentId?.amountOfGreen ?? 0}</Text>
              </View>
              <View style={[styles.statusContainer, { backgroundColor: '#FAC003' }]}>
                <Text style={styles.statusText}>{evaluationsData?.estimationsByDepartmentId?.amountOfYellow ?? 0}</Text>
              </View>
              <View style={[styles.statusContainer, { backgroundColor: '#E87722' }]}>
                <Text style={styles.statusText}>{evaluationsData?.estimationsByDepartmentId?.amountOfOrange ?? 0}</Text>
              </View>
            </View>
            <CostDisplay isPerMonth cost={Math.round(evaluationsData?.estimationsByDepartmentId?.calculatedCost ?? 0)} />
          </View>
        </View>
      </View>

      {subDepartment.departments?.map((department) => (
        <SubDepartment key={department._id} subDepartment={department} index={index + 1} />
      ))}
    </>
  );
};

const styles = StyleSheet.create({
  department: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
    marginVertical: 10,
  },
  departmentInfo: {
    flex: 1,
    justifyContent: 'center',
    gap: 3,
  },
  departmentText: {
    fontSize: 16,
    fontWeight: '500',
  },
  statsContainer: {
    flexDirection: 'row',
    gap: 6,
    paddingTop: 3,
  },
  statsCenterContainer: {
    flexDirection: 'row',
    paddingRight: 6,
    gap: 6,
  },
  statusContainer: {
    height: 20,
    minWidth: 20,
    paddingHorizontal: 3,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 6,
    backgroundColor: '#6CC24A',
  },
  statusText: {
    color: '#fff',
    fontWeight: 'bold',
  },
  borderBottom: {
    borderBottomWidth: 1,
    paddingBottom: 10,
    borderColor: '#EDEAE6',
  },
});

export default SubDepartment;
