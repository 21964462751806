import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import {
  View, Text, StyleSheet, useWindowDimensions, Platform,
} from 'react-native';
import { Button, Text as Title, useTheme } from 'react-native-paper';
import { Ionicons } from '@expo/vector-icons';
import { useMutation, useQuery } from '@apollo/client';
import type { CompanyFromDepartmentQuery, GetContactedByArendraMutation } from '@types';
import AlertFromBottom from '../../../components/AlertFromBottom';
import useTranslation from '../../../hooks/useTranslation';
import StyledTextInput from '../../../components/StyledTextInput';
import useMediaQueries from '../../../hooks/useMediaQueries';
import { regex } from '../../../utils';
import { companyFromDepartmentQuery, getContactedByArendraMutation } from '../../../queries';
import FormErrorMessage from '../../../components/FormErrorMessage';
import UserSettingsContext from '../../../contexts/UserSettingsContext';

const ActContact = () => {
  const theme = useTheme();
  const { height } = useWindowDimensions();
  const { isDesktop } = useMediaQueries();
  const { screen, clientErrors } = useTranslation();
  const { currentDepartment } = useContext(UserSettingsContext);
  const { data: companyData } = useQuery<CompanyFromDepartmentQuery>(companyFromDepartmentQuery, {
    variables: {
      departmentId: currentDepartment,
    },
  });
  const [getContactedByArendra, { loading: contactMutationLoading, error: contactMutatationError }] = useMutation<GetContactedByArendraMutation>(getContactedByArendraMutation);

  const [clientError, setClientError] = useState<string>('');
  const [mutationSucceeded, setMutationSucceeded] = useState<boolean>(false);
  const [contact, setContact] = useState({
    email: '',
    phone: '',
  });

  const isWeb = Platform.OS === 'web';

  useEffect(() => () => {
    setMutationSucceeded(false);
  }, []);

  const isFieldsCorrect = useCallback(() => {
    if (contact.email === '' || contact.phone === '') {
      setClientError(clientErrors.fillOutAllFields);
      return false;
    }

    if (!regex.email.test(contact.email)) {
      setClientError(clientErrors.invalidEmail);
      return false;
    }

    return true;
  }, [contact.email, contact.phone]);

  const handleSend = useCallback(() => {
    if (contactMutationLoading) return;
    if (isFieldsCorrect()) {
      getContactedByArendra({
        variables: {
          email: contact.email,
          phoneNumber: contact.phone,
        },
      }).then(() => {
        setContact({
          email: '',
          phone: '',
        });
        setClientError('');
        setMutationSucceeded(true);
      }).catch((err) => {
        console.log(err);
      });
    }
  }, [contact.email, contact.phone, contactMutationLoading, getContactedByArendra, isFieldsCorrect]);

  const handleKeyPress = useCallback((e: any) => {
    if (e.key === 'Enter') {
      handleSend();
    }
  }, [handleSend]);

  useEffect(() => {
    if (isWeb) {
      if (contact.email === '' || contact.phone === '') {
        window.removeEventListener('keypress', handleKeyPress);
      } else {
        window.addEventListener('keypress', handleKeyPress);
      }
      return () => {
        window.removeEventListener('keypress', handleKeyPress);
      };
    }

    return () => { };
  }, [contact.email, contact.phone, handleKeyPress, isWeb]);

  return (
    <View
      style={[styles.container, { backgroundColor: theme.colors.primary },
        isDesktop && {
          flex: 1,
          minHeight: height,
          paddingHorizontal: 20,
        },
      ]}
    >
      <View style={[styles.sectionShapeContainer, isDesktop && styles.sectionShapeContainerDesktop]}>
        <View style={[styles.sectionShape, { backgroundColor: theme.colors.background }, isDesktop && styles.sectionShapeDesktop]} />
      </View>
      <View style={[styles.content, { paddingHorizontal: 20 }]}>
        <View>
          <Title style={[styles.title, { color: theme.colors.onPrimary }]}>{screen.act.contactTitle}</Title>
          <Title style={[styles.title, { color: theme.colors.onPrimary }]}>{screen.act.contactArendra}</Title>
        </View>

        <View style={{ gap: 12 }}>
          <Title style={[styles.subtitle, { color: theme.colors.onPrimary }]}>{screen.act.mailOrPhone}</Title>
          <View>
            <View style={styles.row}>
              <Ionicons name='mail' size={24} color={theme.colors.accent} />
              <Text style={{ color: theme.colors.onPrimary }}>info@arendra.se</Text>
            </View>
            <View style={styles.row}>
              <Ionicons name='call' size={24} color={theme.colors.accent} />
              <Text style={{ color: theme.colors.onPrimary }}>08-911 112</Text>
            </View>
          </View>
        </View>

        <View style={{ gap: 12 }}>
          <Title style={[styles.subtitle, { color: theme.colors.onPrimary }]}>{screen.act.formInfo}</Title>
          <StyledTextInput
            value={contact.email}
            onChangeText={(text) => setContact({ ...contact, email: text })}
            placeholder={screen.act.email}
            keyboardType='email-address'
            autoComplete='email'
          />
          <StyledTextInput
            value={contact.phone}
            onChangeText={(text) => setContact({ ...contact, phone: text })}
            placeholder={screen.act.phone}
            keyboardType='phone-pad'
            autoComplete='tel'
          />
          {clientError !== '' ? <FormErrorMessage message={clientError} /> : contactMutatationError && <FormErrorMessage message={contactMutatationError.message} />}
          <View style={styles.buttonContainer}>
            <Button
              style={styles.button}
              contentStyle={{ height: 50 }}
              mode='contained'
              onPress={handleSend}
              buttonColor={theme.colors.secondary}
              textColor={theme.colors.onSecondary}
              loading={contactMutationLoading}
              uppercase={false}
            >
              {screen.act.send}
            </Button>
          </View>
          <View style={{ gap: 12 }}>
            <Title style={[styles.subtitle, { color: theme.colors.onPrimary }]}>{screen.act.contactHR}</Title>
            <View>
              <View style={styles.row}>
                <Ionicons name='mail' size={24} color={theme.colors.accent} />
                <Text style={{ color: theme.colors.onPrimary }}>
                  {companyData?.department.companyEmail || ''}
                </Text>
              </View>
              <View style={styles.row}>
                <Ionicons name='call' size={24} color={theme.colors.accent} />
                <Text style={{ color: theme.colors.onPrimary }}>
                  {companyData?.department.companyPhoneNumber || ''}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
      {
        mutationSucceeded
        && (
          <AlertFromBottom
            isShown={mutationSucceeded}
            message={screen.contact.mutationSucceededMessage}
          />
        )
      }
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingVertical: 60,
  },
  sectionShapeContainer: {
    top: -80,
    width: 40,
    alignSelf: 'center',
    height: 40,
  },
  sectionShapeContainerDesktop: {
    alignSelf: 'flex-start',
    left: -41,
    top: 40,
  },
  sectionShape: {
    width: 40,
    height: 40,
    transform: [{ rotate: '-38deg' }, { skewX: '15deg' }],
  },
  sectionShapeDesktop: {
    transform: [{ rotate: '52deg' }, { skewX: '15deg' }],
  },
  title: {
    fontSize: 28,
    textAlign: 'left',
    padding: 0,
    margin: 0,
  },
  subtitle: {
    fontSize: 20,
  },
  content: {
    gap: 30,
  },
  row: {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    marginBottom: 6,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
    padding: 6,
  },
  buttonContainer: {
    alignItems: 'flex-end',
  },
  button: {
    borderRadius: 25,
    marginBottom: 20,
    paddingHorizontal: 40,
  },
  alertWrapper: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
});

export default ActContact;
