import type { UserRoles } from '@types';
import React, {
  useMemo, ReactNode, createContext, useState,
} from 'react';

type UserSettingsContextType = {
  userRoles?: UserRoles;
  setUserRoles: React.Dispatch<React.SetStateAction<UserRoles | undefined>>;
  currentDepartment?: string;
  setCurrentDepartment: React.Dispatch<React.SetStateAction<string | undefined>>;
  isWebRoleSelectionOpen: boolean;
  setIsWebRoleSelectionOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const UserSettingsContext = createContext<UserSettingsContextType>({
  userRoles: undefined,
  setUserRoles: () => { },
  currentDepartment: undefined,
  setCurrentDepartment: () => { },
  isWebRoleSelectionOpen: false,
  setIsWebRoleSelectionOpen: () => { },
});

type UserSettingsProviderProps = {
  children: ReactNode;
};

export const UserSettingsProvider: React.FC<UserSettingsProviderProps> = ({ children }) => {
  const [userRoles, setUserRoles] = useState<UserRoles>();
  const [currentDepartment, setCurrentDepartment] = useState<string>('');

  const [isWebRoleSelectionOpen, setIsWebRoleSelectionOpen] = useState<boolean>(false);

  const state = useMemo(() => ({
    userRoles,
    setUserRoles,
    currentDepartment,
    setCurrentDepartment,
    isWebRoleSelectionOpen,
    setIsWebRoleSelectionOpen,
  }), [
    userRoles,
    setUserRoles,
    currentDepartment,
    setCurrentDepartment,
    isWebRoleSelectionOpen,
    setIsWebRoleSelectionOpen,
  ]);

  return (
    <UserSettingsContext.Provider value={state}>
      {children}
    </UserSettingsContext.Provider>
  );
};

export default UserSettingsContext;
