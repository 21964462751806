import type { Company, RootStackParamList } from '@types';
import React, { useState } from 'react';
import {
  View, Text, StyleSheet, TouchableHighlight,
} from 'react-native';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { Ionicons } from '@expo/vector-icons';
import { useTheme } from 'react-native-paper';
import ListItem from '../../../../components/ListItem';
import useMediaQueries from '../../../../hooks/useMediaQueries';
import EditCompany from './EditCompany';
import useTranslation from '../../../../hooks/useTranslation';

type CompanyTableItemProps = {
  companies: Company[];
  company: Company;
  index: number;
};

const CompanyTableItem: React.FC<CompanyTableItemProps> = ({ companies, company, index }) => {
  const { colors } = useTheme();
  const { screen } = useTranslation();
  const { isTablet } = useMediaQueries();
  const { navigate } = useNavigation<NavigationProp<RootStackParamList>>();

  const [isEditing, setIsEditing] = useState(false);

  const itemSm = isTablet ? 150 : 75;
  const itemMd = isTablet ? 300 : 150;

  const handleNavigateToCompany = () => {
    navigate('company', { id: company._id });
  };

  const handleEditCompany = () => {
    setIsEditing(!isEditing);
  };

  return (
    <>
      <TouchableHighlight onPress={handleNavigateToCompany} underlayColor='#E5E5E5'>
        <ListItem style={styles.company} isBorderBottom={index !== companies.length - 1}>
          <Text style={[styles.flex, { maxWidth: itemMd }]} numberOfLines={1}>{company.name}</Text>
          <Text numberOfLines={2} style={styles.flex}>
            {new Date(company.endDate as string).toLocaleDateString('sv-SE')}
            {' '}
            /
            {' '}
            {company.orgNumber}
            {' '}
            /
            {' '}
            {company.email}
            {' '}
            /
            {' '}
            {company.phoneNumber}
            {' '}
          </Text>
          <Text style={[styles.flex, { maxWidth: itemSm }]}>{company.status === 'Påbörjad' ? screen.superAdmin.companies.started : company.status}</Text>
          <View style={[styles.edit, { maxWidth: itemSm }]}>
            <TouchableHighlight onPress={handleEditCompany} style={[styles.iconContainer, { backgroundColor: colors.background }]} underlayColor='#E5E5E5'>
              <Ionicons name='create-outline' size={18} color='#000' />
            </TouchableHighlight>
            <TouchableHighlight onPress={handleNavigateToCompany} style={[styles.iconContainer, { backgroundColor: colors.background }]} underlayColor='#E5E5E5'>
              <Ionicons name='chevron-forward-sharp' size={20} color='#000' />
            </TouchableHighlight>
          </View>
        </ListItem>
      </TouchableHighlight>

      {isEditing && <EditCompany companies={companies} company={company} index={index} setIsEditing={setIsEditing} />}
    </>
  );
};

const styles = StyleSheet.create({
  company: {
    paddingHorizontal: 20,
    gap: 8,
  },
  flex: {
    flex: 1,
  },
  edit: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
    justifyContent: 'flex-end',
    maxWidth: 150,
  },
  iconContainer: {
    height: 30,
    width: 30,
    borderRadius: 15,
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
});

export default CompanyTableItem;
