import React, { useContext } from 'react';
import {
  View, StyleSheet, Platform, Text,
} from 'react-native';
import Constants from 'expo-constants';
import { Drawer, useTheme } from 'react-native-paper';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Ionicons } from '@expo/vector-icons';
import { DrawerContentComponentProps } from '@react-navigation/drawer';
import User from './User';
import useTranslation from '../../hooks/useTranslation';
import AuthContext from '../../contexts/AuthContext';
import Icon from '../../components/Icon';
import Arendra from '../../assets/icons/arendra.svg';
import Contact from '../../assets/icons/contact.svg';
import DocumentLock from '../../assets/icons/document-lock-outline.svg';
import ArendraIcon from '../../components/ArendraIcon';

type IconProps = {
  color: string;
};

const ArendraModelIcon: React.FC<IconProps> = () => (
  <ArendraIcon name='icon-graph' size={32} style={{ marginRight: -22 }} />
);
const AboutArendraIcon: React.FC<IconProps> = () => (
  <Icon source={Arendra} width={30} height={30} style={{ marginRight: -20 }} />
);
const UserTermsIcon: React.FC<IconProps> = ({ color }) => (
  <Icon source={DocumentLock} width={26} height={26} style={{ marginRight: -17, marginLeft: 1 }} />
);
const AgreementsIcon: React.FC<IconProps> = () => (
  <Icon source={DocumentLock} width={26} height={26} style={{ marginRight: -17, marginLeft: 1 }} />
);
const AboutIcon: React.FC<IconProps> = ({ color }) => (
  <Ionicons name='settings-outline' color={color} size={28} style={{ marginRight: -16 }} />
);
const ContactIcon: React.FC<IconProps> = () => (
  <Icon source={Contact} width={30} height={30} style={{ marginRight: -20 }} />
);
const LogoutIcon: React.FC<IconProps> = ({ color }) => (
  <Ionicons name='log-in-outline' color={color} size={30} style={{ marginRight: -20 }} />
);

const DrawerMenu: React.FC<DrawerContentComponentProps> = ({ navigation }) => {
  const { menu } = useTranslation();
  const isWeb = Platform.OS === 'web';
  const { colors } = useTheme();
  const { logout } = useContext(AuthContext);

  const closeDrawer = () => new Promise<void>((resolve) => {
    navigation.closeDrawer();
    resolve();
  });

  const onLogoutPress = async () => {
    await closeDrawer();
    logout();
  };

  const onPressAbout = () => {
    navigation.navigate('about');
  };

  const onPressUserTerms = () => {
    navigation.navigate('userTerms');
  };

  const onPressIntegrity = () => {
    navigation.navigate('integrityPolicy');
  };

  const onPressAboutArendraModel = () => {
    navigation.navigate('aboutArendraModel');
  };

  const onPressSettings = () => {
    navigation.navigate('settings');
  };

  const onPressContact = () => {
    navigation.navigate('contact');
  };

  return (
    <View style={[styles.container, { paddingTop: isWeb ? 10 : 0 }]}>
      <SafeAreaView style={{ flex: 1 }}>
        <View style={{ flex: 1 }}>
          <User navigation={navigation} />
          <Drawer.Item
            style={styles.menuItem}
            label={menu.arendraModel}
            accessibilityLabel={menu.arendraModel}
            onPress={onPressAboutArendraModel}
            icon={ArendraModelIcon}
          />
          <Drawer.Item
            style={styles.menuItem}
            label={menu.about}
            accessibilityLabel={menu.about}
            onPress={onPressAbout}
            icon={AboutArendraIcon}
          />
          <Drawer.Item
            style={styles.menuItem}
            label={menu.userTerms}
            accessibilityLabel={menu.userTerms}
            onPress={onPressUserTerms}
            icon={UserTermsIcon}
          />
          <Drawer.Item
            style={styles.menuItem}
            label={menu.integrityPolicy}
            accessibilityLabel={menu.integrityPolicy}
            onPress={onPressIntegrity}
            icon={AgreementsIcon}
          />
          <Drawer.Item
            style={styles.menuItem}
            label={menu.settings}
            accessibilityLabel={menu.settings}
            onPress={onPressSettings}
            icon={AboutIcon}
          />

          <Drawer.Item
            style={styles.menuItem}
            label={menu.contactArendra}
            theme={{ colors: { text: colors.primary } }}
            accessibilityLabel={menu.contactArendra}
            onPress={onPressContact}
            icon={ContactIcon}
          />
        </View>
        <Drawer.Item
          style={[styles.menuItem, { marginTop: 'auto' }]}
          label={menu.logout}
          accessibilityLabel={menu.logout}
          onPress={onLogoutPress}
          icon={LogoutIcon}
        />
        <Text style={styles.versionNumber}>
          {`v ${Constants?.expoConfig?.version}`}
        </Text>
      </SafeAreaView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  menuItem: {
    paddingVertical: 2,
    borderRadius: 6,
  },
  versionNumber: {
    textAlign: 'right',
    color: '#f1f1f1',
  },
});

export default DrawerMenu;
