import { gql } from '@apollo/client';
/**
 * When you add
 * a new query, mutation or subscription
 * a new field to a query, mutation or subscription
 * you need to run yarn codegen to update the types
 */

export const getMyUserQuery = gql`
  query User {
    user {
      _id
      personalNumber
      isSuperAdmin
    }
  }
`;

export const getXlsxDataQuery = gql`
  query getXlsxData($departmentId: ObjectId!, $fromDate: DateTime, $toDate: DateTime) {
    getXlsxData(departmentId: $departmentId, fromDate: $fromDate, toDate: $toDate) {
      _id
      departmentName
      xlsRows
    }
  }
`;

export const addPushNotificationTokenMutation = gql`
  mutation AddPushNotificationToken($deviceToken: String!) {
    addPushNotificationToken(deviceToken: $deviceToken) {
      _id
      devicePushTokens
    }
  }
`;

export const deletePushNotificationTokenMutation = gql`
  mutation DeletePushNotificationToken($deviceToken: String!) {
    deletePushNotificationToken(deviceToken: $deviceToken) {
      _id
      devicePushTokens
    }
  }
`;

export const getMyUserEmailQuery = gql`
  query UserEmail {
    user {
      email
      _id
    }
  }
`;

export const updateMyUserMutation = gql`
  mutation UpdateMyUser($email: String!) {
    updateMyUser(email: $email) {
      _id
      email
    }
  }
`;

export const hasSeenTutorialMutation = gql`
  mutation HasSeenTutorial($tutorialVersion: Float!) {
    hasSeenTutorial(tutorialVersion: $tutorialVersion) {
      _id
      hasSeenTutorial
    }
  }
`;

export const loginAsReviewerMutation = gql`
  mutation loginAsReviewer($code: String!) {
    loginAsReviewer(code: $code)
  }
`;

export const myRolesQuery = gql`
  query MyRoles {
    myRoles {
      departmentsIManage {
        _id
        name
        companyName
      }
      departmentsICowork {
        _id
        name
      }
      isSuperAdmin
      hasSeenTutorial
    }
  }
`;

export const loginWithBankIdMutation = gql`
  mutation loginWithBankId {
    loginWithBankId {
      orderRef
      autoStartToken
      qrStartToken
      qrAuthCode
      qrData
    }
  }
`;

export const bankIdLoginStatusQuery = gql`
  query bankIdLoginStatus($orderRef: String!, $devicePushToken: String) {
    bankIdLoginStatus(orderRef: $orderRef, devicePushToken: $devicePushToken) {
      token
      userId
      status
      role
      name
      givenName
      surname
      personalNumber
    }   
  }
`;

export const agreeToTermsMutation = gql`
  mutation AgreeToTerms($bankIdUser: BankIdUser!, $email: String!, $devicePushToken: String) {
    agreeToTerms(bankIdUser: $bankIdUser, email: $email, devicePushToken: $devicePushToken) {
      token
    }
  }
`;

export const bankIdLoginStatusWithQRQuery = gql`
  query bankIdLoginStatusWithQR($orderRef: String!, $devicePushToken: String) {
    bankIdLoginStatusWithQR(orderRef: $orderRef, devicePushToken: $devicePushToken) {
      token
      userId
      status
      role
      name
      givenName
      surname
      personalNumber
      qrCodeString 
    }
  }
`;

export const listCoworkersQuery = gql`
  query Coworkers($departmentId: ObjectId!) {
    department(departmentId: $departmentId) {
      name
      coworkersWithEstimations {
        userId
        firstName
        lastName
        salary
        workPercentage
        todaysEstimation {
          status
          date
          activelySkippedStatusBasis {
            offDuty
            haveNotMet
            absence
            other
          }
          statusBasis {
            workPerformance
            followLawsAndDecisions
            workEnvironmentResponsibility
            dutyOfLoyalty
            dutyToCooperate
          }
        }
      }
    }
  }
`;

export const departmentQuery = gql`
  query Department($departmentId: ObjectId!) {
    department(departmentId: $departmentId) {
      _id
      name
      departments {
        _id
        name
        managers {
          userId
        }
        departments {
          _id
          name
          managers {
            userId
          }
          departments {
            _id
            name
            managers {
              userId
            }
            departments {
              _id
              name
              managers {
                userId
              }
              departments {
                _id
                name
                managers {
                  userId
                }
                departments {
                  _id
                  name
                  managers {
                    userId
                  }
                  departments {
                    _id
                    name
                    managers {
                      userId
                    }
                    departments {
                      _id
                      name
                      managers {
                        userId
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const companyFromDepartmentQuery = gql`
  query CompanyFromDepartment($departmentId: ObjectId!) {
    department(departmentId: $departmentId) {
      companyPhoneNumber
      companyEmail
      companyName
      companyId
    }
  }
`;

export const companiesQuery = gql`
  query Companies {
    companies {
      _id
      name
      orgNumber
      phoneNumber
      email
      endDate
      departmentIds
      status
      departments {
        _id
        name
      }
    }
  }
`;

export const companyQuery = gql`
  query Company($companyId: ObjectId!) {
    company(companyId: $companyId) {
      _id
      name
      orgNumber
      phoneNumber
      email
      endDate
      departmentIds
      status
      departments {
        _id
        name
        managers {
          userId
          firstName
          lastName
        }
        departments {
          _id
          name
          managers {
            userId
            firstName
            lastName
          }
          departments {
            _id
            name
            managers {
              userId
              firstName
              lastName
            }
            departments {
              _id
              name
              managers {
                userId
                firstName
                lastName
              }
              departments {
                _id
                name
                managers {
                  userId
                  firstName
                  lastName
                }
                departments {
                  _id
                  name
                  managers {
                    userId
                    firstName
                    lastName
                  }
                  departments {
                    _id
                    name
                    managers {
                      userId
                      firstName
                      lastName
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const addCompanyMutation = gql`
  mutation AddCompany($name: String!, $orgNumber: String!, $phoneNumber: String!, $email: String!, $endDate: DateTime!) {
    addCompany(name: $name, orgNumber: $orgNumber, phoneNumber: $phoneNumber, email: $email, endDate: $endDate) {
      _id
      name
    }
  }
`;

export const updateCompanyMutation = gql`
  mutation UpdateCompany($id: ObjectId!, $name: String!, $orgNumber: String, $phoneNumber: String, $email: String, $endDate: DateTime) {
    updateCompany(_id: $id, name: $name, orgNumber: $orgNumber, phoneNumber: $phoneNumber, email: $email, endDate: $endDate) {
      _id
    }
  }
`;

export const addDepartmentCoworkerMutation = gql`
  mutation AddDepartmentCoworker($personalNumber: String!, $departmentId: ObjectId!, $firstName: String!, $lastName: String!, $salary: Float!, $workPercentage: Float!) {
    addDepartmentCoworker(personalNumber: $personalNumber, departmentId: $departmentId, firstName: $firstName, lastName: $lastName, salary: $salary, workPercentage: $workPercentage) {
      _id
    }
  }
`;

export const updateDepartmentCoworkerMutation = gql`
  mutation UpdateDepartmentCoworker($userId: ObjectId!, $departmentId: ObjectId!, $firstName: String, $lastName: String, $salary: Float, $workPercentage: Float) {
    updateDepartmentCoworker(userId: $userId, departmentId: $departmentId, firstName: $firstName, lastName: $lastName, salary: $salary, workPercentage: $workPercentage) {
      _id
    }
  }
`;

export const updateDepartmentManagerMutation = gql`
  mutation UpdateDepartmentManager($userId: ObjectId!, $departmentId: ObjectId!, $firstName: String, $lastName: String) {
    updateDepartmentManager(userId: $userId, departmentId: $departmentId, firstName: $firstName, lastName: $lastName) {
      _id
    }
  }
`;

export const deleteDepartmentCoworkerMutation = gql`
  mutation DeleteDepartmentCoworker($userId: ObjectId!, $departmentId: ObjectId!) {
    deleteDepartmentCoworker(userId: $userId, departmentId: $departmentId) {
      _id
    }
  }
`;

export const deleteDepartmentManagerMutation = gql`
  mutation DeleteDepartmentManager($userId: ObjectId!, $departmentId: ObjectId!) {
    deleteDepartmentManager(userId: $userId, departmentId: $departmentId) {
      _id
    }
  }
`;

export const deleteCompanyMutation = gql`
  mutation DeleteCompany($companyId: ObjectId!) {
    deleteCompany(companyId: $companyId)
  }
`;

export const addDepartmentMutation = gql`
  mutation AddDepartment($companyId: ObjectId!, $departmentName: String!, $departmentParentId: ObjectId) {
    addDepartment(companyId: $companyId, departmentName: $departmentName, departmentParentId: $departmentParentId) {
      _id
      name
    }
  }
`;

export const updateDepartmentMutation = gql`
  mutation UpdateDepartment($id: ObjectId!, $name: String) {
    updateDepartment(_id: $id, name: $name) {
      _id
      name
    }
  }
`;

export const addDepartmentManagerMutation = gql`
  mutation AddDepartmentManager($personalNumber: String!, $departmentId: ObjectId!, $firstName: String!, $lastName: String!) {
    addDepartmentManager(personalNumber: $personalNumber, departmentId: $departmentId, firstName: $firstName, lastName: $lastName) {
      _id
      name
      managers {
        userId
        firstName
        lastName
      }
    }
  }
`;

export const deleteDepartmentMutation = gql`
  mutation DeleteDepartment($departmentId: ObjectId!) {
    deleteDepartment(departmentId: $departmentId)
  }
`;

export const estimateCoworkerMutation = gql`
  mutation EstimateCoworker($userId: ObjectId!, $departmentId: ObjectId!, $status: Int, $statusBasis: [StatusBasisInput!], $activelySkippedStatusBasis: [SkippedStatusBasisInput!]) {
    estimateCoworker(userId: $userId, departmentId: $departmentId, status: $status, statusBasis: $statusBasis, activelySkippedStatusBasis: $activelySkippedStatusBasis) {
      _id
      status
    }
  }
`;

export const estimationsByUserIdQuery = gql`
  query EstimationsByUserId($userId: ObjectId!, $departmentId: ObjectId!, $fromDate: DateTime) {
    estimationsByUserId(userId: $userId, departmentId: $departmentId, fromDate: $fromDate) {
      estimations {
        _id
        userId
        departmentId
        companyId
        status
        date
        statusBasis {
          workPerformance
          followLawsAndDecisions
          workEnvironmentResponsibility
          dutyOfLoyalty
          dutyToCooperate
        }
        activelySkippedStatusBasis {
          offDuty
          haveNotMet
          absence
          other
        }
      }
      calculatedCost
      calculatedPercentage
      
    }
  }
`;

export const estimationsByDepartmentIdQuery = gql`
  query EstimationsByDepartmentId($departmentId: ObjectId!, $fromDate: DateTime) {
    estimationsByDepartmentId(departmentId: $departmentId, fromDate: $fromDate) {
      departmentManagers {
        userId
        firstName
        lastName
      }
      amountOfTotalCoworkers
      calculatedCost
      calculatedPercentage,
      amountOfGreen
      amountOfOrange
      amountOfYellow
      coworkersWithGreenStatus {
        userFirstName
        userLastName
        userId
      }
      coworkersWithOrangeStatus {
        userFirstName
        userLastName
        userId
      }
      coworkersWithYellowStatus {
        userFirstName
        userLastName
        userId
      }
      coworkersWithoutStatus {
        userId
        userFirstName
        userLastName
      }
    }
  }
`;

export const getContactedByArendraMutation = gql`
  mutation GetContactedByArendra($email: String, $phoneNumber: String) {
    getContactedByArendra(email: $email, phoneNumber: $phoneNumber)
  }
`;
