import React from 'react';
import {
  View, Text, StyleSheet, useWindowDimensions,
} from 'react-native';
import { Text as PaperText, useTheme } from 'react-native-paper';
import { ScrollView } from 'react-native-gesture-handler';
import useTranslation from '../../hooks/useTranslation';
import useMediaQueries from '../../hooks/useMediaQueries';
import DesktopWrapper from '../../components/DesktopWrapper';
import ArendraIcon from '../../components/ArendraIcon';

const AboutArendraModelScreen = () => {
  const { screen } = useTranslation();
  const { colors } = useTheme();
  const { height } = useWindowDimensions();
  const { isDesktop } = useMediaQueries();

  return (
    <ScrollView style={[
      styles.infoContainer,
      { backgroundColor: colors.background },
      isDesktop && {
        flex: 1,
        minHeight: height,
      }]}
    >
      <DesktopWrapper style={{ justifyContent: 'center', padding: 20, marginBottom: 100 }}>
        <PaperText style={styles.title}>{screen.aboutArendraModel.title}</PaperText>
        <Text style={styles.text}>{screen.aboutArendraModel.p1}</Text>
        <Text style={styles.text}>{screen.aboutArendraModel.p2}</Text>
        <Text style={styles.text}>{screen.aboutArendraModel.p3}</Text>
        <Text style={styles.text}>{screen.aboutArendraModel.p4}</Text>

        <View style={styles.row}>
          <View style={styles.iconContainer}>
            <ArendraIcon name='icon-fungerar-val' size={60} />
          </View>
          <Text style={styles.textBold}>{screen.aboutArendraModel.green}</Text>
        </View>

        <Text style={styles.text}>{screen.aboutArendraModel.statusGreen}</Text>

        <View style={styles.row}>
          <View style={styles.iconContainer}>
            <ArendraIcon name='icon-brister-delvis' size={60} />
          </View>
          <Text style={styles.textBold}>{screen.aboutArendraModel.yellow}</Text>
        </View>

        <Text style={styles.text}>{screen.aboutArendraModel.statusYellow}</Text>

        <View style={styles.row}>
          <View style={styles.iconContainer}>
            <ArendraIcon name='icon-brister-patagligt' size={60} />
          </View>
          <Text style={styles.textBold}>{screen.aboutArendraModel.orange}</Text>
        </View>

        <Text style={styles.text}>{screen.aboutArendraModel.statusOrange}</Text>

        <View style={styles.row}>
          <View style={styles.iconContainer}>
            <ArendraIcon name='icon-temp' size={60} />
          </View>
          <Text style={styles.textBold}>{screen.aboutArendraModel.red}</Text>
        </View>
        <Text style={styles.text}>{screen.aboutArendraModel.statusRed}</Text>

        <View style={styles.row}>
          <View style={styles.iconContainer}>
            <ArendraIcon name='icon-faller' size={60} />
          </View>
          <Text style={styles.textBold}>{screen.aboutArendraModel.black}</Text>
        </View>
        <Text style={styles.text}>{screen.aboutArendraModel.statusBlack}</Text>

        <Text style={[styles.text, { fontStyle: 'italic', marginTop: 40 }]}>{screen.aboutArendraModel.p5}</Text>

        <View style={[styles.row, { justifyContent: 'center', marginTop: 60 }]}>
          <ArendraIcon name='icon-spin-arendra' size={50} color={colors.secondary} />
        </View>
        <Text style={styles.p6}>{screen.aboutArendraModel.p6}</Text>
      </DesktopWrapper>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  infoContainer: {
    paddingVertical: 50,
    gap: 16,
  },
  title: {
    fontSize: 26,
    textAlign: 'center',
    marginBottom: 16,
  },
  text: {
    marginTop: 20,
    fontSize: 16,
  },
  textBold: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  row: {
    marginTop: 40,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 16,
  },
  iconContainer: {
    backgroundColor: '#fff',
    borderRadius: 30,
    height: 60,
    width: 60,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 6,
    borderColor: '#EEEAE6',
  },
  p6: {
    marginTop: 10,
    textAlign: 'center',
    maxWidth: 330,
    alignSelf: 'center',
  },
});

export default AboutArendraModelScreen;
