/* eslint-disable max-len */
import Messages from './types';

const sv: Messages = {
  screen: {
    department: {
      title: 'Hem',
      coworker: 'Medarbetare',
      day: 'Dag',
      status: 'Snitt (3 mån)',
      today: 'Idag',
      export: 'Exportera',
      addCoworker: 'Lägg till medarbetare',
      arendraModel: 'Arendramodellen',
      simplified: 'Förenklad',
      cost: 'Kostnad',
      time: 'Tid',
      timeToEvaluate: 'Dags att skatta dina medarbetare',
      welcome: 'Välkommen!',
      stepOneText: 'Steg 1 är att lägga till dina medarbetare, när du lagt till medarbetare kan du börja skatta.',
      name: 'Namn',
      groupAverage: 'Gruppens snitt',
      threeMonths: '3 mån',
      accordingToArendraModel: 'enl. Arendramodellen',
      month: 'månad',
      noCoworkers: 'Lägg till medarebetare för att komma igång',
      SEK: 'kr',
      SEKPerMonth: 'kr/mån',
      thousandSEK: 'tkr',
      thousandSEKPerMonth: 'tkr/mån',
      coworkersError: 'Något gick fel när medarbetare skulle hämtas',
    },
    login: {
      title: 'Logga in med mobilt BankID',
      logInWithBankID: 'Logga in med BankID',
      logInWithBankIDWeb: 'BankID på denna enhet',
      bankIdOnOtherDevice: 'Logga in med QR-kod',
      readQrCode: 'Läs av QR-koden med BankID-appen',
    },
    termsAndConditions: {
      title: 'Villkor',
      instructions: 'För att använda Arendras skattningsverktyg behöver du ange din e-post och godkänna våra villkor.',
      email: 'E-postadress',
      acceptTerms: 'Jag godkänner era',
      continue: 'Gå vidare',
      terms: 'allmänna villkor',
    },
    onBoarding: {
      title: 'Välkommen till Arendra-verktyget',
      subTitle: 'Klicka dig igenom guiden för att komma vidare',
      continue: 'Gå vidare',
      next: 'Nästa',
      stepOneTitle: 'Lägg till medarbetare',
      stepOneDescription: 'Börja med att lägga till dina medarbetare, deras lön och anställningsgrad.',
      stepTwoTitle: 'Skatta',
      stepTwoDescription: 'Fyll i hur dina medarbetare mår och presterar baserat på Arendramodellen.',
      stepThreeTitle: 'Resultat',
      stepThreeDescription: 'Se hur dina medarbetare mår, hur det påverkar företaget ekonomiskt och när du ska agera.',
    },
    contact: {
      title: 'Kontakt',
      send: 'Skicka',
      contactArendra: 'Kontakta Arendra',
      mutationSucceededMessage: 'Tack för ditt meddelande. Vi hör av oss så snart vi kan!',
    },
    roleSelection: {
      title: 'Välj roll',
      superAdmin: 'Superadmin',
      superAdminNotAvailable: 'Superadmin - endast på webben',
      youAreLoggedInAs: 'Du är inloggad som {{name}}',
    },
    evaluation: {
      title: 'Skattning',
      startEvaluation: 'Starta skattning',
      close: 'Stäng',
      evaluate: 'Skatta',
      activelySkip: 'Kan ej skatta',
      activelySkipText: 'För din egen uppföljning',
      activelySkipText2: 'Varför vill du avstå skattning?',
      offDuty: 'Tjänstledig',
      haveNotMet: 'Har ej träffat',
      absence: 'Frånvaro',
      other: 'Annan anledning',
      workPerformance: 'Arbetsprestation',
      followLawsAndDecisions: 'Följa lagar och regler',
      workEnvironmentResponsibility: 'Arbetsmiljöansvar',
      dutyOfLoyalty: 'Lojalitet',
      dutyToCooperate: 'Samarbete',
      cancel: 'Avbryt',
      done: 'Färdig',
      skip: 'Hoppa över',
      save: 'Spara',
      of: 'av',
      goodWork: 'Bra jobbat!',
      doneForToday: 'Nu är du klar för idag!',
      rememberToConfirm: 'Kom ihåg att bekräfta de gröna',
      activelySkipTitle: 'Vad beror det på?',
      statusBasisTitle: 'Vilka områden baseras dit val på?',
      seeGroupAverage: 'Se gruppens snitt',
      reEvaluate: 'Omvärdera skattning',
    },
    evaluationCoworkers: {
      title: 'Dagens skattningar',
      description: 'Dina medarbetare i lista från A-Ö. I högra kolumnen ser du hur du skattat respektive person idag.',
    },
    act: {
      title: 'Agera',
      actTitle: 'Dags att agera!',
      actSubTitle: 'Utifrån snitt, 3 månader',
      statusGrayTitle: 'ej skattade medarbetare',
      statusGrayText: 'Vad beror det på?',
      statusYellowTitle: 'medarbetare med statusen gul',
      statusYellowText: 'Har du pratat med din medarbetare?',
      statusYellowText2: 'Har du dokumenterat samtalen?',
      statusRedTitle: 'medarbetare med statusen orange',
      statusRedText: 'Har du haft dokumenterade samtal?',
      statusRedText2: 'Är ni överens om orsak?',
      contactTitle: 'Behöver du hjälp?',
      contactArendra: 'Kontakta Arendra!',
      mailOrPhone: 'Maila eller ring',
      formInfo: 'Fyll i formuläret så kontaktar vi dig',
      email: 'E-postadress',
      phone: 'Telefonnummer',
      send: 'Skicka',
      contactHR: 'Kontakta HR på ditt företag',
    },
    departments: {
      title: 'Avdelningar',
    },
    about: {
      title: 'Om Arendra',
      p1: 'Arendra verkar för god arbetshälsa, en modig kultur och framåtlutade kommunikativa ledare hos arbetsgivaren, för att nå en sundare arbetsmarknad och ett hållbart arbetsliv. Arendra jobbar för att fokus ska ligga på främjande och preventiva aktiviteter på arbetsplatser, minska inflödet i vår allmänna sjukförsäkring och sänka sjukskrivningstalen i Sverige.',
      p2: 'I våra tjänster tydliggör vi arbetsgivarens ansvar och medarbetarens ansvar och var gränsdragningarna bör gå för att nå de bästa förutsättningarna för god prestation utifrån arbetsrollen hos en individ, på gruppnivå och för hållbara organisationer.',
      p3: 'Vi bygger våra samarbeten efter utveckling tillsammans med våra kunder då vi ständigt mäter effekter och resultat av vårt arbete. Hos oss hittar du beteendevetare, hälsopedagoger, fysioterapeuter, arbetsterapeuter, studie- och yrkesvägledare, psykologer och socionomer med ett gemensamt mål i sikte – att bidra till att fler människor mår bra och orkar prestera i arbetslivet.',
      p4: '',
      link: `https://www.arendra.se/om-oss/`,
    },
    aboutArendraModel: {
      title: 'Om Arendramodellen',
      p1: 'År 2017 utvecklade vi Arendramodellen. Att kurvan går uppåt handlar om att ju längre vi väntar med att agera på tidiga och tydliga signaler i arbetet, desto mer ökar kostnaden för medarbetare, arbetsgivare och samhälle. Du som har arbetsledningsansvar för personal hos arbetsgivaren har skyldigheter, rättigheter och möjligheter för att skapa en god arbetsmiljö för alla. Det innebär att bekräfta medarbetares motivation och prestation men även våga se och agera på medarbetare du är oroad över på grund av förändrade beteenden, så kallad riskbedömning.',
      p2: 'Med det här verktyget får du hjälp att minnas det du ser, för att inte tappa bort möjligheten att agera. Dessutom kopplar vi ihop riskbeteenden med ekonomiska incitament i form av produktionsbortfall, eller värdeminskning av utfört arbete, som riskerar uppstå. Studier visar att människor i alla lägen mår bra av ett tydligt och respektfullt bemötande. Energin är som störst under strecket i modellen, både hos chef och medarbetare.',
      p3: 'När vi medvetandegörs har vi större möjligheter att ta ansvar för vår situation och själva göra det som krävs för att välja ett hållbart arbetsliv eller få hjälp med aktiva insatser där du som arbetsgivare är delaktig. Stöd för skattning är ett hjälpmedel för din skattning. De olika kategorierna härstammar ifrån arbetsledningsrätten, arbetsplikten, lojalitetsplikten men även gällande arbetsmiljöföreskrifter om att vi alla är ansvariga för att skapa en god arbetsmiljö för varandra.',
      p4: 'Arendramodellen ger er möjlighet att tillsammans systematiskt reflektera och agera, inte minst för att eftersträva en god organisatorisk och social arbetsmiljö.',
      p5: 'I samtliga nivåer ovan kan en omplacering internt vara en möjlighet om förut-sättningarna för detta finns i verksamheten. Gjord omplacering skall alltid utvärderas. Vi rekommenderar även arbetsgivare att reflektera över om det finns förutsättningar till en bättre arbetsförmåga utanför organi-sationen och hur ni i så fall kan hjälpa medarbetaren på vägen. I väldigt många fall leder detta till att medarbetaren återfår del- eller hel arbetsförmåga.',
      p6: 'Arendramodellen ägs av Arendra och är även skyddad av Patent- och Registreringsverket, PRV. Det är därmed otillåtet att sprida, tillverka, sälja eller importera kopior av den.',
      statusGreen: 'Normalpresterare eller högpresterare. Här är det viktigt att specifikt bekräfta medarbetaren för att upprätthålla en god prestation.',
      statusYellow: 'Tidiga signaler på förändrad motivation, prestation eller hälsa. Signalerna kan vara många. Ställ dig frågan om de kommer från arbetslivet, privatlivet eller hälsan. Våga fråga och lyssna. Berätta för medarbetaren vad du ser och om din oro. Följ upp genom att be medarbetaren själv reflektera över vad som kan förbättra situationen. Kom ihåg att dokumentera. Fundera på om ett internt eller externt stöd kan förbättra möjligheterna att gå till grönt läge igen.',
      statusOrange: 'Tydliga signaler på försämrad motivation, prestation eller hälsa. Signalerna kan vara många. Berätta för medarbetaren vad du ser och var mycket tydlig med din oro. Ställ dig frågan om signalerna kommer från arbetslivet, privatlivet eller hälsan. Våga fråga och lyssna. Be medarbetaren själv reflektera över eget ansvar och vad som kan förbättra situationen. Följ upp och använd tidigare dokumentation. Gör en tidsatt handlingsplan tillsammans med målbild och tydliggör även konsekvenser. Fundera på om ett internt eller externt stöd kan förbättra möjligheterna att gå till grönt läge igen.',
      statusRed: 'Korttidsfrånvaro eller sjukskrivning. Ha koll på er verksamhets rutiner avseende antal tillfällen för att påbörja en handlingsplan och/eller en rehabiliteringsplan om arbetsförmågan är nedsatt på grund av sjukdom. Om medarbetaren förväntas vara sjukskriven i mer än 60 dagar och ett läkarintyg finns, se till att du har en bedömd prognos om när medarbetaren förväntas vara åter och säkerställ en god kontakt med medarbetaren och eventuellt sjukskrivande läkare för att alla parter ska kunna delta aktivt i rehabiliteringsplanen. Kom ihåg att avsluta den när medarbetaren åter är i arbete med förväntad arbetsprestation.',
      statusBlack: 'Utanförskap. Medarbetare som varit sjukskrivna återkommande i långa perioder. Självförtroende och självkänsla försämras ofta kraftigt vilket över tid påverkar medarbetarens förutsättningar på arbetsmarknaden.',
      green: 'Grön',
      yellow: 'Gul',
      orange: 'Orange',
      red: 'Röd',
      black: 'Svart',
    },
    editCoworker: {
      title: 'Redigera medarbetare',
      deleteCoworkerTitle: 'Ta bort medarbetare',
      deleteCoworkerText: 'Är du säker på att du vill ta bort {{coworkerToDelete}}?',
      workPercentage: 'Anställningsgrad',
      salary: 'Månadslön',
      firstName: 'Förnamn',
      lastName: 'Efternamn',
    },
    coworker: {
      title: 'Medarbetare',
      edit: 'Redigera',
      deleteCoworker: 'Ta bort medarbetare',
      status: 'Status',
      averageTitle: 'Snitt (3 mån)',
      cost: 'Kostnad',
      history: 'Historik',
      evaluations: 'Skattningar',
      summary: 'Sammanfattning',
      estimatedCost: 'Uppskattad värdeminskning per månad',
      SEK: 'kr',
    },
    addCoworker: {
      title: 'Lägg till medarbetare',
      add: 'Lägg till',
      save: 'Spara',
      name: 'Namn',
      firstName: 'Förnamn',
      lastName: 'Efternamn',
      workPercentage: 'Anställningsgrad',
      salary: 'Månadslön',
      edit: 'Redigera',
    },
    cheatSheet: {
      title: 'Stöd för skattning',
      firstDescription: 'Fundera på om följande påståenden fungerar väl, brister delvis, eller brister påtagligt. Bedöm sedan hur du vill skatta personen.',
      secondDescription: 'Om du av någon anledning inte kan skatta personen väljer du ”Kan ej skatta”.',
      statusGreen: 'Fungerar väl',
      statusYellow: 'Brister delvis',
      statusRed: 'Brister påtagligt',
      sections: [
        {
          title: 'Arbetsprestation',
          list: [
            'Förväntad normalprestation',
            'Förväntad närvaro',
            'Förmår ta hand om hälsan för att klara sitt arbete',
            'Tar ansvar för återhämtning på arbetstid',
          ],
        },
        {
          title: 'Följa regler och beslut',
          list: [
            'Säkerställer att regler följs',
            'Förstår och följer policy/code of conduct',
          ],
        },
        {
          title: 'Arbetsmiljöansvar',
          list: [
            'Är motiverad i sitt arbete och på arbetsplatsen',
            'Kommunicerar tydligt',
            'Bidrar till en god arbetsmiljö socialt och mentalt',
            'Förmår se egna brister och ta konstruktiv feedback',
            'Har en trevlig attityd',
          ],
        },
        {
          title: 'Lojalitet med arbetsgivaren',
          list: [
            'Förmår hantera sitt privatliv utanför arbetstid',
            'Respekterar tystnadsplikten',
            'Arbetsgivarens intresse går före de egna på arbetstid',
            'Visar förväntad lojalitet i arbetet',
            'Är konstruktivt ifrågasättande',
          ],
        },
        {
          title: 'Samarbete i arbetet',
          list: [
            'Vill växa med kollegor',
            'Jobbar tillsammans i de uppgifter som kräver det',
            'Ser till verksamhetens bästa',
            'Kan och vill samarbeta',
          ],
        },
      ],
    },
    superAdmin: {
      companies: {
        title: 'Företag',
        addCompany: 'Lägg till företag',
        company: 'Företag',
        orgNumber: 'Organisationsnummer',
        endDate: 'Slutdatum',
        email: 'E-post',
        phoneNumber: 'Telefonnummer',
        phone: 'Telefon',
        dateFormat: 'ÅÅÅÅ-MM-DD',
        deleteCompany: 'Ta bort företag',
        deleteText: 'Är du säker på att du vill ta bort {{companyToDelete}}?',
        started: 'Påbörjad',
      },
      company: {
        title: 'Avdelning',
        addDepartment: 'Lägg till avdelning',
        addSubDepartment: 'Lägg till underavdelning',
        cost: 'Kostnad',
        admin: 'Ansvarig',
        firstName: 'Förnamn',
        lastName: 'Efternamn',
        personalNumberFormat: 'ÅÅÅÅMMDD-XXXX',
        deleteDepartment: 'Ta bort avdelning',
        deleteText: 'Är du säker på att du vill ta bort {{departmentToDelete}}?',
        deleteManager: 'Ta bort ansvarig',
        deleteManagerText: 'Är du säker på att du vill ta bort {{managerToDelete}}?',
        importOrganization: 'Importera organisation',
        chooseFile: 'Välj fil',
        import: 'Importera',
      },
      manage: 'Hantera',
      add: 'Lägg till',
      save: 'Spara',
      cancel: 'Avbryt',
      delete: 'Ta bort',
    },
    settings: {
      title: 'Inställningar',
      loggedInAs: 'Du är inloggad som',
      emailPlaceholder: 'min-epost@mail.se',
      edit: 'Redigera',
    },
    costInfo: {
      title: 'Uppskattad värdeminskning',
      p1: `Uppskattad värdeminskning, det vill säga den uppskattade förbättringspotentialen, i medarbetarens arbetsinsats - utifrån dess lönekostnader. Värdeminskningen kan bero på förändrade beteenden eller förhållanden som bedöms inverka negativt på medarbetarens prestation, motivation eller hälsa.`,
      p2: `Historiskt har organisationer vanligen endast räknat produktionsbortfall som kommer från sjukfrånvaro eller stillastående maskiner. Då vi idag vet att en mängd orsaker som leder till bristande prestation, motivation och/eller hälsa, ofta innan ens sjukfrånvaro uppstått, är det rimligt att arbetsgivaren utifrån ett riskperspektiv tar ansvar att föra dialog med sina medarbetare även där.`,
      p3: `Risker kan finnas både i medarbetarens privatliv eller hälsa, men även på arbetet i form av en dålig arbetsmiljö, dåligt ledarskap eller i att medarbetarens motivation och vilja att bidra till verksamheten helt enkelt minskat avsevärt.`,
      p4: `Att agera i god tid är helt avgörande för ett seriöst proaktivt arbete, för att minimera skada för personal och organisation och öka chanserna till långsiktig och hälsosam hållbarhet. Även förståelsen att ett underlåtande att agera även påverkar individ, grupp och organisation negativt ekonomiskt.`,
    },
    integrityPolicy: {
      title: 'Integritetspolicy',
      p1: {
        title: 'Allmänt',
        text: 'Arendra tar personlig integritet på allvar och har arbetat med ett flertal åtgärder för att säkerställa skyddet för den personliga integriteten och för att följa reglerna i Dataskyddsförordningen (GDPR).',
      },
      p2: {
        title: 'Behandling av uppgift om värderande omdömen',
        text: 'Det finns inga hinder för en arbetsgivare att behandla uppgift om värderande omdömen om medarbetare i syfte att identifiera tecken på ohälsa eller sjukdom, eller för att förebygga samarbetssvårigheter etc. Tvärtom är det arbetsgivarens rätt och skyldighet – med stöd av arbetsledningsrätten och arbetsmiljöregler – att säkerställa en god arbetsmiljö och att förebygga ohälsa och sjukdom. Se nedan för deras bedömning av rättslig grund för behandling av uppgifter om medarbetare. För att minska det potentiella integritetsintrånget så långt som möjligt bygger Arendras modell på standardiserade frågor och svar, se nästa punkt.',
      },
      p3: {
        title: 'Uppgiftsminimering',
        text: 'Arendras modell innebär att vi så långt som det är möjligt arbetar med standardiserade frågor anpassade för ett antal olika situationer. Genom att minimera antalet fritextfält säkerställer vi att de svar och de frågor som ställs är adekvata och relevanta och inte innebär att kunden får del av onödig information. Allt för att säkerställa att våra kunder kan efterleva de grundläggande kraven om bl.a. uppgiftsminimering i artikel 5 i GDPR.',
      },
      p4: {
        title: 'Privacy by Design and Default',
        text: 'Vår plattform har en mycket hög säkerhetsstandard som bl.a. innebär att infoga säkerhetsstandarder, t.ex. kryptering i transit och at rest. Inloggning till vår plattform kommer att ske via s.k. stark autentisering (t.ex. BankID) för att uppfylla Integritetsskyddsmyndighetens riktlinjer.',
      },
      p5: {
        title: 'Vem är personuppgiftsansvarig',
        text: 'För tjänsterna i detta digitala verktyg är Arendra ett personuppgiftsbiträde och kommer bl.a. att efterleva kraven i artikel 28 i GDPR och t.ex. se till att det med varje kund finns ett personuppgiftsbiträdesavtal som tydligt pekar ut kundens och Arendras ansvarsområden.',
      },
      p6: {
        title: '',
        text: 'Kunden kommer att vara personuppgiftsansvarig enligt reglerna i GDPR och det åvilar således kunden att tillse att kundens hantering av de personuppgifter som förekommer har stöd i GDPR. Såvitt avser kundens hantering i allmänhet av personuppgifter inom ramen för verktyget är det Arendras uppfattning att kunderna kommer att kunna stödja sin behandling av personuppgifter dels på artikel 6.1 c) (rättslig förpliktelse, berättigat intresse) och artikel 9.2 b) i GDPR, innebärande att kunderna (arbetsgivaren) kan uppfylla sina skyldigheter enligt arbetsmiljölagstiftningen. För att underlätta för våra kunder har vi tagit fram ett paket med bl.a. informationstexter enligt GDPR och schematisk bedömning av arbetsgivarens rätt och möjlighet att hantera personuppgifter inom ramen för Arendras digitala verktyg.',
      },
    },
    userTerms: {
      title: 'Användarvillkor för Arendra-verktyget',
      text1: 'Dessa användarvillkor \b("Användarvillkoren")\b gäller för din användning av Arendras digitala verktyg för uppföljning av medarbetares mående och prestationer enligt den så kallade Arendramodellen ("Arendra-verktyget"). För mer information om Arendramodellen, se vår webbplats www.arendra.se ("Webbplatsen"). Användarvillkoren för Arendra-verktyget ingås mellan dig som användare ("du") och Arendra AB, org. nr. 559124-0667, ("Arendra", "vi", "oss", "vår").',
      text2: 'Arendra-verktyget utgörs dels av en webbapplikation som är tillgänglig på app.arendra.se, dels av en mobilapp ("Arendra-appen"). En beskrivning av Arendra-verktyget finns på Webbplatsen. Verktyget är tillgängligt både på svenska och engelska. Vänligen läs igenom dessa Användarvillkor noggrant innan du registrerar ett användarkonto för användning av Arendra-verktyget.',
      useOfArendraTool: {
        title: 'Användning av Arendra-verktyget',
        p1: {
          title: 'Tillgång till Arendra-verktyget',
          text1: 'För att använda Arendra-verktyget krävs (i) att din arbetsgivare eller det företag som du arbetar för har ett giltigt avtal med Arendra avseende användning av Arendra-verktyget, (ii) att den enhet som du använder uppfyller systemkraven (se nedan), samt (iii) att du registrerar ett användarkonto i Arendra-verktyget genom att tillhandahålla den information som efterfrågas.',
          text2: 'Arendra-verktyget är kostnadsfritt för dig som användare, eftersom din användning omfattas av avtalet mellan din arbetsgivare eller det företag som du arbetar för och Arendra, vilket också reglerar kostnaden för användningen av Arendra-verktyget. Arendra-verktyget är inte tillgängligt för privatpersoner (konsumenter).',
        },
        p2: {
          title: 'Användning av Arendra-verktyget',
          text1: 'Genom att registrera ett användarkonto för användning av Arendra-verktyget bekräftar du att (i) du har tillhandahållit korrekt och fullständig information som efterfrågats under registreringsprocessen och att du kommer att hålla denna information uppdaterad, (ii) du inte gör intrång i någon tredje parts rättigheter (till exempel genom att tillhandahålla information tillhörande tredje parter såsom deras namn eller e-postadress när du registrerar ett användarkonto), och att (iii) du endast kommer att använda Arendra-verktyget för de ändamål som det utvecklats och skapats för och för lagliga ändamål.',
          text2: 'Du ansvarar för att hålla dina inloggningsuppgifter till ditt användarkonto hemliga och säkerställa att ingen obehörig använder ditt användarkonto. Du ansvarar för den användning av Arendra-verktyget som sker genom ditt användarkonto. Om du misstänker att någon obehörig använder ditt användarkonto meddela oss omedelbart och ändra ditt lösenord så snart som möjligt.',
          text3: 'Du accepterar att du inte får utan Arendras på förhand lämnade skriftliga godkännande:',
          listItem1: 'kopiera Arendra-verktyget eller Arendra-appen (på annat sätt än att installera Arendra-appen på din mobila enhet genom den appbutik som appen tillgängliggörs),',
          listItem2: 'ändra, översätta, anpassa eller på annat sätt utarbeta härledda verk eller förbättringar av Arendra-verktyget,',
          listItem3: 'demontera, dekompilera, avkoda, så kallat reverse engineer eller på annat sätt försöka härleda eller få tillgång till källkoden för Andra-verktyget eller någon del av verktyget i större utsträckning än vad som är tillåtet enligt lag,',
          listItem4: 'ta bort, radera, ändra eller dölja varumärken eller andra immateriella rättigheter i Arendra-verktyget,',
          listItem5: 'hyra, låna ut, sälja, vidarelicensiera, tilldela, distribuera, publicera, offentliggöra eller visa, överföra eller på annat sätt tillgängliggöra Arendra-verktyget till någon tredje part,',
          listItem6: 'ta bort, inaktivera, eller på annat sätt kringgå det kopieringsskydd, system för förvaltning av rättigheter (DRM-system) eller säkerhetsfunktioner i eller som skyddar Arendra-verktyget, eller',
          listItem7: 'ladda upp eller införa virus, trojaner, maskar, spionprogram, sabotageprogram, time-bombs eller andra skadliga komponenter till Arendra-verktyget.',
          text4: 'Om du (enligt vår mening) agerar i strid med dessa Användarvillkor, tillämpliga lagar eller föreskrifter, eller i övrigt missbrukar Arendra-verktyget, har vi rätt att (i) begränsa, stänga av eller neka dig tillgång till Arendra-verktyget, (ii) vidta tekniska och/eller rättsliga åtgärder för att förhindra dig från vidare användning av Arendra-vertyget, och (iii) säga upp och/eller avsluta din användning eller tillgång till Arendra-verktyget med omedelbar verkan, inklusive genom att ta bort ditt användarkonto. Detta begränsar inte ytterligare åtgärder som Arendra kan ha rätt att vidta enligt lag eller dessa Användarvillkor till följd av ditt brott mot dessa Användarvillkor.',
        },
        p3: {
          title: 'Systemkrav',
          text1: 'Användning av Arendra-verktyget kräver en kompatibel enhet med ett operativsystem som stödjs och åtkomst till Internet. Såvitt avser användning av Arendra-appen krävs att du också har laddat ner Arendra-appen från en appbutik där appen tillgängliggörs och installerat Arendra-appen på din mobila enhet. Om du använder webbapplikationen krävs också att du använder en kompatibel webbläsare.',
          text2: 'Du är ensamt ansvarig för din egen Internet-uppkoppling för att kunna använda Arendra-verktyget.',
          text3: 'Information rörarande kompatibla enheter, operativsystem och webbläsare finns tillgängligt på vår Webbplats. Detaljerad information om vilka systemkrav som gäller för Arendra-appen finns även direkt i den appbutik där Arendra-appen tillgängliggörs.',
          text4: 'Vi kommer att underrätta dig på förhand om vi ändrar systemkraven för Arendra-verktyget på lämpligt sätt, till exempel genom e-post eller, såvitt avser Arendra-appen, genom den appbutik som Arendra-appen tillgängliggörs (till exempel Apple App Store).',
          text5: 'Du ansvarar för att uppdatera din enhet och ditt operativsystem för att uppfylla systemkraven. Om du inte uppdaterar din enhet och ditt operativsystem kan det innebära att du inte kan använda Arendra-verktyget, inklusive Arendra-appen.',
        },
        p4: {
          title: 'Uppdateringar till Arendra-verktyget',
          text1: 'Du accepterar att vi, från tid till annan, kan utfärda uppdaterade versioner av Arendra-verktyget, som kan innehålla till exempel uppgraderingar och felkorrigeringar, och att vi kan automatiskt uppdatera den version av Arendra-verktyget som du använder. Du accepterar också att dessa Användarvillkor gäller för sådana uppdaterade versioner av Arendra-verktyget.',
          text2: 'Du är också införstådd med och accepterar att en uppdatering kan innebära att funktionalitet tillkommer och/eller försvinner i Arendra-verktyget.',
          text3: 'Vi kommer att informera dig om nya versioner av Arendra-verktyget, inklusive Arendra-appen, på lämpligt sätt, till exempel via e-post, i verktyget eller, såvitt avser Arendra-appen, genom den appbutik där Arendra-appen tillgängliggörs.',
          text4: 'Du ansvarar för att uppdatera Arendra-appen på din mobila enhet. Beroende på inställningarna på din mobila enhet kan Arendra-appen automatiskt installeras och uppdateras eller så får du information om att en uppdatering är tillgänglig. Om du inte uppdaterar Arendra-appen på din mobila enhet till den senaste versionen kan det innebära att Arendra-appen inte fungerar som den ska eller alls.',
        },
        p5: {
          title: 'Kostnader för dataanvändning',
          text1: 'Du ansvarar för kostnader och avgifter hänförliga till din egen Internet-trafik och dataanvändning som kan förekomma när du ges tillgång till och använder Arendra-verktyget.',
        },
        p6: {
          title: 'Tillgänglighet',
          text1: 'Arendra-verktyget tillhandahålls "som tillgängligt". Detta innebär att vi inte garanterar att Arendra-verktyget alltid är helt tillgängligt, till exempel till följd av oplanerat underhåll eller tekniska problem. Du bekräftar och godtar att Arendra-verktyget kan vara otillgängligt till följd av oplanerad service eller oplanerat underhåll, tekniska problem och/eller uppdateringar.',
        },
      },
      rightsAndOwnership: {
        title: 'Rättigheter och äganderätt',
        p1: {
          title: 'Licens att använda Arendra-verktyget',
          text1: 'Du ges härmed en icke-exklusiv, icke-överlåtbar, återkallelig och begränsad rätt och licens att använda Arendra-verktyget, inklusive Arendra-appen, i enlighet med dessa Användarvillkor.',
          text2: 'Din rätt att använda Arendra-verktyget är villkorat dels av att du följer dessa Användarvillkor, dels att din arbetsgivare eller det företag som du arbetar för har ett giltigt avtal med Arendra om användning av Arendra-verktyget. Om avtalet mellan din arbetsgivare eller det företag som du arbetar för och Arendra avseende användning av Arendra-verktyget upphör (oavsett anledning till detta) upphör även din rätt och licens att använda Arendra-verktyget enligt dessa Användarvillkor.',
        },
        p2: {
          title: 'Äganderätt',
          text1: 'Allt innehåll i Arendra-verktyget, inklusive text, grafik, logotyper, ikoner, bilder, källkod och/eller annat hänförligt material ägs eller licenseras av oss. Ingenting i dessa Användarvillkor ska tolkas som att någon av våra eller våra licensgivares materiella eller immateriella rättigheter överförs eller överlåts till dig.',
          text2: 'Tredje parters rättigheter i Arendra-verktyget licensieras till dig antingen enligt avtal eller i enlighet med respektive tredje parts licens för sådan mjukvara. Användarvillkoren för sådan tredje parts-mjukvara finns publicerad i Arendra-verktyget.',
        },
      },
      dataProtection: {
        title: 'Dataskydd',
        p1: {
          title: 'Personuppgiftsansvar',
          text1: 'Din arbetsgivare eller det företag som du arbetar för är personuppgiftsansvarig för behandlingen av dina personuppgifter i samband med att du använder Arendra-verktyget. Arendra är ett så kallat personuppgiftsbiträde i förhållande till din arbetsgivare eller det företag som du arbetar för och behandlar dina personuppgifter för att tillhandahålla Arendra-verktyget och enligt de instruktioner som din arbetsgivare eller det företag som du arbetar för lämnar.',
          text2: 'Arendra ansvarar dock för behandlingen av dina personuppgifter i samband med anonymisering av dina personuppgifter för att analysera, följa upp och utvärdera hur Arendra-verktyget används och för test och utveckling av Arendra-verktyget.',
        },
        p2: {
          title: 'Information om vår behandling av personuppgifter',
          text1: 'För information om vår behandling av personuppgifter för egen räkning, se vår informationstext om behandling av personuppgifter på vår Webbplats.',
        },
      },
      agreementTimeAndTermination: {
        title: 'Avtalstid och uppsägning',
        p1: {
          title: 'Avtalstid',
          text1: 'Dessa Användarvillkor gäller från och med att du börjar använda Arendra-verktyget eller hämtar och installerar Arendra-appen på din mobila enhet och under den tid som det finns ett giltigt avtal mellan din arbetsgivare eller det företag som du arbetar för och Arendra avseende användning av Arendra-verktyget eller den tidigare tidpunkt som Användarvillkoren upphör.',
        },
        p2: {
          title: 'Uppsägning',
          text1: 'Du kan när som helst säga upp dessa Användarvillkor genom att sluta använda Arendra-verktyget och begära radering av ditt användarkonto. Du kan begära radering av ditt användarkonto genom att kontakta oss via e-post på följande adress: support@arendra.se.',
          text2: 'Dessa Användarvillkor upphör även automatiskt:',
          listItem1: 'om avtalet avseende användning av Arendra-verktyget mellan din arbetsgivare eller det företag som du arbetar för och Arendra upphör (oavsett anledning därtill), och/eller',
          listItem2: 'om din anställning eller ditt uppdrag upphör (oavsett anledning därtill) hos den arbetsgivare eller det företag som du arbetar för och som har ingått avtalet avseende användning av Arendra-verktyget.',
          text3: 'Användarvillkoren upphör i sådant fall på samma dag som det relevanta avtalsförhållandet upphör',
        },
        p3: {
          title: 'Konsekvenser vid upphörande',
          text1: 'I samband med Användarvillkorens upphörande (oavsett anledning till detta): (i) upphör din rätt att använda Arendra-verktyget, (ii) måste du upphöra med din användning av Arendra-verktyget och avinstallera Arendra-appen från din mobila enhet, samt (iii) kommer din tillgång till Arendra-verktyget och ditt användarkonto att upphöra.',
        },
        p4: {
          title: 'Fortsatt giltighet av vissa villkor',
          text1: 'Punkterna 2 (Rättigheter och Äganderätt), 6 (Ansvar), 8 (Övrigt) och 9 (Tillämplig lag och Tvister) ska fortsätta gälla även efter det att dessa Användarvillkor har upphört att gälla oavsett anledning till detta.',
        },
      },
      changesToTheseTerms: {
        title: 'Ändringar till dessa användarvillkor',
        p1: {
          text1: 'Vi kan, från tid till annan, göra ändringar till dessa Användarvillkor. Vi kommer att meddela dig trettio (30) dagar på förhand om ändringar till dessa Användarvillkor på lämpligt sätt, till exempel genom e-post eller via Arendra-verktyget. Om du inte motsätter dig de uppdaterade Användarvillkoren och fortsätter att använda Arendra-verktyget efter det att de uppdaterade Användarvillkoren har börjat gälla anses du ha godkänt de uppdaterade Användarvillkoren. Om du inte vill godkänna de uppdaterade Användarvillkoren kan du inte fortsätta använda Arendra-verktyget efter det att de uppdaterade Användarvillkoren har börjat gälla.',
        },
      },
      responsibility: {
        title: 'Ansvar',
        p1: {
          title: 'Reklamationstid',
          text1: 'Om du vill göra gällande att det föreligger ett fel i Arendra-verktyget som vi ansvarar för ska du underrätta oss (reklamera) detta inom två (2) månader från den tidpunkt då du fick kännedom om eller borde ha fått kännedom om felet. Du kan underrätta oss på kontaktuppgifterna nedan. När du har underrättat oss kommer vi att bekräfta mottagandet av din underrättelse och därefter återkoppla till dig så snart som möjligt för att hantera ditt ärende.',
        },
        p2: {
          title: 'Ansvarsbegränsning',
          text1: 'Vi är inte skyldiga för: (i) förluster eller skador som härrör från eller har samband med användningen av, eller oförmågan att använda, Arendra-verktyget, eller användningen av eller förlitandet på innehållet i Arendra-verktyget, (ii) indirekta förluster, följdförluster eller skador, (iii) uteblivna vinster, (iv) avbrott i verksamhet, (v) förlust av förväntande besparingar, (vi) förlust eller skada av affärsmöjlighet, goodwill eller rykte, eller (vii) förlust till följd av driftstopp, förlust av data eller liknande förluster.',
          text2: 'Vårt ansvar för skador som uppkommer under eller i samband med dessa Användarvillkor är begränsat till, per kalenderår, ett belopp motsvarande ett prisbasbelopp enligt socialförsäkringsbalken (2010:110).',
        },
        p3: {
          title: 'Undantag',
          text1: 'Ansvarsbegränsningen i dessa Användarvillkor gäller inte om skadan uppkommit till följd av grov oaktsamhet eller uppsåt.',
        },
      },
      specialTermsForArendraApp: {
        title: 'Särskilda villkor för Arendra-appen',
        text1: 'Om du har laddat ner Arendra-appen från Apple App Store eller om du använder Arendra-appen på en iOS-baserad mobil enhet bekräftar du att du har läst, förstått och accepterar följande villkor:',
        listItem1: 'Dessa Användarvillkor är mellan dig och oss, och inte med Apple Inc. ("Apple"), och Apple är inte ansvarig för Arendra-verktyget eller innehållet däri.',
        listItem2: 'Apple har ingen som helst skyldighet att tillhandahålla underhålls- och supporttjänster avseende Arendra-verktyget.',
        listItem3: 'I händelse av att Arendra-verktyget inte överensstämmer med någon tillämplig garanti har du möjlighet att underrätta Apple om detta och Apple kommer då att återbetala tillämpligt inköpspris för Arendra-verktyget till dig (notera dock att Arendra-appen är kostnadsfri för dig enligt ovan), och i den utsträckning det är tillåtet enligt tillämplig lag, har Apple ingen som helst skyldighet eller garantiförpliktelse för Arendra-verktyget.',
        listItem4: 'Apple ansvarar inte för att hantera några anspråk mellan dig och någon tredje part avseende Arendra-verktyget eller din användning av Arendra-verktyget, inklusive: (i) produktansvarskrav, (ii) anspråk innebärande att Arendra-verktyget inte uppfyller gällande juridiska eller regulatoriska krav, (iii) anspråk enligt gällande konsumenträttslig eller liknande lagstiftning, samt (iv) eventuella krav avseende intrång i immateriella rättigheter.',
        listItem5: 'Apple ansvarar inte för eventuella krav från tredje part att Arendra-verktyget eller ditt innehav eller användning av Arendra-verktyget innebär ett intrång i tredje parts immateriella rättigheter.',
        listItem6: 'Apple och dess koncernbolag är berättigade tredje parter till dessa Användarvillkor och genom att acceptera dessa Användarvillkor har Apple rätt (och ska anses ha accepterat en sådan rätt) att göra Användarvillkoren gällande mot dig som en berättigad tredje part till dessa Användarvillkor.',
      },
      other: {
        title: 'Övrigt',
        p1: {
          title: 'Hela avtalet',
          text1: 'Dessa Användarvillkor utgör hela avtalet mellan dig och oss avseende användningen av Arendra-verktyget.',
        },
        p2: {
          title: 'Överlåtelse',
          text1: 'Vi har rätt att överlåta hela eller delar av våra rättigheter och skyldigheter enligt dessa Användarvillkor till en tredje part utan ditt samtycke. Du kan dock inte överföra dina rättigheter eller skyldigheter enligt dessa Användarvillkor utan vårt på förhand lämnade skriftliga samtycke.',
        },
        p3: {
          title: 'Icke-avstående',
          text1: 'Om en part underlåter att utöva en rättighet enligt dessa Användarvillkor ska detta inte innebära att parten avstår från fortsatt utövande av denna rättighet. En parts avstående från att vidta åtgärder med anledning av en överträdelse av någon bestämmelse i dessa Användarvillkor innebär inte att sådant avstående ska gälla för efterföljande överträdelser av sådan eller annan bestämmelse i dessa Användarvillkor.',
        },
        p4: {
          title: 'Enskilda bestämmelsers ogiltighet',
          text1: 'Om någon bestämmelse i dessa Användarvillkor är eller blir ogiltig, olaglig eller verkningslöst enligt tillämplig lag, ska giltigheten, lagligheten och verkan av resterande bestämmelser i dessa Användarvillkor inte påverkas. Om en bestämmelse i ett förfarande förklaras ogiltig, olaglig eller verkningslöst ska sådan bestämmelse ersättas med en ny bestämmelse som är förenlig med tillämplig lag och med en verkan som är så lik den ursprungliga bestämmelsen som möjligt.',
        },
      },
      applicableLawAndDisputes: {
        title: 'Tillämplig lag och tvister',
        p1: {
          title: 'Tillämplig lag',
          text1: 'Dessa Användarvillkor omfattas av svensk lag, utan beaktande av bestämmelser om motstridiga regler om lagval.',
        },
        p2: {
          title: 'Tvister',
          text1: 'Eventuella tvister och anspråk som uppstår till följd av eller i samband med dessa Användarvillkor, eller brott mot, uppsägning eller ogiltigförklaring av dessa ska slutligt avgöras av svensk domstol.',
        },
      },
      contactInformation: {
        title: 'Kontaktuppgifter',
        text1: 'Om du har frågor rörande Arendra-verktyget, vänligen kontakta oss på: info@arendra.se.',
        email: 'E-post',
        text2: 'Kul att du använder Arendra-verktyget!',
      },
    },
    status: {
      greenTitle: 'medarbetare med statusen grön',
      yellowTitle: 'medarbetare med statusen gul',
      orangeTitle: 'medarbetare med statusen orange',
      greenWhatDoesItMean: 'Vad betyder det om en person är grön?',
      yellowWhatDoesItMean: 'Vad betyder det om en person är gul?',
      orangeWhatDoesItMean: 'Vad betyder det om en person är orange?',
    },
  },
  statuses: {
    worksWell: 'Fungerar väl',
    partiallyLacking: 'Brister delvis',
    noticeablyLacking: 'Brister påtagligt',
    couldNotEvaluate: 'Kan ej skatta',
    skipped: 'Skippad',
  },
  clientErrors: {
    fillOutAllFields: 'Fyll i alla fält',
    invalidEmail: 'Ogiltig e-postadress',
    invalidPersonalNumber: 'Ogiltigt personnummer',
    invalidEndDate: 'Ogiltigt slutdatum',
    salaryNotANumber: 'Lön måste vara ett nummer',
  },
  menu: {
    arendraModel: 'Om Arendramodellen',
    about: 'Om Arendra',
    settings: 'Inställningar',
    userTerms: 'Användarvillkor',
    integrityPolicy: 'Integritetspolicy',
    contactArendra: 'Kontakta Arendra',
    logout: 'Logga ut',
  },
  // LAST!
  bankIdErrors: {
    RFA1: 'Starta BankID-appen.',
    RFA2: 'Du har inte BankID-appen installerad. Kontakta din bank.',
    RFA3: 'Åtgärden avbruten. Försök igen',
    RFA4: 'En identifiering eller underskrift för det här personnumret är redan påbörjad. Försök igen.',
    RFA5: 'Internt tekniskt fel. Försök igen.',
    RFA6: 'Åtgärden avbruten.',
    RFA8: 'BankID-appen svarar inte. Kontrollera att den är startad och att du har internetanslutning. Om du inte har något giltigt BankID kan du skaffa ett hos din bank. Försök sedan igen.',
    RFA9: 'Skriv in din säkerhetskod i BankID-appen och välj Identifiera eller Skriv under.',
    RFA13: 'Försöker starta BankID-appen.',
    RFA14A: 'Söker efter BankID, det kan ta en liten stund... Om det har gått några sekunder och inget BankID har hittats har du sannolikt inget BankID som går att använda för den aktuella identifieringen/underskriften i den här datorn. Om du har ett BankID-kort, sätt in det i kortläsaren. Om du inte har något BankID kan du skaffa ett hos din bank. Om du har ett BankID på en annan enhet kan du starta din BankID-app där.',
    RFA14B: 'Söker efter BankID, det kan ta en liten stund... Om det har gått några sekunder och inget BankID har hittats har du sannolikt inget BankID som går att använda för den aktuella identifieringen/underskriften i den här enheten. Om du inte har något BankID kan du skaffa ett hos din bank. Om du har ett BankID på en annan enhet kan du starta din BankID-app där.',
    RFA15A: 'Söker efter BankID, det kan ta en liten stund... Om det har gått några sekunder och inget BankID har hittats har du sannolikt inget BankID som går att använda för den aktuella identifieringen/underskriften i den här datorn. Om du har ett BankID-kort, sätt in det i kortläsaren. Om du inte har något BankID kan du skaffa ett hos din bank.',
    RFA15B: 'Söker efter BankID, det kan ta en liten stund... Om det har gått några sekunder och inget BankID har hittats har du sannolikt inget BankID som går att använda för den aktuella identifieringen/underskriften i den här enheten. Om du inte har något BankID kan du skaffa ett hos din bank.',
    RFA16: 'Det BankID du försöker använda är för gammalt eller spärrat. Använd ett annat BankID eller skaffa ett nytt hos din bank.',
    RFA17A: 'BankID-appen verkar inte finnas i din dator eller mobil. Installera den och skaffa ett BankID hos din bank. Installera appen från din appbutik eller https://install.bankid.com.',
    RPA17B: 'Misslyckades att läsa av QR-koden. Starta BankID-appen och läs av QR-koden. Kontrollera att BankID-appen är uppdaterad. Om du inte har BankID-appen måste du installera den och skaffa ett BankID hos din bank. Installera appen från din appbutik eller https://install.bankid.com.',
    RFA18: 'Starta BankID-appen.',
    RFA19: 'Vill du identifiera dig eller skriva under med BankID på den här datorn eller med ett Mobilt BankID?',
    RFA20: 'Vill du identifiera dig eller skriva under med ett BankID på den här enheten eller med ett BankID på en annan enhet?',
    RFA21: 'Identifiering eller underskrift pågår.',
    RFA22: 'Okänt fel. Försök igen.',
    RFA23: 'Fotografera och läs av din ID-handling med BankID-appen.',
    BAD_USER_INPUT: '',
  },
};

export default sv;
