import React, { useContext } from 'react';
import type {
  DepartmentCoworker, DepartmentCoworkerWithCalculatedPercentage, RootStackParamList,
} from '@types';
import {
  Text, Pressable, View, StyleSheet, Platform,
} from 'react-native';
import { Feather } from '@expo/vector-icons';
import { NavigationProp, useNavigation, useTheme } from '@react-navigation/native';
import { TouchableHighlight } from 'react-native-gesture-handler';
import StatusIcon from '../../../components/StatusIcon';
import SlideOverContext from '../../../contexts/SlideOverContext';
import CoworkerContext from '../../../contexts/CoworkerContext';
import { getColorFromPercent } from '../../../utils';
import ArendraIcon from '../../../components/ArendraIcon';

type PercentageWithIconProps = {
  percentage?: number;
  coworker: DepartmentCoworker;
  isWeb?: boolean;
};

const PercentageWithIcon: React.FC<PercentageWithIconProps> = ({ coworker, percentage, isWeb }) => {
  const { colors } = useTheme();
  const defaultValue = 0;

  const correctColor = getColorFromPercent(percentage || defaultValue);

  const iconColor = correctColor === 'green' ? '#6CC24A'
    : correctColor === 'yellow' ? '#FAC003'
      : correctColor === 'orange' ? '#E87722'
        : 'gray';

  const iconName = correctColor === 'green' ? 'icon-fungerar-val'
    : correctColor === 'yellow' ? 'icon-brister-delvis'
      : correctColor === 'orange' ? 'icon-brister-patagligt'
        : 'icon-kan-ej-skatta';
  const progressBarWidth = `${percentage ?? defaultValue}%`;

  if (isWeb) {
    return (
      <View style={{
        gap: 8, flexDirection: 'row', flex: 1, marginLeft: 10,
      }}
      >
        <View style={[styles.iconContainer, { borderColor: colors.background }]}>
          <ArendraIcon name={iconName} size={48} />
        </View>
        <View style={[styles.progressbarContainer, { backgroundColor: colors.background }]}>
          {percentage ? (
            <View style={[styles.progress, { width: progressBarWidth, backgroundColor: iconColor }]}>

              <Text style={{ fontWeight: '500' }}>
                {Math.round(percentage || defaultValue)}
                {' '}
                %
              </Text>
            </View>
          ) : null}
        </View>
      </View>
    );
  }

  return (
    <View style={[{
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: 10,

    }]}
    >
      <View style={{ marginRight: -4 }}>
        <ArendraIcon name={iconName} size={48} />
      </View>
      <Text style={{
        fontSize: 12, fontWeight: 'bold', width: 40,
      }}
      >
        {Math.round(percentage || defaultValue)}
        {' '}
        %
      </Text>
    </View>
  );
};

type CoworkerProps = {
  coworker: DepartmentCoworkerWithCalculatedPercentage;
  isBorderBottom?: boolean;
};

const CoworkerRow: React.FC<CoworkerProps> = ({
  coworker, isBorderBottom,
}) => {
  const { navigate } = useNavigation<NavigationProp<RootStackParamList>>();
  const { setSlideOver } = useContext(SlideOverContext);
  const { setCoworkerEdit } = useContext(CoworkerContext);
  const isWeb = Platform.OS === 'web';

  const handleEditCoworker = () => {
    if (isWeb) {
      setSlideOver('editCoworker');
      setCoworkerEdit(coworker);
    } else {
      navigate('coworker', { id: coworker.userId });
      setCoworkerEdit(coworker);
    }
  };

  return (
    <TouchableHighlight
      onPress={handleEditCoworker}
      key={coworker.firstName}
      style={{
        borderBottomWidth: isBorderBottom ? 1 : 0,
        borderBottomColor: '#E5E5E5',
      }}
      underlayColor='#E5E5E5'
    >
      <Pressable style={[styles.rowStyle, isWeb && styles.rowStyleWeb]}>
        <Text style={[styles.headerNameStyle, isWeb && styles.headerNameStyleWeb]}>{`${coworker.firstName} ${coworker.lastName}`}</Text>
        <View style={[styles.columnStyle, isWeb && styles.columnStyleWeb, { maxWidth: 120 }]}>
          <StatusIcon status={coworker.todaysEstimation?.status} height={32} width={32} />
        </View>

        <PercentageWithIcon
          percentage={coworker.calculatedPercentage}
          coworker={coworker}
          isWeb={isWeb}
        />

        <Feather name='chevron-right' size={22} color='#909090' style={styles.coworkerArrowStyle} />

      </Pressable>
    </TouchableHighlight>
  );
};

export default CoworkerRow;

const styles = StyleSheet.create({
  rowStyle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    alignItems: 'center',
    paddingVertical: 16,
    paddingRight: 24,
  },
  rowStyleWeb: {
    justifyContent: 'flex-start',
  },
  columnStyle: {
    flex: 1,
    margin: 1,
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    textTransform: 'uppercase',
    fontSize: 12,
  },
  columnStyleWeb: {
    maxWidth: 240,
    maxHeight: 50,
  },
  headerNameStyle: {
    flex: 3,
    paddingLeft: 16,
  },
  headerNameStyleWeb: {
    flex: 1,
    maxWidth: 240,
  },
  coworkerArrowStyle: {
    position: 'absolute',
    right: 0,
    paddingRight: 10,
  },
  progress: {
    // minHeight: 120,
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'center',
    paddingHorizontal: 10,
    borderRadius: 10,
  },
  progressbarContainer: {
    flex: 1,
    minHeight: 20,
    borderRadius: 10,
    maxWidth: 180,
  },
  iconContainer: {
    height: 20,
    aspectRatio: 1,
    borderRadius: 25,
    // borderWidth: 2,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
