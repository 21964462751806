import { DocumentTitleOptions, NavigationContainer, NavigationContainerRef } from '@react-navigation/native';
import React, { useRef } from 'react';
import { Text } from 'react-native';
import AppNavigator from './AppNavigator';
import linkingConfig from './linkingConfig';

// If you are not familiar with React Navigation, we recommend going through the
// "Fundamentals" guide: https://reactnavigation.org/docs/getting-started
const Navigation: React.FC = () => {
  const navigationRef = useRef<NavigationContainerRef>(null);
  // const { setDevicePushToken } = useContext(AuthContext);

  // const { isLoadingComplete, expoPushToken } = useNotifications();
  // setDevicePushToken(expoPushToken);

  const documentTitleFormatter: DocumentTitleOptions['formatter'] = (
    options,
  ) => (options?.title ? `${options.title as string} | Arendra` : 'Arendra');

  return (
    <NavigationContainer
      documentTitle={{
        formatter: documentTitleFormatter,
      }}
      ref={navigationRef}
      // theme={navigationTheme}
      linking={linkingConfig}
      fallback={<Text>Laddar...</Text>}
    >
      <AppNavigator />
    </NavigationContainer>
  );
};

export default Navigation;
