import React, { useRef, useEffect } from 'react';
import {
  StyleSheet, Animated, ViewStyle, useWindowDimensions, Easing, Platform,
} from 'react-native';
import { IconButton, useTheme } from 'react-native-paper';
import useMediaQueries from '../hooks/useMediaQueries';

type SlideInViewProps = {
  children: React.ReactNode;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  style?: ViewStyle;
  closeButtonStyle?: ViewStyle;
  isCloseButtonRight?: boolean;
};

const SlideInView: React.FC<SlideInViewProps> = ({
  children, isOpen, setIsOpen, style, closeButtonStyle, isCloseButtonRight,
}) => {
  const { height, width } = useWindowDimensions();
  const { colors } = useTheme();
  const { isDesktop } = useMediaQueries();
  const slideAnimation = useRef(new Animated.Value(0)).current;
  const containerWidth = isDesktop ? 500 : width;

  const isWeb = Platform.OS === 'web';

  useEffect(() => {
    if (isOpen) {
      Animated.timing(slideAnimation, {
        toValue: 1,
        duration: 300,
        easing: Easing.inOut(Easing.quad),
        useNativeDriver: false,
      }).start();
    } else {
      Animated.timing(slideAnimation, {
        toValue: 0,
        duration: 300,
        easing: Easing.inOut(Easing.quad),
        useNativeDriver: false,
      }).start();
    }
  }, [isOpen, slideAnimation]);

  const close = () => {
    setIsOpen(false);
  };

  const animatedContainerStyles = {
    height,
    maxWidth: slideAnimation.interpolate({
      inputRange: [0, 1],
      outputRange: [0, containerWidth],
    }),
  };

  return (
    <Animated.View style={[
      styles.container,
      animatedContainerStyles,
      !isWeb && { flex: 1 },
      style,
    ]}
    >
      <IconButton
        icon='close'
        onPress={close}
        style={[styles.closeButton, {
          left: isCloseButtonRight ? undefined : 10,
          right: isCloseButtonRight ? 10 : undefined,
          backgroundColor: colors.background,
        }, closeButtonStyle]}
      />
      {children}
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  container: {
    zIndex: 10000,
    shadowOpacity: 0.2,
    shadowRadius: 4,
    backgroundColor: 'white',
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    zIndex: 3000,
  },
});

export default SlideInView;
