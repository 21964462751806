import React from 'react';
import {
  View, StyleSheet, Modal as RNModal, TouchableOpacity, TouchableWithoutFeedback, Text, useWindowDimensions, ScrollView, ViewStyle, Platform,
} from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { Text as PaperText, useTheme } from 'react-native-paper';

type Props = {
  isVisible: boolean,
  setIsVisible: (isVis: boolean) => void,
  title?: string,
  text?: string,
  content?: JSX.Element,
  isClickOutsideAllowed?: boolean,
  maxWidth?: number,
  modalStyle?: ViewStyle,
  bottomContent?: JSX.Element,
};
const Modal: React.FC<Props> = ({
  isVisible, setIsVisible, title, text, content, isClickOutsideAllowed, maxWidth, bottomContent, modalStyle,
}) => {
  const hideModal = () => setIsVisible(false);
  const { width, height } = useWindowDimensions();
  const { colors } = useTheme();

  const isApp = Platform.OS !== 'web';

  const handleClickOutside = () => {
    if (isClickOutsideAllowed) {
      hideModal();
    }
  };

  return (
    <RNModal
      animationType='fade'
      transparent
      visible={isVisible}
      onRequestClose={() => {
        setIsVisible(!isVisible);
      }}
    >
      <TouchableOpacity
        style={styles.centeredView}
        onPress={handleClickOutside}
        activeOpacity={1}
      />
      <View style={[styles.modalView, {
        width: isApp ? width : width * 0.95,
        backgroundColor: colors.background,
        maxWidth: maxWidth || 500,
        position: isApp ? 'absolute' : 'relative',
        bottom: isApp ? 0 : 'unset',
        paddingBottom: isApp ? 40 : 20,
      }, modalStyle]}
      >
        <View style={styles.closeButtonContainer}>
          <TouchableOpacity onPress={hideModal} style={{ marginLeft: 'auto' }}>
            <Ionicons name='close-outline' size={30} />
          </TouchableOpacity>
        </View>
        <ScrollView style={{ maxHeight: height * 0.8, paddingHorizontal: 20 }}>
          <TouchableWithoutFeedback>
            <View style={{ paddingBottom: 20 }}>
              {title ? <PaperText style={styles.title}>{title}</PaperText> : null}
              {text ? <Text style={styles.text}>{text}</Text> : null}
              {content || null}
            </View>
          </TouchableWithoutFeedback>
        </ScrollView>
        {bottomContent && (
          <View style={{ paddingHorizontal: 20 }}>
            {bottomContent}
          </View>
        )}
      </View>
    </RNModal>

  );
};
export default Modal;

const styles = StyleSheet.create({
  centeredView: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
  closeButtonContainer: {
    justifyContent: 'flex-end',
    paddingTop: 10,
    paddingHorizontal: 10,
  },
  modalView: {
    margin: 'auto',
    borderRadius: 20,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    alignSelf: 'center',
    justifySelf: 'center',
  },
  title: {
    fontSize: 26,
    marginBottom: 20,
    textAlign: 'center',
  },
  text: {
    fontSize: 16,
    marginBottom: 20,
    textAlign: 'center',
  },
});
