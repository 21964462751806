import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Text, useTheme } from 'react-native-paper';

import useTranslation from '../../../hooks/useTranslation';
import SaveFileAsXLSX from './SaveFileAsXLSX';

type ExportRowProps = {
  departmentName?: string;
};

const ExportRow: React.FC<ExportRowProps> = ({ departmentName }) => {
  const { colors } = useTheme();
  const { screen } = useTranslation();

  return (
    <View style={[styles.container, { backgroundColor: colors.background }]}>
      <View style={styles.borderBottom}>
        <Text style={styles.title}>{screen.department.export}</Text>
        <View style={styles.buttonRow}>
          <View style={styles.button}>
            <SaveFileAsXLSX departmentName={departmentName} xlsxType='Coworkers' />
          </View>
          {/* <View style={styles.button}>
            <SaveFileAsPDF departmentName={departmentName} />
          </View> */}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingTop: 20,
    paddingHorizontal: 20,
  },
  title: {
    fontSize: 20,
  },
  buttonRow: {
    flexDirection: 'row',
    gap: 16,
    paddingVertical: 16,
  },
  button: {
    borderRadius: 21,
    minWidth: 120,
    justifyContent: 'center',
  },
  borderBottom: {
    borderBottomColor: '#E5E5E5',
    borderBottomWidth: 1,
  },
});

export default ExportRow;
