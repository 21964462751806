import type { EvaluationCarouselStep } from '@types';
import React, {
  RefObject, useEffect, useRef,
} from 'react';
import { StyleSheet, Pressable, Animated } from 'react-native';
import { useTheme } from 'react-native-paper';
import { ICarouselInstance } from 'react-native-reanimated-carousel';
import useMediaQueries from '../../../hooks/useMediaQueries';

type PaginationDotProps = {
  carouselRef: RefObject<ICarouselInstance> | null;
  index: number;
  activeSlide: number;
  isTutorial?: boolean;
  coworker?: EvaluationCarouselStep;
};

const PaginationDot: React.FC<PaginationDotProps> = ({
  carouselRef, index, activeSlide, isTutorial, coworker,
}) => {
  const { colors } = useTheme();
  const { isDesktop } = useMediaQueries();
  const animatedValue = useRef(new Animated.Value(activeSlide)).current;

  const isActive = index === activeSlide;
  const isCoworkerEstimated = coworker?.todaysEstimation && coworker.todaysEstimation.status !== null;

  useEffect(() => {
    Animated.spring(animatedValue, {
      toValue: activeSlide,
      useNativeDriver: false,
    }).start();
  }, [activeSlide, animatedValue]);

  const dotScale = animatedValue.interpolate({
    inputRange: [index - 10, index - 9, index - 8, index - 7, index - 6, index - 5, index - 4, index - 3, index, index + 3, index + 4, index + 5, index + 6, index + 7, index + 8, index + 9, index + 10],
    outputRange: isDesktop
      ? [0, 0.3, 0.5, 0.6, 0.7, 0.8, 0.9, 0.9, 1.2, 0.9, 0.9, 0.8, 0.7, 0.6, 0.5, 0.3, 0]
      : [0, 0.1, 0.15, 0.3, 0.45, 0.6, 0.75, 0.9, 1.2, 0.9, 0.75, 0.6, 0.45, 0.3, 0.15, 0.1, 0],
    extrapolate: 'clamp',
  });

  const goToSlide = (i: number) => {
    if (carouselRef?.current) {
      carouselRef.current.scrollTo({ index: i, animated: true });
    }
  };

  return (
    <Pressable onPress={() => goToSlide(index)}>
      <Animated.View
        style={[
          styles.dotStyle,
          {
            opacity: isActive ? 1 : !isCoworkerEstimated ? 0.4 : 1,
            transform: [{ scale: dotScale }],
            backgroundColor: isActive && isTutorial ? colors.onPrimary
              : isActive ? colors.primary
                : !isActive && isTutorial ? colors.onSecondary
                  : !isActive && !isTutorial ? colors.onBackground
                    : colors.onBackground,
          },
        ]}
      />
    </Pressable>
  );
};

const styles = StyleSheet.create({
  dotStyle: {
    width: 10,
    height: 10,
    borderRadius: 5,
  },
});

export default PaginationDot;
