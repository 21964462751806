import React, { useContext, useState } from 'react';
import {
  View, StyleSheet, useWindowDimensions,
} from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import type {
  HasSeenTutorialMutation, MyRolesQuery, OnboardingStep, RootStackParamList, UserRoles,
} from '@types';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { useLazyQuery, useMutation } from '@apollo/client';
import Spinner from '../../assets/images/arendra_spinner.svg';
import Svg from '../../components/Icon';
import useMediaQueries from '../../hooks/useMediaQueries';
import OnboardingWeb from './components/OnboardingWeb';
import OnboardingCarousel from './components/OnboardingCarousel';
import { hasSeenTutorialMutation, myRolesQuery } from '../../queries';
import UserSettingsContext from '../../contexts/UserSettingsContext';
import PickRoute from '../../navigation/PickRoute';
import useTranslation from '../../hooks/useTranslation';

const OnboardingScreen = () => {
  const { screen } = useTranslation();
  const { width } = useWindowDimensions();
  const { isDesktop } = useMediaQueries();
  const { setUserRoles, setCurrentDepartment } = useContext(UserSettingsContext);
  const { navigate } = useNavigation<NavigationProp<RootStackParamList>>();

  const [setSeenTutorial, { data, loading, error }] = useMutation<HasSeenTutorialMutation>(hasSeenTutorialMutation, { variables: { tutorialVersion: 1 } });
  const [getMyRoles] = useLazyQuery<MyRolesQuery>(myRolesQuery, { fetchPolicy: 'network-only' });
  const backgroundGradient = ['#F07E28', '#F78A39', '#F3822E'];

  const navigateOnContinue = async () => {
    /** Set tutorial as seen */
    await setSeenTutorial().then(async () => {
      /** Get myRoles again so we update if we have seen the tutorial */
      await getMyRoles().then((res) => {
        console.log('res: ', res);
        const myRoles = res.data?.myRoles;
        if (myRoles) {
          setUserRoles(myRoles as UserRoles);

          const navigateToRoute = PickRoute(myRoles);
          const departmentId: string | undefined = myRoles
            && myRoles?.departmentsIManage
            && myRoles?.departmentsIManage[0]
            ? myRoles?.departmentsIManage[0]._id : undefined;

          console.log(`
          navigateToRoute: ${navigateToRoute}
          departmentId: ${departmentId}
          `);

          if (navigateToRoute === 'department' && departmentId) {
            setCurrentDepartment(departmentId);
            navigate(navigateToRoute, { departmentId });
          } else {
            navigate(navigateToRoute);
          }
        }
      }).catch((err) => {
        console.log(err);
      });
    }).catch((err) => {
    });
  };

  const [steps] = useState<OnboardingStep[]>([
    {
      id: 1,
      title: screen.onBoarding.stepOneTitle,
      description: screen.onBoarding.stepOneDescription,
      icon: '',
    },
    {
      id: 2,
      title: screen.onBoarding.stepTwoTitle,
      description: screen.onBoarding.stepTwoDescription,
      icon: '',
    },
    {
      id: 3,
      title: screen.onBoarding.stepThreeTitle,
      description: screen.onBoarding.stepThreeDescription,
      icon: '',
    },
  ]);

  return (

    <View style={styles.container}>
      <LinearGradient
        colors={backgroundGradient}
        style={{ flex: 1 }}
      >
        <Svg
          source={Spinner}
          width={width * 0.6}
          style={[styles.spinner, {
            right: isDesktop ? -80 : -300,
            bottom: -200,
          }]}
        />
        {isDesktop ? <OnboardingWeb steps={steps} navigateOnContinue={navigateOnContinue} /> : <OnboardingCarousel steps={steps} navigateOnContinue={navigateOnContinue} />}
      </LinearGradient>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: '#F6F4F2',
    overflow: 'hidden',
  },
  spinner: {
    position: 'absolute',
    aspectRatio: 1,
    minWidth: 800,
    minHeight: 800,
    maxHeight: 1000,
    maxWidth: 1000,
  },
});

export default OnboardingScreen;
