import React, { useEffect, useState } from 'react';
import {
  StyleSheet, View, Text, TextStyle,
} from 'react-native';
import type { SkippedStatusBasis, StatusBasis } from '@types';
import { useTheme } from 'react-native-paper';
import ArendraIcon from './ArendraIcon';
import useTranslation from '../hooks/useTranslation';

type StatusIconProps = {
  status?: number | null;
  height: number;
  width: number;
  style?: any;
  isWithText?: boolean;
  textStyle?: TextStyle;
  iconSize?: number;
  iconWidth?: number;
  skippedStatus?: SkippedStatusBasis;
  statusBasis?: StatusBasis;
};

const StatusIcon: React.FC<StatusIconProps> = ({
  status, height, width, isWithText, textStyle, style, iconSize, iconWidth, skippedStatus, statusBasis,
}) => {
  const { screen, statuses } = useTranslation();
  const { colors } = useTheme();
  const [skippedReason, setSkippedReason] = useState<string | undefined>();
  const [statusBasises, setStatusBasises] = useState<string[] | undefined>([]);

  useEffect(() => {
    if (skippedStatus?.offDuty) setSkippedReason(screen.evaluation.offDuty);
    if (skippedStatus?.haveNotMet) setSkippedReason(screen.evaluation.haveNotMet);
    if (skippedStatus?.absence) setSkippedReason(screen.evaluation.absence);
    if (skippedStatus?.other) setSkippedReason(screen.evaluation.other);
  }, [screen.evaluation.offDuty, screen.evaluation.haveNotMet, screen.evaluation.absence, screen.evaluation.other, skippedStatus]);

  useEffect(() => {
    const statusBasisesArray: string[] = [];
    if (statusBasis?.workPerformance) statusBasisesArray.push(screen.evaluation.workPerformance);
    if (statusBasis?.followLawsAndDecisions) statusBasisesArray.push(screen.evaluation.followLawsAndDecisions);
    if (statusBasis?.workEnvironmentResponsibility) statusBasisesArray.push(screen.evaluation.workEnvironmentResponsibility);
    if (statusBasis?.dutyOfLoyalty) statusBasisesArray.push(screen.evaluation.dutyOfLoyalty);
    if (statusBasis?.dutyToCooperate) statusBasisesArray.push(screen.evaluation.dutyToCooperate);
    setStatusBasises(statusBasisesArray);
  }, [screen.evaluation.workPerformance, screen.evaluation.followLawsAndDecisions, screen.evaluation.workEnvironmentResponsibility, screen.evaluation.dutyOfLoyalty, screen.evaluation.dutyToCooperate, statusBasis]);

  switch (status) {
    case 1:
      return (
        <View style={[styles.row, { height }]}>
          <View style={{ width: iconWidth, alignItems: 'center' }}>
            <ArendraIcon
              name='icon-fungerar-val'
              size={iconSize}
            />
          </View>
          {isWithText && <Text style={textStyle}>{statuses.worksWell}</Text>}
        </View>
      );
    case 2:
      return (
        <View>
          <View style={[styles.row, { height }]}>
            <View style={{ width: iconWidth, alignItems: 'center' }}>
              <ArendraIcon
                name='icon-brister-delvis'
                size={iconSize}
              />
            </View>
            {isWithText && <Text style={textStyle}>{statuses.partiallyLacking}</Text>}
          </View>
          {isWithText && statusBasises
            && (
              <View style={{ marginTop: -4, paddingBottom: 4 }}>
                {statusBasises?.map((statusBasisText) => (
                  <View key={statusBasisText} style={styles.row}>
                    <View style={{ width: iconWidth }} />
                    <View style={[styles.row, { flexWrap: 'wrap', flex: 1 }]}>
                      <View style={styles.row}>
                        <View style={[styles.listDot, { backgroundColor: colors.secondary }]} />
                        <Text style={{ opacity: 0.6 }}>{statusBasisText}</Text>
                      </View>
                    </View>
                  </View>
                ))}
              </View>
            )}
        </View>
      );
    case 3:
      return (
        <View>
          <View style={[styles.row, { height }]}>
            <View style={{ width: iconWidth, alignItems: 'center' }}>
              <ArendraIcon
                name='icon-brister-patagligt'
                size={iconSize}
              />
            </View>
            {isWithText && <Text style={textStyle}>{statuses.noticeablyLacking}</Text>}
          </View>
          {isWithText && statusBasises
            && (
              <View style={{ marginTop: -4, paddingBottom: 4 }}>
                {statusBasises?.map((statusBasisText) => (
                  <View key={statusBasisText} style={styles.row}>
                    <View style={{ width: iconWidth }} />
                    <View style={[styles.row, { flexWrap: 'wrap', flex: 1 }]}>
                      <View style={styles.row}>
                        <View style={[styles.listDot, { backgroundColor: colors.secondary }]} />
                        <Text style={{ opacity: 0.6 }}>{statusBasisText}</Text>
                      </View>
                    </View>
                  </View>
                ))}
              </View>
            )}
        </View>
      );
    case 0:
      return (
        <View style={{ height }}>
          <View style={[styles.row, { height }]}>
            <View style={{ width: iconWidth, alignItems: 'center' }}>
              <ArendraIcon
                name='icon-kan-ej-skatta'
                size={iconSize}
              />
            </View>
            {isWithText && <Text style={textStyle}>{skippedReason ?? statuses.couldNotEvaluate}</Text>}
          </View>
        </View>
      );
    default:
      return (
        <View style={[styles.row, { height }]} />
      );
  }
};

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  listDot: {
    width: 4,
    height: 4,
    borderRadius: 2,
    marginRight: 4,
    marginTop: 6.5,
    alignSelf: 'flex-start',
    opacity: 0.6,
  },
});

export default StatusIcon;
