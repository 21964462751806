import 'react-native-gesture-handler';
import React from 'react';
import { StatusBar } from 'expo-status-bar';
import {
  Platform, StyleSheet, Text, TextInput, View,
} from 'react-native';
import { MD2LightTheme, Provider as PaperProvider } from 'react-native-paper';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { EXPO_PUBLIC_SERVER_URL } from '@env';
import Navigation from './src/navigation';
import useCachedResources from './src/hooks/useCachedResources';
import { AuthContextProvider } from './src/contexts/AuthContext';
import Apollo from './src/clients/Apollo';
import { SlideOverProvider } from './src/contexts/SlideOverContext';

interface AppProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  skipLoadingScreen: any;
}

const App: React.FC<AppProps> = ({ skipLoadingScreen }) => {
  const isLoadingComplete = useCachedResources();

  if (!isLoadingComplete && !skipLoadingScreen) {
    return null;
  }

  const theme = {
    ...MD2LightTheme,
    fonts: {
      ...MD2LightTheme.fonts,
      regular: {
        fontFamily: 'EBGaramond',
        fontWeight: 'normal',
      },
      italic: {
        fontFamily: 'EBGaramond-Italic',
      },
    },
    roundness: 40,
    colors: {
      ...MD2LightTheme.colors,
      primary: '#E87722',
      // Secondary is dark gray
      secondary: '#222222',
      onSecondary: '#FFFFFF',
      onPrimary: '#FFFFFF',
      surface: '#EFEFEF',
      accent: '#FFC003',
      background: '#F6F4F2',
      onBackground: '#9A8E81',
      error: '#B00020',
      text: 'black',
      onSurface: '#000000',
      disabled: 'rgba(0, 0, 0, 0.26)',
      placeholder: 'rgba(0, 0, 0, 0.54)',
      backdrop: 'rgba(0, 0, 0, 0.5)',
      notification: 'pink',
      tooltip: 'rgba(28, 27, 31, 1)',
    },
  };

  Text.defaultProps = {
    ...Text.defaultProps,
    allowFontScaling: false,
    // minFontSizeMultiplier: Platform.OS === 'ios' ? 1.0 : 1.0,
    // maxFontSizeMultiplier: Platform.OS === 'ios' ? 1.0 : 1.0,
  };

  TextInput.defaultProps = {
    ...TextInput.defaultProps,
    allowFontScaling: false,
    // minFontSizeMultiplier: Platform.OS === 'ios' ? 1.0 : 1.0,
    // maxFontSizeMultiplier: Platform.OS === 'ios' ? 1 : 1.0,
  };

  return (
    <PaperProvider theme={theme}>
      <View style={styles.container}>
        <AuthContextProvider>
          <Apollo serverUrl={EXPO_PUBLIC_SERVER_URL}>
            <SlideOverProvider>
              <SafeAreaProvider>
                <StatusBar style='auto' />
                <Navigation />
              </SafeAreaProvider>
            </SlideOverProvider>
          </Apollo>
        </AuthContextProvider>
      </View>
    </PaperProvider>
  );
};

export default App;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    overflow: 'hidden',
  },
});
