import React, { RefObject } from 'react';
import {
  View, StyleSheet, Text,
} from 'react-native';
import Animated, {
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { ICarouselInstance } from 'react-native-reanimated-carousel';
import { useTheme } from 'react-native-paper';
import type { EvaluationCarouselStep, OnboardingStep } from '@types';
import PaginationDot from './PaginationDot';
import useTranslation from '../../../hooks/useTranslation';
import useMediaQueries from '../../../hooks/useMediaQueries';

type PaginationProps = {
  carouselRef: RefObject<ICarouselInstance> | null;
  carouselWidth: number;
  steps: EvaluationCarouselStep[] | OnboardingStep[];
  activeSlide: number;
  isTutorial?: boolean;
};

const PaginationItem: React.FC<PaginationProps> = ({
  carouselRef, carouselWidth, steps, activeSlide, isTutorial,
}) => {
  const { colors } = useTheme();
  const { screen } = useTranslation();
  const { isDesktop } = useMediaQueries();

  const leftMovement = useSharedValue(activeSlide * 30);
  leftMovement.value = withTiming(activeSlide * 30, { duration: 100, easing: Easing.ease });

  const paginationRowStyle = useAnimatedStyle(() => ({
    left: carouselWidth * 0.5 - 5 - leftMovement.value,
  }));

  return (
    <View style={styles.pagination}>
      <Text style={{ color: isTutorial ? colors.onPrimary : colors.onBackground }}>
        {activeSlide + 1}
        {' '}
        {screen.evaluation.of}
        {' '}
        {steps.length}
      </Text>
      <Animated.View style={[styles.paginationRow, !isTutorial && paginationRowStyle]}>
        {steps.map((step, index) => (
          <PaginationDot
            key={step.id}
            isTutorial={isTutorial || false}
            coworker={step}
            carouselRef={carouselRef}
            index={index}
            activeSlide={activeSlide}
          />
        ))}
      </Animated.View>
      {!isTutorial && (
        <>
          <View style={[styles.spaceLeft, {
            right: isDesktop ? (carouselWidth * 0.5) + (Math.round((carouselWidth * 0.2) / 30) * 30) + 15 : (carouselWidth * 0.5) + (Math.round((carouselWidth * 0.4) / 30) * 30) + 15,
            backgroundColor: colors.background,
          }]}
          />
          <View style={[styles.spaceRight, {
            left: isDesktop ? (carouselWidth * 0.5) + (Math.round((carouselWidth * 0.2) / 30) * 30) + 15 : (carouselWidth * 0.5) + (Math.round((carouselWidth * 0.4) / 30) * 30) + 15,
            backgroundColor: colors.background,
          }]}
          />
        </>
      )}

    </View>
  );
};

const styles = StyleSheet.create({
  pagination: {
    height: 80,
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
    paddingVertical: 20,
    marginBottom: -20,
  },
  paginationRow: {
    position: 'absolute',
    bottom: 10,
    flexDirection: 'row',
    gap: 20,
  },
  spaceLeft: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    height: 30,
  },
  spaceRight: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    height: 30,
  },
});

export default PaginationItem;
