import React, { useState } from 'react';
import {
  View, Platform, StyleSheet, useWindowDimensions, Text,
} from 'react-native';
import { Text as PaperText, TouchableRipple, useTheme } from 'react-native-paper';
import { Ionicons } from '@expo/vector-icons';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import type { EstimationsByDepartmentIdQuery, RootStackParamList } from '@types';
import StatusGreenScreen from '../../Status/StatusGreenScreen';
import useMediaQueries from '../../../hooks/useMediaQueries';
import arendraModel from '../../../assets/images/arendra_model_bg.svg';
import arendraModelWeb from '../../../assets/images/arendra_model_web_bg.svg';
import Svg from '../../../components/Icon';
import Box from '../../../components/Box';
import CoinsIcon from '../../../assets/icons/coins.svg';
import useTranslation from '../../../hooks/useTranslation';
import ArendraModelIcon from './ArendraModelIcon';
import Modal from '../../../components/Modal';
import StatusYellowScreen from '../../Status/StatusYellowScreen';
import StatusOrangeScreen from '../../Status/StatusOrangeScreen';
import CostInfoScreen from '../../CostInfoScreen/CostInfoScreen';
import CostDisplay from '../../../components/CostDisplay';

type ArendraModelProps = {
  evaluationsData?: EstimationsByDepartmentIdQuery;
};

const ArendraModel: React.FC<ArendraModelProps> = ({ evaluationsData }) => {
  const { colors } = useTheme();
  const { width } = useWindowDimensions();
  const { screen } = useTranslation();
  const { isTablet, isDesktop } = useMediaQueries();

  const [webModal, setWebModal] = useState<'statusGreen' | 'statusYellow' | 'statusOrange' | 'costInfo' | undefined>();

  const { navigate } = useNavigation<NavigationProp<RootStackParamList>>();
  const isWeb = Platform.OS === 'web';

  const navigateToStatus = (status: 'statusGreen' | 'statusYellow' | 'statusOrange' | 'costInfo') => {
    if (isWeb) {
      setWebModal(status);
    } else {
      navigate(status);
    }
  };

  return (
    <>
      <Box style={[styles.container, { aspectRatio: !isTablet ? 1 : undefined }]}>
        <Svg
          source={isTablet ? arendraModelWeb : arendraModel}
          height={isWeb && width < 1290 ? width * 0.36 : isTablet ? 470 : undefined}
          style={{ flex: !isTablet ? 1 : undefined }}
        />
        <View style={[styles.content, isTablet && styles.contentWeb]}>
          <View style={styles.titleContainer}>
            <PaperText style={[styles.title]}>
              <Text>
                {screen.department.groupAverage}
                {' '}
              </Text>
              <Text style={{ color: colors.onBackground }}>
                (
                {screen.department.threeMonths}
                )
              </Text>
            </PaperText>
            <PaperText style={{ color: colors.onBackground }}>
              {screen.department.accordingToArendraModel}
            </PaperText>
          </View>
          <View style={styles.costLeft}>
            <Text style={styles.costLeftText}>{screen.department.cost}</Text>
            <Ionicons
              name='arrow-forward'
              size={16}
              color='#C5B9AC'
            />
          </View>

          <TouchableRipple onPress={() => navigateToStatus('costInfo')} style={[styles.bottomRight, isDesktop && styles.bottomRightDesktop]}>
            <>
              <Svg source={CoinsIcon} height={50} width={50} style={{ marginTop: -10 }} />
              <Text style={[styles.bottomRightText, styles.bottomRightTextDesktop]}>
                <CostDisplay cost={evaluationsData?.estimationsByDepartmentId?.calculatedCost ?? 0} />
              </Text>
              <Text style={{ color: '#736250' }}>
                /
                {screen.department.month}
              </Text>
            </>
          </TouchableRipple>

        </View>
        <ArendraModelIcon
          name='icon-fungerar-val'
          height='17%'
          x={isDesktop ? '9%' : '4%'}
          y={isDesktop ? '73.5%' : '74%'}
          onPress={() => navigateToStatus('statusGreen')}
          badge={{
            size: '32%',
            color: '#6CC24A',
            value: evaluationsData?.estimationsByDepartmentId.amountOfGreen ?? 0,
          }}
        />
        <ArendraModelIcon
          name='icon-brister-delvis'
          height='17%'
          x={isDesktop ? '28.3%' : '27%'}
          y={isDesktop ? '67%' : '69%'}
          onPress={() => navigateToStatus('statusYellow')}
          badge={{
            size: '32%',
            color: '#FAC003',
            value: evaluationsData?.estimationsByDepartmentId.amountOfYellow ?? 0,
          }}
        />
        <ArendraModelIcon
          name='icon-brister-patagligt'
          height='17%'
          x={isDesktop ? '42.3%' : '41%'}
          y='48%'
          onPress={() => navigateToStatus('statusOrange')}
          badge={{
            size: '32%',
            color: '#E87722',
            value: evaluationsData?.estimationsByDepartmentId.amountOfOrange ?? 0,
          }}
        />
        <ArendraModelIcon
          isInnerBorder
          name='icon-temp'
          height='17%'
          x={isDesktop ? '56%' : '55%'}
          y={isDesktop ? '18%' : '19%'}
        />
        <ArendraModelIcon
          isInnerBorder
          name='icon-faller'
          height='17%'
          x={isDesktop ? '77%' : '79%'}
          y={isDesktop ? '10%' : '12%'}
        />
      </Box>
      <View style={[styles.bottomLeft, isTablet && styles.bottomLeftDesktop]}>
        <Text style={styles.bottomLeftText}>{screen.department.time}</Text>
        <Ionicons
          name='arrow-forward'
          size={16}
          color='#C5B9AC'
        />
      </View>

      <Modal
        isVisible={webModal === 'statusGreen'}
        setIsVisible={() => setWebModal(undefined)}
        content={<StatusGreenScreen />}
        isClickOutsideAllowed
      />

      <Modal
        isVisible={webModal === 'statusYellow'}
        setIsVisible={() => setWebModal(undefined)}
        content={<StatusYellowScreen />}
        isClickOutsideAllowed
      />

      <Modal
        isVisible={webModal === 'statusOrange'}
        setIsVisible={() => setWebModal(undefined)}
        content={<StatusOrangeScreen />}
        isClickOutsideAllowed
      />

      <Modal
        isVisible={webModal === 'costInfo'}
        setIsVisible={() => setWebModal(undefined)}
        content={<CostInfoScreen />}
        isClickOutsideAllowed
      />

    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
    position: 'absolute',
    top: '3%',
    bottom: '3%',
    right: 0,
    left: 0,
  },
  contentWeb: {
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
  },
  titleContainer: {
    position: 'absolute',
    top: 20,
    left: 20,
  },
  title: {
    fontSize: 22,
  },
  costLeft: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    transform: [{ rotate: '-90deg' }],
    gap: 2,
    height: 80,
    width: 100,
    position: 'absolute',
    left: 5,
    top: '45%',
  },
  costLeftText: {
    textTransform: 'uppercase',
    color: '#C5B9AC',
  },
  bottomRight: {
    position: 'absolute',
    bottom: 20,
    right: 20,
    height: 120,
    width: 120,
    backgroundColor: '#fff',
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
    gap: 6,
  },
  bottomRightDesktop: {
    width: 150,
  },
  bottomRightText: {
    color: '#736250',
    fontSize: 16,
    marginTop: -5,
    fontWeight: '500',
    textAlign: 'center',
  },
  bottomRightTextDesktop: {
    fontSize: 20,
  },
  bottomLeft: {
    flexDirection: 'row',
    gap: 2,
    position: 'absolute',
    left: 32,
    bottom: 8,
  },
  bottomLeftDesktop: {
    bottom: -4,
  },
  bottomLeftText: {
    textTransform: 'uppercase',
    color: '#C5B9AC',
  },
});

export default ArendraModel;
