import type { EvaluationCarouselStep } from '@types';
import React, {
  useMemo, ReactNode, useState, RefObject, createContext, useRef,
} from 'react';
import { ICarouselInstance } from 'react-native-reanimated-carousel';

type EvaluationContextType = {
  carouselRef: RefObject<ICarouselInstance> | null;
  evaluationSteps: EvaluationCarouselStep[];
  setEvaluationSteps: React.Dispatch<React.SetStateAction<EvaluationCarouselStep[]>>;
};

const EvaluationContext = createContext<EvaluationContextType>({
  carouselRef: null,
  evaluationSteps: [],
  setEvaluationSteps: () => { },
});

type EvaluationProviderProps = {
  children: ReactNode;
};

export const EvaluationProvider: React.FC<EvaluationProviderProps> = ({ children }) => {
  const carouselRef = useRef<ICarouselInstance>(null);
  const [evaluationSteps, setEvaluationSteps] = useState<EvaluationCarouselStep[]>([]);

  const state = useMemo(() => ({
    carouselRef,
    evaluationSteps,
    setEvaluationSteps,
  }), [carouselRef, evaluationSteps, setEvaluationSteps]);

  return (
    <EvaluationContext.Provider value={state}>
      {children}
    </EvaluationContext.Provider>
  );
};

export default EvaluationContext;
