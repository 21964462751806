import React from 'react';
import {
  View, StyleSheet,
} from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import useMediaQueries from '../../../hooks/useMediaQueries';
import SlideInView from '../../../components/SlideInView';
import CoworkersListHeader from '../../EvaluationCoworkersScreen/components/CoworkersListHeader';
import CoworkersList from '../../EvaluationCoworkersScreen/components/CoworkersList';
import SlideOver from '../../../components/SlideOver';

type WebCoworkerListProps = {
  isCoworkerList: boolean;
  setIsCoworkerList: React.Dispatch<React.SetStateAction<boolean>>;
};

const WebCoworkerList: React.FC<WebCoworkerListProps> = ({ isCoworkerList, setIsCoworkerList }) => {
  const { isDesktop } = useMediaQueries();

  const closeCoworkerList = () => {
    setIsCoworkerList(false);
  };

  if (isDesktop) {
    return (
      <SlideInView isOpen={isCoworkerList} setIsOpen={setIsCoworkerList}>
        <View style={styles.listHeaderBg}>
          <CoworkersListHeader />
        </View>
        <ScrollView style={{ marginHorizontal: 20 }}>
          <CoworkersList />
        </ScrollView>
      </SlideInView>
    );
  }

  return (
    <SlideOver isFromRight isOpen={isCoworkerList} close={closeCoworkerList}>
      <View style={styles.listHeaderBg}>
        <CoworkersListHeader />
      </View>
      <ScrollView style={{ marginHorizontal: 20 }}>
        <CoworkersList />
      </ScrollView>
    </SlideOver>
  );
};

const styles = StyleSheet.create({
  listHeaderBg: {
    backgroundColor: '#fff',
    paddingTop: 30,
  },
});

export default WebCoworkerList;
