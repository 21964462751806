import type { AddCompanyMutation, AddCompanyMutationVariables } from '@types';
import React, { useCallback, useEffect, useState } from 'react';
import {
  View, Text, StyleSheet, Platform,
} from 'react-native';
import { Button, useTheme } from 'react-native-paper';
import { useMutation } from '@apollo/client';
import { DatePickerInput } from 'react-native-paper-dates';
import StyledTextInput from '../../../../components/StyledTextInput';
import { addCompanyMutation, companiesQuery } from '../../../../queries';
import { regex } from '../../../../utils';
import FormErrorMessage from '../../../../components/FormErrorMessage';
import useTranslation from '../../../../hooks/useTranslation';

type AddCompanyProps = {
  hideModal: () => void;
};

const AddCompany: React.FC<AddCompanyProps> = ({ hideModal }) => {
  const { colors } = useTheme();
  const { screen, clientErrors } = useTranslation();
  const [addCompany, { loading: addCompanyLoading, error: addCompanyError }] = useMutation<AddCompanyMutation>(addCompanyMutation);

  const [clientError, setClientError] = useState<string>('');
  const [newCompany, setNewCompany] = useState<Partial<AddCompanyMutationVariables>>({
    name: '',
    orgNumber: '',
    endDate: undefined,
    email: '',
    phoneNumber: '',
  });

  const isWeb = Platform.OS === 'web';

  const isFieldsCorrect = useCallback(() => {
    if (newCompany.name === '' || newCompany.orgNumber === '' || newCompany.endDate === undefined || newCompany.email === '' || newCompany.phoneNumber === '') {
      setClientError(clientErrors.fillOutAllFields);
      return false;
    }

    if (newCompany.email && !regex.email.test(newCompany.email)) {
      setClientError(clientErrors.invalidEmail);
      return false;
    }

    if (newCompany.endDate && newCompany.endDate < new Date()) {
      setClientError(clientErrors.invalidEndDate);
      return false;
    }

    return true;
  }, [clientErrors.fillOutAllFields, clientErrors.invalidEmail, clientErrors.invalidEndDate, newCompany.email, newCompany.endDate, newCompany.name, newCompany.orgNumber, newCompany.phoneNumber]);

  const handleAddCompany = useCallback(() => {
    if (addCompanyLoading) return;
    if (isFieldsCorrect()) {
      addCompany({
        variables: {
          name: newCompany.name,
          orgNumber: newCompany.orgNumber,
          endDate: newCompany.endDate,
          email: newCompany.email,
          phoneNumber: newCompany.phoneNumber,
        },
        refetchQueries: [{ query: companiesQuery }],
      }).then((res) => {
        if (res.data?.addCompany) {
          hideModal();
        }
      }).catch((err) => {
        console.log(err);
      });
    }
  }, [addCompany, addCompanyLoading, hideModal, isFieldsCorrect, newCompany.email, newCompany.endDate, newCompany.name, newCompany.orgNumber, newCompany.phoneNumber]);

  const handleKeyPress = useCallback((e: any) => {
    if (e.key === 'Enter') {
      handleAddCompany();
    }
  }, [handleAddCompany]);

  // Enter key listener
  useEffect(() => {
    if (isWeb) {
      if (!newCompany.name || !newCompany.orgNumber || !newCompany.endDate) {
        document.removeEventListener('keypress', handleKeyPress);
      } else {
        document.addEventListener('keypress', handleKeyPress);
      }
      return () => {
        document.removeEventListener('keypress', handleKeyPress);
      };
    }

    return () => { };
  }, [newCompany, handleKeyPress, isWeb]);

  return (
    <View style={styles.container}>
      <View style={{ flexDirection: 'row' }}>
        <View style={styles.inputContainer}>
          <Text>{screen.superAdmin.companies.company}</Text>
          <StyledTextInput value={newCompany.name} onChangeText={(text) => setNewCompany({ ...newCompany, name: text })} />
        </View>
      </View>

      <View style={{ flexDirection: 'row', gap: 8 }}>
        <View style={styles.inputContainer}>
          <Text>{screen.superAdmin.companies.orgNumber}</Text>
          <StyledTextInput
            value={newCompany.orgNumber}
            onChangeText={(text) => setNewCompany({ ...newCompany, orgNumber: text })}
          />
        </View>
        <View style={styles.inputContainer}>
          <Text>{screen.superAdmin.companies.endDate}</Text>
          <DatePickerInput
            locale='sv'
            value={newCompany.endDate}
            onChange={(date) => setNewCompany({ ...newCompany, endDate: date })}
            inputMode='start'
            underlineColor='transparent'
            activeUnderlineColor='transparent'
            style={styles.datePickerStyles}
            contentStyle={{ borderRadius: 6, borderWidth: 0, height: 49 }}
            placeholder={screen.superAdmin.companies.dateFormat}
            saveLabelDisabled
            label=''
            withDateFormatInLabel={false}
            theme={{
              roundness: 6,
              fonts: {
                regular: {
                  fontFamily: '14px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
                },
              },
            }}
            withModal={false}
          />
        </View>
      </View>
      <View style={{ flexDirection: 'row', gap: 8 }}>
        <View style={styles.inputContainer}>
          <Text>{screen.superAdmin.companies.email}</Text>
          <StyledTextInput
            value={newCompany.email ?? ''}
            onChangeText={(text) => setNewCompany({ ...newCompany, email: text })}
          />
        </View>
        <View style={styles.inputContainer}>
          <Text>{screen.superAdmin.companies.phoneNumber}</Text>
          <StyledTextInput
            value={newCompany.phoneNumber ?? ''}
            onChangeText={(text) => setNewCompany({ ...newCompany, phoneNumber: text })}
          />
        </View>
      </View>
      {clientError !== '' ? <FormErrorMessage message={clientError} /> : addCompanyError && <FormErrorMessage message={addCompanyError.message} />}
      <Button
        mode='outlined'
        onPress={handleAddCompany}
        style={{ marginTop: 20, marginRight: 'auto' }}
        buttonColor={colors.secondary}
        textColor={colors.onSecondary}
        uppercase={false}
        loading={addCompanyLoading}
      >
        {screen.superAdmin.add}
        {' '}
        +
      </Button>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    gap: 10,
  },
  inputContainer: {
    flex: 1,
    gap: 4,
  },
  datePickerStyles: {
    borderRadius: 6,
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: '#ccc',
    height: 49,
  },
});

export default AddCompany;
