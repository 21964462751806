import { Platform } from 'react-native';
import { MyRolesQuery } from '../types/generated.types';

/**
 * @name PickRoute
 * @description This function decides which route to show.
 * Used for the initial route of the app, as well as after the onboarding flow.
 * @return A route name as a string.
 * /** The initial route will be the onBoarding screen if the user has not yet seen the tutorial.
   * If the user has seen the tutorial the user will end up on one of the following screens:
   * - onboarding (if the user has not yet seen the tutorial)
   * - roleSelection (if the user is a superadmin on web and is a manager for at least 1 department or is a user that manages multiple departments)
   * - department (if the user is a user that only manages one department)
   * - companies (if the user is a superadmin on web that is not a manager of any department)
   */
const PickRoute = (myRoles?: MyRolesQuery['myRoles']): 'onBoarding' | 'roleSelection' | 'companies' | 'department' => {
  const shouldSeeTutorial = myRoles?.hasSeenTutorial === false;
  const isManagerOfMultipleDepartments = myRoles && myRoles.departmentsIManage && myRoles?.departmentsIManage?.length > 1;
  const isManagerOfAtLeastOneDepartment = myRoles && myRoles.departmentsIManage && myRoles?.departmentsIManage?.length >= 1;
  const isWebSuperAdmin = myRoles?.isSuperAdmin && Platform.OS === 'web';

  const initialRouteName = shouldSeeTutorial ? 'onBoarding'
    : isManagerOfMultipleDepartments || (isWebSuperAdmin && isManagerOfAtLeastOneDepartment) ? 'roleSelection'
      : !isManagerOfAtLeastOneDepartment && isWebSuperAdmin ? 'companies'
        : 'department';

  return initialRouteName;
};

export default PickRoute;
