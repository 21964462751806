import React from 'react';
import {
  View, StyleSheet, useWindowDimensions, ViewStyle,
} from 'react-native';

type DesktopWrapperProps = {
  children?: React.ReactNode;
  style?: ViewStyle
};

const DesktopWrapper: React.FC<DesktopWrapperProps> = ({ children, style }) => {
  const { width } = useWindowDimensions();
  return (
    <View style={[styles.container, { width }, style]}>
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    maxWidth: 1280,
    marginHorizontal: 'auto',
  },
});

export default DesktopWrapper;
