import * as Device from 'expo-device';
import * as React from 'react';
import * as Notifications from 'expo-notifications';
import { Platform } from 'react-native';
import Constants from 'expo-constants';
import { useMutation } from '@apollo/client';
import { AddPushNotificationTokenMutation, DeletePushNotificationTokenMutation } from '../types/generated.types';
import { addPushNotificationTokenMutation, deletePushNotificationTokenMutation } from '../queries';

export default function useNotifications() {
  const [isLoadingComplete, setLoadingComplete] = React.useState(false);
  const [expoPushToken, setExpoPushToken] = React.useState('');
  const [addPushNotificationToken] = useMutation<AddPushNotificationTokenMutation>(addPushNotificationTokenMutation);
  const [deletePushNotificationToken] = useMutation<DeletePushNotificationTokenMutation>(deletePushNotificationTokenMutation);

  /**
   * When a notification is received while the app is running,
   * using this function you can set a callback that will decide whether
   * the notification should be shown to the user or not.
   */
  Notifications.setNotificationHandler({
    handleNotification: async () => ({
      shouldShowAlert: true,
      shouldPlaySound: true,
      shouldSetBadge: false,
      showSubtitle: true,
    }),
  });

  const registerOrUnregisterPushNotifications = React.useCallback(async () => {
    let token: string;
    if (Device.isDevice) {
      const { status: existingStatus } = await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;
      if (Platform.OS === 'android') {
        console.log('device is ANDROID');
        await Notifications.setNotificationChannelAsync('default', {
          name: 'default',
          importance: Notifications.AndroidImportance.MAX,
          vibrationPattern: [0, 250, 250, 250],
          lightColor: '#FF231F7C',
        });
      }

      token = (await Notifications.getExpoPushTokenAsync({ projectId: Constants?.expoConfig?.extra?.eas.projectId })).data;
      if (existingStatus !== 'granted') {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }
      if (finalStatus !== 'granted') {
        await deletePushNotificationToken({ variables: { deviceToken: token } });
        return;
      }

      setExpoPushToken(token);
      await addPushNotificationToken({ variables: { deviceToken: token } });
    } else {
      console.warn('Must use physical device for Push Notifications');
    }

    setLoadingComplete(true);
  }, []);

  // Load any resources or data that we need prior to rendering the app
  React.useEffect(() => {
    void registerOrUnregisterPushNotifications();
  }, []);

  return {
    isLoadingComplete,
    expoPushToken,
    registerOrUnregisterPushNotifications,
  };
}
