import i18n from '../localization';
import Messages from '../localization/types';

// Add variables here if you need to pass them to the translation
interface TranslationVars {
  name?: string;
  companyToDelete?: string;
  departmentToDelete?: string;
  coworkerToDelete?: string;
  managerToDelete?: string;
}

/**
 * @description Hook to get the translation for the current language
 * @param variables
 */
const useTranslation = (variables?: TranslationVars): Messages => ({
  screen: {
    department: {
      title: i18n.t('screen.department.title'),
      coworker: i18n.t('screen.department.coworker'),
      day: i18n.t('screen.department.day'),
      status: i18n.t('screen.department.status'),
      today: i18n.t('screen.department.today'),
      export: i18n.t('screen.department.export'),
      addCoworker: i18n.t('screen.department.addCoworker'),
      arendraModel: i18n.t('screen.department.arendraModel'),
      simplified: i18n.t('screen.department.simplified'),
      cost: i18n.t('screen.department.cost'),
      time: i18n.t('screen.department.time'),
      timeToEvaluate: i18n.t('screen.department.timeToEvaluate'),
      welcome: i18n.t('screen.department.welcome'),
      stepOneText: i18n.t('screen.department.stepOneText'),
      name: i18n.t('screen.department.name'),
      groupAverage: i18n.t('screen.department.groupAverage'),
      threeMonths: i18n.t('screen.department.threeMonths'),
      accordingToArendraModel: i18n.t('screen.department.accordingToArendraModel'),
      month: i18n.t('screen.department.month'),
      noCoworkers: i18n.t('screen.department.noCoworkers'),
      SEK: i18n.t('screen.department.SEK'),
      SEKPerMonth: i18n.t('screen.department.SEKPerMonth'),
      thousandSEK: i18n.t('screen.department.thousandSEK'),
      thousandSEKPerMonth: i18n.t('screen.department.thousandSEKPerMonth'),
      coworkersError: i18n.t('screen.department.coworkersError'),
    },
    login: {
      title: i18n.t('screen.login.title'),
      logInWithBankID: i18n.t('screen.login.logInWithBankID'),
      logInWithBankIDWeb: i18n.t('screen.login.logInWithBankIDWeb'),
      bankIdOnOtherDevice: i18n.t('screen.login.bankIdOnOtherDevice'),
      readQrCode: i18n.t('screen.login.readQrCode'),
    },
    termsAndConditions: {
      title: i18n.t('screen.termsAndConditions.title'),
      instructions: i18n.t('screen.termsAndConditions.instructions'),
      email: i18n.t('screen.termsAndConditions.email'),
      acceptTerms: i18n.t('screen.termsAndConditions.acceptTerms'),
      continue: i18n.t('screen.termsAndConditions.continue'),
      terms: i18n.t('screen.termsAndConditions.terms'),
    },
    onBoarding: {
      title: i18n.t('screen.onBoarding.title'),
      subTitle: i18n.t('screen.onBoarding.subTitle'),
      continue: i18n.t('screen.onBoarding.continue'),
      next: i18n.t('screen.onBoarding.next'),
      stepOneTitle: i18n.t('screen.onBoarding.stepOneTitle'),
      stepOneDescription: i18n.t('screen.onBoarding.stepOneDescription'),
      stepTwoTitle: i18n.t('screen.onBoarding.stepTwoTitle'),
      stepTwoDescription: i18n.t('screen.onBoarding.stepTwoDescription'),
      stepThreeTitle: i18n.t('screen.onBoarding.stepThreeTitle'),
      stepThreeDescription: i18n.t('screen.onBoarding.stepThreeDescription'),
    },
    contact: {
      title: i18n.t('screen.contact.title'),
      send: i18n.t('screen.contact.send'),
      contactArendra: i18n.t('screen.contact.contactArendra'),
      mutationSucceededMessage: i18n.t('screen.contact.mutationSucceededMessage'),
    },
    roleSelection: {
      title: i18n.t('screen.roleSelection.title'),
      superAdmin: i18n.t('screen.roleSelection.superAdmin'),
      superAdminNotAvailable: i18n.t('screen.roleSelection.superAdminNotAvailable'),
      youAreLoggedInAs: i18n.t('screen.roleSelection.youAreLoggedInAs', {
        name: variables?.name || '',
      }),
    },
    evaluation: {
      title: i18n.t('screen.evaluation.title'),
      startEvaluation: i18n.t('screen.evaluation.startEvaluation'),
      close: i18n.t('screen.evaluation.close'),
      evaluate: i18n.t('screen.evaluation.evaluate'),
      activelySkip: i18n.t('screen.evaluation.activelySkip'),
      activelySkipText: i18n.t('screen.evaluation.activelySkipText'),
      activelySkipText2: i18n.t('screen.evaluation.activelySkipText2'),
      offDuty: i18n.t('screen.evaluation.offDuty'),
      haveNotMet: i18n.t('screen.evaluation.haveNotMet'),
      absence: i18n.t('screen.evaluation.absence'),
      other: i18n.t('screen.evaluation.other'),
      workPerformance: i18n.t('screen.evaluation.workPerformance'),
      followLawsAndDecisions: i18n.t('screen.evaluation.followLawsAndDecisions'),
      workEnvironmentResponsibility: i18n.t('screen.evaluation.workEnvironmentResponsibility'),
      dutyOfLoyalty: i18n.t('screen.evaluation.dutyOfLoyalty'),
      dutyToCooperate: i18n.t('screen.evaluation.dutyToCooperate'),
      cancel: i18n.t('screen.evaluation.cancel'),
      done: i18n.t('screen.evaluation.done'),
      skip: i18n.t('screen.evaluation.skip'),
      save: i18n.t('screen.evaluation.save'),
      of: i18n.t('screen.evaluation.of'),
      goodWork: i18n.t('screen.evaluation.goodWork'),
      doneForToday: i18n.t('screen.evaluation.doneForToday'),
      rememberToConfirm: i18n.t('screen.evaluation.rememberToConfirm'),
      activelySkipTitle: i18n.t('screen.evaluation.activelySkipTitle'),
      statusBasisTitle: i18n.t('screen.evaluation.statusBasisTitle'),
      seeGroupAverage: i18n.t('screen.evaluation.seeGroupAverage'),
      reEvaluate: i18n.t('screen.evaluation.reEvaluate'),
    },
    evaluationCoworkers: {
      title: i18n.t('screen.evaluationCoworkers.title'),
      description: i18n.t('screen.evaluationCoworkers.description'),
    },
    act: {
      title: i18n.t('screen.act.title'),
      actTitle: i18n.t('screen.act.actTitle'),
      actSubTitle: i18n.t('screen.act.actSubTitle'),
      statusGrayTitle: i18n.t('screen.act.statusGrayTitle'),
      statusGrayText: i18n.t('screen.act.statusGrayText'),
      statusYellowTitle: i18n.t('screen.act.statusYellowTitle'),
      statusYellowText: i18n.t('screen.act.statusYellowText'),
      statusYellowText2: i18n.t('screen.act.statusYellowText2'),
      statusRedTitle: i18n.t('screen.act.statusRedTitle'),
      statusRedText: i18n.t('screen.act.statusRedText'),
      statusRedText2: i18n.t('screen.act.statusRedText2'),
      contactTitle: i18n.t('screen.act.contactTitle'),
      contactArendra: i18n.t('screen.act.contactArendra'),
      mailOrPhone: i18n.t('screen.act.mailOrPhone'),
      formInfo: i18n.t('screen.act.formInfo'),
      email: i18n.t('screen.act.email'),
      phone: i18n.t('screen.act.phone'),
      send: i18n.t('screen.act.send'),
      contactHR: i18n.t('screen.act.contactHR'),
    },
    departments: {
      title: i18n.t('screen.departments.title'),
    },
    about: {
      title: i18n.t('screen.about.title'),
      p1: i18n.t('screen.about.p1'),
      p2: i18n.t('screen.about.p2'),
      p3: i18n.t('screen.about.p3'),
      p4: i18n.t('screen.about.p4'),
      // p5: i18n.t('screen.about.p5'),
      // p6: i18n.t('screen.about.p6'),
      link: i18n.t('screen.about.link'),
    },
    aboutArendraModel: {
      title: i18n.t('screen.aboutArendraModel.title'),
      p1: i18n.t('screen.aboutArendraModel.p1'),
      p2: i18n.t('screen.aboutArendraModel.p2'),
      p3: i18n.t('screen.aboutArendraModel.p3'),
      p4: i18n.t('screen.aboutArendraModel.p4'),
      p5: i18n.t('screen.aboutArendraModel.p5'),
      p6: i18n.t('screen.aboutArendraModel.p6'),
      statusGreen: i18n.t('screen.aboutArendraModel.statusGreen'),
      statusYellow: i18n.t('screen.aboutArendraModel.statusYellow'),
      statusOrange: i18n.t('screen.aboutArendraModel.statusOrange'),
      statusRed: i18n.t('screen.aboutArendraModel.statusRed'),
      statusBlack: i18n.t('screen.aboutArendraModel.statusBlack'),
      green: i18n.t('screen.aboutArendraModel.green'),
      yellow: i18n.t('screen.aboutArendraModel.yellow'),
      orange: i18n.t('screen.aboutArendraModel.orange'),
      red: i18n.t('screen.aboutArendraModel.red'),
      black: i18n.t('screen.aboutArendraModel.black'),
    },
    editCoworker: {
      title: i18n.t('screen.editCoworker.title'),
      deleteCoworkerTitle: i18n.t('screen.editCoworker.deleteCoworkerTitle'),
      deleteCoworkerText: i18n.t('screen.editCoworker.deleteCoworkerText', {
        coworkerToDelete: variables?.coworkerToDelete || '',
      }),
      workPercentage: i18n.t('screen.editCoworker.workPercentage'),
      salary: i18n.t('screen.editCoworker.salary'),
      firstName: i18n.t('screen.editCoworker.firstName'),
      lastName: i18n.t('screen.editCoworker.lastName'),
    },
    addCoworker: {
      title: i18n.t('screen.addCoworker.title'),
      add: i18n.t('screen.addCoworker.add'),
      save: i18n.t('screen.addCoworker.save'),
      name: i18n.t('screen.addCoworker.name'),
      firstName: i18n.t('screen.addCoworker.firstName'),
      lastName: i18n.t('screen.addCoworker.lastName'),
      workPercentage: i18n.t('screen.addCoworker.workPercentage'),
      salary: i18n.t('screen.addCoworker.salary'),
      edit: i18n.t('screen.addCoworker.edit'),
    },
    coworker: {
      title: i18n.t('screen.coworker.title'),
      edit: i18n.t('screen.coworker.edit'),
      deleteCoworker: i18n.t('screen.coworker.deleteCoworker'),
      status: i18n.t('screen.coworker.status'),
      averageTitle: i18n.t('screen.coworker.averageTitle'),
      cost: i18n.t('screen.coworker.cost'),
      history: i18n.t('screen.coworker.history'),
      evaluations: i18n.t('screen.coworker.evaluations'),
      summary: i18n.t('screen.coworker.summary'),
      estimatedCost: i18n.t('screen.coworker.estimatedCost'),
      SEK: i18n.t('screen.coworker.SEK'),
    },
    cheatSheet: {
      title: i18n.t('screen.cheatSheet.title'),
      firstDescription: i18n.t('screen.cheatSheet.firstDescription'),
      secondDescription: i18n.t('screen.cheatSheet.secondDescription'),
      statusGreen: i18n.t('screen.cheatSheet.statusGreen'),
      statusYellow: i18n.t('screen.cheatSheet.statusYellow'),
      statusRed: i18n.t('screen.cheatSheet.statusRed'),
      sections: [
        {
          title: i18n.t('screen.cheatSheet.sections.0.title'),
          list: [
            i18n.t('screen.cheatSheet.sections.0.list.0'),
            i18n.t('screen.cheatSheet.sections.0.list.1'),
            i18n.t('screen.cheatSheet.sections.0.list.2'),
            i18n.t('screen.cheatSheet.sections.0.list.3'),
          ],
        },
        {
          title: i18n.t('screen.cheatSheet.sections.1.title'),
          list: [
            i18n.t('screen.cheatSheet.sections.1.list.0'),
            i18n.t('screen.cheatSheet.sections.1.list.1'),
          ],
        },
        {
          title: i18n.t('screen.cheatSheet.sections.2.title'),
          list: [
            i18n.t('screen.cheatSheet.sections.2.list.0'),
            i18n.t('screen.cheatSheet.sections.2.list.1'),
            i18n.t('screen.cheatSheet.sections.2.list.2'),
            i18n.t('screen.cheatSheet.sections.2.list.3'),
            i18n.t('screen.cheatSheet.sections.2.list.4'),
          ],
        },
        {
          title: i18n.t('screen.cheatSheet.sections.3.title'),
          list: [
            i18n.t('screen.cheatSheet.sections.3.list.0'),
            i18n.t('screen.cheatSheet.sections.3.list.1'),
            i18n.t('screen.cheatSheet.sections.3.list.2'),
            i18n.t('screen.cheatSheet.sections.3.list.3'),
            i18n.t('screen.cheatSheet.sections.3.list.4'),
          ],
        },
        {
          title: i18n.t('screen.cheatSheet.sections.4.title'),
          list: [
            i18n.t('screen.cheatSheet.sections.4.list.0'),
            i18n.t('screen.cheatSheet.sections.4.list.1'),
            i18n.t('screen.cheatSheet.sections.4.list.2'),
            i18n.t('screen.cheatSheet.sections.4.list.3'),
          ],
        },
      ],
    },
    superAdmin: {
      companies: {
        title: i18n.t('screen.superAdmin.companies.title'),
        addCompany: i18n.t('screen.superAdmin.companies.addCompany'),
        company: i18n.t('screen.superAdmin.companies.company'),
        orgNumber: i18n.t('screen.superAdmin.companies.orgNumber'),
        endDate: i18n.t('screen.superAdmin.companies.endDate'),
        email: i18n.t('screen.superAdmin.companies.email'),
        phoneNumber: i18n.t('screen.superAdmin.companies.phoneNumber'),
        phone: i18n.t('screen.superAdmin.companies.phone'),
        dateFormat: i18n.t('screen.superAdmin.companies.dateFormat'),
        deleteCompany: i18n.t('screen.superAdmin.companies.deleteCompany'),
        deleteText: i18n.t('screen.superAdmin.companies.deleteText', {
          companyToDelete: variables?.companyToDelete || '',
        }),
        started: i18n.t('screen.superAdmin.companies.started'),
      },
      company: {
        title: i18n.t('screen.superAdmin.company.title'),
        addDepartment: i18n.t('screen.superAdmin.company.addDepartment'),
        addSubDepartment: i18n.t('screen.superAdmin.company.addSubDepartment'),
        cost: i18n.t('screen.superAdmin.company.cost'),
        admin: i18n.t('screen.superAdmin.company.admin'),
        firstName: i18n.t('screen.superAdmin.company.firstName'),
        lastName: i18n.t('screen.superAdmin.company.lastName'),
        personalNumberFormat: i18n.t('screen.superAdmin.company.personalNumberFormat'),
        deleteDepartment: i18n.t('screen.superAdmin.company.deleteDepartment'),
        deleteText: i18n.t('screen.superAdmin.company.deleteText', {
          departmentToDelete: variables?.departmentToDelete || '',
        }),
        deleteManager: i18n.t('screen.superAdmin.company.deleteManager'),
        deleteManagerText: i18n.t('screen.superAdmin.company.deleteManagerText', {
          managerToDelete: variables?.managerToDelete || '',
        }),
        importOrganization: i18n.t('screen.superAdmin.company.importOrganization'),
        chooseFile: i18n.t('screen.superAdmin.company.chooseFile'),
        import: i18n.t('screen.superAdmin.company.import'),
      },
      manage: i18n.t('screen.superAdmin.manage'),
      add: i18n.t('screen.superAdmin.add'),
      save: i18n.t('screen.superAdmin.save'),
      cancel: i18n.t('screen.superAdmin.cancel'),
      delete: i18n.t('screen.superAdmin.delete'),
    },
    settings: {
      title: i18n.t('screen.settings.title'),
      loggedInAs: i18n.t('screen.settings.loggedInAs'),
      emailPlaceholder: i18n.t('screen.settings.emailPlaceholder'),
      edit: i18n.t('screen.settings.edit'),
    },
    costInfo: {
      title: i18n.t('screen.costInfo.title'),
      p1: i18n.t('screen.costInfo.p1'),
      p2: i18n.t('screen.costInfo.p2'),
      p3: i18n.t('screen.costInfo.p3'),
      p4: i18n.t('screen.costInfo.p4'),
    },
    integrityPolicy: {
      title: i18n.t('screen.integrityPolicy.title'),
      p1: {
        title: i18n.t('screen.integrityPolicy.p1.title'),
        text: i18n.t('screen.integrityPolicy.p1.text'),
      },
      p2: {
        title: i18n.t('screen.integrityPolicy.p2.title'),
        text: i18n.t('screen.integrityPolicy.p2.text'),
      },
      p3: {
        title: i18n.t('screen.integrityPolicy.p3.title'),
        text: i18n.t('screen.integrityPolicy.p3.text'),
      },
      p4: {
        title: i18n.t('screen.integrityPolicy.p4.title'),
        text: i18n.t('screen.integrityPolicy.p4.text'),
      },
      p5: {
        title: i18n.t('screen.integrityPolicy.p5.title'),
        text: i18n.t('screen.integrityPolicy.p5.text'),
      },
      p6: {
        title: i18n.t('screen.integrityPolicy.p6.title'),
        text: i18n.t('screen.integrityPolicy.p6.text'),
      },
    },
    userTerms: {
      title: i18n.t('screen.userTerms.title'),
      text1: i18n.t('screen.userTerms.text1'),
      text2: i18n.t('screen.userTerms.text2'),
      useOfArendraTool: {
        title: i18n.t('screen.userTerms.useOfArendraTool.title'),
        p1: {
          title: i18n.t('screen.userTerms.useOfArendraTool.p1.title'),
          text1: i18n.t('screen.userTerms.useOfArendraTool.p1.text1'),
          text2: i18n.t('screen.userTerms.useOfArendraTool.p1.text2'),
        },
        p2: {
          title: i18n.t('screen.userTerms.useOfArendraTool.p2.title'),
          text1: i18n.t('screen.userTerms.useOfArendraTool.p2.text1'),
          text2: i18n.t('screen.userTerms.useOfArendraTool.p2.text2'),
          text3: i18n.t('screen.userTerms.useOfArendraTool.p2.text3'),
          listItem1: i18n.t('screen.userTerms.useOfArendraTool.p2.listItem1'),
          listItem2: i18n.t('screen.userTerms.useOfArendraTool.p2.listItem2'),
          listItem3: i18n.t('screen.userTerms.useOfArendraTool.p2.listItem3'),
          listItem4: i18n.t('screen.userTerms.useOfArendraTool.p2.listItem4'),
          listItem5: i18n.t('screen.userTerms.useOfArendraTool.p2.listItem5'),
          listItem6: i18n.t('screen.userTerms.useOfArendraTool.p2.listItem6'),
          listItem7: i18n.t('screen.userTerms.useOfArendraTool.p2.listItem7'),
          text4: i18n.t('screen.userTerms.useOfArendraTool.p2.text4'),
        },
        p3: {
          title: i18n.t('screen.userTerms.useOfArendraTool.p3.title'),
          text1: i18n.t('screen.userTerms.useOfArendraTool.p3.text1'),
          text2: i18n.t('screen.userTerms.useOfArendraTool.p3.text2'),
          text3: i18n.t('screen.userTerms.useOfArendraTool.p3.text3'),
          text4: i18n.t('screen.userTerms.useOfArendraTool.p3.text4'),
          text5: i18n.t('screen.userTerms.useOfArendraTool.p3.text5'),
        },
        p4: {
          title: i18n.t('screen.userTerms.useOfArendraTool.p4.title'),
          text1: i18n.t('screen.userTerms.useOfArendraTool.p4.text1'),
          text2: i18n.t('screen.userTerms.useOfArendraTool.p4.text2'),
          text3: i18n.t('screen.userTerms.useOfArendraTool.p4.text3'),
          text4: i18n.t('screen.userTerms.useOfArendraTool.p4.text4'),
        },
        p5: {
          title: i18n.t('screen.userTerms.useOfArendraTool.p5.title'),
          text1: i18n.t('screen.userTerms.useOfArendraTool.p5.text1'),
        },
        p6: {
          title: i18n.t('screen.userTerms.useOfArendraTool.p6.title'),
          text1: i18n.t('screen.userTerms.useOfArendraTool.p6.text1'),
        },
      },
      rightsAndOwnership: {
        title: i18n.t('screen.userTerms.rightsAndOwnership.title'),
        p1: {
          title: i18n.t('screen.userTerms.rightsAndOwnership.p1.title'),
          text1: i18n.t('screen.userTerms.rightsAndOwnership.p1.text1'),
          text2: i18n.t('screen.userTerms.rightsAndOwnership.p1.text2'),
        },
        p2: {
          title: i18n.t('screen.userTerms.rightsAndOwnership.p2.title'),
          text1: i18n.t('screen.userTerms.rightsAndOwnership.p2.text1'),
          text2: i18n.t('screen.userTerms.rightsAndOwnership.p2.text2'),
        },
      },
      dataProtection: {
        title: i18n.t('screen.userTerms.dataProtection.title'),
        p1: {
          title: i18n.t('screen.userTerms.dataProtection.p1.title'),
          text1: i18n.t('screen.userTerms.dataProtection.p1.text1'),
          text2: i18n.t('screen.userTerms.dataProtection.p1.text2'),
        },
        p2: {
          title: i18n.t('screen.userTerms.dataProtection.p2.title'),
          text1: i18n.t('screen.userTerms.dataProtection.p2.text1'),
        },
      },
      agreementTimeAndTermination: {
        title: i18n.t('screen.userTerms.agreementTimeAndTermination.title'),
        p1: {
          title: i18n.t('screen.userTerms.agreementTimeAndTermination.p1.title'),
          text1: i18n.t('screen.userTerms.agreementTimeAndTermination.p1.text1'),
        },
        p2: {
          title: i18n.t('screen.userTerms.agreementTimeAndTermination.p2.title'),
          text1: i18n.t('screen.userTerms.agreementTimeAndTermination.p2.text1'),
          text2: i18n.t('screen.userTerms.agreementTimeAndTermination.p2.text2'),
          listItem1: i18n.t('screen.userTerms.agreementTimeAndTermination.p2.listItem1'),
          listItem2: i18n.t('screen.userTerms.agreementTimeAndTermination.p2.listItem2'),
          text3: i18n.t('screen.userTerms.agreementTimeAndTermination.p2.text3'),
        },
        p3: {
          title: i18n.t('screen.userTerms.agreementTimeAndTermination.p3.title'),
          text1: i18n.t('screen.userTerms.agreementTimeAndTermination.p3.text1'),
        },
        p4: {
          title: i18n.t('screen.userTerms.agreementTimeAndTermination.p4.title'),
          text1: i18n.t('screen.userTerms.agreementTimeAndTermination.p4.text1'),
        },
      },
      changesToTheseTerms: {
        title: i18n.t('screen.userTerms.changesToTheseTerms.title'),
        p1: {
          text1: i18n.t('screen.userTerms.changesToTheseTerms.p1.text1'),
        },
      },
      responsibility: {
        title: i18n.t('screen.userTerms.responsibility.title'),
        p1: {
          title: i18n.t('screen.userTerms.responsibility.p1.title'),
          text1: i18n.t('screen.userTerms.responsibility.p1.text1'),
        },
        p2: {
          title: i18n.t('screen.userTerms.responsibility.p2.title'),
          text1: i18n.t('screen.userTerms.responsibility.p2.text1'),
          text2: i18n.t('screen.userTerms.responsibility.p2.text2'),
        },
        p3: {
          title: i18n.t('screen.userTerms.responsibility.p3.title'),
          text1: i18n.t('screen.userTerms.responsibility.p3.text1'),
        },
      },
      specialTermsForArendraApp: {
        title: i18n.t('screen.userTerms.specialTermsForArendraApp.title'),
        text1: i18n.t('screen.userTerms.specialTermsForArendraApp.text1'),
        listItem1: i18n.t('screen.userTerms.specialTermsForArendraApp.listItem1'),
        listItem2: i18n.t('screen.userTerms.specialTermsForArendraApp.listItem2'),
        listItem3: i18n.t('screen.userTerms.specialTermsForArendraApp.listItem3'),
        listItem4: i18n.t('screen.userTerms.specialTermsForArendraApp.listItem4'),
        listItem5: i18n.t('screen.userTerms.specialTermsForArendraApp.listItem5'),
        listItem6: i18n.t('screen.userTerms.specialTermsForArendraApp.listItem6'),
      },
      other: {
        title: i18n.t('screen.userTerms.other.title'),
        p1: {
          title: i18n.t('screen.userTerms.other.p1.title'),
          text1: i18n.t('screen.userTerms.other.p1.text1'),
        },
        p2: {
          title: i18n.t('screen.userTerms.other.p2.title'),
          text1: i18n.t('screen.userTerms.other.p2.text1'),
        },
        p3: {
          title: i18n.t('screen.userTerms.other.p3.title'),
          text1: i18n.t('screen.userTerms.other.p3.text1'),
        },
        p4: {
          title: i18n.t('screen.userTerms.other.p4.title'),
          text1: i18n.t('screen.userTerms.other.p4.text1'),
        },
      },
      applicableLawAndDisputes: {
        title: i18n.t('screen.userTerms.applicableLawAndDisputes.title'),
        p1: {
          title: i18n.t('screen.userTerms.applicableLawAndDisputes.p1.title'),
          text1: i18n.t('screen.userTerms.applicableLawAndDisputes.p1.text1'),
        },
        p2: {
          title: i18n.t('screen.userTerms.applicableLawAndDisputes.p2.title'),
          text1: i18n.t('screen.userTerms.applicableLawAndDisputes.p2.text1'),
        },
      },
      contactInformation: {
        title: i18n.t('screen.userTerms.contactInformation.title'),
        text1: i18n.t('screen.userTerms.contactInformation.text1'),
        email: i18n.t('screen.userTerms.contactInformation.email'),
        text2: i18n.t('screen.userTerms.contactInformation.text2'),
      },
    },
    status: {
      greenTitle: i18n.t('screen.status.greenTitle'),
      yellowTitle: i18n.t('screen.status.yellowTitle'),
      orangeTitle: i18n.t('screen.status.orangeTitle'),
      greenWhatDoesItMean: i18n.t('screen.status.greenWhatDoesItMean'),
      yellowWhatDoesItMean: i18n.t('screen.status.yellowWhatDoesItMean'),
      orangeWhatDoesItMean: i18n.t('screen.status.orangeWhatDoesItMean'),
    },
  },
  statuses: {
    worksWell: i18n.t('statuses.worksWell'),
    partiallyLacking: i18n.t('statuses.partiallyLacking'),
    noticeablyLacking: i18n.t('statuses.noticeablyLacking'),
    couldNotEvaluate: i18n.t('statuses.couldNotEvaluate'),
    skipped: i18n.t('statuses.skipped'),
  },
  clientErrors: {
    fillOutAllFields: i18n.t('clientErrors.fillOutAllFields'),
    invalidEmail: i18n.t('clientErrors.invalidEmail'),
    invalidPersonalNumber: i18n.t('clientErrors.invalidPersonalNumber'),
    invalidEndDate: i18n.t('clientErrors.invalidEndDate'),
    salaryNotANumber: i18n.t('clientErrors.salaryNotANumber'),
  },
  menu: {
    arendraModel: i18n.t('menu.arendraModel'),
    about: i18n.t('menu.about'),
    settings: i18n.t('menu.settings'),
    userTerms: i18n.t('menu.userTerms'),
    integrityPolicy: i18n.t('menu.integrityPolicy'),
    contactArendra: i18n.t('menu.contactArendra'),
    logout: i18n.t('menu.logout'),
  },
  bankIdErrors: {
    RFA1: i18n.t('bankIdErrors.RFA1'),
    RFA2: i18n.t('bankIdErrors.RFA2'),
    RFA3: i18n.t('bankIdErrors.RFA3'),
    RFA4: i18n.t('bankIdErrors.RFA4'),
    RFA5: i18n.t('bankIdErrors.RFA5'),
    RFA6: i18n.t('bankIdErrors.RFA6'),
    RFA8: i18n.t('bankIdErrors.RFA8'),
    RFA9: i18n.t('bankIdErrors.RFA9'),
    RFA13: i18n.t('bankIdErrors.RFA13'),
    RFA14A: i18n.t('bankIdErrors.RFA14A'),
    RFA14B: i18n.t('bankIdErrors.RFA14B'),
    RFA15A: i18n.t('bankIdErrors.RFA15A'),
    RFA15B: i18n.t('bankIdErrors.RFA15B'),
    RFA16: i18n.t('bankIdErrors.RFA16'),
    RFA17A: i18n.t('bankIdErrors.RFA17A'),
    RPA17B: i18n.t('bankIdErrors.RFA17B'),
    RFA18: i18n.t('bankIdErrors.RFA18'),
    RFA19: i18n.t('bankIdErrors.RFA19'),
    RFA20: i18n.t('bankIdErrors.RFA20'),
    RFA21: i18n.t('bankIdErrors.RFA21'),
    RFA22: i18n.t('bankIdErrors.RFA22'),
    RFA23: i18n.t('bankIdErrors.RFA23'),
    BAD_USER_INPUT: i18n.t('bankIdErrors.BAD_USER_INPUT'),
  },
});

export default useTranslation;
