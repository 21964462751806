import React from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import Svg, { G, Path } from 'react-native-svg';

const EvaluateBottom = () => (
  <View style={styles.svgContainer}>
    {/* Left */}
    <View style={[{ flex: 1, zIndex: Platform.OS === 'ios' ? 10 : -10 }]}>
      <View style={styles.leftBottom} />
    </View>
    {/* Center */}
    <Svg width={245.674} height={87} style={styles.svgStyles}>
      <G filter='url(#a)'>
        <Path
          fill='#fff'
          d='M236.677 6h-10a39.5 39.5 0 0 1-39.337 36h-129A39.5 39.5 0 0 1 19.003 6h-10v69h227.674'
          data-name='Path 1745'
        />
      </G>
    </Svg>
    {/* Right */}
    <View style={{ flex: 1, zIndex: Platform.OS === 'ios' ? 10 : -10 }}>
      <View style={styles.rightBottom} />
    </View>
  </View>
);

const styles = StyleSheet.create({
  svgContainer: {
    height: 75,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  svgStyles: {
    marginLeft: -7,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: -2,
    },
    shadowOpacity: 0.15,
    shadowRadius: 2,
    elevation: 5,
  },
  leftBottom: {
    position: 'absolute',
    left: 0,
    right: -4,
    bottom: 0,
    borderTopLeftRadius: 20,
    backgroundColor: '#fff',
    height: 69,
    alignSelf: 'flex-end',
    shadowColor: '#000',
    shadowOffset: {
      width: -3,
      height: -2,
    },
    shadowOpacity: 0.15,
    shadowRadius: 2,
    elevation: 2,
  },
  rightBottom: {
    position: 'absolute',
    right: 0,
    left: -10,
    bottom: 0,
    borderTopRightRadius: 20,
    backgroundColor: '#fff',
    height: 69,
    alignSelf: 'flex-end',
    shadowColor: '#000',
    shadowOffset: {
      width: 3,
      height: -2,
    },
    shadowOpacity: 0.15,
    shadowRadius: 2,
    elevation: 2,
  },
});

export default EvaluateBottom;
