import React, { useContext } from 'react';
import { Button, useTheme } from 'react-native-paper';
import axios from 'axios';
import { EXPO_PUBLIC_SERVER_URL } from '@env';
import UserSettingsContext from '../../../contexts/UserSettingsContext';
import FileDownload from '../../../assets/icons/file-download.svg';
import Icon from '../../../components/Icon';
import AuthContext from '../../../contexts/AuthContext';

type SaveFileAsXLSXProps = {
  departmentName?: string;
  xlsxType: 'Coworkers' | 'Departments';
};

const SaveFileAsXLSX: React.FC<SaveFileAsXLSXProps> = ({ departmentName, xlsxType }) => {
  const { colors } = useTheme();
  const { token } = useContext(AuthContext);
  const { currentDepartment } = useContext(UserSettingsContext);
  const endpoint = xlsxType === 'Coworkers' ? 'getCoworkersEstimationsXls' : 'getDepartmentsSummaryReportXls';

  const generateExcel = async () => {
    if (!currentDepartment || !departmentName) return;

    const url = `${EXPO_PUBLIC_SERVER_URL}${endpoint}`;
    const response = await axios.get(`${url}?departmentId=${currentDepartment}`, {
      headers: {
        token,
      },
      responseType: 'arraybuffer',
    });

    // Create a Blob from the response data
    const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const downloadUrl = window.URL.createObjectURL(blob);

    // Trigger the download
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.download = `${departmentName.replace(/\s/g, '_')}-${new Date().toLocaleDateString()}.xlsx`;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();

    // Cleanup
    window.URL.revokeObjectURL(downloadUrl);
    document.body.removeChild(a);
  };

  const onPressExportToExcel = () => {
    void generateExcel();
  };

  return (
    <Button mode='outlined' onPress={onPressExportToExcel} style={{ borderRadius: 20 }} textColor={colors.secondary}>
      <Icon
        source={FileDownload}
        height={14}
        width={14}
        style={{
          marginBottom: -2, marginRight: 5,
        }}
      />
      XLS
    </Button>
  );
};

export default SaveFileAsXLSX;
