import { NavigationProp, useNavigation } from '@react-navigation/native';
import React, { useContext, useEffect, useState } from 'react';
import {
  View, StyleSheet, useWindowDimensions, Platform, Text, TouchableHighlight, RefreshControl,
} from 'react-native';
import { Button, useTheme } from 'react-native-paper';
import type {
  CoworkersQuery, DepartmentCoworker, EstimationsByDepartmentIdQuery, RootStackParamList,
} from '@types';
import { ScrollView } from 'react-native-gesture-handler';
import { Ionicons } from '@expo/vector-icons';
import { useQuery } from '@apollo/client';
import { estimationsByDepartmentIdQuery, listCoworkersQuery } from '../../queries';
import useMediaQueries from '../../hooks/useMediaQueries';
import useTranslation from '../../hooks/useTranslation';
import ArendraModel from './components/ArendraModel';
import ExportRow from './components/ExportRow';
import EvaluateBottom from './components/EvaluateBottom';
import Icon from '../../components/Icon';
import ArendraWhite from '../../assets/icons/arendra-white.svg';
import CoworkersHeader from './components/CoworkersHeader';
import CoworkersTable from './components/Coworkers';
import SlideOverContext from '../../contexts/SlideOverContext';
import TimeToEvaluateWeb from './components/TimeToEvaluateWeb';
import UserSettingsContext from '../../contexts/UserSettingsContext';
import Box from '../../components/Box';

const DepartmentScreen = ({ route }) => {
  const departmentId = route.params?.departmentId;
  const isTimeToAct = route.params?.isTimeToAct;

  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  const { height } = useWindowDimensions();
  const { screen } = useTranslation();
  const { isTablet } = useMediaQueries();
  const { colors } = useTheme();
  const { setSlideOver } = useContext(SlideOverContext);
  const { currentDepartment } = useContext(UserSettingsContext);

  const {
    data: coworkersData,
    loading: coworkersLoading,
    error: coworkersError,
    refetch: refetchCoworkers,
  } = useQuery<CoworkersQuery>(listCoworkersQuery, { variables: { departmentId } });
  const { data: evaluationsData, refetch: refetchEvaluations } = useQuery<EstimationsByDepartmentIdQuery>(estimationsByDepartmentIdQuery, {
    variables: {
      departmentId: currentDepartment,
    },
  });

  const [isRefetching, setIsRefetching] = useState(false);

  const isCoworkersEmpty = coworkersData?.department?.coworkersWithEstimations?.length === 0 && !coworkersLoading;

  const isWeb = Platform.OS === 'web';

  useEffect(() => {
    navigation.setOptions({
      title: 'Min avdelning',
    });
  }, []);

  useEffect(() => {
    if (!isTimeToAct) return;
    if (isWeb) {
      setSlideOver('act');
    } else {
      navigation.navigate('act');
    }
  }, [isTimeToAct, isWeb, navigation, setSlideOver]);

  const navigateToEvaluation = () => {
    navigation.navigate('evaluation');
  };

  const handleAddCoworker = () => {
    if (isWeb) {
      setSlideOver('addCoworker');
    } else {
      navigation.navigate('addCoworker');
    }
  };

  const handleRefresh = () => {
    // if (coworkersLoading || evaluationsLoading) return;
    setIsRefetching(true);
    void refetchCoworkers();
    void refetchEvaluations();
    setIsRefetching(false);
  };

  return (
    <View style={styles.container}>
      <ScrollView
        style={styles.top}
        refreshControl={<RefreshControl refreshing={isRefetching} onRefresh={handleRefresh} />}
      >
        <View style={[styles.arendraModelContainer, !isWeb && { alignItems: 'center' }]}>
          <View style={styles.row}>
            <ArendraModel evaluationsData={evaluationsData} />
            {isWeb && isTablet
              && (
                <TimeToEvaluateWeb navigateToEvaluation={navigateToEvaluation} isCoworkersEmpty={isCoworkersEmpty} isCoworkersLoading={coworkersLoading} />
              )}
          </View>
        </View>
        {isWeb && !isTablet && <TimeToEvaluateWeb navigateToEvaluation={navigateToEvaluation} isCoworkersEmpty={isCoworkersEmpty} isCoworkersLoading={coworkersLoading} />}
        <View style={[styles.contentContainer, { backgroundColor: colors.background }]}>
          <View style={{ flex: 1, maxWidth: 1280, paddingBottom: isWeb ? 40 : 0 }}>
            <View style={{ flex: 1, backgroundColor: colors.background }}>
              <CoworkersHeader departmentName={coworkersData?.department.name} />
              {coworkersData?.department.coworkersWithEstimations && coworkersData?.department.coworkersWithEstimations?.length > 0 && (
                <CoworkersTable
                  coworkers={coworkersData?.department.coworkersWithEstimations as DepartmentCoworker[] || []}
                />
              )}
              {coworkersError && (
                <Box style={{ marginHorizontal: 20, paddingVertical: 30 }}>
                  <Text style={[styles.errorText, { color: colors.error }]}>
                    {screen.department.coworkersError}
                  </Text>
                  <Text style={{ opacity: 0.4, textAlign: 'center', fontStyle: 'italic' }}>{coworkersError.message}</Text>
                </Box>
              )}
            </View>
            {!isWeb && (
              <>
                {(coworkersData && coworkersData.department !== undefined)
                  && <ExportRow departmentName={coworkersData?.department.name} />}
                <Button
                  mode='contained'
                  contentStyle={{ height: 50 }}
                  style={styles.addCoworkerButton}
                  buttonColor={colors.secondary}
                  onPress={handleAddCoworker}
                  uppercase={false}
                >
                  {screen.department.addCoworker}
                  <View style={{ marginBottom: -3 }}>
                    <Ionicons name='add-sharp' size={16} color={colors.onSecondary} />
                  </View>
                </Button>
              </>
            )}
          </View>
        </View>
      </ScrollView>

      {!isWeb && (
        <>
          <EvaluateBottom />
          <View style={styles.evaluateButtonContainer}>
            <TouchableHighlight
              onPress={navigateToEvaluation}
              underlayColor={colors.primary}
              style={[styles.evaluateButton, { backgroundColor: colors.primary }]}
            >
              <View style={{ alignItems: 'center', gap: 3 }}>
                <Icon source={ArendraWhite} width={26} height={26} />
                <Text style={{ color: colors.onPrimary, fontWeight: '500' }}>{screen.evaluation.title}</Text>
              </View>
            </TouchableHighlight>
          </View>
        </>
      )}
      <View style={[styles.bottomBg, { backgroundColor: colors.background, height: height * 0.5 }]} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-end',
    backgroundColor: '#fff',
  },
  top: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  arendraModelContainer: {
    backgroundColor: '#fff',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingBottom: 20,
  },
  contentContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  row: {
    flex: 1,
    padding: 20,
    gap: 20,
    flexDirection: 'row',
    maxWidth: 1280,
  },
  addCoworkerButton: {
    borderRadius: 25,
    marginTop: 16,
    marginHorizontal: 20,
    justifyContent: 'center',
    marginBottom: 130,
  },
  bottomBg: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: -1,
  },
  evaluateButtonContainer: {
    paddingHorizontal: 20,
    paddingBottom: 20,
    borderRadius: 20,
    bottom: 20,
    marginLeft: -6,
    width: 232,
    alignSelf: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.15,
  },
  evaluateButton: {
    height: 66,
    borderRadius: 33,
    alignItems: 'center',
    justifyContent: 'center',
  },
  errorText: {
    textAlign: 'center',
    marginBottom: 6,
  },
});

export default DepartmentScreen;
