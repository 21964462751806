import React from 'react';
import {
  View, StyleSheet, Image, useWindowDimensions,
} from 'react-native';
import { Text } from 'react-native-paper';
import { LinearGradient } from 'expo-linear-gradient';

import { ScrollView } from 'react-native-gesture-handler';

import ArendraLogo from '../../assets/images/arendra_logo_white.svg';
import Spinner from '../../assets/images/arendra_spinner.svg';
import BankIdQrLogin from './components/BankIdQrLogin';
// import BankIdTestLogin from './components/BankIdTestLogin';
import BankIdWhite from '../../assets/images/BankID_logo.png';
import useTranslation from '../../hooks/useTranslation';
import BankIdLogin from './components/BankIdLogin';

const LoginScreen = ({ route }) => {
  const { screen } = useTranslation();
  const backgroundGradient = ['#F07E28', '#F78A39', '#F3822E'];
  const { height, width } = useWindowDimensions();

  /** we are in web, but are we in a smaller sized screen? */
  const isProbablyPhoneScreen = window.innerWidth < 600;

  return (
    <View style={styles.container}>
      <LinearGradient
        colors={backgroundGradient}
        style={{ overflow: 'hidden', height, width }}
      >
        {/* The spinner has an absolute position so it will not affect the rest but should always be printed out before the children. */}
        <Image source={Spinner} style={styles.spinner} />
        <ScrollView style={{ paddingVertical: 50 }} contentContainerStyle={{ marginVertical: 'auto' }}>
          <View>
            <Image source={ArendraLogo} style={styles.logo} />
            <View style={{ marginTop: 30, marginBottom: 15 }}>
              <Text style={styles.centeredText}>
                {screen.login.title}
              </Text>
            </View>
            <View style={[styles.buttonsContainer, {
              borderRadius: 16,
              // height: 500,
            }]}
            >
              <Image
                source={BankIdWhite}
                style={styles.bankIdImage}
              />
              {isProbablyPhoneScreen
                ? (
                  <>
                    <BankIdLogin showAsButton route={route} />
                    <BankIdQrLogin showAsButton={false} route={route} />
                  </>
                )
                : (
                  <>
                    <BankIdQrLogin showAsButton route={route} />
                    <BankIdLogin showAsButton={false} route={route} />
                  </>
                )}
            </View>
            {/* <BankIdTestLogin /> */}
          </View>
        </ScrollView>
      </LinearGradient>
    </View>
  );
};

const bankIdProportions = {
  width: 620,
  height: 515,
  scale: 0.2,
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#F6F4F2',
    overflow: 'hidden',
  },
  centeredText: {
    textAlign: 'center',
    color: 'white',
    marginVertical: 10,
    fontSize: 18,
  },
  spinner: {
    position: 'absolute',
    top: '40%',
    left: '40%',
    width: 1062,
    height: 1034,
  },
  logo: {
    alignSelf: 'center',
    width: 278,
    height: 69,
  },
  buttonsContainer: {
    // marginTop: 40,
    padding: 40,
    width: 400,
    // maxWidth: 600,
    backgroundColor: 'white',
    alignSelf: 'center',
  },
  bankIdImage: {
    alignSelf: 'center',
    resizeMode: 'contain',
    height: bankIdProportions.height * bankIdProportions.scale,
    width: bankIdProportions.width * bankIdProportions.scale,
  },
});

export default LoginScreen;
