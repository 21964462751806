import type { Company, DeleteCompanyMutation, UpdateCompanyMutation } from '@types';
import React, { useCallback, useEffect, useState } from 'react';
import {
  View, Text, StyleSheet, TouchableHighlight, Platform,
} from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { Button, useTheme } from 'react-native-paper';
import { DatePickerInput } from 'react-native-paper-dates';
import { useMutation } from '@apollo/client';
import Modal from '../../../../components/Modal';
import FormErrorMessage from '../../../../components/FormErrorMessage';
import useMediaQueries from '../../../../hooks/useMediaQueries';
import StyledTextInput from '../../../../components/StyledTextInput';
import { companiesQuery, deleteCompanyMutation, updateCompanyMutation } from '../../../../queries';
import useTranslation from '../../../../hooks/useTranslation';
import { regex } from '../../../../utils';

type EditCompanyProps = {
  companies: Company[];
  company: Company;
  index: number;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
};

const EditCompany: React.FC<EditCompanyProps> = ({
  companies, company, index, setIsEditing,
}) => {
  const { colors } = useTheme();
  const { screen, clientErrors } = useTranslation({
    companyToDelete: company.name,
  });
  const { isTablet } = useMediaQueries();

  const [updateCompany, { loading: updateCompanyLoading, error: updateCompanyError }] = useMutation<UpdateCompanyMutation>(updateCompanyMutation);
  const [deleteCompany, { loading: deleteCompanyLoading, error: deleteCompanyError }] = useMutation<DeleteCompanyMutation>(deleteCompanyMutation);

  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [companyEdit, setCompanyEdit] = useState<Company>(company);
  const [clientError, setClientError] = useState('');

  const isWeb = Platform.OS === 'web';
  const tempDate = new Date();

  const isFieldsCorrect = useCallback(() => {
    if (companyEdit.name === '' || companyEdit.orgNumber === '' || companyEdit.email === '' || companyEdit.phoneNumber === '') {
      setClientError(clientErrors.fillOutAllFields);
      return false;
    }

    if (companyEdit.email && !regex.email.test(companyEdit.email)) {
      setClientError(clientErrors.invalidEmail);
      return false;
    }

    if (companyEdit.endDate && companyEdit.endDate < new Date()) {
      setClientError(clientErrors.invalidEndDate);
      return false;
    }

    return true;
  }, [companyEdit.email, companyEdit.endDate, companyEdit.name, companyEdit.orgNumber, companyEdit.phoneNumber]);

  const handleUpdateCompany = useCallback(() => {
    if (updateCompanyLoading) return;
    if (isFieldsCorrect()) {
      updateCompany({
        variables: {
          id: company._id,
          name: companyEdit.name,
          endDate: companyEdit.endDate,
          orgNumber: companyEdit.orgNumber,
          email: companyEdit.email,
          phoneNumber: companyEdit.phoneNumber,
        },
        refetchQueries: [{ query: companiesQuery }],
      }).then(() => {
        setIsEditing(false);
      }).catch((err) => {
        console.log(err);
      });
    }
  }, [company._id, companyEdit.email, companyEdit.endDate, companyEdit.name, companyEdit.orgNumber, companyEdit.phoneNumber, isFieldsCorrect, setIsEditing, updateCompany, updateCompanyLoading]);

  const hideConfirmDelete = () => {
    setIsConfirmDeleteOpen(false);
  };

  const handleShowConfirmDelete = () => {
    setIsConfirmDeleteOpen(true);
  };

  const handleDeleteCompany = () => {
    if (deleteCompanyLoading) return;
    deleteCompany({
      variables: { companyId: company._id },
      refetchQueries: [{ query: companiesQuery }],
    }).then(() => {
      setIsConfirmDeleteOpen(false);
    }).catch((err) => {
      console.log(err);
    });
  };

  const handleKeyPress = useCallback((e) => {
    if (e.key === 'Enter') {
      handleUpdateCompany();
    }
  }, [handleUpdateCompany]);

  useEffect(() => {
    if (isWeb) {
      if (!companyEdit.name && !companyEdit.endDate && !companyEdit.orgNumber && !companyEdit.email && !companyEdit.phoneNumber) {
        document.removeEventListener('keypress', handleKeyPress);
      } else if (isWeb) {
        document.addEventListener('keypress', handleKeyPress);
      }
      return () => {
        if (isWeb) {
          document.removeEventListener('keypress', handleKeyPress);
        }
      };
    }

    return () => { };
  }, [companyEdit, handleKeyPress, isWeb]);

  if (isConfirmDeleteOpen) {
    return (
      <Modal
        title={screen.superAdmin.companies.deleteCompany}
        text={screen.superAdmin.companies.deleteText}
        isVisible={isConfirmDeleteOpen}
        setIsVisible={setIsConfirmDeleteOpen}
        content={(
          <View style={styles.deleteButtonRow}>
            {deleteCompanyError && <FormErrorMessage message={deleteCompanyError.message} />}
            <Button
              mode='contained'
              buttonColor={colors.secondary}
              onPress={hideConfirmDelete}
              uppercase={false}
              contentStyle={{ paddingHorizontal: 16 }}
            >
              {screen.superAdmin.cancel}
            </Button>
            <Button
              mode='contained'
              buttonColor={colors.primary}
              textColor={colors.onPrimary}
              onPress={handleDeleteCompany}
              uppercase={false}
              contentStyle={{ paddingHorizontal: 16 }}
              loading={deleteCompanyLoading}
            >
              {screen.superAdmin.delete}
            </Button>
          </View>
        )}
      />
    );
  }

  return (
    <View style={{ borderBottomWidth: index !== companies.length - 1 ? 1 : 0, borderBottomColor: '#E5E5E5' }}>
      <View style={[styles.companyEdit, { flexDirection: isTablet ? 'row' : 'column' }]}>
        <View style={{ flex: isTablet ? 1 : undefined }}>
          <Text style={styles.label}>{screen.superAdmin.companies.company}</Text>
          <StyledTextInput
            value={companyEdit.name}
            onChangeText={(text) => setCompanyEdit({ ...companyEdit, name: text })}
            inputStyle={{ padding: 12 }}
          />
        </View>
        <View style={{ flex: isTablet ? 1 : undefined }}>
          <Text style={styles.label}>{screen.superAdmin.companies.endDate}</Text>
          <DatePickerInput
            locale='sv'
            value={tempDate}
            onChange={(date) => setCompanyEdit({ ...companyEdit, endDate: date })}
            inputMode='start'
            underlineColor='transparent'
            activeUnderlineColor='transparent'
            style={styles.datePickerStyles}
            contentStyle={{
              borderRadius: 6, borderWidth: 0, height: 41, fontSize: 14,
            }}
            placeholder='YYYY-MM-DD'
            saveLabelDisabled
            label=''
            withDateFormatInLabel={false}
            theme={{
              roundness: 6,
              fonts: {
                regular: {
                  fontFamily: '14px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
                },
              },
            }}
            withModal={false}
          />
        </View>
        <View style={{ flex: isTablet ? 1 : undefined }}>
          <Text style={styles.label}>Org.nr</Text>
          <StyledTextInput
            value={companyEdit.orgNumber}
            onChangeText={(text) => setCompanyEdit({ ...companyEdit, orgNumber: text })}
            inputStyle={{ padding: 12 }}
          />
        </View>
        <View style={{ flex: isTablet ? 1 : undefined }}>
          <Text style={styles.label}>{screen.superAdmin.companies.email}</Text>
          <StyledTextInput
            value={companyEdit.email ?? ''}
            onChangeText={(text) => setCompanyEdit({ ...companyEdit, email: text })}
            inputStyle={{ padding: 12 }}
          />
        </View>
        <View style={{ flex: isTablet ? 1 : undefined }}>
          <Text style={styles.label}>{screen.superAdmin.companies.phone}</Text>
          <StyledTextInput
            value={companyEdit.phoneNumber ?? ''}
            onChangeText={(text) => setCompanyEdit({ ...companyEdit, phoneNumber: text })}
            inputStyle={{ padding: 12 }}
          />
        </View>
      </View>
      {(clientError !== '' || updateCompanyError) && (
        <View style={styles.companyEdit}>
          {clientError !== '' ? <FormErrorMessage message={clientError} /> : updateCompanyError && <FormErrorMessage message={updateCompanyError.message} />}
        </View>
      )}
      <View style={styles.companyEditButtonContainer}>
        <View style={styles.companyEditButtonRow}>
          <Button mode='contained' buttonColor={colors.secondary} textColor={colors.onSecondary} onPress={() => setIsEditing(false)} uppercase={false}>{screen.superAdmin.cancel}</Button>
          <Button loading={updateCompanyLoading} mode='contained' buttonColor={colors.primary} textColor={colors.onPrimary} onPress={handleUpdateCompany} uppercase={false}>{screen.superAdmin.save}</Button>
        </View>
        <TouchableHighlight onPress={handleShowConfirmDelete} style={[styles.iconContainer, { backgroundColor: colors.background }]} underlayColor='#E5E5E5'>
          <Ionicons name='trash-outline' size={18} color='#000' />
        </TouchableHighlight>
      </View>

    </View>
  );
};

const styles = StyleSheet.create({
  companyEdit: {
    paddingTop: 16,
    paddingHorizontal: 20,
    gap: 8,
  },
  label: {
    fontWeight: '500',
    marginBottom: 6,
  },
  companyEditButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 16,
  },
  companyEditButtonRow: {
    flexDirection: 'row',
    gap: 6,
    alignItems: 'center',
  },
  datePickerStyles: {
    borderRadius: 6,
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: '#ccc',
    height: 41,
  },
  iconContainer: {
    height: 30,
    width: 30,
    borderRadius: 15,
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  deleteButtonRow: {
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 10,
  },
});

export default EditCompany;
