import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import {
  View, StyleSheet, useWindowDimensions, Text,
} from 'react-native';
import Carousel, { ICarouselInstance } from 'react-native-reanimated-carousel';
import { useTheme, Text as PaperText } from 'react-native-paper';
import type {
  CoworkersQuery, DepartmentCoworker, EstimateCoworkerMutation, EvaluationCarouselStep, EvaluationStatusBasisHeader,
} from '@types';
import { useMutation, useQuery } from '@apollo/client';
import { shuffle } from 'lodash';
import useMediaQueries from '../../../hooks/useMediaQueries';
import Pagination from './Pagination';
import CoworkerEvaluate from './CoworkerEvaluate';
import EvaluationContext from '../../../contexts/EvaluationContext';
import useTranslation from '../../../hooks/useTranslation';
import { estimateCoworkerMutation, listCoworkersQuery } from '../../../queries';
import UserSettingsContext from '../../../contexts/UserSettingsContext';
import EvaluationCompleted from './EvaluationCompleted';
import ArendraIcon from '../../../components/ArendraIcon';

interface EvaluationStepperProps {
  carouselWidth: number;
  activeSlide: number;
  setActiveSlide: React.Dispatch<React.SetStateAction<number>>;
}

const EvaluationStepper: React.FC<EvaluationStepperProps> = ({ carouselWidth, activeSlide, setActiveSlide }) => {
  const { colors } = useTheme();
  const { screen } = useTranslation();
  const { isDesktopWide } = useMediaQueries();
  const { width } = useWindowDimensions();
  const { currentDepartment } = useContext(UserSettingsContext);
  const { carouselRef, evaluationSteps, setEvaluationSteps } = useContext(EvaluationContext);

  const { data: coworkersData, loading: isCoworkersLoading } = useQuery<CoworkersQuery>(listCoworkersQuery, { variables: { departmentId: currentDepartment } });
  const [estimateCoworker] = useMutation<EstimateCoworkerMutation>(estimateCoworkerMutation);

  const [coworkers, setCoworkers] = useState<DepartmentCoworker[]>([]);
  const [isShuffled, setIsShuffled] = useState<boolean>(false);
  const [shuffledOrder, setShuffledOrder] = useState<number[]>([]);
  const [previousSlide, setPreviousSlide] = useState<number>(0);

  const [statusBasisStepHeader, setStatusBasisStepHeader] = useState<EvaluationStatusBasisHeader>();
  const [isEvaluationCompleted, setIsEvaluationCompleted] = useState<boolean>(false);
  const [isReEvaluating, setIsReEvaluating] = useState<boolean>(false);

  const completedRef = useRef<ICarouselInstance>(null);

  // Set coworkers, check if evaluation is completed and shuffle order
  useEffect(() => {
    if (!coworkersData?.department.coworkersWithEstimations) return;
    setCoworkers(coworkersData.department.coworkersWithEstimations as DepartmentCoworker[]);

    // Check if all coworkers have todaysEstimation and status isn't null
    const evaluationCompleted = coworkersData.department.coworkersWithEstimations.every((coworker) => coworker.todaysEstimation && coworker.todaysEstimation.status !== null);
    setIsEvaluationCompleted(evaluationCompleted);

    // Shuffle order if not already shuffled
    if (!isShuffled) {
      const orderArray = coworkersData.department.coworkersWithEstimations.map((_, index) => index);
      const shuffledArray: number[] = shuffle(orderArray);

      setShuffledOrder(shuffledArray);
      setIsShuffled(true);
    }
  }, [coworkersData, isShuffled]);

  // Initialize carousel steps
  useEffect(() => {
    if (isShuffled && coworkers.length > 0) {
      const coworkerSteps = coworkers.map((coworker) => ({
        id: coworker.userId,
        title: `${coworker.firstName} ${coworker.lastName}`,
        todaysEstimation: coworker.todaysEstimation,
        component: <CoworkerEvaluate key={coworker.userId} coworker={coworker} nextStep={nextStep} setStatusBasisStepHeader={setStatusBasisStepHeader} />,
      }));

      // Arrange the steps in the shuffled order
      const orderedSteps = shuffledOrder.map((index) => coworkerSteps[index]);
      setEvaluationSteps(orderedSteps as EvaluationCarouselStep[]);
    }
  }, [coworkers, isShuffled, shuffledOrder]);

  const nextStep = () => {
    if (carouselRef?.current) {
      carouselRef.current.next();
    }
  };

  const handleSnapToItem = (index: number) => {
    setActiveSlide(index);

    // Moved 1 step - Evaluate previous as skipped if not already evaluated
    if (index - previousSlide === 1) {
      const skippedCoworker = evaluationSteps[previousSlide];
      if (!skippedCoworker.todaysEstimation) {
        estimateCoworker({
          variables: {
            userId: skippedCoworker.id,
            departmentId: currentDepartment,
            status: undefined,
          },
          refetchQueries: [
            { query: listCoworkersQuery, variables: { departmentId: currentDepartment } },
          ],
        }).then(() => {
          console.log(`Evaluated ${skippedCoworker.title} as skipped (undefined)`);
        }).catch((err) => {
          console.log(err);
        });
      }
    }

    setPreviousSlide(index);
  };

  return (
    <View style={styles.container}>
      {!isCoworkersLoading && (
        <>
          {statusBasisStepHeader ? (
            <>
              <PaperText style={styles.title}>{statusBasisStepHeader.title}</PaperText>
              <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                <View style={styles.iconBg} />
                <ArendraIcon
                  name={statusBasisStepHeader.status === 2 ? 'icon-brister-delvis' : statusBasisStepHeader.status === 3 ? 'icon-brister-patagligt' : 'icon-kan-ej-skatta'}
                  size={60}
                  style={{
                    borderRadius: 100,
                    zIndex: 10,
                  }}
                />
              </View>
              <Text style={{ fontSize: 16, textAlign: 'center', marginTop: 10 }}>
                {statusBasisStepHeader.coworkerName}
              </Text>
            </>
          )
            : <PaperText style={[styles.title, { color: colors.onBackground }]}>{screen.evaluation.evaluate}</PaperText>}

          {(!isEvaluationCompleted || isReEvaluating) && (
            <>
              <Carousel
                key={carouselWidth}
                ref={carouselRef}
                height={400}
                width={carouselWidth || width}
                data={evaluationSteps}
                renderItem={({ item }) => item.component}
                onSnapToItem={(index) => handleSnapToItem(index)}
                loop={false}
                pagingEnabled
                mode='parallax'
                modeConfig={{
                  parallaxScrollingScale: 0.87,
                  parallaxScrollingOffset: isDesktopWide ? width * 0.3 : 50,
                }}
                enabled={!statusBasisStepHeader}
              />
              <Pagination carouselRef={carouselRef} carouselWidth={carouselWidth} steps={evaluationSteps} activeSlide={activeSlide} />
            </>
          )}
          {isEvaluationCompleted && !isReEvaluating && (
            <>
              <Carousel
                ref={completedRef}
                height={400}
                width={carouselWidth || width}
                data={[{
                  title: screen.evaluation.goodWork,
                  component: <EvaluationCompleted setIsReEvaluating={setIsReEvaluating} />,
                }]}
                renderItem={({ item }) => item.component}
                pagingEnabled={false}
                mode='parallax'
                modeConfig={{
                  parallaxScrollingScale: 0.87,
                  parallaxScrollingOffset: 50,
                }}
                enabled={false}
                snapEnabled={false}
                overscrollEnabled={false}
              />
              <View style={{ height: 80 }} />
            </>
          )}
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 50,
    justifyContent: 'center',
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    marginBottom: 30,
  },
  iconBg: {
    position: 'absolute',
    height: 50,
    width: 50,
    backgroundColor: '#fff',
    borderRadius: 25,
  },
});

export default EvaluationStepper;
