import { useMutation } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import {
  View, Text, StyleSheet, KeyboardAvoidingView, Platform,
} from 'react-native';
import {
  Button, Checkbox, useTheme, Text as PaperText,
} from 'react-native-paper';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import type { RootStackParamList } from '@types';
import { AgreeToTermsMutation, BankIdUser } from '../../types/generated.types';
import { agreeToTermsMutation } from '../../queries';
import AuthContext from '../../contexts/AuthContext';
import useTranslation from '../../hooks/useTranslation';
import StyledTextInput from '../../components/StyledTextInput';
import DesktopWrapper from '../../components/DesktopWrapper';
import ArendraIcon from '../../components/ArendraIcon';
import Modal from '../../components/Modal';
import UserTerms from '../UserTermsScreen/components/UserTerms';

type TermsAndConditionsScreenProps = {
  route: {
    params: {
      bankIdUser: BankIdUser;
    };
  };
};

const TermsAndConditionsScreen: React.FC<TermsAndConditionsScreenProps> = ({ route }) => {
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [isWebTermsModalVisible, setIsWebTermsModalVisible] = useState(false);
  const [email, setEmail] = useState<string>('');
  const emailDoesNotEndWithDotOrAt = !email.endsWith('.') && !email.endsWith('@');
  const validEmail = email.includes('@') && email.includes('.') && email.length >= 5 && emailDoesNotEndWithDotOrAt;
  const { setToken: setTokenInAuthContext, devicePushToken } = useContext(AuthContext);
  const { colors } = useTheme();
  const [bankIdUser, setBankIdUser] = React.useState<BankIdUser>(route.params.bankIdUser);
  const { screen } = useTranslation();
  const { navigate } = useNavigation<NavigationProp<RootStackParamList>>();

  const isWeb = Platform.OS === 'web';

  const [agreeToTerms, { data, loading, error }] = useMutation<AgreeToTermsMutation>(agreeToTermsMutation, { variables: { bankIdUser, email } });

  const toggleTermsAccepted = () => setAcceptedTerms(!acceptedTerms);

  const onAcceptTerms = () => {
    if (acceptedTerms) {
      console.log('onAcceptTerms');
      void agreeToTerms();
    }
  };

  useEffect(() => {
    if (data) {
      if (data.agreeToTerms.token) {
        // save token
        setTokenInAuthContext(data.agreeToTerms.token);
      }
    }
  }, [data, setTokenInAuthContext]);

  const navigateToTerms = () => {
    if (isWeb) {
      setIsWebTermsModalVisible(true);
    } else {
      navigate('userTerms');
    }
  };

  return (
    <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : 'height'} style={styles.container}>
      <DesktopWrapper style={{ justifyContent: 'center' }}>
        <View style={styles.card}>
          <ArendraIcon name='icon-spin-arendra' color={colors.secondary} size={40} style={styles.arendraIcon} />
          <PaperText style={styles.title}>{screen.termsAndConditions.title}</PaperText>
          <Text style={styles.instructions}>
            {screen.termsAndConditions.instructions}
          </Text>

          <View style={styles.inputContainer}>
            <StyledTextInput
              placeholder={screen.termsAndConditions.email}
              keyboardType='email-address'
              autoCorrect={false}
              autoComplete='email'
              value={email}
              onChangeText={(text) => setEmail(text)}
            />
          </View>

          <View style={styles.checkboxContainer}>
            <View style={Platform.OS === 'ios' ? styles.checkboxIos : styles.checkbox}>
              <Checkbox
                // disabled={false}
                status={acceptedTerms ? 'checked' : 'unchecked'}
                onPress={toggleTermsAccepted}
                color='green'
              />
            </View>
            <Text style={styles.accept}>
              {screen.termsAndConditions.acceptTerms}
              {' '}
              <Text onPress={navigateToTerms} style={{ textDecorationLine: 'underline' }}>{screen.termsAndConditions.terms}</Text>
            </Text>
          </View>

          <View style={{ height: 70, justifyContent: 'center' }}>
            <Button
              mode='contained'
              buttonColor={colors.secondary}
              uppercase={false}
              style={[{ borderRadius: 25, padding: 5, alignSelf: 'center' }]}
              disabled={!acceptedTerms || !validEmail}
              onPress={onAcceptTerms}
            >
              {screen.termsAndConditions.continue}
            </Button>
          </View>

          {/* {loading && <Text>Loading...</Text>} */}
          {error && <Text>{`Error... ${error.message}`}</Text>}
        </View>
      </DesktopWrapper>

      {isWebTermsModalVisible && (
        <Modal
          isClickOutsideAllowed
          isVisible={isWebTermsModalVisible}
          setIsVisible={setIsWebTermsModalVisible}
          content={<UserTerms isWebModal />}
          maxWidth={1280}
        />
      )}

    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    paddingVertical: 40,
    overflowY: 'auto',
  },
  card: {
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 20,
    justifyContent: 'center',
    alignSelf: 'center',
    maxWidth: 400,
  },
  arendraIcon: {
    alignSelf: 'center',
    marginBottom: 10,
  },
  title: {
    textAlign: 'center',
    fontSize: 26,
    marginBottom: 20,
  },
  instructions: {
    textAlign: 'center',
    fontSize: 16,
  },
  accept: {
    textAlign: 'center',
    marginTop: 15,
    fontStyle: 'italic',
    fontSize: 14,
  },
  inputContainer: {
    marginTop: 40,
  },
  checkboxContainer: {
    marginTop: 40,
    marginBottom: 10,
    justifyContent: 'center',
  },
  checkbox: {
    alignSelf: 'center',
    height: 50,
    width: 50,
  },
  checkboxIos: {
    alignSelf: 'center',
    height: 40,
    width: 40,
    borderWidth: 2,
    borderRadius: 5,
    borderColor: 'gray',
  },
});

export default TermsAndConditionsScreen;
