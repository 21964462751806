import React from 'react';
import {
  View, Text, Pressable, Platform, StyleSheet,
} from 'react-native';
import type { DepartmentCoworker } from '@types';
import { TouchableHighlight } from 'react-native-gesture-handler';
import StatusIcon from '../../../components/StatusIcon';
import ListItem from '../../../components/ListItem';

type GroupedCoworkerProps = {
  coworker: DepartmentCoworker;
  index: number;
  letter: string;
  groupedCoworkers: { [key: string]: DepartmentCoworker[] };
  goToCoworker: (id: string) => void;
}

const GroupedCoworker: React.FC<GroupedCoworkerProps> = ({
  coworker, index, letter, groupedCoworkers, goToCoworker,
}) => {
  const isWeb = Platform.OS === 'web';
  return (
    <TouchableHighlight
      key={coworker.userId}
      onPress={() => goToCoworker(coworker.userId as string)}
      underlayColor='#E5E5E5'
    >
      <Pressable>
        <ListItem isBorderBottom={index !== groupedCoworkers[letter].length - 1} style={{ gap: 16, paddingVertical: 12 }}>
          <View style={{ flex: 1 }}>
            <Text>
              {coworker.firstName}
              {' '}
              {coworker.lastName}
            </Text>
          </View>
          <View style={{ width: isWeb ? 200 : 175 }}>
            {coworker.todaysEstimation && coworker.todaysEstimation.status !== null
              ? (
                <StatusIcon
                  isWithText
                  status={coworker?.todaysEstimation?.status}
                  skippedStatus={coworker?.todaysEstimation?.activelySkippedStatusBasis ? coworker.todaysEstimation.activelySkippedStatusBasis[0] : undefined}
                  statusBasis={coworker?.todaysEstimation?.statusBasis ? coworker.todaysEstimation.statusBasis[0] : undefined}
                  height={36}
                  width={36}
                  iconWidth={55}
                />
              )
              : (
                <View style={styles.notEvaluated}>
                  <Text style={{ fontStyle: 'italic' }}>Ej skattad</Text>
                </View>
              )}
          </View>
        </ListItem>
      </Pressable>
    </TouchableHighlight>
  );
};

export default GroupedCoworker;

const styles = StyleSheet.create({
  notEvaluated: {
    marginLeft: 55,
    height: 36,
    opacity: 0.6,
    justifyContent: 'center',
  },
});
