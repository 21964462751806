import { NativeStackHeaderProps } from '@react-navigation/native-stack';
import React, { useContext, useState } from 'react';
import {
  View, StyleSheet, Platform, Pressable, Text,
} from 'react-native';
import { Avatar, Button, useTheme } from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import type { RootStackParamList } from '@types';
import useTranslation from '../hooks/useTranslation';
import useMediaQueries from '../hooks/useMediaQueries';
import Icon from '../components/Icon';
import ArendraLogo from '../assets/images/arendra-logo.svg';
import Modal from '../components/Modal';
import AddCompany from '../screens/SuperAdmin/components/companies/AddCompany';
import SelectRole from '../screens/SuperAdmin/components/SelectRole';
import UserSettingsContext from '../contexts/UserSettingsContext';
import AuthContext from '../contexts/AuthContext';

const CustomHeader: React.FC<NativeStackHeaderProps> = ({ navigation, options }) => {
  const theme = useTheme();
  const { tokenGivenName, tokenSurname } = useContext(AuthContext);
  const { isDesktopWide } = useMediaQueries();
  const { navigate } = useNavigation<NavigationProp<RootStackParamList>>();
  const { screen } = useTranslation({
    name: `${tokenGivenName.charAt(0).toUpperCase() + tokenGivenName.slice(1).toLowerCase()} ${tokenSurname.charAt(0).toUpperCase() + tokenSurname.slice(1).toLowerCase()}`,
  });
  const { isWebRoleSelectionOpen, setIsWebRoleSelectionOpen } = useContext(UserSettingsContext);
  const [isAddCompany, setIsAddCompany] = useState(false);

  const insets = useSafeAreaInsets();
  const isWeb = Platform.OS === 'web';

  const initials = `${tokenGivenName[0]}${tokenSurname[0]}`;

  const handleChangeRole = () => {
    if (isWeb) {
      setIsWebRoleSelectionOpen(true);
    } else {
      navigate('roleSelection');
    }
  };

  const handleAddCompany = () => {
    setIsAddCompany(true);
  };

  const handleHideModal = () => {
    setIsAddCompany(false);
    setIsWebRoleSelectionOpen(false);
  };

  return (
    <>
      <View style={[styles.header, !isWeb && { height: insets.top + 70 }]}>
        <View style={[styles.headerFlex, isDesktopWide && styles.headerFlexDesktop]}>
          <View style={{ height: 50 }}>
            <Icon
              source={ArendraLogo}
              width={150}
              style={{
                position: 'absolute', left: 0, top: -6, bottom: 0,
              }}
            />
          </View>
          <View style={{ flexDirection: 'row', alignItems: 'center', gap: 16 }}>
            {options.title?.toLowerCase() === 'companies'
              && (
                <Button
                  mode='contained'
                  onPress={handleAddCompany}
                  buttonColor={theme.colors.secondary}
                  textColor={theme.colors.onSecondary}
                  style={{ width: 170 }}
                  contentStyle={{ height: 46 }}
                  uppercase={false}
                >
                  {screen.superAdmin.companies.addCompany}
                  {' '}
                  +
                </Button>
              )}
            <Pressable onPress={handleChangeRole}>
              <TouchableOpacity activeOpacity={0.7}>
                <View style={styles.superAdminItem}>
                  <Text style={{ fontWeight: '500' }}>Superadmin</Text>
                  <View style={[styles.headerItem, { backgroundColor: theme.colors.background }]}>
                    <Avatar.Text
                      label={initials}
                      size={38}
                      style={{ backgroundColor: '#fff' }}
                      labelStyle={{ fontSize: 18, color: '#707070', fontWeight: '500' }}
                    />
                  </View>
                </View>
              </TouchableOpacity>
            </Pressable>
          </View>

        </View>
      </View>

      {isAddCompany && (
        <Modal
          isVisible={isAddCompany}
          setIsVisible={setIsAddCompany}
          title={screen.superAdmin.companies.addCompany}
          content={<AddCompany hideModal={handleHideModal} />}
        />
      )}
      {isWebRoleSelectionOpen && (
        <Modal
          isVisible={isWebRoleSelectionOpen}
          setIsVisible={setIsWebRoleSelectionOpen}
          title={screen.roleSelection.title}
          text={screen.roleSelection.youAreLoggedInAs}
          isClickOutsideAllowed
          content={<SelectRole />}
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  header: {
    backgroundColor: '#fff',
    paddingTop: 10,
    paddingHorizontal: 20,
    paddingBottom: 10,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  headerFlex: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    maxWidth: 1280,
    gap: 20,
  },
  headerFlexDesktop: {
    paddingHorizontal: 16,
  },
  headerItem: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 40,
    width: 40,
    borderRadius: 25,
  },
  superAdminItem: {
    height: 46,
    width: 170,
    borderRadius: 23,
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#EEEAE6',
    paddingRight: 3,
    paddingLeft: 16,
  },
});

export default CustomHeader;
