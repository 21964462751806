import React from 'react';
import { StyleSheet } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import ActInfo from './components/ActInfo';
import ActContact from './components/ActContact';

const ActScreen = () => (
  <ScrollView style={styles.container}>
    <ActInfo />
    <ActContact />
  </ScrollView>
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

export default ActScreen;
