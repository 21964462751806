import React from 'react';
import {
  Text, StyleSheet, Platform, View,
} from 'react-native';
import { Text as PaperText, useTheme } from 'react-native-paper';
import useTranslation from '../../../hooks/useTranslation';
import DesktopWrapper from '../../../components/DesktopWrapper';
import useMediaQueries from '../../../hooks/useMediaQueries';

type UserTermsProps = {
  isWebModal?: boolean;
};

const UserTerms: React.FC<UserTermsProps> = ({ isWebModal }) => {
  const { screen } = useTranslation();
  const { colors } = useTheme();
  const { isDesktop } = useMediaQueries();

  const isWeb = Platform.OS === 'web';
  return (
    <View style={{ flexDirection: 'row' }}>
      <DesktopWrapper style={{
        justifyContent: 'center',
        paddingVertical: 20,
        paddingHorizontal: isWebModal && !isDesktop ? 0 : 20,
        marginBottom: isWeb ? 0 : 100,
      }}
      >
        <PaperText style={styles.title}>{screen.userTerms.title}</PaperText>
        <Text style={[styles.text, { marginBottom: 16, marginTop: 26 }]}>{screen.userTerms.text1}</Text>
        <Text style={styles.text}>{screen.userTerms.text2}</Text>

        <Text style={styles.sectionTitle}>
          1.
          {' '}
          {screen.userTerms.useOfArendraTool.title}
        </Text>
        <Text style={styles.subTitle}>{screen.userTerms.useOfArendraTool.p1.title}</Text>
        <Text style={styles.text}>{screen.userTerms.useOfArendraTool.p1.text1}</Text>
        <Text style={styles.text}>{screen.userTerms.useOfArendraTool.p1.text2}</Text>

        <Text style={styles.subTitle}>{screen.userTerms.useOfArendraTool.p2.title}</Text>
        <Text style={styles.text}>{screen.userTerms.useOfArendraTool.p2.text1}</Text>
        <Text style={styles.text}>{screen.userTerms.useOfArendraTool.p2.text2}</Text>
        <Text style={styles.text}>{screen.userTerms.useOfArendraTool.p2.text3}</Text>
        <View style={[styles.listItem, { marginTop: 26 }]}>
          <View style={[styles.dot, { backgroundColor: colors.secondary }]} />
          <Text style={styles.dotText}>{screen.userTerms.useOfArendraTool.p2.listItem1}</Text>
        </View>
        <View style={styles.listItem}>
          <View style={[styles.dot, { backgroundColor: colors.secondary }]} />
          <Text style={styles.dotText}>{screen.userTerms.useOfArendraTool.p2.listItem2}</Text>
        </View>
        <View style={styles.listItem}>
          <View style={[styles.dot, { backgroundColor: colors.secondary }]} />
          <Text style={styles.dotText}>{screen.userTerms.useOfArendraTool.p2.listItem3}</Text>
        </View>
        <View style={styles.listItem}>
          <View style={[styles.dot, { backgroundColor: colors.secondary }]} />
          <Text style={styles.dotText}>{screen.userTerms.useOfArendraTool.p2.listItem4}</Text>
        </View>
        <View style={styles.listItem}>
          <View style={[styles.dot, { backgroundColor: colors.secondary }]} />
          <Text style={styles.dotText}>{screen.userTerms.useOfArendraTool.p2.listItem5}</Text>
        </View>
        <View style={styles.listItem}>
          <View style={[styles.dot, { backgroundColor: colors.secondary }]} />
          <Text style={styles.dotText}>{screen.userTerms.useOfArendraTool.p2.listItem6}</Text>
        </View>
        <View style={[styles.listItem, { marginBottom: 26 }]}>
          <View style={[styles.dot, { backgroundColor: colors.secondary }]} />
          <Text style={styles.dotText}>{screen.userTerms.useOfArendraTool.p2.listItem7}</Text>
        </View>
        <Text style={styles.text}>{screen.userTerms.useOfArendraTool.p2.text4}</Text>

        <Text style={styles.subTitle}>{screen.userTerms.useOfArendraTool.p3.title}</Text>
        <Text style={styles.text}>{screen.userTerms.useOfArendraTool.p3.text1}</Text>
        <Text style={styles.text}>{screen.userTerms.useOfArendraTool.p3.text2}</Text>
        <Text style={styles.text}>{screen.userTerms.useOfArendraTool.p3.text3}</Text>
        <Text style={styles.text}>{screen.userTerms.useOfArendraTool.p3.text4}</Text>
        <Text style={styles.text}>{screen.userTerms.useOfArendraTool.p3.text5}</Text>

        <Text style={styles.subTitle}>{screen.userTerms.useOfArendraTool.p4.title}</Text>
        <Text style={styles.text}>{screen.userTerms.useOfArendraTool.p4.text1}</Text>
        <Text style={styles.text}>{screen.userTerms.useOfArendraTool.p4.text2}</Text>
        <Text style={styles.text}>{screen.userTerms.useOfArendraTool.p4.text3}</Text>
        <Text style={styles.text}>{screen.userTerms.useOfArendraTool.p4.text4}</Text>

        <Text style={styles.subTitle}>{screen.userTerms.useOfArendraTool.p5.title}</Text>
        <Text style={styles.text}>{screen.userTerms.useOfArendraTool.p5.text1}</Text>

        <Text style={styles.subTitle}>{screen.userTerms.useOfArendraTool.p6.title}</Text>
        <Text style={styles.text}>{screen.userTerms.useOfArendraTool.p6.text1}</Text>

        <Text style={styles.sectionTitle}>
          2.
          {' '}
          {screen.userTerms.rightsAndOwnership.title}
        </Text>
        <Text style={styles.subTitle}>{screen.userTerms.rightsAndOwnership.p1.title}</Text>
        <Text style={styles.text}>{screen.userTerms.rightsAndOwnership.p1.text1}</Text>
        <Text style={styles.text}>{screen.userTerms.rightsAndOwnership.p1.text2}</Text>

        <Text style={styles.subTitle}>{screen.userTerms.rightsAndOwnership.p2.title}</Text>
        <Text style={styles.text}>{screen.userTerms.rightsAndOwnership.p2.text1}</Text>
        <Text style={styles.text}>{screen.userTerms.rightsAndOwnership.p2.text2}</Text>

        <Text style={styles.sectionTitle}>
          3.
          {' '}
          {screen.userTerms.dataProtection.title}
        </Text>
        <Text style={styles.subTitle}>{screen.userTerms.dataProtection.p1.title}</Text>
        <Text style={styles.text}>{screen.userTerms.dataProtection.p1.text1}</Text>
        <Text style={styles.text}>{screen.userTerms.dataProtection.p1.text2}</Text>

        <Text style={styles.subTitle}>{screen.userTerms.dataProtection.p2.title}</Text>
        <Text style={styles.text}>{screen.userTerms.dataProtection.p2.text1}</Text>

        <Text style={styles.sectionTitle}>
          4.
          {' '}
          {screen.userTerms.agreementTimeAndTermination.title}
        </Text>
        <Text style={styles.subTitle}>{screen.userTerms.agreementTimeAndTermination.p1.title}</Text>
        <Text style={styles.text}>{screen.userTerms.agreementTimeAndTermination.p1.text1}</Text>

        <Text style={styles.subTitle}>{screen.userTerms.agreementTimeAndTermination.p2.title}</Text>
        <Text style={styles.text}>{screen.userTerms.agreementTimeAndTermination.p2.text1}</Text>
        <Text style={styles.text}>{screen.userTerms.agreementTimeAndTermination.p2.text2}</Text>
        <View style={styles.listItem}>
          <View style={[styles.dot, { backgroundColor: colors.secondary }]} />
          <Text style={styles.dotText}>{screen.userTerms.agreementTimeAndTermination.p2.listItem1}</Text>
        </View>
        <View style={styles.listItem}>
          <View style={[styles.dot, { backgroundColor: colors.secondary }]} />
          <Text style={styles.dotText}>{screen.userTerms.agreementTimeAndTermination.p2.listItem2}</Text>
        </View>
        <Text style={styles.text}>{screen.userTerms.agreementTimeAndTermination.p2.text3}</Text>

        <Text style={styles.subTitle}>{screen.userTerms.agreementTimeAndTermination.p3.title}</Text>
        <Text style={styles.text}>{screen.userTerms.agreementTimeAndTermination.p3.text1}</Text>

        <Text style={styles.subTitle}>{screen.userTerms.agreementTimeAndTermination.p4.title}</Text>
        <Text style={styles.text}>{screen.userTerms.agreementTimeAndTermination.p4.text1}</Text>

        <Text style={styles.sectionTitle}>
          5.
          {' '}
          {screen.userTerms.changesToTheseTerms.title}
        </Text>
        <Text style={[styles.text, { marginTop: 16 }]}>{screen.userTerms.changesToTheseTerms.p1.text1}</Text>

        <Text style={styles.sectionTitle}>
          6.
          {' '}
          {screen.userTerms.responsibility.title}
        </Text>
        <Text style={styles.subTitle}>{screen.userTerms.responsibility.p1.title}</Text>
        <Text style={styles.text}>{screen.userTerms.responsibility.p1.text1}</Text>

        <Text style={styles.subTitle}>{screen.userTerms.responsibility.p2.title}</Text>
        <Text style={styles.text}>{screen.userTerms.responsibility.p2.text1}</Text>
        <Text style={styles.text}>{screen.userTerms.responsibility.p2.text2}</Text>

        <Text style={styles.subTitle}>{screen.userTerms.responsibility.p3.title}</Text>
        <Text style={styles.text}>{screen.userTerms.responsibility.p3.text1}</Text>

        <Text style={styles.sectionTitle}>
          7.
          {' '}
          {screen.userTerms.specialTermsForArendraApp.title}
        </Text>
        <Text style={[styles.text, { marginTop: 16 }]}>{screen.userTerms.specialTermsForArendraApp.text1}</Text>
        <View style={[styles.listItem, { marginTop: 26 }]}>
          <View style={[styles.dot, { backgroundColor: colors.secondary }]} />
          <Text style={styles.dotText}>{screen.userTerms.specialTermsForArendraApp.listItem1}</Text>
        </View>
        <View style={styles.listItem}>
          <View style={[styles.dot, { backgroundColor: colors.secondary }]} />
          <Text style={styles.dotText}>{screen.userTerms.specialTermsForArendraApp.listItem2}</Text>
        </View>
        <View style={styles.listItem}>
          <View style={[styles.dot, { backgroundColor: colors.secondary }]} />
          <Text style={styles.dotText}>{screen.userTerms.specialTermsForArendraApp.listItem3}</Text>
        </View>
        <View style={styles.listItem}>
          <View style={[styles.dot, { backgroundColor: colors.secondary }]} />
          <Text style={styles.dotText}>{screen.userTerms.specialTermsForArendraApp.listItem4}</Text>
        </View>
        <View style={styles.listItem}>
          <View style={[styles.dot, { backgroundColor: colors.secondary }]} />
          <Text style={styles.dotText}>{screen.userTerms.specialTermsForArendraApp.listItem5}</Text>
        </View>
        <View style={[styles.listItem, { marginBottom: 26 }]}>
          <View style={[styles.dot, { backgroundColor: colors.secondary }]} />
          <Text style={styles.dotText}>{screen.userTerms.specialTermsForArendraApp.listItem6}</Text>
        </View>

        <Text style={styles.sectionTitle}>
          8.
          {' '}
          {screen.userTerms.other.title}
        </Text>
        <Text style={styles.subTitle}>{screen.userTerms.other.p1.title}</Text>
        <Text style={styles.text}>{screen.userTerms.other.p1.text1}</Text>

        <Text style={styles.subTitle}>{screen.userTerms.other.p2.title}</Text>
        <Text style={styles.text}>{screen.userTerms.other.p2.text1}</Text>

        <Text style={styles.subTitle}>{screen.userTerms.other.p3.title}</Text>
        <Text style={styles.text}>{screen.userTerms.other.p3.text1}</Text>

        <Text style={styles.subTitle}>{screen.userTerms.other.p4.title}</Text>
        <Text style={styles.text}>{screen.userTerms.other.p4.text1}</Text>

        <Text style={styles.sectionTitle}>
          9.
          {' '}
          {screen.userTerms.applicableLawAndDisputes.title}
        </Text>
        <Text style={styles.subTitle}>{screen.userTerms.applicableLawAndDisputes.p1.title}</Text>
        <Text style={styles.text}>{screen.userTerms.applicableLawAndDisputes.p1.text1}</Text>

        <Text style={styles.subTitle}>{screen.userTerms.applicableLawAndDisputes.p2.title}</Text>
        <Text style={styles.text}>{screen.userTerms.applicableLawAndDisputes.p2.text1}</Text>

        <Text style={styles.sectionTitle}>
          10.
          {' '}
          {screen.userTerms.contactInformation.title}
        </Text>
        <Text style={styles.text}>{screen.userTerms.contactInformation.text1}</Text>
        <Text style={styles.subTitle}>Arendra AB</Text>
        <Text style={styles.text}>Org. nr: 559124-0667</Text>
        <Text style={styles.text}>
          {screen.userTerms.contactInformation.email}
          : info@arendra.se
        </Text>
        <Text style={[styles.text, { marginTop: 26 }]}>
          {screen.userTerms.contactInformation.text2}
        </Text>

      </DesktopWrapper>
    </View>

  );
};

const styles = StyleSheet.create({
  title: {
    fontSize: 26,
    textAlign: 'center',
    marginBottom: 16,
  },
  sectionTitle: {
    marginTop: 36,
    fontSize: 18,
    fontWeight: '600',
    textTransform: 'uppercase',
  },
  subTitle: {
    marginTop: 26,
    fontSize: 16,
    // marginBottom: 6,
    lineHeight: 24,
    fontWeight: '600',
  },
  text: {
    marginTop: 12,
    fontSize: 16,
    flex: 1,
    flexWrap: 'wrap',
  },
  dotText: {
    fontSize: 16,
    flex: 1,
    flexWrap: 'wrap',
  },
  listItem: {
    marginTop: 20,
    flexDirection: 'row',
  },
  dot: {
    width: 8,
    height: 8,
    borderRadius: 4,
    marginRight: 10,
    marginTop: 6,
  },
});

export default UserTerms;
