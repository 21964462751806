import React from 'react';
import { View, StyleSheet, ViewStyle } from 'react-native';

type ListItemProps = {
  children: React.ReactNode;
  isBorderBottom?: boolean;
  style?: ViewStyle | ViewStyle[];
};

const ListItem: React.FC<ListItemProps> = ({ children, isBorderBottom, style }) => (
  <View style={[styles.container, {
    borderBottomWidth: isBorderBottom ? 1 : 0,
    borderBottomColor: '#E5E5E5',
  }, style]}
  >
    {children}
  </View>
);

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    alignItems: 'center',
    padding: 20,
  },
});

export default ListItem;
