/* eslint-disable max-len */
import Messages from './types';

const en: Messages = {
  screen: {
    department: {
      title: 'Department',
      coworker: 'Coworker',
      day: 'Day',
      status: 'Average (3 months)',
      today: 'Today',
      export: 'Export',
      addCoworker: 'Add coworker',
      arendraModel: 'Arendramodel',
      simplified: 'Simplified',
      cost: 'Cost',
      time: 'Time',
      timeToEvaluate: 'Time to evaluate your coworkers',
      welcome: 'Welcome!',
      stepOneText: 'Step 1 is to add your coworkers, when you have added coworkers you can start evaluating.',
      name: 'Name',
      groupAverage: 'Group average',
      threeMonths: '3 months',
      accordingToArendraModel: 'Arendra model',
      month: 'month',
      noCoworkers: 'Add coworkers to get started',
      SEK: 'SEK',
      SEKPerMonth: 'SEK/month',
      thousandSEK: 'kSEK',
      thousandSEKPerMonth: 'kSEK/month',
      coworkersError: 'Something went wrong when fetching coworkers',
    },
    login: {
      title: 'Log in with mobile BankID',
      logInWithBankID: 'Log in with BankID',
      logInWithBankIDWeb: 'BankID på denna enhet',
      bankIdOnOtherDevice: 'BankID on other device',
      readQrCode: 'Read the QR-code with BankID app',
    },
    termsAndConditions: {
      title: 'Terms',
      instructions: 'To use Arendra´s evaluation tool you need to supply your email and accept our terms.',
      email: 'Email address',
      acceptTerms: 'I accept your',
      continue: 'Continue',
      terms: 'terms and conditions',
    },
    onBoarding: {
      title: 'Welcome to Arendra - the tool',
      subTitle: 'Click through the guide to continue',
      continue: 'Continue',
      next: 'Next',
      stepOneTitle: 'Add coworkers',
      stepOneDescription: 'Start by adding your coworkers, their salary and employment percentage.',
      stepTwoTitle: 'Evaluate',
      stepTwoDescription: 'Fill in how your coworkers are feeling and performing based on the Arendra model.',
      stepThreeTitle: 'Result',
      stepThreeDescription: 'See how your coworkers are feeling, how it affects the company financially and when you should act.',
    },
    contact: {
      title: 'Contact',
      send: 'Send',
      contactArendra: 'Contact Arendra',
      mutationSucceededMessage: 'Thank you for your message. We will get back to you as soon as we can!',
    },
    roleSelection: {
      title: 'Choose role',
      superAdmin: 'Superadmin',
      superAdminNotAvailable: 'Superadmin - only on the web',
      youAreLoggedInAs: 'You are logged in as {{name}}',
    },
    evaluation: {
      title: 'Evaluation',
      startEvaluation: 'Start evaluation',
      close: 'Close',
      evaluate: 'Evaluate',
      activelySkip: 'Cannot evaluate',
      activelySkipText: 'For your own follow-up',
      activelySkipText2: 'Why do you want to refrain from evaluation?',
      offDuty: 'On leave',
      haveNotMet: 'Have not met',
      absence: 'Absence',
      other: 'Other reason',
      workPerformance: 'Work performance',
      followLawsAndDecisions: 'Follow rules and decisions',
      workEnvironmentResponsibility: 'Work environment responsibility',
      dutyOfLoyalty: 'Loyalty',
      dutyToCooperate: 'Cooperation',
      cancel: 'Cancel',
      done: 'Done',
      skip: 'Skip',
      save: 'Save',
      of: 'of',
      goodWork: 'Good work!',
      doneForToday: 'Now you are done for today!',
      rememberToConfirm: 'Remember to confirm the green',
      activelySkipTitle: 'What is the reason?',
      statusBasisTitle: 'Which areas is your choice based on?',
      seeGroupAverage: 'See the group average',
      reEvaluate: 'Re-evaluate',
    },
    evaluationCoworkers: {
      title: 'Todays evaluations',
      description: 'Your coworkers in a list from A-Ö. In the right column you can see how you have evaluated each person today.',
    },
    act: {
      title: 'Act',
      actTitle: 'Time to act!',
      actSubTitle: 'Based on average, 3 months',
      statusGrayTitle: 'non-evaluated coworkers',
      statusGrayText: 'What is the reason?',
      statusYellowTitle: 'coworkers with the status yellow',
      statusYellowText: 'Have you talked to your coworker?',
      statusYellowText2: 'Have you documented the conversations?',
      statusRedTitle: 'coworkers with the status orange',
      statusRedText: 'Have you had documented conversations?',
      statusRedText2: 'Do you agree on the cause?',
      contactTitle: 'Do you need help?',
      contactArendra: 'Contact Arendra!',
      mailOrPhone: 'Email or call',
      formInfo: 'Fill in the form and we will contact you',
      email: 'Email',
      phone: 'Phone',
      send: 'Send',
      contactHR: 'Contact HR at your company',
    },
    departments: {
      title: 'Departments',
    },
    about: {
      title: 'About Arendra',
      p1: 'Arendra works for good occupational health, a courageous culture and forward- leaning communicative leaders at the employer, in order to reach a healthier labor market and a sustainable working life.',
      p2: 'Arendra is working to ensure focus on promotion and preventive activities at workplaces, reduce the inflow into our general health insurance and lower the sick leave rate in Sweden.',
      p3: `In our services, we clarify the employer's responsibility and the employee's responsibility and where the boundaries should be drawn to reach the best conditions for good performance based on the work role of an individual, at group level and for sustainable organizations.`,
      p4: `We build our collaborations after development together with our customers as we constantly measure the effects and results of our work. With us you will find behavioral experts, health educators, physiotherapists, occupational therapists, study guidance counselors, psychologists and sociologists with a common goal in mind – to contribute to more people feeling well and being able to perform in work life.`,
      link: `https://www.arendra.se/om-oss/`,
    },
    aboutArendraModel: {
      title: 'Arendra Model',
      p1: 'In 2017, we developed the Arendra Model. The curve goes upwards, and that means that the longer we wait to act on early and clear signals at work, the more the cost increases for employees, employers and society. You, as a manager, have work management responsibility for your staff. You have obligations, rights and opportunities to create a good working environment for everyone. This means confirming employee motivation and performance, but also daring to see and act on employees you are concerned about due to changed behavior, so- called risk assessment.',
      p2: 'With this tool, you get reminded to note what you see, to assure not to lose the opportunity to act. In addition, you can directly see the risk behaviors with connecting financial incentives in the form of loss of production, or reduction in value of work performed, when risks occur. Studies show that people in all situations feel good and safe from clear and respectful treatment. The energy is at its greatest below the line in the model, both for the manager and employees. Early, it is easier to start a conversation about engagement and wellbeing.',
      p3: 'When we are aware, we have greater opportunities to take responsibility for our situation and do what is required to choose a sustainable working life or recieve help with active efforts where you as an employer are involved. The support for evaluation is an aid for your assessment. The various categories derive from regular work management rights, work duty, duty of loyalty but also the current work environment regulations which means that we are all responsible for creating a good work environment for each other.',
      p4: 'The Arendra Model gives you the opportunity to systematically reflect and act together, not least to strive for a good organizational and social work environment.',
      p5: 'In all levels above, a relocation internally can be a possibility if the conditions for this exist in the business. A relocation must always be preceded by an evaluation. We also recommend employers to reflect on whether there are conditions for a better working ability outside the organization and how you can help the employee along the way. In a lot of cases, this leads to the employee regaining partial or full working ability which must be seen as a big profit for everyone involved.',
      p6: 'The Arendra Model is owned by Arendra and is also protected by the Swedish Patent and Registration Office, PRV. It is therefore not allowed to spread, manufacture, sell or import copies of it.',
      statusGreen: 'Average performer or high performer. Here, it is important to specifically acknowledge the employee to maintain good performance.',
      statusYellow: 'Early signals of changed motivation, performance or health. The signals can be many. Ask yourself whether they come from work life, private life or health. Dare to ask and listen.Tell the employee what you see and your concerns. Follow up by asking the employee to reflect on what could improve the situation. Remember to document. Consider whether internal or external support can improve the chances of going green again.',
      statusOrange: 'Clear signals of impaired motivation, performance or health. The signals can be many. Tell the employee what you see and be very clear about your concerns. Ask yourself whether the signals come from work life, private life or health. Dare to ask and listen. Ask the employee to reflect on their own responsibility and what can improve the situation. Follow up and use previous documentation. Make a timed action plan together with a target picture and also clarify the consequences. Consider whether internal or external support can improve the chances of going green again.',
      statusRed: `Short-term absence or sick leave. Keep track of your company's routines regarding the number of occasions to start an action plan and/or a rehabilitation plan if the ability to work is reduced due to illness. If the employee is expected to be on sick leave for more than 60 days and a medical certificate is available, ensure that you have an estimated prognosis of when the employee is expected to be back and ensure good contact with the employee and any prescribing doctor so that all parties can actively participate in the rehabilitation plan. Remember to finish it when the employee is back at work with expected work performance.`,
      statusBlack: `Exclusion. Employees who have been on sick leave repeatedly for long periods. Self-confidence and self-esteem often deteriorate sharply, which over time affects the employee's conditions in the labor market.`,
      green: 'Green',
      yellow: 'Yellow',
      orange: 'Orange',
      red: 'Red',
      black: 'Black',
    },
    editCoworker: {
      title: 'Edit coworker',
      deleteCoworkerTitle: 'Delete coworker',
      deleteCoworkerText: 'Are you sure you want to delete {{coworkerToDelete}}?',
      workPercentage: 'Employment percentage',
      salary: 'Monthly salary',
      firstName: 'First name',
      lastName: 'Last name',
    },
    addCoworker: {
      title: 'Add coworker',
      add: 'Add',
      save: 'Save',
      name: 'Name',
      firstName: 'First name',
      lastName: 'Last name',
      workPercentage: 'Employment percentage',
      salary: 'Monthly salary',
      edit: 'Edit',
    },
    coworker: {
      title: 'Coworker',
      edit: 'Edit',
      deleteCoworker: 'Delete coworker',
      averageTitle: 'Average (3 months)',
      status: 'Status',
      cost: 'Cost',
      history: 'History',
      evaluations: 'Evaluations',
      summary: 'Summary',
      estimatedCost: 'Estimated cost',
      SEK: 'SEK',
    },
    cheatSheet: {
      title: 'Support for evaluation',
      firstDescription: 'Consider whether the following statements work well, are partially deficient, or are significantly deficient. Then make an assessment of how you want to evaluate the person.',
      secondDescription: 'If for some reason you cannot evaluate the person, choose "Cannot evaluate".',
      statusGreen: 'Works well',
      statusYellow: 'Partially deficient',
      statusRed: 'Significantly deficient',
      sections: [
        {
          title: 'Work performance',
          list: [
            'Expected quality and quantity of normal performance',
            'Expected attendance',
            'Able to take care of their health to cope with their work',
            'Takes responsibility for recovery during working hours',
          ],
        },
        {
          title: 'Follow rules and decisions',
          list: [
            'Ensures that rules are followed',
            'Understands and follows policy/code of conduct',
          ],
        },
        {
          title: 'Work environment responsibility',
          list: [
            'Is motivated in their work and at the workplace',
            'Communicates clearly',
            'Contributes to a good working environment socially and mentally',
            'Able to see their own shortcomings and take constructive feedback',
            'Has a nice attitude',
          ],
        },
        {
          title: 'Loyalty to the employer',
          list: [
            'Able to manage their private life outside working hours',
            'Respects confidentiality',
            'The employer’s interest comes before their own during working hours',
            'Shows expected loyalty at work',
            'Is constructively questioning',
          ],
        },
        {
          title: 'Cooperation at work',
          list: [
            'Wants to grow with colleagues',
            'Works together in tasks that require it',
            'Looks after the best interests of the business',
            'Can and wants to cooperate',
          ],
        },
      ],
    },
    superAdmin: {
      companies: {
        title: 'Companies',
        addCompany: 'Add company',
        company: 'Company',
        orgNumber: 'Organization number',
        endDate: 'End date',
        email: 'Email',
        phoneNumber: 'Phone number',
        phone: 'Phone',
        dateFormat: 'YYYY-MM-DD',
        deleteCompany: 'Delete company',
        deleteText: 'Are you sure you want to delete {{companyToDelete}}?',
        started: 'Started',
      },
      company: {
        title: 'Department',
        addDepartment: 'Add department',
        addSubDepartment: 'Add sub department',
        cost: 'Cost',
        admin: 'Admin',
        firstName: 'First name',
        lastName: 'Last name',
        personalNumberFormat: 'YYYYMMDD-XXXX',
        deleteDepartment: 'Delete department',
        deleteText: 'Are you sure you want to delete {{departmentToDelete}}?',
        deleteManager: 'Delete admin',
        deleteManagerText: 'Are you sure you want to delete {{managerToDelete}}?',
        importOrganization: 'Import organization',
        chooseFile: 'Choose file',
        import: 'Import',
      },
      manage: 'Manage',
      add: 'Add',
      save: 'Save',
      cancel: 'Cancel',
      delete: 'Delete',
    },
    settings: {
      title: 'Settings',
      loggedInAs: 'You are logged in as',
      emailPlaceholder: 'myEmail@mail.com',
      edit: 'Edit',
    },
    costInfo: {
      title: 'Estimated reduction in value',
      p1: `Estimated reduction in value, that means the estimated improvement potential, in the employee's work effort - based on its salary costs. The reduction in value may be due to changed behaviors or conditions that are assessed to have a negative impact on the employee's performance, motivation or health.`,
      p2: `Historically, organizations have usually only counted lost production from sickness absence or broken machines. As we today know a number of reasons lead to a lack of performance, motivation and/or health, often before one's sick leave has arisen, it is reasonable that the employer (read their managers), based on a risk perspective, takes responsibility for an early dialogue with their employees as well.`,
      p3: `Risks can exist both in the employee's private life or health, but also at work in the form of a poor working environment, poor leadership or in the fact that the employee's motivation and willingness to contribute to the business has simply decreased significantly.`,
      p4: `Acting early is absolutely crucial for serious proactive work, to minimize damage to personnel and organization and increase the chances of long term and healthy sustainability. Also the understanding that when we consistantly fail to act, it affects both the individual, group and organization negatively financially.`,
    },
    integrityPolicy: {
      title: 'Integrity policy',
      p1: {
        title: 'General',
        text: 'Arendra takes personal privacy seriously and has worked with several measures to ensure the protection of personal privacy and to comply with the rules of the General Data Protection Regulation (GDPR).',
      },
      p2: {
        title: 'Processing of data on evaluative judgments',
        text: `There are no obstacles for an employer to process information about evaluative judgments about employees in order to identify signs of ill health or illness, or to prevent cooperation difficulties, etc. On the contrary, it is the employer's right and obligation - with the support of labor management law and work environment regulations - to ensure a good working environment and to prevent ill health and illness. See below for their assessment of the legal basis for processing employee data. To reduce the potential breach of privacy as far as possible, Arendra´s model is based on standardized questions and answers, see the next point.`,
      },
      p3: {
        title: 'Task minimization',
        text: `Arendra's model means that, as far as possible, we work with standardized questions adapted to a number of different situations. By minimizing the number of free text fields, we ensure that the answers and questions asked are adequate and relevant and do not mean that the customer receives unnecessary information. Everything to ensure that our customers can comply with the basic requirements regarding, among other things, data minimization in Article 5 of the GDPR`,
      },
      p4: {
        title: 'Privacy by Design and Default',
        text: `Our platform has a very high security standard which, among other things, means that insert security standards, e.g. encryption in transit and at rest. Login to our platform will take place via so-called strong authentication (e.g. BankID) to meet the Swedish Privacy Agency's guidelines`,
      },
      p5: {
        title: 'Who is responsible for personal data',
        text: `For the services in this digital tool, Arendra is a personal data assistant and will, among other things, to comply with the requirements of Article 28 of the GDPR and e.g. ensure that with each customer there is a personal data assistant agreement that clearly points out the customer's and Arendra's areas of responsibility.`,
      },
      p6: {
        title: '',
        text: `The customer will be responsible for personal data according to the rules of the GDPR and it is therefore the customer's responsibility to ensure that the customer’s  handling of the personal data that occurs is supported by the GDPR. As far as the customer's handling of personal data in general within the framework of this digital tool is concerned, it is Arendra's opinion that customers will be able to support their processing of personal data partly on Article 6.1 c) (legal obligation, legitimate interest) and Article 9.2 b) of the GDPR, meaning that the customers (the employer) can fulfill their obligations according to the work environment legislation. To make it easier for our customers, we intend to produce a package with e.g. information texts according to GDPR and schematic assessment of the employer's right and possibility to handle personal data within the framework of this digital tool`,
      },
    },
    userTerms: {
      title: 'Användarvillkor för Arendra-verktyget',
      text1: 'Dessa användarvillkor \b("Användarvillkoren")\b gäller för din användning av Arendras digitala verktyg för uppföljning av medarbetares mående och prestationer enligt den så kallade Arendramodellen ("Arendra-verktyget"). För mer information om Arendramodellen, se vår webbplats www.arendra.se ("Webbplatsen"). Användarvillkoren för Arendra-verktyget ingås mellan dig som användare ("du") och Arendra AB, org. nr. 559124-0667, ("Arendra", "vi", "oss", "vår").',
      text2: 'Arendra-verktyget utgörs dels av en webbapplikation som är tillgänglig på app.arendra.se, dels av en mobilapp ("Arendra-appen"). En beskrivning av Arendra-verktyget finns på Webbplatsen. Verktyget är tillgängligt både på svenska och engelska. Vänligen läs igenom dessa Användarvillkor noggrant innan du registrerar ett användarkonto för användning av Arendra-verktyget.',
      useOfArendraTool: {
        title: 'Användning av Arendra-verktyget',
        p1: {
          title: 'Tillgång till Arendra-verktyget',
          text1: 'För att använda Arendra-verktyget krävs (i) att din arbetsgivare eller det företag som du arbetar för har ett giltigt avtal med Arendra avseende användning av Arendra-verktyget, (ii) att den enhet som du använder uppfyller systemkraven (se nedan), samt (iii) att du registrerar ett användarkonto i Arendra-verktyget genom att tillhandahålla den information som efterfrågas.',
          text2: 'Arendra-verktyget är kostnadsfritt för dig som användare, eftersom din användning omfattas av avtalet mellan din arbetsgivare eller det företag som du arbetar för och Arendra, vilket också reglerar kostnaden för användningen av Arendra-verktyget. Arendra-verktyget är inte tillgängligt för privatpersoner (konsumenter).',
        },
        p2: {
          title: 'Användning av Arendra-verktyget',
          text1: 'Genom att registrera ett användarkonto för användning av Arendra-verktyget bekräftar du att (i) du har tillhandahållit korrekt och fullständig information som efterfrågats under registreringsprocessen och att du kommer att hålla denna information uppdaterad, (ii) du inte gör intrång i någon tredje parts rättigheter (till exempel genom att tillhandahålla information tillhörande tredje parter såsom deras namn eller e-postadress när du registrerar ett användarkonto), och att (iii) du endast kommer att använda Arendra-verktyget för de ändamål som det utvecklats och skapats för och för lagliga ändamål.',
          text2: 'Du ansvarar för att hålla dina inloggningsuppgifter till ditt användarkonto hemliga och säkerställa att ingen obehörig använder ditt användarkonto. Du ansvarar för den användning av Arendra-verktyget som sker genom ditt användarkonto. Om du misstänker att någon obehörig använder ditt användarkonto meddela oss omedelbart och ändra ditt lösenord så snart som möjligt.',
          text3: 'Du accepterar att du inte får utan Arendras på förhand lämnade skriftliga godkännande:',
          listItem1: 'kopiera Arendra-verktyget eller Arendra-appen (på annat sätt än att installera Arendra-appen på din mobila enhet genom den appbutik som appen tillgängliggörs),',
          listItem2: 'ändra, översätta, anpassa eller på annat sätt utarbeta härledda verk eller förbättringar av Arendra-verktyget,',
          listItem3: 'demontera, dekompilera, avkoda, så kallat reverse engineer eller på annat sätt försöka härleda eller få tillgång till källkoden för Andra-verktyget eller någon del av verktyget i större utsträckning än vad som är tillåtet enligt lag,',
          listItem4: 'ta bort, radera, ändra eller dölja varumärken eller andra immateriella rättigheter i Arendra-verktyget,',
          listItem5: 'hyra, låna ut, sälja, vidarelicensiera, tilldela, distribuera, publicera, offentliggöra eller visa, överföra eller på annat sätt tillgängliggöra Arendra-verktyget till någon tredje part,',
          listItem6: 'ta bort, inaktivera, eller på annat sätt kringgå det kopieringsskydd, system för förvaltning av rättigheter (DRM-system) eller säkerhetsfunktioner i eller som skyddar Arendra-verktyget, eller',
          listItem7: 'ladda upp eller införa virus, trojaner, maskar, spionprogram, sabotageprogram, time-bombs eller andra skadliga komponenter till Arendra-verktyget.',
          text4: 'Om du (enligt vår mening) agerar i strid med dessa Användarvillkor, tillämpliga lagar eller föreskrifter, eller i övrigt missbrukar Arendra-verktyget, har vi rätt att (i) begränsa, stänga av eller neka dig tillgång till Arendra-verktyget, (ii) vidta tekniska och/eller rättsliga åtgärder för att förhindra dig från vidare användning av Arendra-vertyget, och (iii) säga upp och/eller avsluta din användning eller tillgång till Arendra-verktyget med omedelbar verkan, inklusive genom att ta bort ditt användarkonto. Detta begränsar inte ytterligare åtgärder som Arendra kan ha rätt att vidta enligt lag eller dessa Användarvillkor till följd av ditt brott mot dessa Användarvillkor.',
        },
        p3: {
          title: 'Systemkrav',
          text1: 'Användning av Arendra-verktyget kräver en kompatibel enhet med ett operativsystem som stödjs och åtkomst till Internet. Såvitt avser användning av Arendra-appen krävs att du också har laddat ner Arendra-appen från en appbutik där appen tillgängliggörs och installerat Arendra-appen på din mobila enhet. Om du använder webbapplikationen krävs också att du använder en kompatibel webbläsare.',
          text2: 'Du är ensamt ansvarig för din egen Internet-uppkoppling för att kunna använda Arendra-verktyget.',
          text3: 'Information rörarande kompatibla enheter, operativsystem och webbläsare finns tillgängligt på vår Webbplats. Detaljerad information om vilka systemkrav som gäller för Arendra-appen finns även direkt i den appbutik där Arendra-appen tillgängliggörs.',
          text4: 'Vi kommer att underrätta dig på förhand om vi ändrar systemkraven för Arendra-verktyget på lämpligt sätt, till exempel genom e-post eller, såvitt avser Arendra-appen, genom den appbutik som Arendra-appen tillgängliggörs (till exempel Apple App Store).',
          text5: 'Du ansvarar för att uppdatera din enhet och ditt operativsystem för att uppfylla systemkraven. Om du inte uppdaterar din enhet och ditt operativsystem kan det innebära att du inte kan använda Arendra-verktyget, inklusive Arendra-appen.',
        },
        p4: {
          title: 'Uppdateringar till Arendra-verktyget',
          text1: 'Du accepterar att vi, från tid till annan, kan utfärda uppdaterade versioner av Arendra-verktyget, som kan innehålla till exempel uppgraderingar och felkorrigeringar, och att vi kan automatiskt uppdatera den version av Arendra-verktyget som du använder. Du accepterar också att dessa Användarvillkor gäller för sådana uppdaterade versioner av Arendra-verktyget.',
          text2: 'Du är också införstådd med och accepterar att en uppdatering kan innebära att funktionalitet tillkommer och/eller försvinner i Arendra-verktyget.',
          text3: 'Vi kommer att informera dig om nya versioner av Arendra-verktyget, inklusive Arendra-appen, på lämpligt sätt, till exempel via e-post, i verktyget eller, såvitt avser Arendra-appen, genom den appbutik där Arendra-appen tillgängliggörs.',
          text4: 'Du ansvarar för att uppdatera Arendra-appen på din mobila enhet. Beroende på inställningarna på din mobila enhet kan Arendra-appen automatiskt installeras och uppdateras eller så får du information om att en uppdatering är tillgänglig. Om du inte uppdaterar Arendra-appen på din mobila enhet till den senaste versionen kan det innebära att Arendra-appen inte fungerar som den ska eller alls.',
        },
        p5: {
          title: 'Kostnader för dataanvändning',
          text1: 'Du ansvarar för kostnader och avgifter hänförliga till din egen Internet-trafik och dataanvändning som kan förekomma när du ges tillgång till och använder Arendra-verktyget.',
        },
        p6: {
          title: 'Tillgänglighet',
          text1: 'Arendra-verktyget tillhandahålls "som tillgängligt". Detta innebär att vi inte garanterar att Arendra-verktyget alltid är helt tillgängligt, till exempel till följd av oplanerat underhåll eller tekniska problem. Du bekräftar och godtar att Arendra-verktyget kan vara otillgängligt till följd av oplanerad service eller oplanerat underhåll, tekniska problem och/eller uppdateringar.',
        },
      },
      rightsAndOwnership: {
        title: 'Rättigheter och äganderätt',
        p1: {
          title: 'Licens att använda Arendra-verktyget',
          text1: 'Du ges härmed en icke-exklusiv, icke-överlåtbar, återkallelig och begränsad rätt och licens att använda Arendra-verktyget, inklusive Arendra-appen, i enlighet med dessa Användarvillkor.',
          text2: 'Din rätt att använda Arendra-verktyget är villkorat dels av att du följer dessa Användarvillkor, dels att din arbetsgivare eller det företag som du arbetar för har ett giltigt avtal med Arendra om användning av Arendra-verktyget. Om avtalet mellan din arbetsgivare eller det företag som du arbetar för och Arendra avseende användning av Arendra-verktyget upphör (oavsett anledning till detta) upphör även din rätt och licens att använda Arendra-verktyget enligt dessa Användarvillkor.',
        },
        p2: {
          title: 'Äganderätt',
          text1: 'Allt innehåll i Arendra-verktyget, inklusive text, grafik, logotyper, ikoner, bilder, källkod och/eller annat hänförligt material ägs eller licenseras av oss. Ingenting i dessa Användarvillkor ska tolkas som att någon av våra eller våra licensgivares materiella eller immateriella rättigheter överförs eller överlåts till dig.',
          text2: 'Tredje parters rättigheter i Arendra-verktyget licensieras till dig antingen enligt avtal eller i enlighet med respektive tredje parts licens för sådan mjukvara. Användarvillkoren för sådan tredje parts-mjukvara finns publicerad i Arendra-verktyget.',
        },
      },
      dataProtection: {
        title: 'Dataskydd',
        p1: {
          title: 'Personuppgiftsansvar',
          text1: 'Din arbetsgivare eller det företag som du arbetar för är personuppgiftsansvarig för behandlingen av dina personuppgifter i samband med att du använder Arendra-verktyget. Arendra är ett så kallat personuppgiftsbiträde i förhållande till din arbetsgivare eller det företag som du arbetar för och behandlar dina personuppgifter för att tillhandahålla Arendra-verktyget och enligt de instruktioner som din arbetsgivare eller det företag som du arbetar för lämnar.',
          text2: 'Arendra ansvarar dock för behandlingen av dina personuppgifter i samband med anonymisering av dina personuppgifter för att analysera, följa upp och utvärdera hur Arendra-verktyget används och för test och utveckling av Arendra-verktyget.',
        },
        p2: {
          title: 'Information om vår behandling av personuppgifter',
          text1: 'För information om vår behandling av personuppgifter för egen räkning, se vår informationstext om behandling av personuppgifter på vår Webbplats.',
        },
      },
      agreementTimeAndTermination: {
        title: 'Avtalstid och uppsägning',
        p1: {
          title: 'Avtalstid',
          text1: 'Dessa Användarvillkor gäller från och med att du börjar använda Arendra-verktyget eller hämtar och installerar Arendra-appen på din mobila enhet och under den tid som det finns ett giltigt avtal mellan din arbetsgivare eller det företag som du arbetar för och Arendra avseende användning av Arendra-verktyget eller den tidigare tidpunkt som Användarvillkoren upphör.',
        },
        p2: {
          title: 'Uppsägning',
          text1: 'Du kan när som helst säga upp dessa Användarvillkor genom att sluta använda Arendra-verktyget och begära radering av ditt användarkonto. Du kan begära radering av ditt användarkonto genom att kontakta oss via e-post på följande adress: support@arendra.se.',
          text2: 'Dessa Användarvillkor upphör även automatiskt:',
          listItem1: 'om avtalet avseende användning av Arendra-verktyget mellan din arbetsgivare eller det företag som du arbetar för och Arendra upphör (oavsett anledning därtill), och/eller',
          listItem2: 'om din anställning eller ditt uppdrag upphör (oavsett anledning därtill) hos den arbetsgivare eller det företag som du arbetar för och som har ingått avtalet avseende användning av Arendra-verktyget.',
          text3: 'Användarvillkoren upphör i sådant fall på samma dag som det relevanta avtalsförhållandet upphör',
        },
        p3: {
          title: 'Konsekvenser vid upphörande',
          text1: 'I samband med Användarvillkorens upphörande (oavsett anledning till detta): (i) upphör din rätt att använda Arendra-verktyget, (ii) måste du upphöra med din användning av Arendra-verktyget och avinstallera Arendra-appen från din mobila enhet, samt (iii) kommer din tillgång till Arendra-verktyget och ditt användarkonto att upphöra.',
        },
        p4: {
          title: 'Fortsatt giltighet av vissa villkor',
          text1: 'Punkterna 2 (Rättigheter och Äganderätt), 6 (Ansvar), 8 (Övrigt) och 9 (Tillämplig lag och Tvister) ska fortsätta gälla även efter det att dessa Användarvillkor har upphört att gälla oavsett anledning till detta.',
        },
      },
      changesToTheseTerms: {
        title: 'Ändringar till dessa användarvillkor',
        p1: {
          text1: 'Vi kan, från tid till annan, göra ändringar till dessa Användarvillkor. Vi kommer att meddela dig trettio (30) dagar på förhand om ändringar till dessa Användarvillkor på lämpligt sätt, till exempel genom e-post eller via Arendra-verktyget. Om du inte motsätter dig de uppdaterade Användarvillkoren och fortsätter att använda Arendra-verktyget efter det att de uppdaterade Användarvillkoren har börjat gälla anses du ha godkänt de uppdaterade Användarvillkoren. Om du inte vill godkänna de uppdaterade Användarvillkoren kan du inte fortsätta använda Arendra-verktyget efter det att de uppdaterade Användarvillkoren har börjat gälla.',
        },
      },
      responsibility: {
        title: 'Ansvar',
        p1: {
          title: 'Reklamationstid',
          text1: 'Om du vill göra gällande att det föreligger ett fel i Arendra-verktyget som vi ansvarar för ska du underrätta oss (reklamera) detta inom två (2) månader från den tidpunkt då du fick kännedom om eller borde ha fått kännedom om felet. Du kan underrätta oss på kontaktuppgifterna nedan. När du har underrättat oss kommer vi att bekräfta mottagandet av din underrättelse och därefter återkoppla till dig så snart som möjligt för att hantera ditt ärende.',
        },
        p2: {
          title: 'Ansvarsbegränsning',
          text1: 'Vi är inte skyldiga för: (i) förluster eller skador som härrör från eller har samband med användningen av, eller oförmågan att använda, Arendra-verktyget, eller användningen av eller förlitandet på innehållet i Arendra-verktyget, (ii) indirekta förluster, följdförluster eller skador, (iii) uteblivna vinster, (iv) avbrott i verksamhet, (v) förlust av förväntande besparingar, (vi) förlust eller skada av affärsmöjlighet, goodwill eller rykte, eller (vii) förlust till följd av driftstopp, förlust av data eller liknande förluster.',
          text2: 'Vårt ansvar för skador som uppkommer under eller i samband med dessa Användarvillkor är begränsat till, per kalenderår, ett belopp motsvarande ett prisbasbelopp enligt socialförsäkringsbalken (2010:110).',
        },
        p3: {
          title: 'Undantag',
          text1: 'Ansvarsbegränsningen i dessa Användarvillkor gäller inte om skadan uppkommit till följd av grov oaktsamhet eller uppsåt.',
        },
      },
      specialTermsForArendraApp: {
        title: 'Särskilda villkor för Arendra-appen',
        text1: 'Om du har laddat ner Arendra-appen från Apple App Store eller om du använder Arendra-appen på en iOS-baserad mobil enhet bekräftar du att du har läst, förstått och accepterar följande villkor:',
        listItem1: 'Dessa Användarvillkor är mellan dig och oss, och inte med Apple Inc. ("Apple"), och Apple är inte ansvarig för Arendra-verktyget eller innehållet däri.',
        listItem2: 'Apple har ingen som helst skyldighet att tillhandahålla underhålls- och supporttjänster avseende Arendra-verktyget.',
        listItem3: 'I händelse av att Arendra-verktyget inte överensstämmer med någon tillämplig garanti har du möjlighet att underrätta Apple om detta och Apple kommer då att återbetala tillämpligt inköpspris för Arendra-verktyget till dig (notera dock att Arendra-appen är kostnadsfri för dig enligt ovan), och i den utsträckning det är tillåtet enligt tillämplig lag, har Apple ingen som helst skyldighet eller garantiförpliktelse för Arendra-verktyget.',
        listItem4: 'Apple ansvarar inte för att hantera några anspråk mellan dig och någon tredje part avseende Arendra-verktyget eller din användning av Arendra-verktyget, inklusive: (i) produktansvarskrav, (ii) anspråk innebärande att Arendra-verktyget inte uppfyller gällande juridiska eller regulatoriska krav, (iii) anspråk enligt gällande konsumenträttslig eller liknande lagstiftning, samt (iv) eventuella krav avseende intrång i immateriella rättigheter.',
        listItem5: 'Apple ansvarar inte för eventuella krav från tredje part att Arendra-verktyget eller ditt innehav eller användning av Arendra-verktyget innebär ett intrång i tredje parts immateriella rättigheter.',
        listItem6: 'Apple och dess koncernbolag är berättigade tredje parter till dessa Användarvillkor och genom att acceptera dessa Användarvillkor har Apple rätt (och ska anses ha accepterat en sådan rätt) att göra Användarvillkoren gällande mot dig som en berättigad tredje part till dessa Användarvillkor.',
      },
      other: {
        title: 'Övrigt',
        p1: {
          title: 'Hela avtalet',
          text1: 'Dessa Användarvillkor utgör hela avtalet mellan dig och oss avseende användningen av Arendra-verktyget.',
        },
        p2: {
          title: 'Överlåtelse',
          text1: 'Vi har rätt att överlåta hela eller delar av våra rättigheter och skyldigheter enligt dessa Användarvillkor till en tredje part utan ditt samtycke. Du kan dock inte överföra dina rättigheter eller skyldigheter enligt dessa Användarvillkor utan vårt på förhand lämnade skriftliga samtycke.',
        },
        p3: {
          title: 'Icke-avstående',
          text1: 'Om en part underlåter att utöva en rättighet enligt dessa Användarvillkor ska detta inte innebära att parten avstår från fortsatt utövande av denna rättighet. En parts avstående från att vidta åtgärder med anledning av en överträdelse av någon bestämmelse i dessa Användarvillkor innebär inte att sådant avstående ska gälla för efterföljande överträdelser av sådan eller annan bestämmelse i dessa Användarvillkor.',
        },
        p4: {
          title: 'Enskilda bestämmelsers ogiltighet',
          text1: 'Om någon bestämmelse i dessa Användarvillkor är eller blir ogiltig, olaglig eller verkningslöst enligt tillämplig lag, ska giltigheten, lagligheten och verkan av resterande bestämmelser i dessa Användarvillkor inte påverkas. Om en bestämmelse i ett förfarande förklaras ogiltig, olaglig eller verkningslöst ska sådan bestämmelse ersättas med en ny bestämmelse som är förenlig med tillämplig lag och med en verkan som är så lik den ursprungliga bestämmelsen som möjligt.',
        },
      },
      applicableLawAndDisputes: {
        title: 'Tillämplig lag och tvister',
        p1: {
          title: 'Tillämplig lag',
          text1: 'Dessa Användarvillkor omfattas av svensk lag, utan beaktande av bestämmelser om motstridiga regler om lagval.',
        },
        p2: {
          title: 'Tvister',
          text1: 'Eventuella tvister och anspråk som uppstår till följd av eller i samband med dessa Användarvillkor, eller brott mot, uppsägning eller ogiltigförklaring av dessa ska slutligt avgöras av svensk domstol.',
        },
      },
      contactInformation: {
        title: 'Kontaktuppgifter',
        text1: 'Om du har frågor rörande Arendra-verktyget, vänligen kontakta oss på: info@arendra.se.',
        email: 'E-post',
        text2: 'Kul att du använder Arendra-verktyget!',
      },
    },
    status: {
      greenTitle: 'coworkers with the status green',
      yellowTitle: 'coworkers with the status yellow',
      orangeTitle: 'coworkers with the status orange',
      greenWhatDoesItMean: 'What does it mean if a person is green?',
      yellowWhatDoesItMean: 'What does it mean if a person is yellow?',
      orangeWhatDoesItMean: 'What does it mean if a person is orange?',
    },
  },
  statuses: {
    worksWell: 'Works well',
    partiallyLacking: 'Partially lacking',
    noticeablyLacking: 'Noticeably lacking',
    couldNotEvaluate: 'Could not evaluate',
    skipped: 'Skipped',
  },
  clientErrors: {
    fillOutAllFields: 'Fill out all fields',
    invalidEmail: 'Invalid email address',
    invalidPersonalNumber: 'Invalid personal number',
    invalidEndDate: 'Invalid end date',
    salaryNotANumber: 'Salary must be a number',
  },
  menu: {
    arendraModel: 'Arendramodel',
    about: 'About Arendra',
    settings: 'Settings',
    userTerms: 'User terms',
    integrityPolicy: 'Integrity policy',
    contactArendra: 'Contact Arendra',
    logout: 'Logout',
  },
  // LAST!
  bankIdErrors: {
    RFA1: 'Start your BankID app.',
    RFA2: 'The BankID app is not installed. Please contact your bank.',
    RFA3: 'Action cancelled. Please try again.',
    RFA4: 'An identification or signing for this personal number is already started. Please try again.',
    RFA5: 'Internal error. Please try again.',
    RFA6: 'Action cancelled.',
    RFA8: 'The BankID app is not responding. Please check that it’s started and that you have internet access. If you don’t have a valid BankID you can get one from your bank. Try again.',
    RFA9: 'Enter your security code in the BankID app and select Identify or Sign.',
    RFA13: 'Trying to start your BankID app.',
    RFA14A: 'Searching for BankID, it may take a little while... If a few seconds have passed and still no BankID has been found, you probably don’t have a BankID which can be used for this identification/signing on this computer. If you have a BankID card, please insert it into your card reader. If you don’t have a BankID you can get one from your bank. If you have a BankID on another device you can start the BankID app on that device.',
    RFA14B: 'Searching for BankID, it may take a little while... If a few seconds have passed and still no BankID has been found, you probably don’t have a BankID which can be used for this identification/signing on this device. If you don’t have a BankID you can get one from your bank. If you have a BankID on another device you can start the BankID app on that device.',
    RFA15A: 'Searching for BankID:s, it may take a little while... If a few seconds have passed and still no BankID has been found, you probably don’t have a BankID which can be used for this identification/signing on this computer. If you have a BankID card, please insert it into your card reader. If you don’t have a BankID you can get one from your bank.',
    RFA15B: 'Searching for BankID, it may take a little while... If a few seconds have passed and still no BankID has been found, you probably don’t have a BankID which can be used for this identification/signing on this device. If you don’t have a BankID you can get one from your bank.',
    RFA16: 'The BankID you are trying to use is blocked or too old. Please use another BankID or get a new one from your bank.',
    RFA17A: 'The BankID app couldn’t be found on your computer or mobile device. Please install it and get a BankID from your bank. Install the app from your app store or https://install.bankid.com.',
    RPA17B: "Failed to scan the QR code. Start the BankID app and scan the QR code. Check that the BankID app is up to date. If you don't have the BankID app, you need to install it and get a BankID from your bank. Install the app from your app store or https://install.bankid.com",
    RFA18: 'Start the BankID app.',
    RFA19: 'Would you like to identify yourself or sign with a BankID on this computer, or with a Mobile BankID?',
    RFA20: 'Would you like to identify yourself or sign with a BankID on this device, or with a BankID on another device?',
    RFA21: 'Identification or signing in progress.',
    RFA22: 'Unknown error. Please try again.',
    RFA23: 'Process your machine-readable travel document using the BankID app.',
    BAD_USER_INPUT: '',
  },
};

export default en;
