import React, { useContext } from 'react';
import {
  View, Text, StyleSheet, TouchableHighlight, Platform,
} from 'react-native';
import { Button, useTheme } from 'react-native-paper';
import { Ionicons } from '@expo/vector-icons';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import type { Department, RootStackParamList } from '@types';
import Box from '../../../components/Box';
import ListItem from '../../../components/ListItem';
import AuthContext from '../../../contexts/AuthContext';
import useTranslation from '../../../hooks/useTranslation';
import UserSettingsContext from '../../../contexts/UserSettingsContext';

const SelectRole = () => {
  const { colors } = useTheme();
  const { menu, screen } = useTranslation();
  const { logout } = useContext(AuthContext);
  const { userRoles, setCurrentDepartment, setIsWebRoleSelectionOpen } = useContext(UserSettingsContext);
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();

  const handleSuperAdminSelection = () => {
    setCurrentDepartment(undefined);
    setIsWebRoleSelectionOpen(false);
    navigation.goBack(); // We need this to close the modal-screen before navigating to department
    navigation.navigate('companies');
  };

  const handleDepartmentSelection = (departmentId: string) => {
    setCurrentDepartment(departmentId);
    setIsWebRoleSelectionOpen(false);
    navigation.goBack(); // We need this to close the modal-screen before navigating to department
    navigation.navigate('department', { departmentId });
  };

  return (
    <>
      <Box>
        {userRoles?.isSuperAdmin && (
          <TouchableHighlight disabled={Platform.OS !== 'web'} onPress={handleSuperAdminSelection} style={{ borderBottomColor: '#E5E5E5' }} underlayColor='#E5E5E5'>
            <ListItem isBorderBottom style={{ height: 65 }}>
              <View>
                {Platform.OS === 'web' ? <Text style={styles.role}>{screen.roleSelection.superAdmin}</Text>
                  : <Text style={[styles.role, styles.disabled, { color: colors.backdrop }]}>{screen.roleSelection.superAdminNotAvailable}</Text>}
              </View>
              <Ionicons name='chevron-forward-sharp' size={24} color={colors.onBackground} />
            </ListItem>
          </TouchableHighlight>
        )}
        {userRoles?.departmentsIManage?.map((department: Department, index) => (
          <TouchableHighlight
            key={department?._id}
            onPress={() => handleDepartmentSelection(department?._id as string)}
            style={{ borderBottomColor: '#E5E5E5' }}
            underlayColor='#E5E5E5'
          >
            <ListItem isBorderBottom={index !== userRoles.departmentsIManage.length - 1} style={{ height: 65 }}>
              <View>
                <Text style={styles.role}>{department.companyName}</Text>
                <Text style={[styles.department, { color: colors.onBackground }]}>{department?.name}</Text>
              </View>
              <Ionicons name='chevron-forward-sharp' size={24} color={colors.onBackground} />
            </ListItem>
          </TouchableHighlight>
        ))}
      </Box>

      <Button
        mode='outlined'
        onPress={logout}
        style={{ marginTop: 20, marginRight: 'auto' }}
        buttonColor={colors.secondary}
        textColor={colors.onSecondary}
        uppercase={false}
      >
        {menu.logout}
      </Button>
    </>
  );
};

const styles = StyleSheet.create({
  role: {
    fontSize: 16,
  },
  disabled: {
    fontStyle: 'italic',
  },
  department: {
    textTransform: 'uppercase',
    fontSize: 12,
    marginTop: 2,
  },
});

export default SelectRole;
