import React, { useContext, useEffect, useRef } from 'react';
import {
  View, Text, StyleSheet, Animated,
} from 'react-native';
import { Button, Text as PaperText, useTheme } from 'react-native-paper';
import { Ionicons } from '@expo/vector-icons';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import type { EstimationsByDepartmentIdQuery, RootStackParamList } from '@types';
import { useQuery } from '@apollo/client';
import Slide from './Slide';
import UserSettingsContext from '../../../contexts/UserSettingsContext';
import useTranslation from '../../../hooks/useTranslation';
import { estimationsByDepartmentIdQuery } from '../../../queries';

type EvaluationCompletedProps = {
  setIsReEvaluating: React.Dispatch<React.SetStateAction<boolean>>;
};

const EvaluationCompleted: React.FC<EvaluationCompletedProps> = ({ setIsReEvaluating }) => {
  const { colors } = useTheme();
  const { screen } = useTranslation();
  const { currentDepartment } = useContext(UserSettingsContext);
  const { data: evaluationsData } = useQuery<EstimationsByDepartmentIdQuery>(estimationsByDepartmentIdQuery, {
    variables: {
      departmentId: currentDepartment,
    },
  });
  const { navigate } = useNavigation<NavigationProp<RootStackParamList>>();

  const fadeAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 200,
      useNativeDriver: true,
    }).start();
  }, [fadeAnim]);

  const handleNavigate = () => {
    if (currentDepartment) {
      const isAnyCoworkerYellowOrOrange = (evaluationsData?.estimationsByDepartmentId.amountOfYellow && evaluationsData?.estimationsByDepartmentId.amountOfYellow > 0)
        || (evaluationsData?.estimationsByDepartmentId.amountOfOrange && evaluationsData?.estimationsByDepartmentId.amountOfOrange > 0);

      if (isAnyCoworkerYellowOrOrange) {
        navigate('department', { departmentId: currentDepartment, isTimeToAct: true });
      } else {
        navigate('department', { departmentId: currentDepartment });
      }
    }
  };

  const handleReEvaluate = () => {
    setIsReEvaluating(true);
  };

  return (
    <Slide style={styles.slide}>
      <Animated.View style={[styles.container, { opacity: fadeAnim }]}>
        <View style={[styles.checkContainer, { backgroundColor: colors.primary }]}>
          <Ionicons name='checkmark' size={60} color='#fff' />
        </View>
        <PaperText style={{ fontSize: 32 }}>{screen.evaluation.goodWork}</PaperText>
        <View style={styles.textContainer}>
          <Text style={{ fontSize: 16 }}>{screen.evaluation.doneForToday}</Text>
          <Text style={{ fontSize: 16 }}>{screen.evaluation.rememberToConfirm}</Text>
        </View>
        <Button
          mode='contained'
          style={{ marginTop: 20 }}
          contentStyle={{ height: 50, paddingHorizontal: 10, width: 230 }}
          onPress={handleNavigate}
          buttonColor={colors.secondary}
          textColor={colors.onSecondary}
          uppercase={false}
        >
          {screen.evaluation.seeGroupAverage}
        </Button>
        <Button
          mode='outlined'
          style={{ marginTop: 10, borderColor: colors.secondary, borderWidth: 1 }}
          contentStyle={{ height: 50, paddingHorizontal: 10, width: 230 }}
          onPress={handleReEvaluate}
          textColor={colors.secondary}
          uppercase={false}
        >
          {screen.evaluation.reEvaluate}
        </Button>
      </Animated.View>
    </Slide>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    gap: 6,
  },
  slide: {
    minHeight: 400,
    justifyContent: 'center',
    paddingTop: 0,
  },
  checkContainer: {
    width: 95,
    height: 95,
    borderRadius: 50,
    position: 'absolute',
    top: -30,
    borderWidth: 8,
    borderColor: '#fff',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 6,
    backgroundColor: '#fff',
  },
  textContainer: {
    alignItems: 'center',
    paddingVertical: 12,
    gap: 6,
  },
});

export default EvaluationCompleted;
