import { Ionicons } from '@expo/vector-icons';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Animated, StyleSheet, Text, ViewStyle,
} from 'react-native';
import { useTheme } from 'react-native-paper';

type AlertFromBottomProps = {
  isShown: boolean;
  message: string;
  style?: ViewStyle;
};

const AlertFromBottom: React.FC<AlertFromBottomProps> = ({ isShown, message, style }) => {
  const { colors } = useTheme();
  const [slideAnimation] = useState(new Animated.Value(0));
  const [opacityAnimation] = useState(new Animated.Value(0));

  const animateAlert = useCallback(() => {
    Animated.parallel([
      Animated.timing(slideAnimation, {
        toValue: 1,
        duration: 500,
        useNativeDriver: false,
      }),
      Animated.timing(opacityAnimation, {
        toValue: 1,
        duration: 500,
        useNativeDriver: false,
      }),
    ]).start();
  }, [opacityAnimation, slideAnimation]);

  useEffect(() => {
    if (isShown) {
      animateAlert();
    }
  }, [animateAlert, isShown]);

  const alertTranslateY = slideAnimation.interpolate({
    inputRange: [0, 1],
    outputRange: [100, 0],
  });

  const alertOpacity = opacityAnimation;

  return (
    <Animated.View
      style={[
        styles.alertContainer,
        style,
        {
          transform: [{ translateY: alertTranslateY }],
          opacity: alertOpacity,
        },
      ]}
    >
      <Ionicons name='checkmark-circle-sharp' size={24} color={colors.primary} />
      <Text style={{ flex: 1 }}>{message}</Text>
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  alertContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#fff',
    paddingHorizontal: 16,
    paddingVertical: 12,
    marginBottom: 20,
    borderRadius: 6,
    marginHorizontal: 20,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 6,
  },
});

export default AlertFromBottom;
