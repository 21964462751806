import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import type {
  CoworkerSort, DepartmentCoworkerWithCalculatedPercentage,
} from '@types';
import { TouchableRipple, useTheme } from 'react-native-paper';
import useMediaQueries from '../../../hooks/useMediaQueries';
import useTranslation from '../../../hooks/useTranslation';

type CoworkersTableHeaderProps = {
  coworkers: DepartmentCoworkerWithCalculatedPercentage[];
  setCoworkers: React.Dispatch<React.SetStateAction<DepartmentCoworkerWithCalculatedPercentage[]>>;
};

const CoworkersTableHeader: React.FC<CoworkersTableHeaderProps> = ({ coworkers, setCoworkers }) => {
  const { colors } = useTheme();
  const { isTablet } = useMediaQueries();
  const { screen } = useTranslation();

  const [sort, setSort] = useState<CoworkerSort>({
    type: 'firstName',
    order: 'asc',
  });

  const sortCoworkers = (coworkersArray: DepartmentCoworkerWithCalculatedPercentage[]) => {
    const { type, order } = sort;
    const sorted = [...coworkersArray].sort((a, b) => {
      if (type === 'firstName') {
        if (order === 'asc') {
          return a.firstName.localeCompare(b.firstName);
        }
        return b.firstName.localeCompare(a.firstName);
      }
      if (type === 'lastName') {
        if (order === 'asc') {
          return a.lastName.localeCompare(b.lastName);
        }
        return b.lastName.localeCompare(a.lastName);
      }
      if (type === 'evaluations') {
        const aStatus = ((Number(a.todaysEstimation?.status) === 0) || (Number(a.todaysEstimation?.status === undefined))) && order === 'asc' ? 0.5
          : (Number((a.todaysEstimation?.status) === 0) || (Number(a.todaysEstimation?.status === undefined))) && order === 'desc' ? 4
            : Number(a.todaysEstimation?.status);

        const bStatus = ((Number(b.todaysEstimation?.status) === 0) || (Number(b.todaysEstimation?.status === undefined))) && order === 'asc' ? 0.5
          : (Number((b.todaysEstimation?.status) === 0) || (Number(b.todaysEstimation?.status === undefined))) && order === 'desc' ? 4
            : Number(b.todaysEstimation?.status);

        if (order === 'asc' && aStatus && bStatus) {
          return (bStatus || 0) - (aStatus || 0);
        }
        return (aStatus || 0) - (bStatus || 0);
      }
      if (type === 'calculatedPercentage' && a.calculatedPercentage && b.calculatedPercentage) {
        if (order === 'asc') {
          return a.calculatedPercentage - b.calculatedPercentage;
        }
        return b.calculatedPercentage - a.calculatedPercentage;
      }
      return 0;
    });
    return sorted;
  };

  const handleSort = (type: CoworkerSort['type']) => {
    if (sort.type === type) {
      setSort({
        ...sort,
        order: sort.order === 'asc' ? 'desc' : 'asc',
      });
    } else {
      setSort({
        type,
        order: sort.order === 'asc' ? 'desc' : 'asc',
      });
    }
  };

  useEffect(() => {
    setCoworkers(sortCoworkers(coworkers));
  }, [sort.type, sort.order]);

  return (
    <View style={styles.tableHeader}>
      <View style={[styles.tableHeaderItem, { borderRightColor: colors.onBackground }]}>
        <TouchableRipple onPress={() => handleSort('firstName')}>
          <View style={styles.tableHeaderPressable}>
            <Text style={{ textTransform: 'uppercase', color: colors.onBackground }}>{screen.department.name}</Text>
            <Ionicons
              name={sort.type === 'firstName' && sort.order === 'desc' ? 'caret-up-sharp' : 'caret-down-sharp'}
              color={colors.onBackground}
            />
          </View>
        </TouchableRipple>
      </View>
      <View style={[styles.tableHeaderItem, { maxWidth: 120, borderRightColor: colors.onBackground }]}>
        <TouchableRipple onPress={() => handleSort('evaluations')}>
          <View style={styles.tableHeaderPressable}>
            <Text style={{ textTransform: 'uppercase', color: colors.onBackground }}>{screen.department.today}</Text>
            <Ionicons
              name={sort.type === 'evaluations' && sort.order === 'desc' ? 'caret-up-sharp' : 'caret-down-sharp'}
              color={colors.onBackground}
            />
          </View>
        </TouchableRipple>
      </View>
      <View style={[styles.tableHeaderItem, { borderRightColor: colors.onBackground, borderRightWidth: isTablet ? 1 : 0 }]}>
        <TouchableRipple onPress={() => handleSort('calculatedPercentage')}>
          <View style={styles.tableHeaderPressable}>
            <Text style={{ textTransform: 'uppercase', color: colors.onBackground }}>{screen.department.status}</Text>
            <Ionicons
              name={sort.type === 'calculatedPercentage' && sort.order === 'desc' ? 'caret-up-sharp' : 'caret-down-sharp'}
              color={colors.onBackground}
            />
          </View>
        </TouchableRipple>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  tableHeader: {
    backgroundColor: '#EEEAE6',
    flexDirection: 'row',
    alignItems: 'center',
    height: 60,
  },
  tableHeaderItem: {
    flex: 1,
    justifyContent: 'center',
    maxWidth: 240,
    borderRightWidth: 1,
  },
  tableHeaderPressable: {
    height: 60,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
  },
});

export default CoworkersTableHeader;
