import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import useMediaQueries from '../../../../hooks/useMediaQueries';
import useTranslation from '../../../../hooks/useTranslation';

type CompaniesHeaderProps = unknown;

const CompaniesHeader: React.FC<CompaniesHeaderProps> = () => {
  const { isTablet } = useMediaQueries();
  const { screen } = useTranslation();
  const itemSm = isTablet ? 150 : 75;
  const itemMd = isTablet ? 300 : 150;

  return (
    <View style={styles.container}>
      <Text numberOfLines={1} style={[styles.flex, { maxWidth: itemMd }]}>{screen.superAdmin.companies.title}</Text>
      <Text numberOfLines={2} style={styles.flex}>
        {screen.superAdmin.companies.endDate}
        {' '}
        / org.nr /
        {' '}
        {screen.superAdmin.companies.email.toLowerCase()}
        {' '}
        /
        {' '}
        {screen.superAdmin.companies.phone.toLowerCase()}
      </Text>
      <Text numberOfLines={1} style={[styles.flex, { maxWidth: itemSm }]}>Status</Text>
      <Text numberOfLines={1} style={[styles.flex, { maxWidth: itemSm, textAlign: 'right' }]}>{screen.superAdmin.manage}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#EEEAE6',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 16,
    paddingHorizontal: 20,
    gap: 8,
  },
  flex: {
    flex: 1,
    fontWeight: 'bold',
  },
});

export default CompaniesHeader;
