import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import {
  View, Text, StyleSheet, Platform,
} from 'react-native';
import { useTheme, Text as PaperText } from 'react-native-paper';
import { useMutation, useQuery } from '@apollo/client';
import type { UpdateMyUserMutation, UserEmailQuery } from '@types';
import { Ionicons } from '@expo/vector-icons';
import { TouchableOpacity } from 'react-native-gesture-handler';
import useTranslation from '../../hooks/useTranslation';
import AuthContext from '../../contexts/AuthContext';
import DesktopWrapper from '../../components/DesktopWrapper';
import StyledTextInput from '../../components/StyledTextInput';
import { getMyUserEmailQuery, updateMyUserMutation } from '../../queries';
import { regex } from '../../utils';
import FormErrorMessage from '../../components/FormErrorMessage';

const SettingsScreen: React.FC = () => {
  const { colors } = useTheme();
  const { data: userEmailData } = useQuery<UserEmailQuery>(getMyUserEmailQuery);
  const [updateMyUser, { loading: updateUserLoading, error: updateUserError }] = useMutation<UpdateMyUserMutation>(updateMyUserMutation);
  const { tokenGivenName, tokenSurname } = useContext(AuthContext);
  const { screen } = useTranslation({
    name: `${tokenGivenName.charAt(0).toUpperCase() + tokenGivenName.slice(1).toLowerCase()} ${tokenSurname.charAt(0).toUpperCase() + tokenSurname.slice(1).toLowerCase()}`,
  });

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [clientError, setClientError] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  const isWeb = Platform.OS === 'web';

  useEffect(() => {
    if (userEmailData && userEmailData.user.email) {
      setEmail(userEmailData.user.email);
    }
  }, [userEmailData]);

  const handleSetEditing = () => setIsEditing(true);

  const handleUpdate = useCallback(() => {
    if (updateUserLoading) return;
    if (!regex.email.test(email)) {
      setClientError('Ogiltig e-postadress');
      return;
    }

    updateMyUser({
      variables: {
        email,
      },
    }).then(() => {
      setIsEditing(false);
      setClientError('');
    }).catch((e) => {
      console.log(e);
    });
  }, [email, updateMyUser, updateUserLoading]);

  const handleKeyPress = useCallback((e: any) => {
    if (e.key === 'Enter') {
      handleUpdate();
    }
  }, [handleUpdate]);

  useEffect(() => {
    if (isWeb) {
      if (email === '') {
        window.removeEventListener('keypress', handleKeyPress);
      } else {
        window.addEventListener('keypress', handleKeyPress);
      }
      return () => {
        window.removeEventListener('keypress', handleKeyPress);
      };
    }

    return () => { };
  }, [email, handleKeyPress, isWeb]);

  return (
    <View style={[styles.container, { backgroundColor: colors.background }]}>
      <DesktopWrapper style={{ paddingHorizontal: 20 }}>
        <View style={styles.centered}>
          <PaperText style={styles.title}>
            {screen.settings.title}
          </PaperText>
          <Text style={{ fontSize: 16 }}>{screen.roleSelection.youAreLoggedInAs}</Text>
        </View>
        {isEditing
          ? (
            <StyledTextInput
              placeholder='Email'
              value={email}
              onChangeText={setEmail}
              keyboardType='email-address'
              autoComplete='email'
              autoFocus
            />
          )
          : (
            <View style={styles.row}>
              <Text style={{ color: '#555' }}>{email}</Text>
            </View>
          )}
        {clientError !== '' ? <FormErrorMessage message={clientError} /> : updateUserError && <FormErrorMessage message={updateUserError.message} />}
        <View style={styles.buttonRow}>
          <TouchableOpacity
            style={styles.button}
            onPress={isEditing ? handleUpdate : handleSetEditing}
          >
            <Text style={{ paddingTop: isEditing ? 0 : 2, marginLeft: 4 }}>{isEditing ? screen.evaluation.save : screen.settings.edit}</Text>
            {isEditing ? <Ionicons name='save-outline' size={20} /> : <Ionicons name='create-outline' size={20} />}
          </TouchableOpacity>
        </View>
      </DesktopWrapper>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingVertical: 70,
  },
  centered: {
    justifyContent: 'center',
    alignItems: 'center',
    gap: 20,
    marginBottom: 30,
  },
  title: {
    fontSize: 26,
  },
  row: {
    backgroundColor: '#fff',
    borderWidth: 1,
    borderRadius: 6,
    borderColor: '#ccc',
    padding: 16,
  },
  buttonRow: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
  button: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 6,
    borderWidth: 1,
    paddingHorizontal: 14,
    paddingVertical: 6,
    borderRadius: 20,
  },
});

export default SettingsScreen;
