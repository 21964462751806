import type {
  UpdateDepartmentMutation, DeleteDepartmentMutation, Department, AddDepartmentManagerMutationVariables, UpdateDepartmentManagerMutation, DeleteDepartmentManagerMutation,
} from '@types';
import React, { useCallback, useEffect, useState } from 'react';
import {
  View, Text, StyleSheet, TouchableHighlight, Platform,
} from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { Button, useTheme } from 'react-native-paper';
import { useMutation } from '@apollo/client';
import StyledTextInput from '../../../../components/StyledTextInput';
import useMediaQueries from '../../../../hooks/useMediaQueries';
import AddDepartmentManager from './AddDepartmentManager';
import Modal from '../../../../components/Modal';
import {
  companyQuery, deleteDepartmentManagerMutation, deleteDepartmentMutation, updateDepartmentManagerMutation, updateDepartmentMutation,
} from '../../../../queries';
import FormErrorMessage from '../../../../components/FormErrorMessage';
import useTranslation from '../../../../hooks/useTranslation';

type EditDepartmentProps = {
  department: Department;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  nestedMargin: number;
  companyId: string;
};

const EditDepartment: React.FC<EditDepartmentProps> = ({
  department, setIsEditing, nestedMargin, companyId,
}) => {
  const { colors } = useTheme();
  const { isTablet } = useMediaQueries();

  const [updateDepartment, { loading: updateDepartmentLoading }] = useMutation<UpdateDepartmentMutation>(updateDepartmentMutation);
  const [deleteDepartment, { loading: deleteDepartmentLoading, error: deleteDepartmentError }] = useMutation<DeleteDepartmentMutation>(deleteDepartmentMutation);
  const [updateDepartmentManager, { loading: updateDepartmentManagerLoading }] = useMutation<UpdateDepartmentManagerMutation>(updateDepartmentManagerMutation);
  const [deleteDepartmentManager, {
    loading: deleteDepartmentManagerLoading,
    error: deleteDepartmentManagerError,
  }] = useMutation<DeleteDepartmentManagerMutation>(deleteDepartmentManagerMutation);

  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [confirmDeleteManagerId, setConfirmDeleteManagerId] = useState<string>('');
  const [managerEdit, setManagerEdit] = useState('');
  const [departmentEdit, setDepartmentEdit] = useState<Department>(department);
  const [newDepartmentManager, setNewDepartmentManager] = useState<Partial<AddDepartmentManagerMutationVariables>>({
    firstName: '',
    lastName: '',
    personalNumber: '',
  });

  const { screen } = useTranslation({
    departmentToDelete: department.name,
    managerToDelete:
      `${department.managers?.find((manager) => manager.userId === confirmDeleteManagerId)?.firstName || ``
      } ${department.managers?.find((manager) => manager.userId === confirmDeleteManagerId)?.lastName || ''}`,
  });
  const isWeb = Platform.OS === 'web';

  const handleUpdateDepartment = useCallback(() => {
    if (updateDepartmentLoading || departmentEdit.name === '') return;

    updateDepartment({
      variables: {
        id: department._id,
        name: departmentEdit.name,
      },
    }).then(() => {
      departmentEdit.managers.forEach((manager) => {
        if (manager.firstName === '' || manager.lastName === '') return;
        void updateDepartmentManager({
          variables: {
            departmentId: department._id,
            userId: manager.userId,
            firstName: manager.firstName,
            lastName: manager.lastName,
          },
          refetchQueries: [{ query: companyQuery, variables: { companyId } }],
        });
      });
      setIsEditing(false);
    })
      .catch((err) => {
        console.log(err);
      });
  }, [updateDepartmentLoading, departmentEdit.name, departmentEdit.managers, updateDepartment, department._id, companyId, updateDepartmentManager, setIsEditing]);

  const handleShowConfirmDelete = () => {
    setIsConfirmDeleteOpen(true);
  };

  const hideConfirmDelete = () => {
    setIsConfirmDeleteOpen(false);
    setConfirmDeleteManagerId('');
  };

  const handleDeleteDepartment = () => {
    if (deleteDepartmentLoading) return;
    deleteDepartment({
      variables: { departmentId: department._id },
      refetchQueries: [{ query: companyQuery, variables: { companyId } }],
    }).then(() => {
      setIsConfirmDeleteOpen(false);
      setIsEditing(false);
    }).catch((err) => {
      console.log(err);
    });
  };

  const handleDeleteDepartmentManager = () => {
    if (deleteDepartmentManagerLoading) return;
    deleteDepartmentManager({
      variables: {
        departmentId: department._id,
        userId: confirmDeleteManagerId,
      },
      refetchQueries: [{ query: companyQuery, variables: { companyId } }],
    }).then(() => {
      setConfirmDeleteManagerId('');
      setIsEditing(false);
    }).catch((err) => {
      console.log(err);
    });
  };

  const handleKeyPress = useCallback((e: any) => {
    if (e.key === 'Enter') {
      handleUpdateDepartment();
    }
  }, [handleUpdateDepartment]);

  useEffect(() => {
    if (!isWeb) return;
    // Save on enter press if new manager is not being added
    if (!departmentEdit.name || newDepartmentManager.firstName !== '' || newDepartmentManager.lastName !== '' || newDepartmentManager.personalNumber !== '') {
      document.removeEventListener('keypress', handleKeyPress);
    } else {
      document.addEventListener('keypress', handleKeyPress);
    }
    return () => {
      document.removeEventListener('keypress', handleKeyPress);
    };
  }, [departmentEdit, handleKeyPress, isWeb, newDepartmentManager.firstName, newDepartmentManager.lastName, newDepartmentManager.personalNumber]);

  const handleEditManager = (managerId: string) => {
    if (managerEdit === managerId) {
      setManagerEdit('');
      return;
    }
    setManagerEdit(managerId);
  };

  return (
    <>
      <View style={{ paddingLeft: nestedMargin }}>
        <View style={[styles.departmentEdit, {
          flexDirection: isTablet ? 'row' : 'column',
          alignItems: isTablet ? 'center' : undefined,
        }]}
        >
          <View style={{ flex: isTablet ? 1 : undefined }}>
            <Text style={styles.label}>{screen.superAdmin.company.title}</Text>
            <View style={{ flexDirection: 'row', gap: 8 }}>
              <StyledTextInput
                value={departmentEdit.name}
                onChangeText={(text) => setDepartmentEdit({ ...departmentEdit, name: text })}
                inputStyle={{ padding: 12 }}
                containerStyle={{ flex: 1 }}
              />
            </View>
          </View>
        </View>

        <View style={[styles.departmentEdit, {
          flexDirection: isTablet ? 'row' : 'column',
          alignItems: isTablet ? 'center' : undefined,
        }]}
        >
          <View style={{ flex: isTablet ? 1 : undefined }}>
            <Text style={styles.label}>{screen.superAdmin.company.admin}</Text>

            {/* LIST MANAGERS */}
            {departmentEdit?.managers && departmentEdit?.managers.map((manager) => (
              <View key={manager.userId} style={styles.departmentManager}>
                {managerEdit === manager.userId ? (
                  <>
                    <StyledTextInput
                      value={manager.firstName}
                      onChangeText={(text) => setDepartmentEdit({
                        ...departmentEdit,
                        managers: departmentEdit.managers.map((m) => (m.userId === manager.userId ? { ...m, firstName: text } : m)),
                      })}
                      containerStyle={{ flex: 1 }}
                      inputStyle={[styles.departmentManagerInput]}
                      autoFocus
                    />
                    <StyledTextInput
                      value={manager.lastName}
                      onChangeText={(text) => setDepartmentEdit({
                        ...departmentEdit,
                        managers: departmentEdit.managers.map((m) => (m.userId === manager.userId ? { ...m, lastName: text } : m)),
                      })}
                      containerStyle={{ flex: 1 }}
                      inputStyle={[styles.departmentManagerInput]}
                    />
                  </>
                ) : (
                  <>
                    <Text style={styles.departmentManagerInput}>{manager.firstName}</Text>
                    <Text style={styles.departmentManagerInput}>{manager.lastName}</Text>
                  </>
                )}

                <TouchableHighlight
                  onPress={() => handleEditManager(manager.userId as string)}
                  style={[styles.iconContainer, { backgroundColor: colors.background, marginLeft: 12 }]}
                  underlayColor='#E5E5E5'
                >
                  <Ionicons name='create-outline' size={18} color='#000' />
                </TouchableHighlight>
                <TouchableHighlight
                  style={[styles.iconContainer, { backgroundColor: colors.background }]}
                  onPress={() => setConfirmDeleteManagerId(manager.userId as string)}
                  underlayColor='#E5E5E5'
                >
                  <Ionicons name='close-sharp' size={18} color='#000' />
                </TouchableHighlight>
              </View>
            ))}

            {/* ADD MANAGER */}
            <AddDepartmentManager
              departmentId={department._id}
              companyId={companyId}
              setDepartmentEdit={setDepartmentEdit}
              newDepartmentManager={newDepartmentManager}
              setNewDepartmentManager={setNewDepartmentManager}
            />
          </View>

        </View>
        <View style={[styles.departmentEdit, styles.flexBetween]}>
          <View style={styles.row}>
            <Button
              mode='contained'
              onPress={() => setIsEditing(false)}
              buttonColor={colors.secondary}
              textColor={colors.onSecondary}
              uppercase={false}
              contentStyle={{ paddingHorizontal: 10, height: 41 }}
            >
              {screen.superAdmin.cancel}
            </Button>
            <Button
              mode='contained'
              onPress={handleUpdateDepartment}
              buttonColor={colors.primary}
              textColor={colors.onPrimary}
              uppercase={false}
              contentStyle={{ paddingHorizontal: 10, height: 41 }}
              loading={updateDepartmentLoading || updateDepartmentManagerLoading}
            >
              {screen.superAdmin.save}
            </Button>
          </View>
          <TouchableHighlight onPress={handleShowConfirmDelete} style={[styles.iconContainer, { backgroundColor: colors.background }]} underlayColor='#E5E5E5'>
            <Ionicons name='trash-outline' size={18} color='#000' />
          </TouchableHighlight>
        </View>
      </View>

      {confirmDeleteManagerId !== '' && (
        <Modal
          isClickOutsideAllowed
          title={screen.superAdmin.company.deleteManager}
          text={screen.superAdmin.company.deleteManagerText}
          isVisible={confirmDeleteManagerId !== ''}
          setIsVisible={() => setConfirmDeleteManagerId('')}
          content={(
            <View style={styles.deleteButtonRow}>
              {deleteDepartmentManagerError && <FormErrorMessage message={deleteDepartmentManagerError.message} />}
              <Button
                mode='contained'
                onPress={hideConfirmDelete}
                buttonColor={colors.secondary}
                textColor={colors.onSecondary}
                uppercase={false}
                contentStyle={{ paddingHorizontal: 10, height: 41 }}
              >
                {screen.superAdmin.cancel}
              </Button>
              <Button
                mode='contained'
                onPress={handleDeleteDepartmentManager}
                buttonColor={colors.primary}
                textColor={colors.onPrimary}
                uppercase={false}
                contentStyle={{ paddingHorizontal: 10, height: 41 }}
                loading={deleteDepartmentManagerLoading}
              >
                {screen.superAdmin.delete}
              </Button>
            </View>
          )}
        />
      )}

      {isConfirmDeleteOpen && (
        <Modal
          isClickOutsideAllowed
          title={screen.superAdmin.company.deleteDepartment}
          text={screen.superAdmin.company.deleteText}
          isVisible={isConfirmDeleteOpen}
          setIsVisible={setIsConfirmDeleteOpen}
          content={(
            <View style={styles.deleteButtonRow}>
              {deleteDepartmentError && <FormErrorMessage message={deleteDepartmentError.message} />}
              <Button
                mode='contained'
                onPress={hideConfirmDelete}
                buttonColor={colors.secondary}
                textColor={colors.onSecondary}
                uppercase={false}
                contentStyle={{ paddingHorizontal: 10, height: 41 }}
              >
                {screen.superAdmin.cancel}
              </Button>
              <Button
                mode='contained'
                onPress={handleDeleteDepartment}
                buttonColor={colors.primary}
                textColor={colors.onPrimary}
                uppercase={false}
                contentStyle={{ paddingHorizontal: 10, height: 41 }}
              >
                {screen.superAdmin.delete}
              </Button>
            </View>
          )}
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  departmentEdit: {
    paddingTop: 10,
    paddingRight: 20,
    paddingBottom: 16,
    gap: 8,
  },
  label: {
    fontWeight: '500',
    marginBottom: 6,
  },
  departmentManager: {
    flexDirection: 'row',
    gap: 8,
    marginBottom: 16,
    alignItems: 'center',
  },
  departmentManagerInput: {
    flex: 1,
    padding: 12,
    marginVertical: 4,
    borderBottomWidth: 1,
    borderColor: '#ccc',
  },
  iconContainer: {
    height: 30,
    width: 30,
    borderRadius: 15,
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  deleteButtonRow: {
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 10,
  },
  flexBetween: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  row: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
});

export default EditDepartment;
