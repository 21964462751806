import React from 'react';
import { View, StyleSheet } from 'react-native';

const CustomModalHeader = () => (
  <View style={styles.container}>
    <View style={styles.lineStyle} />
  </View>
);

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
  },
  lineStyle: {
    height: 2,
    backgroundColor: '#707070',
    borderRadius: 1,
    opacity: 0.5,
    width: 100,
  },
});

export default CustomModalHeader;
