import React, { useContext } from 'react';
import { View, StyleSheet } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { useQuery } from '@apollo/client';
import type { EstimationsByUserIdQuery } from '@types';
import { estimationsByUserIdQuery } from '../../../queries';
import EditCoworker from './EditCoworker';
import CoworkerContext from '../../../contexts/CoworkerContext';
import CoworkerSummary from './CoworkerSummary';
import UserSettingsContext from '../../../contexts/UserSettingsContext';

const CoworkerWeb = () => {
  const { coworkerEdit } = useContext(CoworkerContext);
  const { currentDepartment } = useContext(UserSettingsContext);

  const { data: evaluationsData } = useQuery<EstimationsByUserIdQuery>(estimationsByUserIdQuery, {
    variables: {
      userId: coworkerEdit?.userId,
      departmentId: currentDepartment,
    },
  });

  return (
    <View style={[styles.container]}>
      <ScrollView style={{ flex: 1, paddingHorizontal: 20 }}>
        <EditCoworker />
        <CoworkerSummary evaluationsData={evaluationsData} />
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    gap: 20,
    paddingTop: 50,
    paddingBottom: 20,
  },

});

export default CoworkerWeb;
