import type {
  EstimateCoworkerMutation, DepartmentCoworker, EvaluateArgs, EvaluationStatusBasisHeader,
} from '@types';
import React, { useContext, useState } from 'react';
import {
  View, StyleSheet, Pressable, Text,
} from 'react-native';
import { Text as PaperText } from 'react-native-paper';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { useMutation } from '@apollo/client';
import useTranslation from '../../../hooks/useTranslation';
import Slide from './Slide';
import {
  estimateCoworkerMutation, listCoworkersQuery, estimationsByUserIdQuery, estimationsByDepartmentIdQuery,
} from '../../../queries';
import UserSettingsContext from '../../../contexts/UserSettingsContext';
import ArendraIcon from '../../../components/ArendraIcon';
import useMediaQueries from '../../../hooks/useMediaQueries';
import ActivelySkipped from './ActivelySkipped';
import StatusBasis from './StatusBasis';

type CoworkerEvaluateProps = {
  coworker: DepartmentCoworker;
  nextStep: () => void;
  setStatusBasisStepHeader: React.Dispatch<React.SetStateAction<EvaluationStatusBasisHeader | undefined>>;
};

const CoworkerEvaluate: React.FC<CoworkerEvaluateProps> = ({
  coworker, nextStep, setStatusBasisStepHeader,
}) => {
  const { screen } = useTranslation();
  const { isTablet } = useMediaQueries();
  const { currentDepartment } = useContext(UserSettingsContext);

  const [estimateCoworker, { loading: estimateLoading }] = useMutation<EstimateCoworkerMutation>(estimateCoworkerMutation);

  const [showActivelySkippedStep, setShowActivelySkippedStep] = useState<boolean>(false);
  const [showStatusBasisStep, setShowStatusBasisStep] = useState<boolean>(false);

  const [localStatus, setLocalStatus] = useState<number | undefined>(coworker.todaysEstimation?.status || 0);

  const evaluate = ({ status, statusBasis, activelySkippedStatusBasis }: EvaluateArgs) => {
    if (estimateLoading) return;

    estimateCoworker({
      variables: {
        userId: coworker.userId,
        departmentId: currentDepartment,
        status,
        statusBasis,
        activelySkippedStatusBasis,
      },
      refetchQueries: [
        { query: listCoworkersQuery, variables: { departmentId: currentDepartment } },
        { query: estimationsByUserIdQuery, variables: { userId: coworker.userId, departmentId: currentDepartment } },
        { query: estimationsByDepartmentIdQuery, variables: { departmentId: currentDepartment } },
      ],
    }).then((res) => {
      console.log(res);
      hideExtraStep();
      setTimeout(() => {
        nextStep();
      }, 500);
    }).catch((err) => {
      console.log(err);
      setLocalStatus(coworker.todaysEstimation?.status || 0);
    });
  };

  const handleEvaluate = (status: number) => {
    setLocalStatus(status);

    // Kan ej skatta
    if (status === 0) {
      setStatusBasisStepHeader({
        title: screen.evaluation.activelySkipTitle,
        coworkerName: `${coworker.firstName} ${coworker.lastName}`,
        status,
      });
      setShowActivelySkippedStep(true);
      // Grön gubbe
    } else if (status === 1) {
      evaluate({ status });
      // Gul eller orange gubbe
    } else if (status === 2 || status === 3) {
      setStatusBasisStepHeader({
        title: screen.evaluation.statusBasisTitle,
        coworkerName: `${coworker.firstName} ${coworker.lastName}`,
        status,
      });
      setShowStatusBasisStep(true);
    }
  };

  const hideExtraStep = () => {
    setShowStatusBasisStep(false);
    setShowActivelySkippedStep(false);
    setStatusBasisStepHeader(undefined);
  };

  return (
    <Slide style={{
      paddingTop: showActivelySkippedStep || showStatusBasisStep ? 0 : 70,
      alignItems: showActivelySkippedStep || showStatusBasisStep ? 'stretch' : 'center',
    }}
    >
      {showActivelySkippedStep
        ? <ActivelySkipped evaluate={evaluate} localStatus={localStatus} coworker={coworker} /> : showStatusBasisStep
          ? <StatusBasis evaluate={evaluate} localStatus={localStatus} coworker={coworker} />
          : (
            <>
              <PaperText style={{ fontSize: 32 }} numberOfLines={1}>
                {coworker.firstName}
                {' '}
                {coworker.lastName}
              </PaperText>
              <View style={{ flex: 1, gap: 30 }}>
                <View style={[styles.evaluateRow, {
                  gap: isTablet ? 40 : 16,
                }]}
                >

                  <Pressable
                    onPress={() => handleEvaluate(1)}
                    style={[styles.evaluationStatusPressable, {
                      opacity: localStatus === 1 ? 1 : 0.5,
                    }]}
                  >
                    <ArendraIcon
                      name='icon-fungerar-val'
                      size={100}
                      style={{
                        borderRadius: 50,
                      }}
                    />
                  </Pressable>

                  <Pressable
                    onPress={() => handleEvaluate(2)}
                    style={[styles.evaluationStatusPressable, {
                      opacity: localStatus === 2 ? 1 : 0.5,
                    }]}
                  >
                    <ArendraIcon
                      name='icon-brister-delvis'
                      size={100}
                      style={{
                        borderRadius: 50,
                      }}
                    />
                  </Pressable>
                  <Pressable
                    onPress={() => handleEvaluate(3)}
                    style={[styles.evaluationStatusPressable, {
                      opacity: localStatus === 3 ? 1 : 0.5,
                    }]}
                  >
                    <ArendraIcon
                      name='icon-brister-patagligt'
                      size={100}
                      style={{
                        borderRadius: 50,
                      }}
                    />
                  </Pressable>
                </View>
                <Pressable style={styles.statusNone} onPress={() => handleEvaluate(0)}>
                  <TouchableOpacity activeOpacity={0.7} style={{ alignItems: 'center', flexDirection: 'row' }}>
                    <ArendraIcon name='icon-kan-ej-skatta' size={50} />
                    <Text style={styles.statusNoneText}>{screen.evaluation.activelySkip}</Text>
                  </TouchableOpacity>
                </Pressable>
              </View>
            </>
          )}
    </Slide>
  );
};

const styles = StyleSheet.create({
  evaluateRow: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
    marginVertical: 12,
  },
  evaluationStatusPressable: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 100,
    width: 100,
    borderRadius: 55,
    borderWidth: 4,
    borderColor: '#F6F4F2',
  },
  statusNone: {
    justifyContent: 'center',
    alignItems: 'center',
    gap: 2,
    alignSelf: 'center',
  },
  statusNoneText: {
    fontSize: 16,
    fontWeight: '500',
    color: 'grey',
  },

});

export default CoworkerEvaluate;
