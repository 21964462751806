import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import {
  View, StyleSheet, Text, Platform,
} from 'react-native';
import { Button as PaperButton, Text as PaperText, useTheme } from 'react-native-paper';
import { Ionicons } from '@expo/vector-icons';
import { useMutation } from '@apollo/client';
import type { RootStackParamList, DeleteDepartmentCoworkerMutation, UpdateDepartmentCoworkerMutation } from '@types';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { getLocales } from 'expo-localization';
import {
  deleteDepartmentCoworkerMutation, estimationsByDepartmentIdQuery, listCoworkersQuery, updateDepartmentCoworkerMutation,
} from '../../../queries';
import CoworkerContext from '../../../contexts/CoworkerContext';
import useTranslation from '../../../hooks/useTranslation';
import StyledTextInput from '../../../components/StyledTextInput';
import UserSettingsContext from '../../../contexts/UserSettingsContext';
import FormErrorMessage from '../../../components/FormErrorMessage';
import Modal from '../../../components/Modal';
import SlideOverContext from '../../../contexts/SlideOverContext';

const EditButton = ({
  isEditing, colors, onPress, isLoading,
}) => {
  const { screen } = useTranslation();
  // if (Platform.OS === 'android') {
  //   return (
  //     <PaperButton
  //       mode={isEditing ? 'contained' : 'outlined'}
  //       contentStyle={{ height: 40 }}
  //       onPress={onPress}
  //       uppercase={false}
  //       loading={isLoading}
  //     >
  //       <>
  //         <Text style={{}}>{isEditing ? screen.addCoworker.save : screen.addCoworker.edit}</Text>
  //         {!onPress && <Ionicons name={isEditing ? 'save-outline' : 'create-outline'} size={16} color='#000' />}
  //       </>

  //     </PaperButton>
  //   );
  // }
  const ch = (
    <>
      <Text
        style={{ color: isEditing ? colors.onSecondary : '#000' }}
      >
        {isEditing ? screen.addCoworker.save : screen.addCoworker.edit}
      </Text>
      {isEditing
        && (
          <Ionicons
            name={isEditing ? 'save-outline' : 'create-outline'}
            size={16}
            color={isEditing ? colors.onSecondary : '#000'}
          />
        )}
    </>
  );
  return (
    <PaperButton
      mode={isEditing ? 'contained' : 'outlined'}
      contentStyle={{ height: 40 }}
      onPress={onPress}
      uppercase={false}
      buttonColor={isEditing ? colors.secondary : 'white'}
      textColor={isEditing ? colors.onSecondary : '#000'}
      style={{
        borderRadius: 20, marginLeft: 'auto', borderColor: '#000',
      }}
      loading={isLoading}
    >

      {Platform.OS === 'android'
        ? (
          <>
            {ch}
          </>
        )
        : (
          <View style={styles.editButtonContainer}>
            {ch}
          </View>
        )}

    </PaperButton>
  );
};

const EditCoworker = () => {
  const { colors } = useTheme();
  const { navigate } = useNavigation<NavigationProp<RootStackParamList>>();
  const [updateCoworker, { loading: updateCoworkerLoading, error: updateCoworkerError }] = useMutation<UpdateDepartmentCoworkerMutation>(updateDepartmentCoworkerMutation);
  const [deleteCoworker, { loading: deleteCoworkerLoading, error: deleteCoworkerError }] = useMutation<DeleteDepartmentCoworkerMutation>(deleteDepartmentCoworkerMutation);

  const { currentDepartment } = useContext(UserSettingsContext);
  const { setSlideOver } = useContext(SlideOverContext);
  const { coworkerEdit, setCoworkerEdit } = useContext(CoworkerContext);
  const [clientError, setClientError] = useState<string>('');

  const { screen, clientErrors } = useTranslation({
    coworkerToDelete: `${coworkerEdit?.firstName || ''} ${coworkerEdit?.lastName || ''}`,
  });

  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const isWeb = Platform.OS === 'web';
  const isLocaleEn = getLocales()[0].languageCode === 'en';

  const isFieldsCorrect = useCallback(() => {
    if (coworkerEdit?.firstName === '' || coworkerEdit?.lastName === '' || coworkerEdit?.salary === '') {
      setClientError(clientErrors.fillOutAllFields);
      return false;
    }

    // Check if salary is a number
    if (Number.isNaN(Number(coworkerEdit?.salary)) || Number(coworkerEdit?.salary) === 0) {
      setClientError(clientErrors.salaryNotANumber);
      return false;
    }

    return true;
  }, [clientErrors.fillOutAllFields, clientErrors.salaryNotANumber, coworkerEdit?.firstName, coworkerEdit?.lastName, coworkerEdit?.salary]);

  const handleUpdateCoworker = useCallback(() => {
    if (!isEditing) {
      setIsEditing(true);
    } else if (isFieldsCorrect()) {
      updateCoworker({
        variables: {
          userId: coworkerEdit?.userId,
          departmentId: currentDepartment,
          // salary: coworkerEdit?.salary,
          salary: Number(coworkerEdit?.salary) || 0,
          workPercentage: coworkerEdit?.workPercentage,
          firstName: coworkerEdit?.firstName,
          lastName: coworkerEdit?.lastName,
        },
        refetchQueries: [{ query: listCoworkersQuery, variables: { departmentId: currentDepartment } }],
      }).then((res) => {
        if (res.data?.updateDepartmentCoworker) {
          console.log('coworker updated');
          setClientError('');
        }
      }).catch((err) => {
        console.log(err);
      });
      setIsEditing(false);
    }
    // eslint-disable-next-line max-len
  }, [coworkerEdit?.firstName, coworkerEdit?.lastName, coworkerEdit?.salary, coworkerEdit?.userId, coworkerEdit?.workPercentage, currentDepartment, isEditing, isFieldsCorrect, updateCoworker]);

  const handleKeyPress = useCallback((e) => {
    if (e.key === 'Enter' && isEditing) {
      handleUpdateCoworker();
    }
  }, [handleUpdateCoworker, isEditing]);

  useEffect(() => {
    if (isWeb) {
      window.addEventListener('keypress', handleKeyPress);

      return () => {
        window.removeEventListener('keypress', handleKeyPress);
      };
    }

    return () => { };
  }, [handleKeyPress, isWeb]);

  const handleShowConfirmDelete = () => {
    setIsConfirmDeleteOpen(true);
  };

  const hideConfirmDelete = () => {
    setIsConfirmDeleteOpen(false);
  };

  const handleDeleteCoworker = () => {
    if (deleteCoworkerLoading) return;
    deleteCoworker({
      variables: {
        userId: coworkerEdit?.userId,
        departmentId: currentDepartment,
      },
      refetchQueries: [
        { query: listCoworkersQuery, variables: { departmentId: currentDepartment } },
        { query: estimationsByDepartmentIdQuery, variables: { departmentId: currentDepartment } },
      ],
    }).then(() => {
      setIsConfirmDeleteOpen(false);
      setCoworkerEdit(undefined);

      if (isWeb) {
        setSlideOver(undefined);
      } else {
        navigate('department', { departmentId: currentDepartment || '' });
      }
    }).catch((err) => {
      console.log(err);
    });
  };

  return (
    <>
      <View style={styles.container}>
        <EditButton
          isEditing={isEditing}
          colors={colors}
          onPress={handleUpdateCoworker}
          isLoading={updateCoworkerLoading}
        />
        <View style={[styles.details, { backgroundColor: colors.background, gap: isEditing ? 10 : 30 }]}>
          {!isEditing && (
            <View style={styles.topRow}>
              <PaperText style={styles.title}>
                {coworkerEdit?.firstName}
                {' '}
                {coworkerEdit?.lastName}
              </PaperText>
            </View>
          )}
          {isEditing && (
            <View style={{
              gap: 10,
              borderBottomWidth: 1,
              borderBottomColor: '#E5E5E5',
              paddingBottom: 10,
            }}
            >
              <View style={styles.row}>
                <Text style={[styles.label, {
                  minWidth: isLocaleEn ? 200 : 150,
                }]}
                >
                  {screen.editCoworker.firstName}
                </Text>
                <StyledTextInput
                  placeholder={screen.addCoworker.firstName}
                  value={coworkerEdit?.firstName}
                  onChangeText={(text) => coworkerEdit && setCoworkerEdit({ ...coworkerEdit, firstName: text })}
                  containerStyle={{ flex: 1, minWidth: 120 }}
                  inputStyle={{ padding: 10 }}
                />
              </View>
              <View style={styles.row}>
                <Text style={[styles.label, {
                  minWidth: isLocaleEn ? 200 : 150,
                }]}
                >
                  {screen.editCoworker.lastName}
                </Text>
                <StyledTextInput
                  placeholder={screen.addCoworker.lastName}
                  value={coworkerEdit?.lastName}
                  onChangeText={(text) => coworkerEdit && setCoworkerEdit({ ...coworkerEdit, lastName: text })}
                  containerStyle={{ flex: 1, minWidth: 120 }}
                  inputStyle={{ padding: 10 }}
                />
              </View>
            </View>
          )}
          <View style={styles.row}>
            <Text style={[styles.label, {
              minWidth: isLocaleEn ? 200 : 150,
            }]}
            >
              {screen.editCoworker.workPercentage}
            </Text>
            {isEditing
              ? (
                <StyledTextInput
                  placeholder={screen.addCoworker.workPercentage}
                  value={coworkerEdit?.workPercentage.toString()}
                  onChangeText={(text) => coworkerEdit && setCoworkerEdit({ ...coworkerEdit, workPercentage: Number(text) || 0 })}
                  placeholderRight='%'
                  containerStyle={{ flex: 1, minWidth: 120 }}
                  inputStyle={{ padding: 10 }}
                />
              )
              : (
                <Text style={{ fontSize: 16, minWidth: 120 }}>
                  {coworkerEdit?.workPercentage}
                  %
                </Text>
              )}

          </View>
          <View style={styles.row}>
            <Text style={[styles.label, {
              minWidth: isLocaleEn ? 200 : 150,
            }]}
            >
              {screen.editCoworker.salary}
            </Text>
            {isEditing
              ? (
                <StyledTextInput
                  placeholder={screen.addCoworker.salary}
                  value={coworkerEdit?.salary.toString()}
                  onChangeText={(text) => coworkerEdit && setCoworkerEdit({ ...coworkerEdit, salary: text })}
                  placeholderRight={screen.coworker.SEK}
                  containerStyle={{ flex: 1, minWidth: 120 }}
                  inputStyle={{ padding: 10 }}
                />
              )
              : (
                <Text style={{ fontSize: 16, minWidth: 120 }}>
                  {coworkerEdit?.salary}
                  {' '}
                  {screen.coworker.SEK}
                </Text>
              )}
          </View>
          {clientError
            ? <FormErrorMessage style={{ marginLeft: 'auto' }} message={clientError} />
            : updateCoworkerError && (
              <FormErrorMessage
                style={{ marginLeft: 'auto' }}
                message={updateCoworkerError.message}
              />
            )}
          {isEditing && (
            <PaperButton
              mode='outlined'
              contentStyle={{ height: 40 }}
              onPress={handleShowConfirmDelete}
              uppercase={false}
              buttonColor='transparent'
              textColor='#000'
              style={styles.deleteButton}
            >
              {screen.coworker.deleteCoworker}
            </PaperButton>
          )}
        </View>

      </View>

      {isConfirmDeleteOpen && (
        <Modal
          isClickOutsideAllowed
          title={screen.editCoworker.deleteCoworkerTitle}
          text={screen.editCoworker.deleteCoworkerText}
          isVisible={isConfirmDeleteOpen}
          setIsVisible={setIsConfirmDeleteOpen}
          content={(
            <View style={styles.deleteButtonRow}>
              {deleteCoworkerError && <FormErrorMessage message={deleteCoworkerError.message} />}
              <PaperButton
                mode='contained'
                buttonColor={colors.secondary}
                onPress={hideConfirmDelete}
                uppercase={false}
                contentStyle={{ paddingHorizontal: 16 }}
              >
                {screen.superAdmin.cancel}
              </PaperButton>
              <PaperButton
                mode='contained'
                buttonColor={colors.primary}
                textColor={colors.onPrimary}
                onPress={handleDeleteCoworker}
                uppercase={false}
                contentStyle={{ paddingHorizontal: 16 }}
                loading={deleteCoworkerLoading}
              >
                {screen.superAdmin.delete}
              </PaperButton>
            </View>
          )}
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    gap: 20,
  },
  topRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#E5E5E5',
    paddingBottom: 10,
  },
  title: {
    fontSize: 24,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 16,
    flexWrap: 'wrap',
  },

  details: {
    borderRadius: 10,
    paddingHorizontal: 12,
    paddingTop: 20,
    paddingBottom: 40,
  },
  label: {
    textTransform: 'uppercase',
    color: '#5F5E5E',
  },
  editButtonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 6,
    marginBottom: -5,
  },
  deleteButton: {
    marginRight: 'auto',
    borderRadius: 20,
    marginTop: 20,
    borderColor: '#000',
  },
  deleteButtonRow: {
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 10,
  },
});

export default EditCoworker;
