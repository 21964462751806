import React from 'react';
import {
  Platform, Image, StyleProp, ImageStyle,
} from 'react-native';
import { SvgProps } from 'react-native-svg';

interface IconProps {
  source: React.FC<SvgProps>;
  width?: number | string;
  height?: number | string;
  style?: StyleProp<ImageStyle>;
}

const Icon: React.FC<IconProps> = ({
  source: IconComponent, width, height, style, ...props
}) => {
  const isWeb = Platform.OS === 'web';

  if (isWeb) {
    return <Image source={IconComponent} style={[{ width, height }, style]} resizeMode='contain' {...props} />;
  }

  return <IconComponent width={width} height={height} style={style} {...props} />;
};

export default Icon;
