import type { IconName, OnboardingStep } from '@types';
import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Text as PaperText, useTheme } from 'react-native-paper';
import ArendraIcon from '../../../components/ArendraIcon';

type StepContentProps = {
  step: OnboardingStep;
};

const StepContent: React.FC<StepContentProps> = ({ step }) => {
  const { colors } = useTheme();
  const iconName: IconName = step.id === 1 ? 'icon-user-add' : step.id === 2 ? 'icon-spin-arendra' : 'icon-check';
  return (
    <>
      <ArendraIcon name={iconName} size={75} color={colors.primary} style={styles.icon} />
      <View style={styles.stepText}>
        <PaperText style={styles.stepTitle}>{step.title}</PaperText>
        <Text style={styles.stepDescription}>{step.description}</Text>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  icon: {
    marginBottom: -30,
  },
  stepText: {
    gap: 16,
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 320,
    minHeight: 190,
  },
  stepTitle: {
    fontSize: 26,
    textAlign: 'center',
  },
  stepDescription: {
    fontSize: 16,
    textAlign: 'center',
  },
});

export default StepContent;
