import React, { useContext, useEffect, useState } from 'react';
import { Platform, Text, StyleSheet } from 'react-native';
import type {
  DepartmentCoworker, DepartmentCoworkerWithCalculatedPercentage, EstimationsByUserIdQuery,
} from '@types';
import { useLazyQuery } from '@apollo/client';
import Box from '../../../components/Box';
import CoworkerRow from './CoworkerRow';
import CoworkersTableHeader from './CoworkersTableHeader';
import useTranslation from '../../../hooks/useTranslation';
import { estimationsByUserIdQuery } from '../../../queries';
import UserSettingsContext from '../../../contexts/UserSettingsContext';

type CoworkersTableProps = {
  coworkers: DepartmentCoworker[];
};

const CoworkersTable: React.FC<CoworkersTableProps> = ({ coworkers }) => {
  const isWeb = Platform.OS === 'web';
  const { screen } = useTranslation();
  const { currentDepartment } = useContext(UserSettingsContext);
  const [getEvaluations] = useLazyQuery<EstimationsByUserIdQuery>(estimationsByUserIdQuery, { fetchPolicy: 'no-cache' });

  const [coworkersWithCalculatedPercentage, setCoworkersWithCalculatedPercentage] = useState<DepartmentCoworkerWithCalculatedPercentage[]>(coworkers);

  const getEvaluationData = async (userId: string) => {
    const evaluationsData = await getEvaluations({
      variables: {
        userId,
        departmentId: currentDepartment,
      },
    });
    return evaluationsData.data?.estimationsByUserId.calculatedPercentage || 0;
  };

  // Get evaluations for each coworker and update calculatedPercentage
  useEffect(() => {
    const coworkersCalculatedPercentage = coworkers.map(async (coworker) => {
      const calculatedPercentage = await getEvaluationData(coworker.userId as string);
      return {
        ...coworker,
        calculatedPercentage,
      };
    });

    void Promise.all(coworkersCalculatedPercentage).then((coworkersWithPercentage) => {
      setCoworkersWithCalculatedPercentage(coworkersWithPercentage);
    });
  }, [coworkers]);

  return (
    <Box style={{ marginHorizontal: 20 }}>
      {isWeb && <CoworkersTableHeader coworkers={coworkersWithCalculatedPercentage} setCoworkers={setCoworkersWithCalculatedPercentage} />}
      {coworkersWithCalculatedPercentage.map((coworker, index) => (
        <CoworkerRow
          key={coworker.userId}
          coworker={coworker}
          isBorderBottom={index !== coworkers.length - 1}
        />
      ))}
      {coworkers.length === 0 && <Text style={styles.emptyText}>{screen.department.noCoworkers}</Text>}
    </Box>
  );
};

const styles = StyleSheet.create({
  emptyText: {
    textAlign: 'center',
    paddingVertical: 30,
    opacity: 0.5,
    fontStyle: 'italic',
  },
});

export default CoworkersTable;
