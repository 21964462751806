import React, { useContext } from 'react';
import { View, StyleSheet } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import type { EstimationsByUserIdQuery } from '@types';
import { useQuery } from '@apollo/client';
import { estimationsByUserIdQuery } from '../../queries';
import EditCoworker from './components/EditCoworker';
import CoworkerSummary from './components/CoworkerSummary';
import UserSettingsContext from '../../contexts/UserSettingsContext';

const CoworkerScreen = ({ route }) => {
  const { id } = route.params;
  const { currentDepartment } = useContext(UserSettingsContext);

  const { data: evaluationsData } = useQuery<EstimationsByUserIdQuery>(estimationsByUserIdQuery, {
    variables: {
      userId: id,
      departmentId: currentDepartment,
    },
  });

  return (
    <View style={[styles.container]}>
      <ScrollView style={{ flex: 1, paddingHorizontal: 20 }}>
        <EditCoworker />
        <CoworkerSummary evaluationsData={evaluationsData} />
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    gap: 20,
    paddingTop: 50,
    paddingBottom: 20,
    backgroundColor: '#fff',
  },
});

export default CoworkerScreen;
