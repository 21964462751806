import React, { useContext, useState } from 'react';
import {
  View, Text, StyleSheet, useWindowDimensions, Pressable, Platform,
} from 'react-native';
import { Text as Title, useTheme } from 'react-native-paper';
import { Entypo } from '@expo/vector-icons';
import { useNavigation, NavigationProp } from '@react-navigation/native';
import type { RootStackParamList, EstimationsByDepartmentIdQuery } from '@types';
import { useQuery } from '@apollo/client';
import StatusGreyScreen from '../../Status/StatusGreyScreen';
import useMediaQueries from '../../../hooks/useMediaQueries';
import useTranslation from '../../../hooks/useTranslation';
import Box from '../../../components/Box';
import StatusIcon from '../../../components/StatusIcon';
import Modal from '../../../components/Modal';
import StatusYellowScreen from '../../Status/StatusYellowScreen';
import StatusOrangeScreen from '../../Status/StatusOrangeScreen';
import { estimationsByDepartmentIdQuery } from '../../../queries';
import UserSettingsContext from '../../../contexts/UserSettingsContext';

const ActInfo = () => {
  const { screen: { act } } = useTranslation();
  const { colors } = useTheme();
  const { height } = useWindowDimensions();
  const { isDesktop } = useMediaQueries();

  const { currentDepartment } = useContext(UserSettingsContext);

  const { data: evaluationsData } = useQuery<EstimationsByDepartmentIdQuery>(estimationsByDepartmentIdQuery, {
    variables: {
      departmentId: currentDepartment,
    },
  });

  const [webModal, setWebModal] = useState<'statusGrey' | 'statusYellow' | 'statusOrange' | undefined>();

  const { navigate } = useNavigation<NavigationProp<RootStackParamList>>();
  const isWeb = Platform.OS === 'web';

  const navigateToStatus = (status: 'statusGrey' | 'statusYellow' | 'statusOrange') => {
    if (isWeb) {
      setWebModal(status);
    } else {
      navigate(status);
    }
  };

  return (
    <>
      <View style={[styles.infoContainer, { backgroundColor: colors.background },
        isDesktop && {
          flex: 1,
          minHeight: height,
          paddingHorizontal: 40,
        }]}
      >
        <Title style={styles.title}>{act.actTitle}</Title>
        <Text style={styles.subTitle}>{act.actSubTitle}</Text>
        {(evaluationsData?.estimationsByDepartmentId.coworkersWithoutStatus && evaluationsData?.estimationsByDepartmentId.coworkersWithoutStatus.length > 0) ? (
          <Pressable onPress={() => navigateToStatus('statusGrey')}>
            <Box style={styles.statusItem}>
              <View style={[styles.row]}>
                <StatusIcon status={0} width={60} height={60} iconWidth={50} />
                <View>
                  <Text style={styles.infoTitle}>
                    {evaluationsData?.estimationsByDepartmentId.coworkersWithoutStatus.length ?? 0}
                    {' '}
                    {act.statusGrayTitle}
                  </Text>
                  <Text>{act.statusGrayText}</Text>
                </View>
              </View>
              <Entypo name='chevron-thin-right' size={20} color='black' />
            </Box>
          </Pressable>
        ) : null}
        {(evaluationsData?.estimationsByDepartmentId.amountOfYellow && evaluationsData?.estimationsByDepartmentId.amountOfYellow > 0) ? (
          <Pressable onPress={() => navigateToStatus('statusYellow')}>
            <Box style={styles.statusItem}>
              <View style={styles.row}>
                <StatusIcon status={2} width={60} height={60} style={{ width: 30, borderWidth: 1 }} iconWidth={50} />
                <View>
                  <Text style={styles.infoTitle}>
                    {evaluationsData?.estimationsByDepartmentId.amountOfYellow ?? 0}
                    {' '}
                    {act.statusYellowTitle}
                  </Text>
                  <Text>{act.statusYellowText}</Text>
                  <Text>{act.statusYellowText2}</Text>
                </View>
              </View>
              <Entypo name='chevron-thin-right' size={20} color='black' />
            </Box>
          </Pressable>
        ) : null}
        {(evaluationsData?.estimationsByDepartmentId.amountOfOrange && evaluationsData?.estimationsByDepartmentId.amountOfOrange > 0) ? (
          <Pressable onPress={() => navigateToStatus('statusOrange')}>
            <Box style={styles.statusItem}>
              <View style={styles.row}>
                <StatusIcon status={3} width={60} height={60} style={{ width: 30, borderWidth: 1 }} iconWidth={50} />
                <View>
                  <Text style={styles.infoTitle}>
                    {evaluationsData?.estimationsByDepartmentId.amountOfOrange ?? 0}
                    {' '}
                    {act.statusRedTitle}
                  </Text>
                  <Text>{act.statusRedText}</Text>
                  <Text>{act.statusRedText2}</Text>
                </View>
              </View>
              <Entypo name='chevron-thin-right' size={20} color='black' />
            </Box>
          </Pressable>
        ) : null}
      </View>

      <Modal
        isVisible={webModal === 'statusGrey'}
        setIsVisible={() => setWebModal(undefined)}
        content={<StatusGreyScreen />}
        isClickOutsideAllowed
      />
      <Modal
        isVisible={webModal === 'statusYellow'}
        setIsVisible={() => setWebModal(undefined)}
        content={<StatusYellowScreen />}
        isClickOutsideAllowed
      />
      <Modal
        isVisible={webModal === 'statusOrange'}
        setIsVisible={() => setWebModal(undefined)}
        content={<StatusOrangeScreen />}
        isClickOutsideAllowed
      />
    </>

  );
};

const styles = StyleSheet.create({
  infoContainer: {
    paddingHorizontal: 20,
    paddingVertical: 50,
    gap: 16,
  },
  title: {
    fontSize: 26,
    textAlign: 'center',
  },
  subTitle: {
    marginBottom: 16,
    textAlign: 'center',
    marginTop: -5,
  },
  statusItem: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 8,
  },
  infoTitle: {
    fontWeight: '500',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 6,
  },
});

export default ActInfo;
