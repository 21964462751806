import React from 'react';
import {
  View, Text, StyleSheet, Platform,
} from 'react-native';
import { Text as PaperText, useTheme } from 'react-native-paper';
import useTranslation from '../../hooks/useTranslation';

const CostInfoScreen: React.FC = () => {
  const { colors } = useTheme();
  const { screen } = useTranslation();
  const isWeb = Platform.OS === 'web';

  return (
    <View style={[styles.container, {
      backgroundColor: colors.background,
      paddingVertical: isWeb ? 20 : 70,
      paddingHorizontal: isWeb ? 0 : 20,
    }]}
    >
      <View style={styles.centered}>
        <PaperText style={styles.title}>
          {screen.costInfo.title}
        </PaperText>
        <View style={{ gap: 10 }}>
          <Text style={styles.text}>{screen.costInfo.p1}</Text>
          <Text style={styles.text}>{screen.costInfo.p2}</Text>
          <Text style={styles.text}>{screen.costInfo.p3}</Text>
          <Text style={styles.text}>{screen.costInfo.p4}</Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  text: {
    fontSize: 16,
  },
  centered: {
    justifyContent: 'center',
    gap: 30,
    marginBottom: 30,
  },
  title: {
    fontSize: 26,
    textAlign: 'center',
  },
});

export default CostInfoScreen;
