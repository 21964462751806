import React from 'react';
import {
  View, Text, StyleSheet, Platform,
} from 'react-native';
import { Text as PaperText } from 'react-native-paper';
import { ScrollView } from 'react-native-gesture-handler';
import CheatSheetSection from './CheatSheetSection';
import useTranslation from '../../../hooks/useTranslation';

interface CheatSheetProps {
  width?: number;
}

const CheatSheet: React.FC<CheatSheetProps> = ({ width }) => {
  const { screen: { cheatSheet } } = useTranslation();
  const isWeb = Platform.OS === 'web';

  return (
    <ScrollView style={{ width, maxWidth: 500 }}>
      <View style={[styles.cheatSheetContent, { paddingVertical: isWeb ? 50 : 25 }]}>
        <View style={{ paddingHorizontal: 16 }}>
          <PaperText style={styles.title}>{cheatSheet.title}</PaperText>
          <Text style={styles.text}>{cheatSheet.firstDescription}</Text>
        </View>
        <Text style={[styles.text, { paddingHorizontal: 16, fontStyle: 'italic' }]}>
          {cheatSheet.secondDescription}
        </Text>
        {cheatSheet.sections.map((section) => (
          <CheatSheetSection
            key={section.title}
            title={section.title}
            list={section.list}
          />
        ))}
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  title: {
    fontSize: 26,
    textAlign: 'center',
    marginBottom: 16,
  },
  text: {
    textAlign: 'center',
    lineHeight: 22,
    marginBottom: 10,
  },
  cheatSheetContent: {
    gap: 10,
  },
});

export default CheatSheet;
