import React, { useRef, useState } from 'react';
import {
  View, StyleSheet, useWindowDimensions, Text,
} from 'react-native';
import type { OnboardingStep } from '@types';
import { Button, Text as PaperText, useTheme } from 'react-native-paper';
import Carousel, { ICarouselInstance } from 'react-native-reanimated-carousel';
import Pagination from '../../EvaluationScreen/components/Pagination';
import StepContent from './StepContent';
import useTranslation from '../../../hooks/useTranslation';

type OnboardingCarouselProps = {
  steps: OnboardingStep[];
  navigateOnContinue: () => Promise<void>;
};

const OnboardingCarousel: React.FC<OnboardingCarouselProps> = ({ steps, navigateOnContinue }) => {
  const { screen } = useTranslation();
  const { width } = useWindowDimensions();
  const { colors } = useTheme();
  const [activeSlide, setActiveSlide] = useState<number>(0);

  const carouselRef = useRef<ICarouselInstance>(null);

  const handleContinue = () => {
    if (activeSlide !== steps.length - 1) return;
    void navigateOnContinue();
  };

  const handleNextSlide = () => {
    if (activeSlide === steps.length - 1) return;
    carouselRef.current?.next();
  };

  return (
    <View style={styles.container}>
      <PaperText style={[styles.title, { color: colors.onPrimary }]}>{screen.onBoarding.title}</PaperText>
      <Text style={styles.infoTitle}>{screen.onBoarding.subTitle}</Text>
      <Carousel
        ref={carouselRef}
        height={390}
        width={width}
        data={steps}
        renderItem={({ item }) => (
          <View style={styles.stepItem}>
            <StepContent step={item} />
          </View>
        )}
        onSnapToItem={(index) => setActiveSlide(index)}
        loop={false}
        pagingEnabled
        mode='parallax'
        modeConfig={{
          parallaxScrollingScale: 0.85,
          parallaxScrollingOffset: 50,
        }}
        style={{ bottom: -20 }}
      />
      <Pagination isTutorial carouselRef={carouselRef} carouselWidth={width} steps={steps} activeSlide={activeSlide} />
      <Button
        mode='contained'
        contentStyle={styles.button}
        onPress={activeSlide === steps.length - 1 ? handleContinue : handleNextSlide}
        buttonColor='#fff'
        uppercase={false}
        style={{
          marginTop: 30,
        }}
      >
        {activeSlide === steps.length - 1 ? screen.onBoarding.continue : screen.onBoarding.next}
      </Button>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingVertical: 40,
  },
  title: {
    fontSize: 32,
    textAlign: 'center',
    marginBottom: 12,
  },
  infoTitle: {
    fontSize: 16,
    textAlign: 'center',
    color: '#fff',
    opacity: 0.9,
  },
  stepItem: {
    backgroundColor: '#fff',
    borderRadius: 20,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    height: 50,
    paddingHorizontal: 40,
  },
});

export default OnboardingCarousel;
