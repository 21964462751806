import React, { useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { useTheme } from 'react-native-paper';
import type { CompaniesQuery, Company } from '@types';
import { useQuery } from '@apollo/client';
import { ScrollView } from 'react-native-gesture-handler';
import DesktopWrapper from '../../components/DesktopWrapper';
import CompaniesHeader from './components/companies/CompaniesHeader';
import Box from '../../components/Box';
import CompanyTableItem from './components/companies/CompanyTableItem';
import { companiesQuery } from '../../queries';

const CompaniesScreen = () => {
  const { colors } = useTheme();

  const { data: companiesData } = useQuery<CompaniesQuery>(companiesQuery);
  const [companies, setCompanies] = useState<Company[]>([]);

  useEffect(() => {
    if (companiesData?.companies) {
      setCompanies(companiesData.companies as Company[]);
    }
  }, [companiesData]);

  return (
    <View style={[styles.container, { backgroundColor: colors.background }]}>
      <ScrollView style={styles.scrollView}>
        <DesktopWrapper style={{ padding: 20 }}>
          <Box>
            <CompaniesHeader />
            {companies.map((company, index) => (
              <CompanyTableItem
                key={company._id}
                index={index}
                companies={companies}
                company={company}
              />
            ))}
          </Box>
        </DesktopWrapper>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  scrollView: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
});

export default CompaniesScreen;
