import React from 'react';
import { StyleSheet, Text, ViewStyle } from 'react-native';
import { useTheme } from 'react-native-paper';

type FormErrorMessageProps = {
  message: string;
  style?: ViewStyle;
}

const FormErrorMessage: React.FC<FormErrorMessageProps> = ({ message, style }) => {
  const { colors } = useTheme();
  return (
    <Text style={[styles.formErrorMessage, { color: colors.error }, style]}>{message}</Text>
  );
};

export default FormErrorMessage;

const styles = StyleSheet.create({
  formErrorMessage: {
    fontSize: 12,
    marginTop: 4,
  },
});
