import React, { useEffect, useState } from 'react';
import { View, StyleSheet, TouchableHighlight } from 'react-native';
import { useTheme, Text as PaperText, Button } from 'react-native-paper';
import type {
  CompanyQuery, Company, Department, RootStackParamList,
} from '@types';
import { useQuery } from '@apollo/client';
import { Ionicons } from '@expo/vector-icons';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { ScrollView } from 'react-native-gesture-handler';
import { companyQuery } from '../../queries';
import DesktopWrapper from '../../components/DesktopWrapper';
import Box from '../../components/Box';
import DepartmentsHeader from './components/departments/DepartmentsHeader';
import DepartmentTableItem from './components/departments/DepartmentTableItem';
import AddDepartment from './components/departments/AddDepartment';
import useTranslation from '../../hooks/useTranslation';
import Modal from '../../components/Modal';
import ImportOrganisation from './components/companies/ImportOrganisation';

type CompanyScreenProps = {
  route: any
};

const CompanyScreen: React.FC<CompanyScreenProps> = ({ route }) => {
  const { colors } = useTheme();
  const { screen } = useTranslation();
  const { navigate } = useNavigation<NavigationProp<RootStackParamList>>();
  const { data: companyData, refetch: refetchCompany } = useQuery<CompanyQuery>(companyQuery, { variables: { companyId: route.params.id } });
  const [company, setCompany] = useState<Company>();
  const [isImportVisible, setIsImportVisible] = useState(false);

  const iconHeaderSize = 24;

  useEffect(() => {
    if (companyData?.company) {
      setCompany(companyData.company as Company);
      console.log(companyData.company);
    }
  }, [companyData]);

  const navigateToCompanies = () => {
    navigate('companies');
  };

  const handleImport = () => {
    setIsImportVisible(true);
  };

  const hideImport = () => {
    setIsImportVisible(false);
  };

  return (
    <>
      <View style={[styles.container, { backgroundColor: colors.background }]}>
        <ScrollView style={styles.scrollView}>
          <DesktopWrapper style={{ padding: 20 }}>
            <View style={styles.companyHeader}>
              <View style={styles.headerRow}>
                <View style={styles.companyTitle}>
                  <TouchableHighlight
                    onPress={navigateToCompanies}
                    style={{
                      height: 32, width: 32, borderRadius: 16, backgroundColor: colors.secondary, alignItems: 'center', justifyContent: 'center',
                    }}
                  >
                    <Ionicons name='arrow-back' size={16} color={colors.onSecondary} />
                  </TouchableHighlight>
                  <PaperText style={{ fontSize: 24 }}>{company?.name}</PaperText>
                </View>
                <Button
                  icon='file'
                  mode='contained'
                  buttonColor={colors.secondary}
                  textColor={colors.onSecondary}
                  onPress={handleImport}
                  uppercase={false}
                >
                  {screen.superAdmin.company.import}
                </Button>
              </View>

            </View>
            <Box>
              <DepartmentsHeader iconHeaderSize={iconHeaderSize} />
              {company?.departments && company.departments.map((department: Department, index) => (
                <DepartmentTableItem
                  key={department._id}
                  parentId={company._id}
                  companyId={company._id}
                  index={index + 1}
                  department={department}
                  iconHeaderSize={iconHeaderSize}
                />
              ))}
              {company?.departments && company?.departments.length === 0 && (
                <AddDepartment
                  title={screen.superAdmin.company.addDepartment}
                  departmentParentId={company?._id}
                  companyId={company?._id}
                  nestedMargin={20}
                />
              )}
            </Box>
          </DesktopWrapper>
        </ScrollView>
      </View>
      {isImportVisible && (
        <Modal
          isClickOutsideAllowed
          isVisible={isImportVisible}
          setIsVisible={setIsImportVisible}
          title={screen.superAdmin.company.importOrganization}
          content={<ImportOrganisation companyId={route.params.id} refetchCompany={refetchCompany} hideImport={hideImport} />}
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  scrollView: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  companyHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
  },
  headerRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 1,
  },
  companyTitle: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
    padding: 8,
    flex: 1,
  },
});

export default CompanyScreen;
