import React, { useContext } from 'react';
import {
  View, Text, StyleSheet,
} from 'react-native';
import { Text as PaperText, useTheme } from 'react-native-paper';
import { ScrollView } from 'react-native-gesture-handler';
import DesktopWrapper from '../../components/DesktopWrapper';
import AuthContext from '../../contexts/AuthContext';
import useTranslation from '../../hooks/useTranslation';
import SelectRole from '../SuperAdmin/components/SelectRole';

const RoleSelectionScreen = () => {
  const { tokenGivenName, tokenSurname } = useContext(AuthContext);
  const { colors } = useTheme();
  const { screen } = useTranslation({
    name: `${tokenGivenName.charAt(0).toUpperCase() + tokenGivenName.slice(1).toLowerCase()} ${tokenSurname.charAt(0).toUpperCase() + tokenSurname.slice(1).toLowerCase()}`,
  });
  return (
    <View style={[styles.container, { backgroundColor: colors.background }]}>
      <ScrollView style={styles.scrollView}>
        <DesktopWrapper style={styles.desktopWrapper}>
          <View style={styles.centered}>
            <PaperText style={styles.title}>
              {screen.roleSelection.title}
            </PaperText>
            <Text style={{ fontSize: 16 }}>{screen.roleSelection.youAreLoggedInAs}</Text>
          </View>
          <SelectRole />
        </DesktopWrapper>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  scrollView: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  desktopWrapper: {
    paddingHorizontal: 20,
    paddingVertical: 70,
  },
  centered: {
    justifyContent: 'center',
    alignItems: 'center',
    gap: 20,
    marginBottom: 30,
  },
  title: {
    fontSize: 26,
  },
});

export default RoleSelectionScreen;
