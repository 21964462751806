import React, { useState } from 'react';
import {
  View, Text, StyleSheet, TouchableHighlight,
} from 'react-native';
import { Button, useTheme } from 'react-native-paper';
import * as DocumentPicker from 'expo-document-picker';
import { Ionicons } from '@expo/vector-icons';
import { OperationVariables, ApolloQueryResult } from '@apollo/client';
import type { CompanyQuery } from '@types';
import { EXPO_PUBLIC_SERVER_URL } from '@env';
import useTranslation from '../../../../hooks/useTranslation';

type ImportOrganisationProps = {
  companyId: string;
  refetchCompany: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<CompanyQuery>>;
  hideImport: () => void;
};

const ImportOrganisation: React.FC<ImportOrganisationProps> = ({ companyId, refetchCompany, hideImport }) => {
  const { colors } = useTheme();
  const { screen } = useTranslation();
  const [isFileBeingPicked, setIsFileBeingPicked] = useState(false);
  const [file, setFile] = useState<DocumentPicker.DocumentResult>();
  const [isImportLoading, setIsImportLoading] = useState(false);

  const requestUrl = `${EXPO_PUBLIC_SERVER_URL}import`;

  const pickDocument = async () => {
    // There is a slight delay for the picker to open, so we show a loading indicator.
    // As it's not possible to know if user cancel the picker, we set a timeout to hide the loading indicator.
    setIsFileBeingPicked(true);
    setTimeout(() => {
      setIsFileBeingPicked(false);
    }, 3000);

    const result = await DocumentPicker.getDocumentAsync({});
    if (result.type === 'success') {
      console.log(result);
      setFile(result);
    }
  };

  const handlePickFile = () => {
    void pickDocument();
  };

  const importFile = async () => {
    if (!file || isImportLoading) return;

    setIsImportLoading(true);

    try {
      const fileBlob = await fetch(file.uri).then((response) => response.blob());

      const formData = new FormData();
      formData.append('file', fileBlob, file.name);
      formData.append('companyId', companyId);

      const response = await fetch(requestUrl, {
        method: 'POST',
        body: formData,
      });

      const responseJson = await response.json();
      console.log(responseJson);
    } catch (error) {
      console.error(error);
    } finally {
      setIsImportLoading(false);
      setFile(undefined);
      hideImport();
      void refetchCompany();
    }
  };

  const handleImport = () => {
    void importFile();
  };

  return (
    <View style={styles.container}>
      <Button
        onPress={handlePickFile}
        mode='contained'
        buttonColor={colors.secondary}
        textColor={colors.onSecondary}
        uppercase={false}
        loading={isFileBeingPicked}
      >
        {screen.superAdmin.company.chooseFile}
      </Button>

      {file && (
        <View style={styles.file}>
          <Ionicons name='document-outline' size={20} color={colors.secondary} />
          <Text>{file.name}</Text>
          <TouchableHighlight onPress={() => setFile(undefined)} style={[styles.fileRemove, { backgroundColor: colors.secondary }]}>
            <Ionicons name='close' size={14} color={colors.onSecondary} />
          </TouchableHighlight>
        </View>
      )}

      <Button
        onPress={handleImport}
        mode='contained'
        contentStyle={{ height: 46 }}
        style={styles.importButton}
        buttonColor={colors.primary}
        textColor={colors.onPrimary}
        uppercase={false}
        disabled={!file}
        loading={isImportLoading}
      >
        {screen.superAdmin.company.import}
      </Button>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
  },
  file: {
    marginTop: 20,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 2,
  },
  fileRemove: {
    height: 20,
    width: 20,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 4,
  },
  importButton: {
    marginTop: 20,
    width: 150,
  },
});

export default ImportOrganisation;
