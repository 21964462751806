import React from 'react';
import {
  Text, StyleSheet, useWindowDimensions, TouchableOpacity, Linking,
} from 'react-native';
import { useTheme, Text as PaperText } from 'react-native-paper';
import { ScrollView } from 'react-native-gesture-handler';
import { getLocales } from 'expo-localization';
import useTranslation from '../../hooks/useTranslation';
import useMediaQueries from '../../hooks/useMediaQueries';
import DesktopWrapper from '../../components/DesktopWrapper';

const AboutScreen: React.FC = () => {
  const { screen } = useTranslation();
  const { colors } = useTheme();
  const { height } = useWindowDimensions();
  const { isDesktop } = useMediaQueries();

  const locale = getLocales()[0].languageCode;

  const handleLinkPress = () => {
    void Linking.openURL('https://www.arendra.se');
  };

  return (
    <ScrollView style={[
      styles.infoContainer,
      { backgroundColor: colors.background },
      isDesktop && {
        flex: 1,
        minHeight: height,
      }]}
    >
      <DesktopWrapper style={{ justifyContent: 'center', padding: 20, marginBottom: 100 }}>
        <PaperText style={styles.title}>{screen.about.title}</PaperText>
        <Text style={styles.text}>{screen.about.p1}</Text>
        <Text style={styles.text}>{screen.about.p2}</Text>
        <Text style={styles.text}>{screen.about.p3}</Text>

        {locale === 'en' && <Text style={styles.text}>{screen.about.p4}</Text>}
        <TouchableOpacity style={[styles.linkStyle]} onPress={handleLinkPress}>
          <Text style={{ fontSize: 16, color: colors.primary, textDecorationLine: 'underline' }}>
            arendra.se
          </Text>
        </TouchableOpacity>
      </DesktopWrapper>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  infoContainer: {
    paddingVertical: 50,
    gap: 16,
  },
  title: {
    fontSize: 26,
    textAlign: 'center',
    marginBottom: 16,
  },
  text: {
    marginTop: 20,
    fontSize: 16,
  },
  linkStyle: {
    marginTop: 20,
    fontSize: 16,
  },
});

export default AboutScreen;
