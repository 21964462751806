import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { useTheme } from 'react-native-paper';
import useMediaQueries from '../../../../hooks/useMediaQueries';
import useTranslation from '../../../../hooks/useTranslation';
import ArendraIcon from '../../../../components/ArendraIcon';

type DepartmentsHeaderProps = {
  iconHeaderSize: number;
};

const DepartmentsHeader: React.FC<DepartmentsHeaderProps> = ({ iconHeaderSize }) => {
  const { isTablet } = useMediaQueries();
  const { screen } = useTranslation();
  const { colors } = useTheme();
  const itemSm = isTablet ? 150 : 75;
  const itemMd = isTablet ? 300 : 150;
  const iconContainerSize = iconHeaderSize || 24;
  const iconSize = iconContainerSize + 18;

  return (
    <View style={styles.container}>
      <Text numberOfLines={1} style={[styles.flex, { maxWidth: itemMd }]}>{screen.superAdmin.company.title}</Text>
      <View style={[styles.row, { maxWidth: itemSm }]}>
        <View style={[styles.evaluationIcon, { height: iconContainerSize, width: iconContainerSize }]}>
          <ArendraIcon
            name='icon-fungerar-val'
            size={iconSize}
          />
        </View>
        <View style={[styles.evaluationIcon, { height: iconContainerSize, width: iconContainerSize }]}>
          <ArendraIcon
            name='icon-brister-delvis'
            size={iconSize}
          />
        </View>
        <View style={[styles.evaluationIcon, { height: iconContainerSize, width: iconContainerSize }]}>
          <ArendraIcon
            name='icon-brister-patagligt'
            size={iconSize}
          />
        </View>
      </View>
      <Text numberOfLines={1} style={[styles.flex, { maxWidth: itemSm }]}>
        Status /
        {' '}
        {screen.superAdmin.company.cost}
      </Text>
      <Text numberOfLines={1} style={[styles.flex, { maxWidth: itemSm }]}>{screen.superAdmin.company.admin}</Text>
      <Text numberOfLines={1} style={[styles.flex, { maxWidth: itemSm, textAlign: 'right' }]}>{screen.superAdmin.manage}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#EEEAE6',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 16,
    paddingHorizontal: 20,
    gap: 8,
  },
  flex: {
    flex: 1,
    fontWeight: 'bold',
  },
  row: {
    flex: 1,
    flexDirection: 'row',
    gap: 4,
  },
  evaluationIcon: {
    // height: 24,
    // width: 24,
    borderRadius: 12,
    // borderColor: '#000',
    // borderWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default DepartmentsHeader;
