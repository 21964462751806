import React, { useContext } from 'react';
import {
  View, Text, StyleSheet, Platform, ScrollView,
} from 'react-native';
import { Text as PaperText, useTheme } from 'react-native-paper';
import { useQuery } from '@apollo/client';
import type { EstimationsByDepartmentIdQuery } from '@types';
import ListItem from '../../components/ListItem';
import StatusIcon from '../../components/StatusIcon';
import useTranslation from '../../hooks/useTranslation';
import { estimationsByDepartmentIdQuery } from '../../queries';
import UserSettingsContext from '../../contexts/UserSettingsContext';

const StatusOrangeScreen = () => {
  const { colors } = useTheme();
  const { screen } = useTranslation();
  const isWeb = Platform.OS === 'web';

  const { currentDepartment } = useContext(UserSettingsContext);

  const { data: evaluationsData } = useQuery<EstimationsByDepartmentIdQuery>(estimationsByDepartmentIdQuery, {
    variables: {
      departmentId: currentDepartment,
    },
  });

  return (
    <View style={{ backgroundColor: colors.background, paddingVertical: isWeb ? 20 : 70 }}>
      <ScrollView style={{ paddingHorizontal: isWeb ? 0 : 20 }}>
        <View style={styles.centered}>
          <PaperText style={styles.title}>
            {evaluationsData?.estimationsByDepartmentId.amountOfOrange ?? 0}
            {' '}
            {screen.status.orangeTitle}
          </PaperText>
          <View style={{ gap: 10 }}>
            {evaluationsData?.estimationsByDepartmentId.coworkersWithOrangeStatus?.map((coworker) => (
              <ListItem key={coworker.userId} style={styles.listItem}>
                <Text>
                  {coworker.userFirstName}
                  {' '}
                  {coworker.userLastName}
                </Text>
                <StatusIcon isWithText status={3} height={40} width={40} />
              </ListItem>
            ))}
          </View>
          <View style={{ gap: 20 }}>
            <View style={{ gap: 6 }}>
              <Text style={[styles.text, { fontWeight: '500' }]}>{screen.status.orangeWhatDoesItMean}</Text>
              <Text style={styles.text}>
                {screen.aboutArendraModel.statusOrange}
              </Text>
            </View>
          </View>
        </View>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  centered: {
    justifyContent: 'center',
    gap: 30,
    marginBottom: 30,
  },
  title: {
    fontSize: 26,
    textAlign: 'center',
  },
  listItem: {
    borderRadius: 10,
    paddingVertical: 10,
  },
  text: {
    fontSize: 16,
  },
});

export default StatusOrangeScreen;
