import React from 'react';
import { View, StyleSheet, useWindowDimensions } from 'react-native';
import Svg, { G, Path } from 'react-native-svg';

type OnboardingWebStepProps = {
  children: React.ReactNode;
  isLeftShapeHidden?: boolean;
  isRightShapeHidden?: boolean;
};

const OnboardingWebStep: React.FC<OnboardingWebStepProps> = ({ children, isLeftShapeHidden, isRightShapeHidden }) => {
  const { width } = useWindowDimensions();
  return (
    <View style={[styles.container, {
      transform: [{ scale: width < 1230 ? 0.85 : 1 }],
      marginLeft: width < 1230 ? -80 : -20,
    }]}
    >
      <Svg width={426.002} height={514.002}>
        <G fill='#fff' data-name='Group 2032'>
          {!isRightShapeHidden && <Path d='m426.002 244.002-35 35v-70Z' data-name='Polygon 36' />}
          {!isLeftShapeHidden
            ? (
              <Path
                d='M376.002 514.002h-360a16 16 0 0 1-16-16V279.497l37-36.054-37-36.053V16.002a16.006 16.006 0 0 1 16-16h360a16 16 0 0 1 16 16v482a16 16 0 0 1-16 16Z'
                data-name='Subtraction 2'
              />
            )
            : (
              <Path
                d='M376.002 514.002h-360a16 16 0 0 1-16-16V279.497l0-36.054 0-36.053V16.002a16.006 16.006 0 0 1 16-16h360a16 16 0 0 1 16 16v482a16 16 0 0 1-16 16Z'
                data-name='Subtraction 2'
              />
            )}
        </G>
      </Svg>
      <View style={styles.content}>
        {children}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    zIndex: 10,
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: -30,
    // gap: 30,
  },
});

export default OnboardingWebStep;
