import React from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import { useTheme } from 'react-native-paper';
import AddCoworker from './components/AddCoworker';

const AddCoworkerScreen = () => {
  const { colors } = useTheme();
  const isWeb = Platform.OS === 'web';
  return (
    <View style={[styles.container, { backgroundColor: isWeb ? '#fff' : colors.background }]}>
      <AddCoworker />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingVertical: 30,
  },
});

export default AddCoworkerScreen;
