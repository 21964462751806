import * as Linking from 'expo-linking';
import * as Notifications from 'expo-notifications';

/**
 If you are using universal links, you need to add your domain to the prefixes as well:

const linking = {
  prefixes: [Linking.createURL('/'), 'https://app.example.com'],
};

 */

const urlPrefix = Linking.createURL('/');

const config = {
  initialRouteName: 'login',
  screens: {
    department: 'department',
    login: 'login',
    logout: 'logout',
    departments: 'departments',
    editcoworker: 'editcoworker',
    roleSelection: 'roleSelection',
    onBoarding: 'onBoarding',
    act: 'act',
    polling: 'polling',
  },
};

const linkingConfig = {
  prefixes: [
    'http://localhost:3000',
    'http://192.168.1.199:3000',
    urlPrefix,
  ], // This option is only needed for iOS and Android
  config,
  /**
     * Custom function to convert the state object to a valid URL (advanced).
     * Used for creating links for navigation, primarily useful on Web.
     */
  // getInitialURL: async () => {},
  subscribe(listener) {
    /**
     * HANDLE WHAT HAPPENS WHEN CLICKING ON A NOTIFICATION THAT BRINGS YOU INTO THE APP.
     */
    const onReceiveURL = ({ url }: { url: string }) => listener(url);

    // Listen to incoming links from deep linking
    Linking.addEventListener('url', onReceiveURL);

    // Listen to expo push notifications
    const subscription = Notifications.addNotificationResponseReceivedListener((response) => {
      // console.log('response: ', response);
      /**
       * This data will contain whatevery json structure we want it to.
       * We could make types and depending on type
       */
      const { type } = response.notification.request.content.data;
      // Any custom logic to see whether the URL needs to be handled
      // ...
      console.log('type: ', type);
      const url = `/${type}`;

      console.log('test');

      const appURL = Linking.makeUrl('/');
      const actual = `${appURL}errands`;

      // console.log(`${k}profile`);
      void Linking.openURL(actual); //
      // navigation.navigate('errands');
      listener(url);

      // Let React Navigation handle the URL
      // listener(url);
    });

    return () => {
      // Clean up the event listeners
      // Linking.removeEventListener('url', onReceiveURL);
      subscription.remove();
    };
  },
};

export default linkingConfig;
