import React, {
  useState, useEffect, useMemo, useCallback, ReactNode,
} from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
// import equal from 'deep-equal';
// import type { User } from '@types';
import { parseJwt } from '../utils';

const defaultAuthToken = '';

const AsyncKeys = {
  authToken: 'AUTH_TOKEN',
};

type ContextType = {
  token: string,
  isContextLoaded: boolean,
  // user: User | null,
  tokenGivenName: string,
  tokenSurname: string,
  devicePushToken: string,

  setDevicePushToken: (token: string) => void,
  setToken: (token: string) => void,
  logout: () => void,
  isTokenValid: () => boolean,
}

/**
 * @description These functions should not be used by anyone else but the another context that sets these states.
 * We have these states and functions here to be able to use them everywhere.
 */
const AuthContext = React.createContext<ContextType>({
  token: defaultAuthToken,
  isContextLoaded: false,
  // user: null,
  tokenGivenName: '',
  tokenSurname: '',
  devicePushToken: '',

  setDevicePushToken: () => { },
  setToken: () => { },
  logout: () => { },
  isTokenValid: () => false,
});

interface ProviderProps {
  children: ReactNode;
}

export const AuthContextProvider: React.FC<ProviderProps> = ({ children }) => {
  const [token, setInternalToken] = useState<string>(defaultAuthToken);
  const [isContextLoaded, setIsContextLoaded] = useState<boolean>(false);
  const [tokenExpiration, setTokenExpiration] = useState<number>(0);
  const [tokenGivenName, setTokenGivenName] = useState<string>('');
  const [tokenSurname, setTokenSurname] = useState<string>('');
  const [tokenAcceptedTerms, setTokenAcceptedTerms] = useState<number | null>(null);
  const [devicePushToken, setDevicePushToken] = useState<string>('');

  const resetLocalState = useCallback(() => {
    setTokenExpiration(0);
    setTokenGivenName('');
    setTokenSurname('');
    setTokenAcceptedTerms(null);
  }, []);

  const isTokenValid = useCallback(() => {
    if (isContextLoaded && tokenExpiration) {
      const now = Date.now() / 1000;
      if (tokenExpiration > now && tokenAcceptedTerms) {
        // console.log(`token is valid until ${new Date(tokenExpiration * 1000)}`);
        return true;
      }
      console.log(`token EXPIRED ALREADY: ${new Date(tokenExpiration * 1000)}`);
      /** If the token expired more than a year ago we don't actually have a correct token */
      resetLocalState();
      return false;
    }
    return false;
  }, [tokenExpiration, tokenAcceptedTerms, resetLocalState, isContextLoaded]);

  const handleExpiration = useCallback((theNewToken: string) => {
    const {
      exp,
      _id,
      iat,
      givenName,
      surname,
      acceptedTermsVersion,
    } = parseJwt(theNewToken);

    if (givenName) setTokenGivenName(givenName);
    if (surname) setTokenSurname(surname);

    setTokenAcceptedTerms(acceptedTermsVersion);
    setTokenExpiration(exp);
  }, []);

  useEffect(() => {
    /* FIRST INITIALIZATION */
    const initValues = async () => {
      const tokenAsync = await AsyncStorage.getItem(AsyncKeys.authToken);
      if (tokenAsync) {
        handleExpiration(tokenAsync);
        setInternalToken(tokenAsync || defaultAuthToken);
      }

      setIsContextLoaded(true);
    };
    void initValues();
  }, [setInternalToken, handleExpiration]);

  /**
   * @description update token and LoggedIn status
   */
  const setToken = useCallback(async (value: string) => {
    if (isContextLoaded) {
      if (value !== token) {
        if (value === null || value === undefined) {
        }
        // console.log('setting token to', value);

        handleExpiration(value);

        setInternalToken(value);
        await AsyncStorage.setItem(AsyncKeys.authToken, value);
      }
    }
  }, [token, setInternalToken, isContextLoaded, handleExpiration]);

  useEffect(() => {
    if (isContextLoaded) {
      void setToken(token);
    }
  }, [token, isContextLoaded, setToken]);

  const logout = useCallback(async () => {
    if (isContextLoaded) {
      await setToken('');
      resetLocalState();
    }
  }, [setToken, isContextLoaded, resetLocalState]);

  const state = useMemo(() => ({
    token, isContextLoaded, devicePushToken, setDevicePushToken, logout, setToken, isTokenValid, tokenGivenName, tokenSurname,
  }), [token, isContextLoaded, devicePushToken, setDevicePushToken, logout, setToken, isTokenValid, tokenGivenName, tokenSurname]);

  return (
    <AuthContext.Provider value={state}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
