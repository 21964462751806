import React from 'react';
import { View, StyleSheet, useWindowDimensions } from 'react-native';
import { Button, Text as PaperText, useTheme } from 'react-native-paper';
import { ScrollView } from 'react-native-gesture-handler';
import type { OnboardingStep } from '@types';
import useTranslation from '../../../hooks/useTranslation';
import OnboardingWebStep from './OnboardingWebStep';
import StepContent from './StepContent';

type OnboardingWebProps = {
  steps: OnboardingStep[];
  navigateOnContinue: () => Promise<void>;
};

const OnboardingWeb: React.FC<OnboardingWebProps> = ({ steps, navigateOnContinue }) => {
  const { screen } = useTranslation();
  const { colors } = useTheme();
  const { width, height } = useWindowDimensions();

  const handleContinue = () => {
    void navigateOnContinue();
  };

  return (
    <ScrollView>
      <View style={[styles.content, { minHeight: height }]}>
        <PaperText style={[styles.title, { color: colors.onPrimary }]}>{screen.onBoarding.title}</PaperText>
        <View style={[styles.steps, { marginLeft: width < 1230 ? 110 : 60 }]}>
          {steps.map((step, index) => (
            <OnboardingWebStep key={step.id} isLeftShapeHidden={index === 0} isRightShapeHidden={index === steps.length - 1}>
              <StepContent step={step} />
            </OnboardingWebStep>
          ))}
        </View>
        <Button
          mode='contained'
          contentStyle={{ height: 50, paddingHorizontal: 40 }}
          onPress={handleContinue}
          buttonColor='#fff'
          uppercase={false}
        >
          {screen.onBoarding.continue}
        </Button>
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  content: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingVertical: 40,
  },
  title: {
    fontSize: 36,
  },
  steps: {
    flexDirection: 'row',
    marginVertical: 50,
  },

});

export default OnboardingWeb;
