import { useMutation } from '@apollo/client';
import type { AddDepartmentManagerMutation, AddDepartmentManagerMutationVariables, Department } from '@types';
import React, { useCallback, useEffect, useState } from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import { Button, useTheme } from 'react-native-paper';
import { addDepartmentManagerMutation, companyQuery } from '../../../../queries';
import useMediaQueries from '../../../../hooks/useMediaQueries';
import StyledTextInput from '../../../../components/StyledTextInput';
import FormErrorMessage from '../../../../components/FormErrorMessage';
import useTranslation from '../../../../hooks/useTranslation';

type AddDepartmentManagerProps = {
  departmentId: string;
  companyId: string;
  setDepartmentEdit: React.Dispatch<React.SetStateAction<Department>>;
  newDepartmentManager: Partial<AddDepartmentManagerMutationVariables>;
  setNewDepartmentManager: React.Dispatch<React.SetStateAction<Partial<AddDepartmentManagerMutationVariables>>>;
};

const AddDepartmentManager: React.FC<AddDepartmentManagerProps> = ({
  departmentId, companyId, setDepartmentEdit, newDepartmentManager, setNewDepartmentManager,
}) => {
  const { colors } = useTheme();
  const { screen, clientErrors } = useTranslation();
  const { isTablet } = useMediaQueries();

  const [addDepartmentManager, {
    loading: addDepartmentManagerLoading,
    error: addDepartmentManagerError,
  }] = useMutation<AddDepartmentManagerMutation>(addDepartmentManagerMutation);

  const [clientError, setClientError] = useState<string>('');

  const isWeb = Platform.OS === 'web';

  const isFieldsCorrect = useCallback(() => {
    if (newDepartmentManager.firstName === '' || newDepartmentManager.lastName === '' || newDepartmentManager.personalNumber === '') {
      setClientError(clientErrors.fillOutAllFields);
      return false;
    }

    return true;
  }, [newDepartmentManager.firstName, newDepartmentManager.lastName, newDepartmentManager.personalNumber]);

  const handleAddDepartmentManager = useCallback(() => {
    if (addDepartmentManagerLoading) return;
    if (isFieldsCorrect()) {
      addDepartmentManager({
        variables: {
          firstName: newDepartmentManager.firstName,
          lastName: newDepartmentManager.lastName,
          personalNumber: newDepartmentManager.personalNumber,
          departmentId,
        },
        refetchQueries: [{ query: companyQuery, variables: { id: companyId } }],
      }).then((res) => {
        if (res.data?.addDepartmentManager) {
          const newManagers = [...res.data.addDepartmentManager.managers];
          setDepartmentEdit((prevState) => ({
            ...prevState,
            managers: newManagers,
          }));
          setNewDepartmentManager({
            firstName: '',
            lastName: '',
            personalNumber: '',
          });
        }
      }).catch((err) => {
        console.log(err);
      });
    }
  }, [addDepartmentManager, addDepartmentManagerLoading, departmentId, isFieldsCorrect, newDepartmentManager.firstName, newDepartmentManager.lastName, newDepartmentManager.personalNumber]);

  const handleKeyPress = useCallback((e: any) => {
    if (e.key === 'Enter') {
      handleAddDepartmentManager();
    }
  }, [handleAddDepartmentManager]);

  useEffect(() => {
    if (!isWeb) return;
    // Add department manager on enter press
    if (newDepartmentManager.firstName === '' || newDepartmentManager.lastName === '' || newDepartmentManager.personalNumber === '') {
      document.removeEventListener('keypress', handleKeyPress);
    } else {
      document.addEventListener('keypress', handleKeyPress);
    }
    return () => {
      document.removeEventListener('keypress', handleKeyPress);
    };
  }, [handleKeyPress, isWeb, newDepartmentManager.firstName, newDepartmentManager.lastName, newDepartmentManager.personalNumber]);

  return (
    <View>
      <View style={[styles.container, { flexDirection: isTablet ? 'row' : 'column' }]}>
        <StyledTextInput
          value={newDepartmentManager.personalNumber}
          onChangeText={(text) => setNewDepartmentManager({ ...newDepartmentManager, personalNumber: text })}
          containerStyle={{ flex: 1, maxWidth: 170 }}
          inputStyle={{ padding: 12 }}
          placeholder={screen.superAdmin.company.personalNumberFormat}
        />
        <StyledTextInput
          value={newDepartmentManager.firstName}
          onChangeText={(text) => setNewDepartmentManager({ ...newDepartmentManager, firstName: text })}
          containerStyle={{ flex: 1 }}
          inputStyle={{ padding: 12 }}
          placeholder={screen.superAdmin.company.firstName}
        />
        <StyledTextInput
          value={newDepartmentManager.lastName}
          onChangeText={(text) => setNewDepartmentManager({ ...newDepartmentManager, lastName: text })}
          containerStyle={{ flex: 1 }}
          inputStyle={{ padding: 12 }}
          placeholder={screen.superAdmin.company.lastName}
        />
        <Button
          mode='contained'
          onPress={handleAddDepartmentManager}
          loading={addDepartmentManagerLoading}
          style={{ marginRight: 'auto' }}
          uppercase={false}
          buttonColor={colors.secondary}
          textColor={colors.onSecondary}
          contentStyle={{ paddingHorizontal: 10, height: 41 }}
        >
          {screen.superAdmin.add}
        </Button>
      </View>
      <View style={styles.error}>
        {clientError !== '' ? <FormErrorMessage message={clientError} /> : addDepartmentManagerError && <FormErrorMessage message={addDepartmentManagerError.message} />}
      </View>
    </View>

  );
};

const styles = StyleSheet.create({
  container: {
    gap: 8,
  },
  error: {
    marginTop: 8,
  },
});

export default AddDepartmentManager;
