import React from 'react';
import {
  View, StyleSheet, ViewStyle,
} from 'react-native';

type BoxProps = {
  children: React.ReactNode;
  style?: ViewStyle | ViewStyle[];
};

const Box: React.FC<BoxProps> = ({ children, style }) => (
  <View style={[styles.box, style]}>
    {children}
  </View>
);

const styles = StyleSheet.create({
  box: {
    borderRadius: 12,
    overflow: 'hidden',
    backgroundColor: '#fff',
  },
});

export default Box;
