import React from 'react';
import {
  Text, StyleSheet, TextStyle, View,
} from 'react-native';
import { useTheme } from 'react-native-paper';
import type { IconName } from '@types';

type ArendraIconProps = {
  name: IconName;
  size?: number;
  style?: TextStyle;
  color?: string;
};

const ArendraIcon: React.FC<ArendraIconProps> = ({
  name, size, style, color,
}) => {
  const { colors } = useTheme();
  const defaultSize = 48;

  switch (name) {
    case 'icon-fungerar-val':
      return (
        <Text style={[styles.icon, { fontSize: (size ?? defaultSize) * 0.9, color: color ?? '#6CC24A', height: (size ?? defaultSize) * 0.9 }, style]}>D</Text>
      );
    case 'icon-brister-delvis':
      return (
        <Text style={[styles.icon, { fontSize: (size ?? defaultSize) * 0.92, color: color ?? '#FAC003', height: (size ?? defaultSize) * 0.92 }, style]}>H</Text>
      );
    case 'icon-brister-patagligt':
      return (
        <Text style={[styles.icon, { fontSize: (size ?? defaultSize) * 0.9, color: color ?? '#E87722', height: (size ?? defaultSize) * 0.9 }, style]}>K</Text>
      );
    case 'icon-kan-ej-skatta':
      return (
        <View style={{ padding: 9.5 }}>
          <View style={[styles.kanEjSkattaBg, {
            width: (size ?? defaultSize) * 0.5, height: (size ?? defaultSize) * 0.5, borderRadius: (size ?? defaultSize) / 2,
          }]}
          >
            <Text style={[styles.icon, {
              fontSize: (size ?? defaultSize) * 0.8, color: color ?? '#fff', height: (size ?? defaultSize) * 0.8, textTransform: 'lowercase',
            }, style]}
            >
              b
            </Text>
          </View>
        </View>
      );
    case 'icon-tumme-ner':
      return (
        <Text style={[styles.icon, { fontSize: size ?? defaultSize, color: color ?? '#222222', height: size ?? defaultSize }, style]}>B</Text>
      );
    case 'icon-mynt':
      return (
        <Text style={[styles.icon, {
          fontSize: size ?? defaultSize, color: color ?? '#736250', height: size ?? defaultSize, textTransform: 'lowercase',
        }, style]}
        >
          f
        </Text>
      );
    case 'icon-kontakt':
      return (
        <Text style={[styles.icon, {
          fontSize: size ?? defaultSize, color: color ?? colors.primary, height: size ?? defaultSize, textTransform: 'lowercase',
        }, style]}
        >
          c
        </Text>
      );
    case 'icon-skatta':
      return (
        <Text style={[styles.icon, { fontSize: size ?? defaultSize, color: color ?? colors.primary, height: size ?? defaultSize }, style]}>A</Text>
      );
    case 'icon-utropstecken':
      return (
        <Text style={[styles.icon, { fontSize: size ?? defaultSize, color: color ?? '#E87722', height: size ?? defaultSize }, style]}>C</Text>
      );
    case 'icon-warning':
      return (
        <Text style={[styles.icon, { fontSize: (size ?? defaultSize) * 0.85, color: color ?? '#DD3333', height: (size ?? defaultSize) * 0.85 }, style]}>J</Text>
      );
    case 'icon-faller':
      return (
        <Text style={[styles.icon, { fontSize: (size ?? defaultSize) * 0.8, color: color ?? '#191A1A', height: (size ?? defaultSize) * 0.8 }, style]}>G</Text>
      );
    case 'icon-temp':
      return (
        <Text style={[styles.icon, { fontSize: (size ?? defaultSize) * 0.8, color: color ?? '#DD3333', height: (size ?? defaultSize) * 0.8 }, style]}>T</Text>
      );
    case 'icon-arrow':
      return (
        <Text style={[styles.icon, { fontSize: (size ?? defaultSize), color: color ?? '#191A1A', height: (size ?? defaultSize) }, style]}>K</Text>
      );
    case 'icon-graph':
      return (
        <Text style={[styles.icon, {
          fontSize: (size ?? defaultSize), color: color ?? '#191A1A', height: (size ?? defaultSize), textTransform: 'lowercase',
        }, style]}
        >
          g
        </Text>
      );
    case 'icon-check':
      return (
        <Text style={[styles.icon, { fontSize: (size ?? defaultSize), color: color ?? colors.primary, height: (size ?? defaultSize) }, style]}>C</Text>
      );
    case 'icon-spin-arendra':
      return (
        <Text style={[styles.icon, { fontSize: (size ?? defaultSize), color: color ?? colors.primary, height: (size ?? defaultSize) }, style]}>S</Text>
      );
    case 'icon-user-add':
      return (
        <Text style={[styles.icon, { fontSize: (size ?? defaultSize), color: color ?? colors.primary, height: (size ?? defaultSize) }, style]}>U</Text>
      );
    default:
      return (
        <Text style={[styles.icon, { fontSize: size ?? defaultSize }, style]}>{name}</Text>
      );
  }
};

const styles = StyleSheet.create({
  icon: {
    fontFamily: 'ArendraIcons',
    aspectRatio: 1,
  },
  kanEjSkattaBg: {
    backgroundColor: '#A6A6A6',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default ArendraIcon;
