import React, {
  useContext, useEffect, useState,
} from 'react';
import {
  View, StyleSheet, useWindowDimensions, Platform, Pressable, LayoutChangeEvent,
} from 'react-native';
import { useTheme } from 'react-native-paper';
import { Ionicons } from '@expo/vector-icons';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import type { RootStackParamList } from '@types';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { SafeAreaView } from 'react-native-safe-area-context';
import useMediaQueries from '../../hooks/useMediaQueries';
import SlideInView from '../../components/SlideInView';
import CheatSheet from './components/CheatSheet';
import EvaluationStepper from './components/EvaluationStepper';
import SlideOver from '../../components/SlideOver';
import EvaluationContext from '../../contexts/EvaluationContext';
import WebCoworkerList from './components/WebCoworkerList';

const EvaluationScreen = () => {
  const { width } = useWindowDimensions();
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  const { colors } = useTheme();
  const { isDesktop } = useMediaQueries();
  const { carouselRef } = useContext(EvaluationContext);

  const isWeb = Platform.OS === 'web';
  const [carouselWidth, setCarouselWidth] = useState(width);
  const [isWebCheatSheet, setIsWebCheatSheet] = useState(false);
  const cheatSheetWidth = isWeb ? width * 0.9 : width;

  const [isCoworkerList, setIsCoworkerList] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);

  // Initial scroll to active slide
  useEffect(() => {
    if (carouselRef?.current) {
      carouselRef.current.scrollTo({ index: activeSlide, animated: false });
    }
  }, [carouselRef]);

  // Show/hide cheat sheet
  const handleCheatSheet = () => {
    if (isWeb) {
      setIsWebCheatSheet(!isWebCheatSheet);
      setIsCoworkerList(false);
    } else {
      navigation.navigate('cheatSheet');
    }
  };

  // Show/hide coworker list
  const handleCoworkerList = () => {
    if (isWeb) {
      setIsCoworkerList(!isCoworkerList);
      setIsWebCheatSheet(false);
    } else {
      navigation.navigate('evaluationCoworkers');
    }
  };

  // Handle carousel width
  const handleLayout = (event: LayoutChangeEvent) => {
    const { width: stepperWidth } = event.nativeEvent.layout;
    setCarouselWidth(stepperWidth);
    setTimeout(() => {
      if (carouselRef?.current) {
        carouselRef.current.scrollTo({ index: activeSlide, animated: false });
      }
    }, 400);
  };

  const navigateHome = () => {
    navigation.goBack();
  };

  const closeWebCheatSheet = () => {
    setIsWebCheatSheet(false);
  };

  return (
    <View style={[styles.container, { backgroundColor: colors.background }]}>
      <View style={styles.stepperFlex}>
        <SafeAreaView style={styles.header}>
          <View style={styles.evaluationHeader}>
            <TouchableOpacity style={styles.headerButton} onPress={navigateHome} activeOpacity={0.5}>
              <Pressable>
                <Ionicons name={isWeb ? 'ios-chevron-back' : 'ios-chevron-down'} size={32} color={colors.onBackground} />
              </Pressable>
            </TouchableOpacity>
            <View style={styles.row}>
              <TouchableOpacity style={styles.headerButton} onPress={handleCheatSheet} activeOpacity={0.5}>
                <Pressable>
                  <Ionicons name='help-circle-outline' size={32} color={colors.onBackground} />
                </Pressable>
              </TouchableOpacity>
              <TouchableOpacity style={styles.headerButton} onPress={handleCoworkerList} activeOpacity={0.5}>
                <Pressable>
                  <Ionicons name='ios-list' size={32} color={colors.onBackground} />
                </Pressable>
              </TouchableOpacity>
            </View>
          </View>
        </SafeAreaView>

        {isWeb && ( // Web - slide in cheat sheet
          <>
            {isDesktop && ( // Static slide in
              <SlideInView isOpen={isWebCheatSheet} setIsOpen={setIsWebCheatSheet} isCloseButtonRight>
                <CheatSheet />
              </SlideInView>
            )}
            {!isDesktop && ( // Absolute slide over
              <SlideOver isOpen={isWebCheatSheet} maxWidth={500} close={closeWebCheatSheet}>
                <CheatSheet width={cheatSheetWidth} />
              </SlideOver>
            )}
          </>
        )}

        {/* Carousel */}
        <View style={styles.evaluationStepper} onLayout={handleLayout}>
          <EvaluationStepper
            carouselWidth={carouselWidth}
            activeSlide={activeSlide}
            setActiveSlide={setActiveSlide}
          />
        </View>

        {/* Web - slide in coworkerlist */}
        {isWeb && <WebCoworkerList isCoworkerList={isCoworkerList} setIsCoworkerList={setIsCoworkerList} />}

      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    overflow: 'hidden',
  },
  header: {
    paddingTop: -10,
    position: 'absolute',
    left: 0,
    right: 0,
    zIndex: 100,
  },
  evaluationHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    height: 60,
    zIndex: 100,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 3,
  },
  headerButton: {
    height: 40,
    width: 40,
    alignItems: 'center',
    justifyContent: 'center',
  },
  stepperFlex: {
    flexDirection: 'row',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  evaluationStepper: {
    flex: 1,
    alignSelf: 'center',
    zIndex: 10,
  },
});

export default EvaluationScreen;
