import React from 'react';
import { StyleSheet, useWindowDimensions } from 'react-native';
import { useTheme } from 'react-native-paper';
import { ScrollView } from 'react-native-gesture-handler';
import useMediaQueries from '../../hooks/useMediaQueries';
import UserTerms from './components/UserTerms';

const UserTermsScreen = () => {
  const { colors } = useTheme();
  const { height } = useWindowDimensions();
  const { isDesktop } = useMediaQueries();

  return (
    <ScrollView style={[
      styles.infoContainer,
      { backgroundColor: colors.background },
      isDesktop && {
        flex: 1,
        minHeight: height,
      }]}
    >
      <UserTerms />
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  infoContainer: {
    paddingTop: 50,
    paddingBottom: 100,
    gap: 16,
  },
});

export default UserTermsScreen;
