import React from 'react';
import {
  View, Text, StyleSheet, ViewStyle,
} from 'react-native';
import { DepartmentManager } from '../../../types/generated.types';

type ManagerTagProps = {
  managers?: DepartmentManager[];
  containerStyle?: ViewStyle;
};

const DepartmentManagers: React.FC<ManagerTagProps> = ({ managers, containerStyle }) => (
  <View style={[styles.container, containerStyle]}>
    {managers?.map((manager) => (
      <Text key={manager.userId} style={styles.tag}>{`${manager.firstName} ${manager.lastName}`}</Text>
    ))}

  </View>
);

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 4,
  },
  tag: {
    textTransform: 'uppercase',
    fontSize: 12,
    color: '#5F5E5E',
    marginRight: 2,
    paddingRight: 4,
    // borderWidth: 1,
    // borderRadius: 15,
  },
});

export default DepartmentManagers;
