import React from 'react';
import {
  View, StyleSheet, Pressable, Text,
} from 'react-native';
import type { IconName } from '@types';
import ArendraIcon from '../../../components/ArendraIcon';

type ArendraModelIconProps = {
  name: IconName;
  color?: string;
  height: string | number;
  width?: string | number;
  x: string | number;
  y: string | number;
  fontSize?: number;
  isInnerBorder?: boolean;
  onPress?: () => void;
  badge?: { size: number | string, color: string, value: number };
};

const ArendraModelIcon: React.FC<ArendraModelIconProps> = ({
  name, height, width, color, x, y, fontSize, isInnerBorder, onPress, badge,
}) => {
  const [containerHeight, setContainerHeight] = React.useState(0);
  return (
    <Pressable
      onPress={onPress}
      onLayout={(event) => setContainerHeight(event.nativeEvent.layout.height)}
      style={[styles.container, {
        height,
        width,
        top: y,
        left: x,
        borderRadius: containerHeight / 2,
      }]}
    >
      <View style={[styles.iconContainer, {
        borderRadius: containerHeight / 2,
        borderWidth: isInnerBorder ? 6 : 0,
        borderColor: '#EEEAE6',
      }]}
      >
        <ArendraIcon
          name={name}
          size={fontSize ?? containerHeight}
          color={color}
        />
        {badge && (
          <View style={[styles.badgeStyles, {
            backgroundColor: badge.color,
            height: badge.size,
            minWidth: badge.size,
            shadowColor: badge.color,
            shadowOffset: {
              width: 1,
              height: 2,
            },
            shadowOpacity: 0.5,
            shadowRadius: 6,
            elevation: 5,
          }]}
          >
            <Text style={styles.badgeValue}>
              {badge.value}
            </Text>
          </View>
        )}
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    aspectRatio: 1,
    borderWidth: 3,
    borderColor: '#F6F4F2',
  },
  iconContainer: {
    flex: 1,
    aspectRatio: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
  },
  badgeStyles: {
    position: 'absolute',
    bottom: -3,
    right: -3,
    borderRadius: 6,
    justifyContent: 'center',
    alignItems: 'center',
  },
  badgeValue: {
    fontWeight: '600',
    textAlign: 'center',
    color: '#fff',
    fontSize: 12,
    paddingHorizontal: 3,
  },
});

export default ArendraModelIcon;
