import React from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { Text as PaperText } from 'react-native-paper';
import useTranslation from '../../../hooks/useTranslation';

type CoworkersListHeaderProps = unknown;

const CoworkersListHeader: React.FC<CoworkersListHeaderProps> = () => {
  const { screen } = useTranslation();
  return (
    <View style={styles.container}>
      <PaperText style={styles.title}>{screen.evaluationCoworkers.title}</PaperText>
      <Text style={styles.description}>{screen.evaluationCoworkers.description}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 20,
  },
  title: {
    fontSize: 26,
    marginBottom: 10,
    textAlign: 'center',
  },
  description: {
    lineHeight: 22,
    textAlign: 'center',
  },
});

export default CoworkersListHeader;
