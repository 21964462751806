import type { AddDepartmentMutation, AddDepartmentMutationVariables } from '@types';
import React, { useCallback, useEffect, useState } from 'react';
import { View, Text, StyleSheet, Platform } from 'react-native';
import { Button, useTheme } from 'react-native-paper';
import { useMutation } from '@apollo/client';
import StyledTextInput from '../../../../components/StyledTextInput';
import useMediaQueries from '../../../../hooks/useMediaQueries';
import { addDepartmentMutation, companyQuery } from '../../../../queries';
import FormErrorMessage from '../../../../components/FormErrorMessage';
import useTranslation from '../../../../hooks/useTranslation';

type AddDepartmentProps = {
  title: string
  nestedMargin: number;
  departmentParentId: string;
  companyId: string;
  setIsAddDepartmentOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsCollapsed?: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddDepartment: React.FC<AddDepartmentProps> = ({
  title, nestedMargin, departmentParentId, companyId, setIsAddDepartmentOpen, setIsCollapsed,
}) => {
  const { colors } = useTheme();
  const { screen, clientErrors } = useTranslation();
  const { isTablet } = useMediaQueries();

  const [addDepartment, { loading: addDepartmentLoading, error: addDepartmentError }] = useMutation<AddDepartmentMutation>(addDepartmentMutation);

  const [clientError, setClientError] = useState<string>('');
  const [newDepartment, setNewDepartment] = useState<Partial<AddDepartmentMutationVariables>>({
    departmentName: '',
  });

  const isWeb = Platform.OS === 'web';

  const isFieldsCorrect = useCallback(() => {
    if (newDepartment.departmentName === '') {
      setClientError(clientErrors.fillOutAllFields);
      return false;
    }

    return true;
  }, [clientErrors.fillOutAllFields, newDepartment.departmentName]);

  const handleAddDepartment = useCallback(() => {
    if (addDepartmentLoading) return;
    if (isFieldsCorrect()) {
      addDepartment({
        variables: {
          departmentName: newDepartment.departmentName,
          departmentParentId,
          companyId,
        },
        refetchQueries: [{ query: companyQuery, variables: { companyId } }],
      }).then(() => {
        if (setIsAddDepartmentOpen !== undefined) {
          setIsAddDepartmentOpen(false);
        }
        if (setIsCollapsed !== undefined) {
          setIsCollapsed(true);
        }
      }).catch((err) => {
        console.log(err);
      });
    }
  }, [addDepartment, addDepartmentLoading, companyId, departmentParentId, isFieldsCorrect, newDepartment.departmentName, setIsAddDepartmentOpen, setIsCollapsed]);

  const handleKeyPress = useCallback((e: any) => {
    if (e.key === 'Enter') {
      handleAddDepartment();
    }
  }, [handleAddDepartment]);

  useEffect(() => {
    if (!isWeb) return;
    if (!newDepartment.departmentName) {
      document.removeEventListener('keypress', handleKeyPress);
    } else {
      document.addEventListener('keypress', handleKeyPress);
    }
    return () => {
      document.removeEventListener('keypress', handleKeyPress);
    };
  }, [newDepartment, handleKeyPress, isWeb]);

  return (
    <View style={{ marginLeft: nestedMargin }}>
      <View style={[styles.departmentEdit, {
        flexDirection: isTablet ? 'row' : 'column',
        alignItems: isTablet ? 'center' : undefined,
      }]}
      >
        <View style={{ flex: isTablet ? 1 : undefined }}>
          <Text style={styles.label}>{title}</Text>
          <View style={{ flexDirection: 'row', gap: 8 }}>
            <StyledTextInput
              value={newDepartment.departmentName}
              onChangeText={(text) => setNewDepartment({ ...newDepartment, departmentName: text })}
              inputStyle={{ padding: 12 }}
              containerStyle={{ flex: 1 }}
            />
            {setIsAddDepartmentOpen !== undefined && (
              <Button
                mode='contained'
                onPress={() => setIsAddDepartmentOpen(false)}
                buttonColor={colors.secondary}
                textColor={colors.onSecondary}
                uppercase={false}
                contentStyle={{ paddingHorizontal: 10, height: 41 }}
              >
                {screen.superAdmin.cancel}
              </Button>
            )}
            <Button
              mode='contained'
              onPress={handleAddDepartment}
              buttonColor={colors.primary}
              textColor={colors.onPrimary}
              uppercase={false}
              contentStyle={{ paddingHorizontal: 10, height: 41 }}
            >
              {screen.superAdmin.save}
            </Button>
          </View>
        </View>
      </View>

      <View style={[styles.error]}>
        {clientError !== '' ? <FormErrorMessage message={clientError} /> : addDepartmentError && <FormErrorMessage message={addDepartmentError.message} />}
      </View>

    </View>
  );
};

const styles = StyleSheet.create({
  departmentEdit: {
    paddingVertical: 10,
    paddingRight: 20,
    gap: 8,
  },
  label: {
    fontWeight: '500',
    marginBottom: 4,
  },
  error: {
    marginBottom: 10,
  },
});

export default AddDepartment;
