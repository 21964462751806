import React, { useContext } from 'react';

import {
  ApolloProvider, ApolloClient, ApolloLink, Observable, HttpLink,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import inMemoryCache from './cache';
import AuthContext from '../contexts/AuthContext';

type ApolloProps = {
  children: React.ReactNode;
  serverUrl: string;
}

const Apollo: React.FC<ApolloProps> = ({ children, serverUrl }) => {
  const { token, logout } = useContext(AuthContext);

  const errorLink = onError(({
    graphQLErrors, networkError, response, operation,
  }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) => {
        if (message === 'Du är inte inloggad!'
          || message === 'Context creation failed: Your session expired. Sign in again.'
          || message === 'Din session har gått ut. Logga in igen.'
          || message.includes('användare hittas inte')) {
          // we should login again when this happens!
          console.log('GraphQL error handling: ', message);
          logout();
        }
        return message;
      });
    } else if (networkError) {
      console.warn(`[Network error]: ${networkError}`);
    } else {
      console.log('errorLink - blev inget error =) ');
    }
  });

  const request = (operation) => {
    const authToken = token;
    if (authToken !== undefined && authToken !== null) {
      operation.setContext({
        headers: {
          token: `${authToken}`,
        },
      });
    }
  };

  const requestLink = new ApolloLink((operation, forward) => new Observable((observer) => {
    let handle;
    Promise.resolve(operation)
      .then((oper) => request(oper))
      .then(() => {
        handle = forward(operation).subscribe({
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        });
      })
      .catch(observer.error.bind(observer));

    return () => {
      if (handle) handle.unsubscribe();
    };
  }));

  const httpLink = new HttpLink({
    uri: `${serverUrl}graphql`,
    credentials: 'same-origin',
  });

  const client = new ApolloClient({
    uri: `${serverUrl}graphql`,
    link: ApolloLink.from([
      errorLink,
      requestLink,
      httpLink,
    ]),
    cache: inMemoryCache,
  });

  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  );
};

export default Apollo;
