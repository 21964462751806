import type {
  DepartmentCoworker, EvaluateArgs, StatusBasisInput,
} from '@types';
import React, { useEffect, useState } from 'react';
import {
  View, StyleSheet, Pressable, Text,
} from 'react-native';
import { Button, useTheme, Checkbox } from 'react-native-paper';
import ListItem from '../../../components/ListItem';
import useTranslation from '../../../hooks/useTranslation';
import Box from '../../../components/Box';

type StatusBasisProps = {
  localStatus?: number;
  evaluate: (args: EvaluateArgs) => void;
  coworker: DepartmentCoworker;
};

const StatusBasis: React.FC<StatusBasisProps> = ({
  localStatus, evaluate, coworker,
}) => {
  const { colors } = useTheme();
  const { screen } = useTranslation();

  const [basisValue, setBasisValue] = useState<StatusBasisInput>({
    workPerformance: false,
    followLawsAndDecisions: false,
    workEnvironmentResponsibility: false,
    dutyOfLoyalty: false,
    dutyToCooperate: false,
  });

  useEffect(() => {
    if (coworker.todaysEstimation?.statusBasis?.length) {
      setBasisValue({
        workPerformance: coworker.todaysEstimation.statusBasis[0].workPerformance,
        followLawsAndDecisions: coworker.todaysEstimation.statusBasis[0].followLawsAndDecisions,
        workEnvironmentResponsibility: coworker.todaysEstimation.statusBasis[0].workEnvironmentResponsibility,
        dutyOfLoyalty: coworker.todaysEstimation.statusBasis[0].dutyOfLoyalty,
        dutyToCooperate: coworker.todaysEstimation.statusBasis[0].dutyToCooperate,
      });
    }
  }, [coworker.todaysEstimation]);

  const handleStatusBasis = () => {
    evaluate({ status: localStatus, statusBasis: basisValue });
  };

  const handleSkip = () => {
    evaluate({ status: localStatus });
  };

  return (
    <View style={styles.statusBasisContainer}>
      <View style={{ flex: 1 }}>
        <Box>
          <Pressable onPress={() => setBasisValue({ ...basisValue, workPerformance: !basisValue.workPerformance })}>
            <ListItem isBorderBottom style={{ padding: 14 }}>
              <Checkbox.Android
                status={basisValue.workPerformance ? 'checked' : 'unchecked'}
                color={colors.primary}
              />
              <Text style={styles.label}>{screen.evaluation.workPerformance}</Text>
            </ListItem>
          </Pressable>
          <Pressable onPress={() => setBasisValue({ ...basisValue, followLawsAndDecisions: !basisValue.followLawsAndDecisions })}>
            <ListItem isBorderBottom style={{ padding: 14 }}>
              <Checkbox.Android
                status={basisValue.followLawsAndDecisions ? 'checked' : 'unchecked'}
                color={colors.primary}
              />
              <Text style={styles.label}>{screen.evaluation.followLawsAndDecisions}</Text>
            </ListItem>
          </Pressable>
          <Pressable onPress={() => setBasisValue({ ...basisValue, workEnvironmentResponsibility: !basisValue.workEnvironmentResponsibility })}>
            <ListItem isBorderBottom style={{ padding: 14 }}>
              <Checkbox.Android
                status={basisValue.workEnvironmentResponsibility ? 'checked' : 'unchecked'}
                color={colors.primary}
              />
              <Text style={styles.label}>{screen.evaluation.workEnvironmentResponsibility}</Text>
            </ListItem>
          </Pressable>
          <Pressable onPress={() => setBasisValue({ ...basisValue, dutyOfLoyalty: !basisValue.dutyOfLoyalty })}>
            <ListItem isBorderBottom style={{ padding: 14 }}>
              <Checkbox.Android
                status={basisValue.dutyOfLoyalty ? 'checked' : 'unchecked'}
                color={colors.primary}
              />
              <Text style={styles.label}>{screen.evaluation.dutyOfLoyalty}</Text>
            </ListItem>
          </Pressable>
          <Pressable onPress={() => setBasisValue({ ...basisValue, dutyToCooperate: !basisValue.dutyToCooperate })}>
            <ListItem style={{ padding: 14 }}>
              <Checkbox.Android
                status={basisValue.dutyToCooperate ? 'checked' : 'unchecked'}
                color={colors.primary}
              />
              <Text style={styles.label}>{screen.evaluation.dutyToCooperate}</Text>
            </ListItem>
          </Pressable>
        </Box>
      </View>
      <View style={styles.buttonRow}>
        <Button
          mode='outlined'
          onPress={handleSkip}
          textColor='#000'
          uppercase={false}
          contentStyle={styles.button}
        >
          {screen.evaluation.skip}
        </Button>
        <Button
          mode='contained'
          onPress={handleStatusBasis}
          buttonColor={colors.secondary}
          textColor={colors.onSecondary}
          uppercase={false}
          contentStyle={styles.button}
        >
          {screen.evaluation.save}
        </Button>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  statusBasisContainer: {
    flex: 1,
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    paddingBottom: 16,
    paddingTop: 10,
  },
  label: {
    fontSize: 16,
    lineHeight: 26,
    marginRight: 'auto',
  },
  buttonRow: {
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 16,
    alignItems: 'center',
  },
  button: {
    minWidth: 120,
    height: 42,
  },
});

export default StatusBasis;
