import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { StyleSheet, Image, View } from 'react-native';
import { Text, ActivityIndicator, useTheme } from 'react-native-paper';
import {
  NavigationProp, useFocusEffect, useIsFocused, useNavigation,
} from '@react-navigation/native';

import { useQuery } from '@apollo/client';

import { BankIdLoginStatus, BankIdLoginStatusQuery } from '../../types/generated.types';
import { bankIdLoginStatusQuery } from '../../queries';
import AuthContext from '../../contexts/AuthContext';
import { BankIDUser, RootStackParamList } from '../../types/types';
import ArendraStorLogga from '../../assets/images/Arendra_stor_logga.gif';

type BankIdPollingScreenProps = {
  route: {
    params: {
      orderRef: string;
    };
  };
};

const BankIdPollingScreen: React.FC<BankIdPollingScreenProps> = ({ route }) => {
  const { setToken: setTokenInAuthContext, devicePushToken } = useContext(AuthContext);
  const { colors } = useTheme();

  const [orderRef, setOrderRef] = useState<string | null>(route.params.orderRef);
  const [isPolling, setIsPolling] = useState<boolean>(false);

  const navigation = useNavigation<NavigationProp<RootStackParamList>>();

  let canOpenBankId = false;

  const {
    data: bankIdStatusData,
    loading: bankIdStatusLoading,
    error: bankIdStatusError,
    startPolling: startPollingForBankIdStatus,
    stopPolling: stopPollingForBankIdStatus,
  } = useQuery<BankIdLoginStatusQuery>(bankIdLoginStatusQuery, { variables: { orderRef } });

  const changeCanOpen = useCallback((newValue: boolean) => {
    canOpenBankId = newValue;
  }, [canOpenBankId]);

  const isFocused = useIsFocused();

  /**
   * @description We should only start to poll if we
   * are not already polling
   * are are focused on the screen
   * have an orderRef
   */
  const shouldStartPolling = useCallback((): boolean => {
    if (
      !isPolling
      && orderRef
      && orderRef !== ''
      && isFocused
      && bankIdStatusData
      && bankIdStatusData?.bankIdLoginStatus.status === 'pending'
    ) {
      return true;
    }
    return false;
  }, [bankIdStatusData, isFocused, isPolling, orderRef]);

  useFocusEffect(
    useCallback(() => {
      console.log('useFocusEffect');
      changeCanOpen(false);
      const shouldPoll = shouldStartPolling();
      if (shouldPoll) {
        void startPollingForBankIdStatus(2000);
        setIsPolling(true);
      }
    }, [changeCanOpen, startPollingForBankIdStatus, shouldStartPolling]),
  );

  const handleDataNotPending = useCallback((bankIdLoginStatus: BankIdLoginStatus) => {
    console.log('handleDataNotPending');
    /** since we got data that idicate we are no longer pending we should stop polling */
    stopPollingForBankIdStatus();
    setIsPolling(false);

    /** Now we have data to either login or to display the terms and conditions. */
    if (bankIdLoginStatus.status === 'notAcceptedTermsAndConditions') {
      if (bankIdLoginStatus.token) {
        setTokenInAuthContext(bankIdLoginStatus?.token);

        const recievedUserInfo: BankIDUser = {
          givenName: bankIdLoginStatus.givenName ? bankIdLoginStatus.givenName : 'Unknown',
          surname: bankIdLoginStatus.surname ? bankIdLoginStatus.surname : 'Unknown',
          name: bankIdLoginStatus.name ? bankIdLoginStatus.name : 'Unknown',
          personalNumber: bankIdLoginStatus.personalNumber ? bankIdLoginStatus.personalNumber : 'Unknown',
        };

        navigation.navigate('terms', { bankIdUser: recievedUserInfo });
      }
    } else if (bankIdLoginStatus.status === 'complete') {
      if (bankIdLoginStatus.token) {
        setTokenInAuthContext(bankIdLoginStatus?.token);
      }
    } else {
      console.log('We did not get a status that is notAcceptedTermsAndConditions or complete and ended up here...');
      navigation.goBack();
    }
  }, [setTokenInAuthContext, stopPollingForBankIdStatus, navigation]);

  useEffect(() => {
    /** If we get data */
    if (bankIdStatusData && bankIdStatusData.bankIdLoginStatus) {
      console.log('useEffect - if we get data');
      const { bankIdLoginStatus } = bankIdStatusData;
      if (bankIdLoginStatus.status !== 'pending') {
        if (bankIdLoginStatus !== undefined) {
          handleDataNotPending(bankIdLoginStatus);
        }
      }
    }
  }, [bankIdStatusData, stopPollingForBankIdStatus, handleDataNotPending]);

  useEffect(() => {
    if (bankIdStatusError) {
      console.log('useEffect - bankIdStatusError');
      stopPollingForBankIdStatus();
      setIsPolling(false);
    }
  }, [bankIdStatusError, stopPollingForBankIdStatus]);

  return (
    <View style={[styles.container, { backgroundColor: colors.background }]}>
      <Image
        style={styles.loadingImage}
        source={ArendraStorLogga}
      />
      {/* {bankIdStatusLoading ? (
        <ActivityIndicator style={{ marginLeft: 10 }} color='blue' />
      ) : null} */}

      {/* {bankIdStatusError ? <Text style={styles.error}>{`LoginError: ${bankIdStatusError}`}</Text> : null} */}
    </View>
  );
};

export default BankIdPollingScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  loadingImage: {
    width: 350, height: 200, resizeMode: 'contain',
  },
  error: {
    color: 'white',
    fontSize: 12,
  },
});
