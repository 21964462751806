import { InMemoryCache, Reference } from '@apollo/client';

const inMemoryCache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        quizResults: {
          keyArgs: ['categoryId', 'subCategoryId', 'studyCountries', 'merit', 'hp'],
          merge(existing = [], incoming) {
            return [...existing, ...incoming];
          },
        },
      },
    },
  },
});

export default inMemoryCache;
