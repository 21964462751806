import React from 'react';
import { StyleSheet, View, useWindowDimensions } from 'react-native';
import CheatSheet from '../EvaluationScreen/components/CheatSheet';

const CheatSheetScreen = () => {
  const { width } = useWindowDimensions();
  return (
    <View style={styles.container}>
      <CheatSheet width={width} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    paddingVertical: 30,
  },
});

export default CheatSheetScreen;
