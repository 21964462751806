import React, {
  useMemo, ReactNode, useState, createContext,
} from 'react';

type SlideOverContextType = {
  slideOver: 'act' | 'addCoworker' | 'editCoworker' | undefined;
  setSlideOver: React.Dispatch<React.SetStateAction<'act' | 'addCoworker' | 'editCoworker' | undefined>>;
};

const SlideOverContext = createContext<SlideOverContextType>({
  slideOver: undefined,
  setSlideOver: () => { },
});

type SlideOverProviderProps = {
  children: ReactNode;
};

export const SlideOverProvider: React.FC<SlideOverProviderProps> = ({ children }) => {
  const [slideOver, setSlideOver] = useState<'act' | 'addCoworker' | 'editCoworker'>();

  const state = useMemo(() => ({
    slideOver, setSlideOver,
  }), [slideOver, setSlideOver]);

  return (
    <SlideOverContext.Provider value={state}>
      {children}
    </SlideOverContext.Provider>
  );
};

export default SlideOverContext;
