import { Buffer } from 'buffer';
import type { CoworkerEstimation, CoworkerWithAverages, Regex } from '@types';

type JWT = {
  exp: number;
  _id: string;
  iat: string;
  givenName?: string;
  surname?: string;
  acceptedTermsVersion: number | null;
};

export function parseJwt(token: string): JWT {
  if (token !== '') {
    const ret = JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
    return ret;
  }
  return {
    exp: 0,
    _id: '',
    iat: '',
    acceptedTermsVersion: null,
  };
}

/** */
export const getColorFromPercent = (percent?: number): string => {
  /** Add to green, yellow or orange
     * BREAKPOINTS
     * green = 100
     * yellow = 80
     * orange = 50
     * We add the the coworker to the color that is the closest, which means
     * greenLow > 100-80 / 2 = 90
     * yellowHigh < 100-80 / 2 = 90
     * yellowLow > 80-50 / 2 = 65
     * orangeHigh < 80-50 / 2 = 65
     */
  const turnsGreen = 90;
  const turnsOrange = 65;

  if (!percent || percent <= 0) {
    return 'gray';
  }

  if (percent >= turnsGreen) {
    return 'green';
  }
  if (percent >= turnsOrange) {
    return 'yellow';
  }

  return 'orange';
};

export const regex: Regex = {
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};

export const getDate3MonthsAgo = () => {
  const d = new Date();
  d.setMonth(d.getMonth() - 3);
  return d;
};
