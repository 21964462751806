import React, { useContext } from 'react';
import {
  View, Text, StyleSheet, Platform, useWindowDimensions,
} from 'react-native';
import { useTheme, Text as PaperText, Button } from 'react-native-paper';
import { Ionicons } from '@expo/vector-icons';
import useTranslation from '../../../hooks/useTranslation';
import SlideOverContext from '../../../contexts/SlideOverContext';
import SaveFileAsXLSX from './SaveFileAsXLSX.web';
// import SaveFileAsPDF from './SaveFileAsPDF.web';

type CoworkersHeaderProps = {
  departmentName?: string;
};

const CoworkersHeader: React.FC<CoworkersHeaderProps> = ({ departmentName }) => {
  const { screen } = useTranslation();
  const { colors } = useTheme();
  const { width } = useWindowDimensions();
  const { setSlideOver } = useContext(SlideOverContext);

  const isWeb = Platform.OS === 'web';
  const isMobileScreen = width < 568;

  const handleAddCoworker = () => {
    setSlideOver('addCoworker');
  };

  return (
    <View style={[styles.headerRow, isWeb && isMobileScreen && { flexDirection: 'column', alignItems: 'flex-start' }]}>
      <View style={styles.headerName}>
        <PaperText style={[styles.headerColumnTitle, { marginRight: 5 }]}>{screen.department.coworker}</PaperText>
      </View>
      {!isWeb ? (
        <>
          <Text style={[styles.headerColumnText, { paddingLeft: 0 }]}>
            {screen.department.today}
          </Text>
          <Text style={[styles.headerColumnText, { paddingRight: 22 }]}>{screen.department.status}</Text>
        </>
      )
        : (
          <View style={{ flexDirection: 'row', gap: 16, paddingVertical: 10 }}>
            <SaveFileAsXLSX xlsxType='Coworkers' departmentName={departmentName} />
            {/* <SaveFileAsPDF departmentName={departmentName} /> */}
            <Button mode='outlined' onPress={handleAddCoworker} style={{ borderRadius: 20 }} buttonColor={colors.secondary} textColor={colors.onSecondary} uppercase={false}>
              {screen.department.addCoworker}
              <View style={{ bottom: -1 }}><Ionicons name='add-sharp' size={16} color={colors.onSecondary} /></View>
            </Button>
          </View>
        )}
    </View>
  );
};

const styles = StyleSheet.create({
  headerRow: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 24,
    paddingTop: 20,
    paddingBottom: 6,
    paddingHorizontal: 20,
  },
  headerName: {
    flex: 3,
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerColumnTitle: {
    alignContent: 'center',
    justifyContent: 'center',
    fontSize: 20,
  },
  headerColumnText: {
    flex: 1,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: 12,
    alignSelf: 'center',
    alignItems: 'center',
  },
});

export default CoworkersHeader;
