import React, {
  useCallback,
  useContext, useEffect, useState,
} from 'react';
import { StyleSheet, Image, View } from 'react-native';
import {
  Text, Button, useTheme,
} from 'react-native-paper';

import { useMutation, useQuery } from '@apollo/client';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import QRCode from 'react-native-qrcode-svg';
import useTranslation from '../../../hooks/useTranslation';
import { BankIdLoginStatusWithQr, BankIdLoginStatusWithQrQuery, LoginWithBankIdMutation } from '../../../types/generated.types';
import { bankIdLoginStatusWithQRQuery, loginWithBankIdMutation } from '../../../queries';
import AuthContext from '../../../contexts/AuthContext';
import { BankIDUser, RootStackParamList } from '../../../types/types';
import i18n from '../../../localization';

type BankIdLoginProps = {
  route: any;
  showAsButton?: boolean;
}

const BankIdQrLogin: React.FC<BankIdLoginProps> = ({ route, showAsButton = false }) => {
  const { setToken: setTokenInAuthContext, devicePushToken } = useContext(AuthContext);

  const [isLoggingInWithBankId, setIsLoggingInWithBankId] = useState<boolean>(false);

  const [orderRef, setOrderRef] = useState<string | null>(null);
  const [qrCode, setQrCode] = useState<string | null>(null);
  // const [qrCode, setQrCode] = useState<string | null>('bankid.67df3917-fa0d-44e5-b327-edcc928297f8.1.949d559bf23403952a94d103e67743126381eda00f0b3cbddbf7c96b1adcbce2');

  const { screen } = useTranslation();
  const theme = useTheme();
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  const [isPolling, setIsPolling] = useState<boolean>(false);
  const [isSessionExpired, setIsSessionExpired] = useState<boolean>(false);

  const [bankIdErrorMessage, setBankIdErrorMessage] = useState<string | null>(null);

  const {
    data: bankIdStatusData, loading: bankIdStatusLoading, error: bankIdStatusError, startPolling: startPollingForBankIdStatus, stopPolling: stopPollingForBankIdStatus,
  } = useQuery<BankIdLoginStatusWithQrQuery>(bankIdLoginStatusWithQRQuery, { variables: { orderRef } });

  const [getBankIdSession, { data: bankIdSessionData, loading: bankIdSessionLoading, error: bankIdSessionError }] = useMutation<LoginWithBankIdMutation>(loginWithBankIdMutation);

  const shouldStartPolling = useCallback((): boolean => {
    if (
      !isPolling
      && orderRef
      && orderRef !== ''
      && bankIdSessionData
      && !isSessionExpired
    ) {
      return true;
    }
    return false;
  }, [isPolling, orderRef, bankIdSessionData, isSessionExpired]);

  const beginLogin = () => {
    setIsSessionExpired(false);
    void getBankIdSession();
  };

  useEffect(() => {
    if (bankIdSessionData) {
      console.log('WE GOT NEW SESSION DATA: ', bankIdSessionData);
      const { loginWithBankId } = bankIdSessionData;
      const {
        qrData, orderRef: oRef,
      } = loginWithBankId;

      setOrderRef(oRef);
      setQrCode(qrData);
    }
  }, [bankIdSessionData, bankIdSessionError, bankIdSessionLoading]);

  const stopPolling = useCallback(() => {
    setIsSessionExpired(true);
    stopPollingForBankIdStatus();
    setIsPolling(false);
    setQrCode(null);
  }, [setIsSessionExpired, stopPollingForBankIdStatus, setIsPolling]);

  const handleDataNotPending = useCallback((bankIdLoginStatus: BankIdLoginStatusWithQr) => {
    /** since we got data that idicate we are no longer pending we should stop polling */
    stopPolling();

    /** Now we have data to either login or to display the terms and conditions. */
    if (bankIdLoginStatus.status === 'notAcceptedTermsAndConditions') {
      if (bankIdLoginStatus.token) {
        setTokenInAuthContext(bankIdLoginStatus?.token);

        const recievedUserInfo: BankIDUser = {
          givenName: bankIdLoginStatus.givenName ? bankIdLoginStatus.givenName : 'Unknown',
          surname: bankIdLoginStatus.surname ? bankIdLoginStatus.surname : 'Unknown',
          name: bankIdLoginStatus.name ? bankIdLoginStatus.name : 'Unknown',
          personalNumber: bankIdLoginStatus.personalNumber ? bankIdLoginStatus.personalNumber : 'Unknown',
        };

        navigation.navigate('terms', { bankIdUser: recievedUserInfo });
      }
    } else if (bankIdLoginStatus.status === 'complete') {
      if (bankIdLoginStatus.token) {
        setTokenInAuthContext(bankIdLoginStatus?.token);
      }
    } else {
      console.log('We did not get a status that is notAcceptedTermsAndConditions or complete and ended up here...');
    }
  }, [setTokenInAuthContext, navigation, stopPolling]);

  useEffect(() => {
    /** If we get data */
    if (bankIdStatusData && bankIdStatusData.bankIdLoginStatusWithQR) {
      const { bankIdLoginStatusWithQR } = bankIdStatusData;
      if (bankIdLoginStatusWithQR.qrCodeString && bankIdLoginStatusWithQR.qrCodeString !== qrCode) {
        setQrCode(bankIdLoginStatusWithQR.qrCodeString);
      }
      if (bankIdLoginStatusWithQR.status !== 'pending') {
        if (bankIdLoginStatusWithQR !== undefined) {
          handleDataNotPending(bankIdLoginStatusWithQR);
        }
      }
    }
  }, [bankIdStatusData, stopPollingForBankIdStatus, handleDataNotPending, qrCode]);

  useEffect(() => {
    if (bankIdStatusError) {
      if (bankIdStatusError?.graphQLErrors[0]?.extensions?.code !== null && bankIdStatusError?.graphQLErrors[0]?.extensions?.code !== undefined) {
        const error: string = bankIdStatusError?.graphQLErrors[0]?.extensions?.code as string;
        const newErrorMessage = i18n.t(`bankIdErrors.${error}`);
        if (newErrorMessage !== '') {
          setBankIdErrorMessage(newErrorMessage);
        }
      }
      stopPolling();
    }
  }, [orderRef, bankIdStatusError, stopPolling]);

  /**
   * UseEffect to handle if we should start polling or not...
   */
  useEffect(() => {
    const shouldPoll = shouldStartPolling();
    if (shouldPoll) {
      void startPollingForBankIdStatus(1300);
      setIsPolling(true);
    }
  }, [setIsPolling, startPollingForBankIdStatus, shouldStartPolling]);

  const style = showAsButton ? { marginBottom: 20, borderRadius: theme.roundness, backgroundColor: theme.colors.secondary } : {};
  const contentStyle = showAsButton ? { margin: 5, paddingHorizontal: 5, flexDirection: 'row-reverse' } : {};
  const labelStyle = showAsButton ? { color: 'white', fontSize: 16 }
    : { textDecorationLine: 'underline', color: theme.colors.secondary };
  const buttonMode = showAsButton ? 'contained' : 'text';

  return (
    <View style={styles.container}>
      {/* {isPolling === true ? <Text style={styles.centerText}>{screen.login.readQrCode}</Text> : null} */}
      {isPolling === false ? (
        <Button
          onPress={beginLogin}
          uppercase={false}
          mode={buttonMode}
          style={style}
          contentStyle={contentStyle}
          labelStyle={labelStyle}
        >
          {screen.login.bankIdOnOtherDevice}
        </Button>
      ) : null}

      {qrCode !== null && !bankIdStatusError ? (
        <QRCode
          value={qrCode}
          ecl='L'
          size={186}
        />
      ) : null}
      {bankIdStatusError && bankIdErrorMessage ? (
        <Text style={styles.error}>
          {bankIdErrorMessage}
        </Text>
      ) : null}
    </View>
  );
};

export default BankIdQrLogin;
const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 19,
  },
  centerText: {
    textAlign: 'center',
    marginVertical: 10,
    fontSize: 18,
  },
  loginButton: {
    // color: 'white',
    textDecorationLine: 'underline',
  },
  error: {
    marginTop: 10,
    color: 'red',
    fontSize: 12,
  },
});
