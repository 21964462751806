import React, { useContext } from 'react';
import {
  View, Text, StyleSheet, Platform,
} from 'react-native';
import { Avatar, TouchableRipple, useTheme } from 'react-native-paper';
import Entypo from '@expo/vector-icons/Entypo';
import type { DrawerNavigationHelpers } from '@react-navigation/drawer/lib/typescript/src/types';
import AuthContext from '../../contexts/AuthContext';
import UserSettingsContext from '../../contexts/UserSettingsContext';

type UserProps = {
  navigation: DrawerNavigationHelpers;
};

const User: React.FC<UserProps> = ({ navigation }) => {
  const { tokenGivenName, tokenSurname } = useContext(AuthContext);
  const { currentDepartment, userRoles, setIsWebRoleSelectionOpen } = useContext(UserSettingsContext);
  const { colors } = useTheme();
  const initials = `${tokenGivenName[0]}${tokenSurname[0]}`;
  const isWeb = Platform.OS === 'web';

  const current = userRoles?.departmentsIManage.find((department) => department?._id === currentDepartment);

  const navigateToRoleSelection = () => {
    if (isWeb) {
      setIsWebRoleSelectionOpen(true);
      navigation.closeDrawer();
    } else {
      navigation.navigate('roleSelection');
    }
  };

  return (
    <View style={styles.container}>
      <TouchableRipple onPress={navigateToRoleSelection} style={{ borderRadius: 6 }}>
        <View style={styles.row}>
          <Avatar.Text
            label={initials}
            size={55}
            style={{ backgroundColor: colors.background }}
            labelStyle={{ fontSize: 22, color: '#707070' }}
          />
          <View style={styles.right}>
            <View style={styles.roleSelection}>
              <View style={{ gap: 6 }}>
                <Text style={styles.title}>{current?.companyName}</Text>
                <Text style={styles.sub}>{current?.name}</Text>
              </View>
              <View style={{ marginTop: 2 }}>
                <Entypo name='chevron-thin-down' size={16} />
              </View>
            </View>
          </View>
        </View>
      </TouchableRipple>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 10,
    marginBottom: 40,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 6,
    marginRight: 10,
  },
  right: {
    marginLeft: 10,
  },
  roleSelection: {
    flexDirection: 'row',
    paddingVertical: 7,
    paddingHorizontal: 6,
    gap: 6,
  },
  title: {
    fontWeight: '500',
    fontSize: 16,
  },
  sub: {
    color: '#707070',
    fontSize: 12,
    textTransform: 'uppercase',
  },
});

export default User;
