import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Text as PaperText, useTheme } from 'react-native-paper';
import useTranslation from '../../../hooks/useTranslation';
import Box from '../../../components/Box';
import StatusIcon from '../../../components/StatusIcon';

type CheatSheetSectionProps = {
  title: string;
  list: string[];
};

const CheatSheetSection: React.FC<CheatSheetSectionProps> = ({ title, list }) => {
  const { screen: { cheatSheet } } = useTranslation();
  const { colors } = useTheme();
  return (
    <Box style={{ backgroundColor: colors.background, marginHorizontal: 20, paddingBottom: 30 }}>
      <View style={{ paddingHorizontal: 16 }}>
        <PaperText style={styles.title}>{title}</PaperText>
        <View style={styles.statusRow}>
          <View style={styles.status}>
            <StatusIcon status={1} height={40} width={40} />
            <Text style={styles.statusText}>{cheatSheet.statusGreen}</Text>
          </View>
          <View style={styles.status}>
            <StatusIcon status={2} height={40} width={40} />
            <Text style={styles.statusText}>{cheatSheet.statusYellow}</Text>
          </View>
          <View style={styles.status}>
            <StatusIcon status={3} height={40} width={40} />
            <Text style={styles.statusText}>{cheatSheet.statusRed}</Text>
          </View>
        </View>
      </View>
      {list.map((text) => (
        <Text key={text} style={styles.listItem}>
          •
          {' '}
          {text}
        </Text>
      ))}
    </Box>
  );
};

const styles = StyleSheet.create({
  title: {
    fontSize: 20,
    textAlign: 'center',
    paddingVertical: 16,
  },
  statusRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 16,
    borderTopColor: '#ccc',
    borderTopWidth: 1,
  },
  status: {
    alignItems: 'center',
    gap: 2,
  },
  statusText: {
    fontWeight: '500',
  },
  listItem: {
    paddingVertical: 10,
    paddingHorizontal: 16,
  },
});

export default CheatSheetSection;
