import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import { Button, Text, useTheme } from 'react-native-paper';
import { useMutation } from '@apollo/client';
import type { AddDepartmentCoworkerMutation } from '@types';
import UserSettingsContext from '../../../contexts/UserSettingsContext';
import FormErrorMessage from '../../../components/FormErrorMessage';
import useTranslation from '../../../hooks/useTranslation';
import StyledTextInput from '../../../components/StyledTextInput';
import { addDepartmentCoworkerMutation, estimationsByDepartmentIdQuery, listCoworkersQuery } from '../../../queries';

const AddCoworker = () => {
  const theme = useTheme();
  const { screen, clientErrors } = useTranslation();
  const { currentDepartment } = useContext(UserSettingsContext);

  const [addDepartmentCoworker, {
    loading: addDepartmentCoworkerLoading,
    error: addDepartmentCoworkerError,
  }] = useMutation<AddDepartmentCoworkerMutation>(addDepartmentCoworkerMutation);

  const isWeb = Platform.OS === 'web';

  const [clientError, setClientError] = useState<string>('');
  const [coworker, setCoworker] = useState({
    personalNumber: '',
    firstName: '',
    lastName: '',
    workPercentage: '',
    salary: '',
  });

  const clearFields = () => {
    setCoworker({
      personalNumber: '',
      firstName: '',
      lastName: '',
      workPercentage: '',
      salary: '',
    });
  };

  const isFieldsCorrect = useCallback(() => {
    if (coworker.personalNumber === '' || coworker.firstName === '' || coworker.lastName === '' || coworker.workPercentage === '' || coworker.salary === '') {
      setClientError(clientErrors.fillOutAllFields);
      return false;
    }

    if (coworker.personalNumber.replace(/-/g, '').length !== 12) {
      setClientError(clientErrors.invalidPersonalNumber);
      return false;
    }

    return true;
  }, [coworker.firstName, coworker.lastName, coworker.workPercentage, coworker.personalNumber, coworker.salary]);

  const handleAddCoworker = useCallback(() => {
    if (!currentDepartment || addDepartmentCoworkerLoading) return;
    if (isFieldsCorrect()) {
      addDepartmentCoworker({
        variables: {
          departmentId: currentDepartment,
          personalNumber: coworker.personalNumber,
          firstName: coworker.firstName,
          lastName: coworker.lastName,
          workPercentage: Number(coworker.workPercentage),
          salary: Number(coworker.salary),
        },
        refetchQueries: [
          { query: listCoworkersQuery, variables: { departmentId: currentDepartment } },
          { query: estimationsByDepartmentIdQuery, variables: { departmentId: currentDepartment } },
        ],
      }).then(() => {
        clearFields();
      }).catch((err) => {
        console.log(err);
      });
    }
  }, [addDepartmentCoworkerLoading, isFieldsCorrect, addDepartmentCoworker, coworker.personalNumber, coworker.firstName, coworker.lastName, coworker.workPercentage, coworker.salary]);

  const handleKeyPress = useCallback((event) => {
    if (event.key === 'Enter') {
      handleAddCoworker();
    }
  }, [handleAddCoworker]);

  useEffect(() => {
    if (!isWeb) return;
    document.addEventListener('keypress', handleKeyPress);
    return () => {
      document.removeEventListener('keypress', handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <View style={[
      styles.container,
      !isWeb && { flex: 1 },
      isWeb && styles.containerWeb, { backgroundColor: theme.colors.background },
    ]}
    >
      <Text style={[styles.title, {
        textAlign: isWeb ? 'left' : 'center',
      }]}
      >
        {screen.addCoworker.title}
      </Text>
      <StyledTextInput
        placeholder='ÅÅÅÅMMDD-XXXX'
        value={coworker.personalNumber}
        onChangeText={(text) => setCoworker({ ...coworker, personalNumber: text })}
        keyboardType='numeric'
      />
      <StyledTextInput
        placeholder={screen.addCoworker.firstName}
        value={coworker.firstName}
        onChangeText={(text) => setCoworker({ ...coworker, firstName: text })}
      />
      <StyledTextInput
        placeholder={screen.addCoworker.lastName}
        value={coworker.lastName}
        onChangeText={(text) => setCoworker({ ...coworker, lastName: text })}
      />
      <View style={styles.row}>
        <StyledTextInput
          placeholder={screen.addCoworker.workPercentage}
          value={coworker.workPercentage}
          onChangeText={(text) => setCoworker({ ...coworker, workPercentage: text })}
          containerStyle={{ flex: 1 }}
          keyboardType='numeric'
          placeholderRight='%'
        />
        <StyledTextInput
          placeholder={screen.addCoworker.salary}
          value={coworker.salary}
          onChangeText={(text) => setCoworker({ ...coworker, salary: text })}
          containerStyle={{ flex: 1 }}
          keyboardType='numeric'
          placeholderRight={screen.coworker.SEK}
        />
      </View>

      {clientError ? <FormErrorMessage message={clientError} /> : addDepartmentCoworkerError && <FormErrorMessage message={addDepartmentCoworkerError.message} />}

      <Button
        mode='contained'
        onPress={handleAddCoworker}
        contentStyle={{ height: 50 }}
        buttonColor={theme.colors.secondary}
        textColor={theme.colors.onSecondary}
        style={[{ borderRadius: 25, minWidth: 120 }, isWeb && { marginLeft: 'auto' }]}
        uppercase={false}
        loading={addDepartmentCoworkerLoading}
      >
        {screen.addCoworker.add}
      </Button>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    gap: 16,
    padding: 20,
  },
  containerWeb: {
    marginHorizontal: 20,
    marginVertical: 40,
    borderRadius: 10,
  },
  row: {
    flexDirection: 'row',
    gap: 16,
  },
  title: {
    fontSize: 24,
    marginBottom: 10,
  },
});

export default AddCoworker;
