import React, { useContext, useEffect, useRef } from 'react';
import {
  StyleSheet, useWindowDimensions, Animated, Easing, ViewStyle, Platform,
} from 'react-native';
import { IconButton, useTheme } from 'react-native-paper';

type SlideOverProps = {
  children: React.ReactNode;
  maxWidth?: number;
  isOpen: boolean;
  // setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isFromRight?: boolean;
  style?: ViewStyle;
  closeButtonStyle?: ViewStyle;
  close: () => void;
};

const SlideOver: React.FC<SlideOverProps> = ({
  children, maxWidth, isOpen, isFromRight, style, closeButtonStyle, close,
}) => {
  const { colors } = useTheme();
  const { width } = useWindowDimensions();
  const containerWidth = width * 0.9;
  const slideAnimation = useRef(new Animated.Value(isOpen ? 0 : isFromRight ? containerWidth : -containerWidth)).current;
  const isWeb = Platform.OS === 'web';

  useEffect(() => {
    const toValue = isOpen ? 0 : isFromRight ? containerWidth : -containerWidth;

    Animated.timing(slideAnimation, {
      toValue,
      duration: 300,
      easing: Easing.inOut(Easing.quad),
      useNativeDriver: true,
    }).start();
  }, [isOpen, containerWidth]);

  // This effect fixes an issue where the slide over would not be positioned correctly when the window is resized
  useEffect(() => {
    if (!isWeb) return;

    const handleResize = () => {
      if (!isOpen) {
        const toValue = isFromRight ? containerWidth : -containerWidth;
        slideAnimation.setValue(toValue);
      }
    };

    if (!isOpen) {
      window.addEventListener('resize', handleResize);
    } else {
      window.removeEventListener('resize', handleResize);
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isOpen, isFromRight, containerWidth]);

  const handleClose = () => {
    Animated.timing(slideAnimation, {
      toValue: isFromRight ? containerWidth : -containerWidth,
      duration: 300,
      easing: Easing.inOut(Easing.quad),
      useNativeDriver: true,
    }).start(() => {
      // setIsOpen(false)
      close();
    });
  };

  return (
    <Animated.View
      style={[
        styles.container,
        {
          transform: [{ translateX: slideAnimation }],
          left: isFromRight ? undefined : 0,
          right: isFromRight ? 0 : undefined,
          width: containerWidth,
          maxWidth,
          display: isOpen ? 'flex' : 'none',
        },
        style,
      ]}
    >
      <IconButton
        icon='close'
        onPress={handleClose}
        style={[
          styles.closeButton,
          {
            left: isFromRight ? 10 : undefined,
            right: isFromRight ? undefined : 10,
            backgroundColor: colors.background,
          },
          closeButtonStyle,
        ]}
      />
      {children}
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    position: 'absolute',
    top: 0,
    bottom: 0,
    zIndex: 100000,
    shadowOpacity: 0.2,
    shadowRadius: 10,
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    zIndex: 100,
  },
});

export default SlideOver;
