import React from 'react';
import { Text, TextStyle } from 'react-native';
import useTranslation from '../hooks/useTranslation';

type CostDisplayProps = {
  cost: number;
  style?: TextStyle;
  isPerMonth?: boolean;
};

const CostDisplay: React.FC<CostDisplayProps> = ({ cost, style, isPerMonth }) => {
  const { screen } = useTranslation();
  function formatNumber(number: number) {
    if (number >= 1000) {
      if (isPerMonth) {
        return `-${(number / 1000).toFixed(1)} ${screen.department.thousandSEKPerMonth}`;
      }
      return `-${(number / 1000).toFixed(1)} ${screen.department.thousandSEK}`;
    }
    if (isPerMonth) {
      return `-${number.toFixed(1)} ${screen.department.SEKPerMonth}`;
    }
    return `-${number.toFixed(1)} ${screen.department.SEK}`;
  }

  return (
    <Text style={style}>{formatNumber(cost)}</Text>
  );
};

export default CostDisplay;
