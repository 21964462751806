import React, { useContext, useEffect, useState } from 'react';
import {
  View, StyleSheet, Text, Platform,
} from 'react-native';
import type {
  CoworkersQuery,
  DepartmentCoworker, RootStackParamList,
} from '@types';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { useQuery } from '@apollo/client';
import { useTheme } from 'react-native-paper';
import EvaluationContext from '../../../contexts/EvaluationContext';
import Box from '../../../components/Box';
import { listCoworkersQuery } from '../../../queries';
import UserSettingsContext from '../../../contexts/UserSettingsContext';
import CoworkerListItem from './GroupedCoworker';

const CoworkersList = () => {
  const { carouselRef, evaluationSteps } = useContext(EvaluationContext);
  const { colors } = useTheme();
  const { currentDepartment } = useContext(UserSettingsContext);
  const { navigate } = useNavigation<NavigationProp<RootStackParamList>>();
  const isWeb = Platform.OS === 'web';

  const { data: coworkersData } = useQuery<CoworkersQuery>(listCoworkersQuery, { variables: { departmentId: currentDepartment } });
  const [coworkersList, setCoworkersList] = useState<DepartmentCoworker[]>([]);

  useEffect(() => {
    if (!coworkersData?.department.coworkersWithEstimations) return;
    setCoworkersList(coworkersData.department.coworkersWithEstimations as DepartmentCoworker[]);
  }, [coworkersData]);

  // Group coworkers by first name
  const groupCoworkersByFirstName = () => {
    const groupedCoworkers: { [key: string]: DepartmentCoworker[] } = {};

    coworkersList.forEach((coworker) => {
      const firstLetter = coworker.firstName.charAt(0).toUpperCase();
      if (!groupedCoworkers[firstLetter]) {
        groupedCoworkers[firstLetter] = [];
      }
      groupedCoworkers[firstLetter].push(coworker);
    });

    return groupedCoworkers;
  };

  const groupedCoworkers = groupCoworkersByFirstName();

  const handleGoToCoworker = (id: string) => {
    console.log('go to coworker with ID: ', id);
    // const index = TestCoworkers.findIndex((coworker) => coworker._id === id);
    // const index = coworkersList.findIndex((coworker) => coworker.userId === id);
    console.log(evaluationSteps);
    const index = evaluationSteps.findIndex((step) => step.id === id);
    console.log('index: ', index);
    if (carouselRef?.current && index !== undefined) {
      carouselRef.current?.scrollTo({ index, animated: true });

      if (!isWeb) {
        navigate('evaluation');
      }
    }
  };

  return (
    <View style={[
      styles.container,
      !isWeb && { flex: 1, paddingBottom: 50 },
      isWeb && {
        backgroundColor: colors.background,
        borderRadius: 10,
        paddingBottom: 30,
      },
    ]}
    >
      {Object.keys(groupedCoworkers).sort().map((letter) => (
        <View key={letter}>
          <Text style={styles.header}>{letter}</Text>
          <Box>
            {groupedCoworkers[letter].map((coworker: DepartmentCoworker, index) => (
              <CoworkerListItem
                key={coworker.userId}
                coworker={coworker}
                index={index}
                letter={letter}
                groupedCoworkers={groupedCoworkers}
                goToCoworker={handleGoToCoworker}
              />
            ))}
          </Box>
        </View>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    paddingTop: 10,
  },
  header: {
    fontSize: 16,
    marginTop: 16,
    marginBottom: 3,
    marginLeft: 3,
    color: '#707070',
  },
});

export default CoworkersList;
